import { Component, OnInit } from '@angular/core';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.route.data.subscribe(async params => {
    //   this.product = params['product'];
    //   this.productId = this.product?.data[0].attributes.identifier;
    //   console.log(params);
    //   console.log('Length: ' + this.product!.data[0].attributes.key_features.length);
    // });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
