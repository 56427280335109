import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelGuides } from 'src/app/models/guide';
import { StrapiApiService } from 'src/app/services/strapi-api.service';

@Component({
  selector: 'app-channel-guides',
  templateUrl: './channel-guides.component.html',
  styleUrls: ['./channel-guides.component.css']
})
export class ChannelGuidesComponent implements OnInit {
  channelGuides!: ChannelGuides;
  first = true;
  second = false;
  third = false;
  firstSelected = true;
  secondSelecetd = false;
  thirdSelected = false;
  count: number = 1;
  num = 1;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      // this.forBusinesses = params['data'].data.attributes;
      // this.homeDetails = params['data'].data.attributes;
      // this.boxes = params['data'].data.attributes.boxes;
      // this.products = params['data'].data.attributes.products;
      this.channelGuides = params['guides'];
    });
  }

  btnClicked() {
    this.count = 1 + this.count;
    if (this.count == 1) {
      this.first = true;
      this.second = false;
      this.third = false;
      this.firstSelected = true;
      this.secondSelecetd = false;
      this.thirdSelected = false;
    }
    else if (this.count == 2) {
      this.first = true;
      this.second = true;
      this.third = false;
      this.firstSelected = false;
      this.secondSelecetd = true;
      this.thirdSelected = false;
    }
    else if (this.count == 3) {
      this.first = true;
      this.second = true;
      this.third = true;
      this.firstSelected = false;
      this.secondSelecetd = false;
      this.thirdSelected = true;
      this.count = 0;

    }

    // console.log(this.count);
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
