<app-header bg="bg-white bg-primary-50"></app-header>
<div class="absolute inset-0 lg:bottom-0 md:bottom-72">
  <img
    class="lg:h-[820px] md:h-[700px] sm:h-[600px] w-full lg:object-cover md:lg:object-cover sm:object-cover lg:object-center md: sm:object-right"
    [src]="strapiApi.getImage(this.product!.data[0].attributes.background_image.data?.attributes!.url)" alt="">
</div>

<div class="absolute inset-0 lg:bottom-0 md:bottom-72"
  *ngIf="this.productId == 'travel' || this.productId == 'personal' || this.productId == 'expat' ||
                                                          this.productId == 'cyber' || this.productId == 'smartdevice' || this.productId == 'bupa' ">
  <img
    class="lg:h-[820px] md:h-[700px] sm:h-[600px] w-full lg:object-cover md:lg:object-cover sm:object-cover lg:object-center md: sm:object-center"
    [src]="strapiApi.getImage(this.product!.data[0].attributes.background_image.data?.attributes!.url)" alt="">
</div>

<div class="p-2 w-full min-h-[610px]  items-center" dir="auto">
  <br>
  <div class="lg:max-w-3xl md:max-w-2xl sm:max-w-md  mx-auto   absolute inset-x-0 z-10 px-6 container pt-80">
    <!-- <button (click)="changeView()"> {{getLang == 'en' ? 'Change View' : 'غير المظهر' }}</button> -->
    <app-product-form id="box" [product]="this.productId"></app-product-form>
  </div>
</div>

<div class="container mx-auto  lg:max-w-7xl  md:max-w-2xl sm:max-w-md" id="content" dir="auto">

  <div *ngIf="!view">
    <!-- <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br> -->
  </div>
  <br><br>
  <div [ngClass]="this.productId == 'motor' ? 'lg:pt-[550px] md:pt-[550px] sm:pt-[800px]'  : this.productId == 'home' ? 'lg:pt-[340px] md:pt-[340px] sm:pt-[520px]' 
                  : this.productId == 'travel' ? 'lg:pt-[320px] md:pt-[350px] sm:pt-[520px]' : this.productId =='domestic' ? 'lg:pt-[600px] md:pt-[600px] sm:pt-[800px]' 
                  : this.productId =='personal' ? 'lg:pt-[420px] md:pt-[440px] sm:pt-[560px]' : this.productId == 'medical' ? 'lg:pt-[320px] md:pt-[300px] sm:pt-[500px]'
                  : this.productId =='expat' ? 'lg:pt-[320px] md:pt-[300px] sm:pt-[450px]' : this.productId =='cyber' ? 'lg:pt-[300px] md:pt-[300px] sm:pt-[360px]' 
                  : this.productId == 'smartdevice' ? 'lg:pt-[120px] md:pt-[130px] sm:pt-[200px]' : this.productId == 'bupa' ? 'lg:pt-[100px] md:pt-[110px] sm:pt-[200px]'      
                  : 'pt-[348px]'" class="pb-20 w-full bg-white">
    <!-- [style]="'padding-top:' + this.productId == 'motor' ? 552 : 306 + 'px;'" -->
    <br><br>
    <div id="Features_Section" class="mt-10">
      <div class="w-full">
        <p class="lg:text-6xl md:text-[40px] sm:text-3xl font-medium text-Dark-Gray-2">
          {{getLang == 'en' ? "Key Features" : "أهم المميزات"}}</p>
      </div>
      <div class="w-full pb-8">
        <p class="text-md font-medium text-Mid-Gray-1 mt-4">
          {{getLang == 'en' ? this.product!.data[0].attributes.description :
          this.product!.data[0].attributes.description_ar }}
        </p>
      </div>
      <div *ngIf="productId == 'life'" class="lg:grid lg:gap-4 lg:grid-cols-3 lg:grid-rows-3 md:grid md:gap-4 md:grid-cols-2 md:pb-20 sm:pb-20 lg:pb-0" id="Features" >
        <app-fatures-box 
          [ngClass]=""
          class="p-2  flex space-x-4" [img]="this.strapiApi.apiURL +  f.image.data.attributes.url"
          [first_line]="getLang == 'en' ? f.title_en : f.title_ar"
          [second_line]="getLang == 'en' ? f.description_en : f.description_ar"
          *ngFor="let f of this.product!.data[0].attributes.key_features ">
        </app-fatures-box>
      </div>

      <div *ngIf="productId != 'life'" class="lg:grid-container-lg md:grid-container-md" id="Features">
        <app-fatures-box
          [ngClass]="this.product!.data[0].attributes.key_features.length > 2 ? 'lg:grid-item-lg lg:[&:nth-child(4)]:large lg:[&:nth-child(5)]:empty md:grid-item-md md:[&:nth-child(5)]:mid  p-2  flex space-x-4' : 'lg:grid-item-lg lg:[&:nth-child(1)]:large lg:[&:nth-child(5)]:empty md:grid-item-md md:[&:nth-child(5)]:mid  p-2  flex space-x-4 lg:h-[300px]'"
          class=" " [img]="this.strapiApi.apiURL +  f.image.data.attributes.url"
          [first_line]="getLang == 'en' ? f.title_en : f.title_ar"
          [second_line]="getLang == 'en' ? f.description_en : f.description_ar"
          *ngFor="let f of this.product!.data[0].attributes.key_features ">
        </app-fatures-box>
      </div> 

    </div>
    <div id="Beneifts_section" [ngClass]="this.productId == 'life' ? '' : 'mt-48'">
      <div class="w-full">
        <p class="lg:text-6xl md:text-[40px] sm:text-3xl font-medium text-Dark-Gray-2">
          {{getLang == 'en' ? "Benefits & Requirements" : "المزايا والمتطلبات"}}
        </p>
      </div>
      <div class="md:w-3/6 sm:w-full text-justify">
        <p class="text-base font-medium text-Mid-Gray-1 mt-4">
          {{getLang == 'en' ? this.product!.data[0].attributes.benefits_description :
          this.product!.data[0].attributes.benefits_description_ar}}
        </p>
      </div>
      <div class="grid {{this.productId == 'motor' || this.productId == 'travel' || this.productId == 'medical' ? 'lg:grid-cols-4 md:grid-cols-4' : 'lg:grid-cols-2 md:grid-cols-2' }}  mt-4 pt-10 flex-col flex">

        <!-- Mobile View -->
        <div class="mt-6 lg:hidden md:hidden">
          <nav class="grid gap-y-8">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item  bg-white" *ngIf="this.productId == 'home' || this.productId == 'personal' || this.productId == 'domestic' || this.productId == 'expat'
              || this.productId == 'cyber' || this.productId == 'bupa' || this.productId == 'smartdevice' ">
                <h2 class="accordion-header mb-0" id="flush-headingOne">
                  <button class="button accordion-button
                relative
                flex
                items-center
                justify-between
                w-full
                py-4
                
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none
              
                " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                    aria-controls="flush-collapseOne" (click)="benefitsDrop()">
                    <div class="flex">
                      <span class="">
                        <svg class="sm:h-[30px] sm:w-[30px] md:h-[40px] md:w-[40px] lg:h-[40px] lg:w-[40px]"
                          viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M19.9997 3.33325C10.7997 3.33325 3.33301 10.7999 3.33301 19.9999C3.33301 29.1999 10.7997 36.6666 19.9997 36.6666C29.1997 36.6666 36.6663 29.1999 36.6663 19.9999C36.6663 10.7999 29.1997 3.33325 19.9997 3.33325ZM16.6663 28.3333L8.33301 19.9999L10.683 17.6499L16.6663 23.6166L29.3163 10.9666L31.6663 13.3333L16.6663 28.3333Z"
                            fill="#47D16C" />
                        </svg>
                      </span>
                      <label
                        class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl  ltr:ml-2 rtl:mr-2">{{getLang
                        == 'en' ? "It includes" : "يشمل"}}</label>
                    </div>

                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on question open state.
      
                        Heroicon name: outline/chevron-down
      
                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="benefitsDropDown ? 'rotate-180  w-3 transform' : 'rotate-0  w-3 transform'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="gray" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </span>
                  </button>

                </h2>
                <div [ngClass]="benefitsDropDown ? 'block' : 'hidden'"
                  class="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2" id="flush-collapseOne"
                  class="accordion-collapse border-0 collapse show pb-4 pt-0" data-bs-parent="#accordionFlushExample">
                  <nav class="grid gap-y-4 bg-white md:grid-cols-2 sm:grid-cols-1 sm:gap-x-5"
                    aria-labelledby="solutions-heading">

                    <div class="pl-7">
                      <ul class="list-disc">
                        <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                          *ngFor="let f of this.product!.data[0].attributes.benefits">
                          {{getLang == 'en' ? f.feature_en : f.feature_ar}}
                        </li>

                      </ul>

                    </div>

                  </nav>
                </div>
              </div>
              <div class="accordion-item  bg-white" *ngIf="this.productId == 'home' || this.productId == 'personal' || this.productId == 'domestic' || this.productId == 'expat'
              || this.productId == 'cyber' || this.productId == 'bupa' || this.productId == 'smartdevice' ">
                <h2 class="accordion-header mb-0" id="flush-headingOne">
                  <button class="button accordion-button
                relative
                flex
                items-center
                justify-between
                w-full
                py-4
                
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                    (click)="exDrop()" aria-expanded="false" aria-controls="flush-collapseOne">
                    <div class="flex">
                      <span><svg class="sm:h-[30px] sm:w-[30px] md:h-[40px] md:w-[40px] lg:h-[40px] lg:w-[40px]"
                          viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">

                          <path
                            d="M20 3.33301C10.7833 3.33301 3.33331 10.783 3.33331 19.9997C3.33331 29.2163 10.7833 36.6663 20 36.6663C29.2166 36.6663 36.6666 29.2163 36.6666 19.9997C36.6666 10.783 29.2166 3.33301 20 3.33301ZM28.3333 25.983L25.9833 28.333L20 22.3497L14.0166 28.333L11.6666 25.983L17.65 19.9997L11.6666 14.0163L14.0166 11.6663L20 17.6497L25.9833 11.6663L28.3333 14.0163L22.35 19.9997L28.3333 25.983Z"
                            fill="#F76659" />


                        </svg>

                      </span>
                      <label
                        class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl  ltr:ml-2 rtl:mr-2">{{getLang
                        == 'en' ? "It does not include" : "لا يشمل"}}</label>
                    </div>

                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on question open state.
      
                        Heroicon name: outline/chevron-down
      
                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="exDropDown ? 'rotate-180  w-3 transform' : 'rotate-0  w-3 transform'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="gray" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </span>
                  </button>

                </h2>
                <div [ngClass]="exDropDown ? 'block' : 'hidden'"
                  class="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2" id="flush-collapseOne"
                  class="accordion-collapse border-0 collapse show pb-4 pt-0" data-bs-parent="#accordionFlushExample">
                  <nav class="grid gap-y-4 bg-white md:grid-cols-2 sm:grid-cols-1 sm:gap-x-5"
                    aria-labelledby="solutions-heading">

                    <div class="pl-7">
                      <ul class="list-disc">
                        <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                          *ngFor="let f of this.product!.data[0].attributes.exclusions ">
                          {{getLang == 'en' ? f.feature_en : f.feature_ar}}
                        </li>

                      </ul>

                    </div>

                  </nav>
                </div>
              </div>

              <div class="accordion-item  bg-white"  *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
                <h2 class="accordion-header mb-0" id="flush-headingOne">
                  <button class="button accordion-button
                relative
                flex
                items-center
                justify-between
                w-full
                py-4
                
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                    (click)="benefitsDrop()" aria-expanded="false" aria-controls="flush-collapseOne">
                    <div class="flex">
                      <span><svg xmlns="http://www.w3.org/2000/svg" class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]" viewBox="0 0 24 24" fill="#C0C0C0" stroke="#C0C0C0" colo
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                      </svg>

                      </span>
                      <label
                        class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl  ltr:ml-2 rtl:mr-2">{{getLang
                        == 'en' ? bTitle1 : bTitle1_ar}}</label>
                    </div>

                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on question open state.
      
                        Heroicon name: outline/chevron-down
      
                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="benefitsDropDown ? 'rotate-180  w-3 transform' : 'rotate-0  w-3 transform'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="gray" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </span>
                  </button>

                </h2>
                <div [ngClass]="benefitsDropDown ? 'block' : 'hidden'"
                  class="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2" id="flush-collapseOne"
                  class="accordion-collapse border-0 collapse show pb-4 pt-0" data-bs-parent="#accordionFlushExample">
                  <nav class="grid gap-y-4 bg-white md:grid-cols-2 sm:grid-cols-1 sm:gap-x-5"
                    aria-labelledby="solutions-heading">

                    <div class="{{getLang == 'ar' ? 'pr-5' : 'pl-7' }}">
                      <ul class="list-disc">
                        <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                        *ngFor="let data of planDetail">
                          <!-- {{data.benefits}} : {{data.flexi}} -->
                          {{getLang == 'en' ? data.benefits + ':' +  data.flexi : data.benefits_ar + ':' + data.flexi_ar }}
                        </li>

                      </ul>

                    </div>

                  </nav>
                </div>
              </div>

              <div class="accordion-item  bg-white"  *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
                <h2 class="accordion-header mb-0" id="flush-headingOne">
                  <button class="button accordion-button
                relative
                flex
                items-center
                justify-between
                w-full
                py-4
                
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                    (click)="exDrop()" aria-expanded="false" aria-controls="flush-collapseOne">
                    <div class="flex">
                      <span><svg xmlns="http://www.w3.org/2000/svg" class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]" viewBox="0 0 24 24" fill="#FFD700" stroke="#FFD700" colo
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                      </svg>

                      </span>
                      <label
                        class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl  ltr:ml-2 rtl:mr-2">{{getLang
                        == 'en' ? bTitle2 : bTitle2_ar}}</label>
                    </div>

                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on question open state.
      
                        Heroicon name: outline/chevron-down
      
                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="exDropDown ? 'rotate-180  w-3 transform' : 'rotate-0  w-3 transform'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="gray" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </span>
                  </button>

                </h2>
                <div [ngClass]="exDropDown ? 'block' : 'hidden'"
                  class="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2" id="flush-collapseOne"
                  class="accordion-collapse border-0 collapse show pb-4 pt-0" data-bs-parent="#accordionFlushExample">
                  <nav class="grid gap-y-4 bg-white md:grid-cols-2 sm:grid-cols-1 sm:gap-x-5"
                    aria-labelledby="solutions-heading">

                    <div class="pl-7">
                      <ul class="list-disc">
                        <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                        *ngFor="let data of planDetail; let j = index">
                        <!-- {{data.benefits}} : {{data.gold}} -->
                        {{getLang == 'en' ? data.benefits + ':' +  data.gold : data.benefits_ar + ':' + data.gold_ar }}

                        </li>

                      </ul>

                    </div>

                  </nav>
                </div>
              </div>

              <div class="accordion-item  bg-white"  *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
                <h2 class="accordion-header mb-0" id="flush-headingOne">
                  <button class="button accordion-button
                relative
                flex
                items-center
                justify-between
                w-full
                py-4
                
                text-base text-gray-800 text-left
                bg-white
                border-0
                rounded-none
                transition
                focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                    (click)="ex2Drop()" aria-expanded="false" aria-controls="flush-collapseOne">
                    <div class="flex">
                      <span><svg xmlns="http://www.w3.org/2000/svg" class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]" viewBox="0 0 24 24" fill="#52beff" stroke="#52beff" colo
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                      </svg>

                      </span>
                      <label
                        class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl  ltr:ml-2 rtl:mr-2">{{getLang
                        == 'en' ? bTitle3 : bTitle3_ar}}</label>
                    </div>

                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on question open state.
      
                        Heroicon name: outline/chevron-down
      
                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="ex2DropDown ? 'rotate-180  w-3 transform' : 'rotate-0  w-3 transform'"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="gray" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </span>
                  </button>

                </h2>
                <div [ngClass]="ex2DropDown ? 'block' : 'hidden'"
                  class="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2" id="flush-collapseOne"
                  class="accordion-collapse border-0 collapse show pb-4 pt-0" data-bs-parent="#accordionFlushExample">
                  <nav class="grid gap-y-4 bg-white md:grid-cols-2 sm:grid-cols-1 sm:gap-x-5"
                    aria-labelledby="solutions-heading">

                    <div class="pl-7">
                      <ul class="list-disc">
                        <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                        *ngFor="let data of planDetail; let j = index">
                        <!-- {{data.benefits}} : {{data.plat}} -->
                        {{getLang == 'en' ? data.benefits + ':' +  data.plat : data.benefits_ar + ':' + data.plat_ar }}
                        </li>

                      </ul>

                    </div>

                  </nav>
                </div>
              </div>

              


            </div>

          </nav>
        </div>
        <!-- Large/Mid View -->
        <div class="md:block sm:hidden" *ngIf="this.productId == 'home' || this.productId == 'personal' || this.productId == 'domestic' || this.productId == 'expat'
        || this.productId == 'cyber' || this.productId == 'bupa' || this.productId == 'smartdevice' || this.productId == 'life' ">
          <div class="flex">
            <span class=""><svg class="sm:h-[30px] sm:w-[30px] md:h-[40px] md:w-[40px] lg:h-[40px] lg:w-[20px]"
                viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.9997 3.33325C10.7997 3.33325 3.33301 10.7999 3.33301 19.9999C3.33301 29.1999 10.7997 36.6666 19.9997 36.6666C29.1997 36.6666 36.6663 29.1999 36.6663 19.9999C36.6663 10.7999 29.1997 3.33325 19.9997 3.33325ZM16.6663 28.3333L8.33301 19.9999L10.683 17.6499L16.6663 23.6166L29.3163 10.9666L31.6663 13.3333L16.6663 28.3333Z"
                  fill="#47D16C" />
              </svg>
            </span>
            <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl  ltr:ml-2 rtl:mr-2">
              {{getLang == 'en' ? "It includes" : "يشمل"}}
            </p>
          </div>
          <div class="mt-5">
            <ul class="list-none">
              <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                *ngFor="let f of this.product!.data[0].attributes.benefits ">
                {{getLang == 'en' ? f.feature_en : f.feature_ar}}
              </li>

            </ul>
          </div>
        </div>
        <div class="md:block sm:hidden" *ngIf="this.productId == 'home' || this.productId == 'personal' || this.productId == 'domestic' || this.productId == 'expat'
        || this.productId == 'cyber' || this.productId == 'bupa' || this.productId == 'smartdevice' ">
          <div class="flex sm:mt-8 md:mt-8 lg:mt-auto">
            <span><svg class="sm:h-[30px] sm:w-[30px] md:h-[40px] md:w-[40px] lg:h-[40px] lg:w-[20px]"
                viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 3.33301C10.7833 3.33301 3.33331 10.783 3.33331 19.9997C3.33331 29.2163 10.7833 36.6663 20 36.6663C29.2166 36.6663 36.6666 29.2163 36.6666 19.9997C36.6666 10.783 29.2166 3.33301 20 3.33301ZM28.3333 25.983L25.9833 28.333L20 22.3497L14.0166 28.333L11.6666 25.983L17.65 19.9997L11.6666 14.0163L14.0166 11.6663L20 17.6497L25.9833 11.6663L28.3333 14.0163L22.35 19.9997L28.3333 25.983Z"
                  fill="#F76659" />
              </svg>
            </span>
            <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl ltr:ml-2 rtl:mr-2">
              {{getLang == 'en' ? "It does not include" : "لا يشمل"}} </p>
          </div>
          <div>
            <ul class="list-none mt-5">
              <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3"
                *ngFor="let f of this.product!.data[0].attributes.exclusions ">
                {{getLang == 'en' ? f.feature_en : f.feature_ar}}
              </li>

            </ul>
          </div>
        </div>
        <!-- Plans table -->
        <div class="md:block sm:hidden" *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
          <div class="flex sm:mt-8 md:mt-8 lg:mt-auto">
            <span class=""><svg class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]"
                viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.9997 3.33325C10.7997 3.33325 3.33301 10.7999 3.33301 19.9999C3.33301 29.1999 10.7997 36.6666 19.9997 36.6666C29.1997 36.6666 36.6663 29.1999 36.6663 19.9999C36.6663 10.7999 29.1997 3.33325 19.9997 3.33325ZM16.6663 28.3333L8.33301 19.9999L10.683 17.6499L16.6663 23.6166L29.3163 10.9666L31.6663 13.3333L16.6663 28.3333Z"
                  fill="#47D16C" />
              </svg>
            </span>
            <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl ltr:ml-2 rtl:mr-2">
              {{getLang == 'en' ? "Benefits" : "المزايا"}}
            </p>
          </div>
          <div class="lg:mt-5 {{ this.productId == 'medical' ? 'md:mt-14' : 'md:mt-5'}} flex-1">
            <ul class="list-none border-t">
              <!--Table Cell Height. Motor En/Ar, Travel En/Ar, Medical En/Ar -->
              <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3 border-b border-Mid-Gray-4
              {{ j === 0 && this.productId == 'motor' ? 'lg:h-[73px] md:h-[121px]' : ''}}
              {{ j === 1 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 6 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 11 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 12 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 


              {{ j === 0 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[121px] lg:h-[73px]' : ''}} 
              {{ j === 5 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 16 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 




              {{ j === 0 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 1 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 6 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 7 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}   
              {{ j === 8 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}    
              {{ j === 9 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}
              {{ j === 10 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 13 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              
              {{ j === 0 && this.productId == 'medical' ? 'md:h-[49px] lg:h-[73px]' : ''}} 
              {{ j === 1 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 2 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 3 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 4 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 5 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 6 && this.productId == 'medical' ? 'md:h-[49px] lg:' : ''}}


              {{ j === 1 && this.productId == 'medical' && getLang == 'ar' ? 'md:h-[73px] lg:h-[49px]' : ''}} 


              
              "
                *ngFor="let data of planDetail; let j = index">
                <!-- {{ data.benefits }} -->
                {{ getLang == 'en' ? data.benefits : data.benefits_ar  }}
              </li>
        
            </ul>
          </div>
        </div>
        <div class="md:block sm:hidden"  *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
          <div class="flex sm:mt-8 md:mt-8 lg:mt-auto">
            <span><svg xmlns="http://www.w3.org/2000/svg" class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]" viewBox="0 0 24 24" fill="#C0C0C0" stroke="#C0C0C0" colo
                stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
            </span>
            <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl ltr:ml-2 rtl:mr-2">
              {{getLang == 'en' ? bTitle1 : bTitle1_ar}} </p>
          </div>
          <div class="flex-1">
            <ul class="list-none mt-5 border-t">
              <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3 border-b border-Mid-Gray-4 
              {{ j === 0 && this.productId == 'motor' ? 'lg:h-[73px] md:h-[121px]' : ''}}
              {{ j === 1 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 6 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 11 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 12 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 

              {{ j === 0 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[121px] lg:h-[73px]' : ''}} 
              {{ j === 5 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 16 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 

              {{ j === 0 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 1 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 6 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 7 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}   
              {{ j === 8 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}    
              {{ j === 9 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}
              {{ j === 10 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 13 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}    

              {{ j === 0 && this.productId == 'medical' ? 'md:h-[49px] lg:h-[73px]' : ''}} 
              {{ j === 1 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 2 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 3 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 4 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 5 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 6 && this.productId == 'medical' ? 'md:h-[49px] lg:' : ''}}

              "  
                *ngFor="let data of planDetail; let j = index">
                <!-- {{data.flexi}} -->
                {{ getLang == 'en' ? data.flexi : data.flexi_ar }}

              </li>
        
            </ul>
          </div>
        </div>
        <div class="md:block sm:hidden justify-between"  *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
          <div class="flex sm:mt-8 md:mt-8 lg:mt-auto">
            <span><svg xmlns="http://www.w3.org/2000/svg" class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]" viewBox="0 0 24 24" fill="#FFD700" stroke="#FFD700" colo
              stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
            </svg>
            </span>
            <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl ltr:ml-2 rtl:mr-2">
              {{getLang == 'en' ? bTitle2 : bTitle2_ar}} </p>
          </div>
          <div class="flex-1">
            <ul class="list-none mt-5 border-t">
              <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3 border-b border-Mid-Gray-4 
              {{ j === 0 && this.productId == 'motor' ? 'lg:h-[73px] md:h-[121px]' : ''}}
              {{ j === 1 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 6 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 11 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 12 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}}  

              {{ j === 0 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[121px] lg:h-[73px]' : ''}} 
              {{ j === 5 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 16 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 

              {{ j === 0 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 1 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 6 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 7 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}   
              {{ j === 8 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}    
              {{ j === 9 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}
              {{ j === 10 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 13 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 

              {{ j === 0 && this.productId == 'medical' ? 'md:h-[49px] lg:h-[73px]' : ''}} 
              {{ j === 1 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 2 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 3 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 4 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 5 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 6 && this.productId == 'medical' ? 'md:h-[49px] lg:' : ''}}


              "
              
                *ngFor="let data of planDetail; let j = index">
                <!-- {{data.gold}} -->
                {{ getLang == 'en' ? data.gold : data.gold_ar }}

              </li>
        
            </ul>
          </div>
        </div>
        <div class="md:block sm:hidden justify-between"  *ngIf="this.productId == 'motor' || this.productId == 'medical' || this.productId == 'travel' ">
          <div class="flex sm:mt-8 md:mt-8 lg:mt-auto">
            <span><svg xmlns="http://www.w3.org/2000/svg" class="sm:h-[30px] sm:w-[30px] md:h-[20px] md:w-[20px] lg:h-[30px] lg:w-[30px]" viewBox="0 0 24 24" fill="#52beff" stroke="#52beff" colo
              stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
            </svg>
            </span>
            <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl ltr:ml-2 rtl:mr-2">
              {{getLang == 'en' ? bTitle3 : bTitle3_ar}} </p>
          </div>
          <div class="flex-1">
            <ul class="list-none mt-5 border-t">
              <li class="text-Dark-Gray-2 font-normal lg:text-[16px] md:text-[16px] mt-3 border-b border-Mid-Gray-4 
              {{ j === 0 && this.productId == 'motor' ? 'lg:h-[73px] md:h-[121px]' : ''}}
              {{ j === 1 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 6 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 11 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 12 && this.productId == 'motor' ? 'lg:h-[49px] md:h-10' : ''}}
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' ? 'md:h-[49px] lg:h-10' : ''}} 

              
              {{ j === 0 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[121px] lg:h-[73px]' : ''}} 
              {{ j === 5 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 7 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 8 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 9 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 10 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 13 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[73px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 16 && this.productId == 'motor' && getLang == 'ar' ? 'md:h-[49px] lg:h-10' : ''}} 
              

              {{ j === 0 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 1 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 2 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 3 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 4 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 5 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 6 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 7 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}   
              {{ j === 8 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}    
              {{ j === 9 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}}
              {{ j === 10 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 11 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}} 
              {{ j === 12 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  
              {{ j === 13 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 14 && this.productId == 'travel' ? 'md:h-[65px] lg:h-10' : ''}} 
              {{ j === 15 && this.productId == 'travel' ? 'md:h-[49px] lg:h-10' : ''}}  


              {{ j === 2 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}} 
              {{ j === 5 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}}  
              {{ j === 7 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}}   
              {{ j === 8 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}}    
              {{ j === 9 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}} 
              {{ j === 13 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}} 
              {{ j === 14 && this.productId == 'travel' && getLang == 'ar' ? 'md:h-[49px] lg:' : ''}} 

              {{ j === 0 && this.productId == 'medical' ? 'md:h-[49px] lg:h-[73px]' : ''}} 
              {{ j === 1 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 2 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 3 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 4 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 5 && this.productId == 'medical' ? 'md:h-[73px] lg:h-[49px]' : ''}}
              {{ j === 6 && this.productId == 'medical' ? 'md:h-[49px] lg:' : ''}}

              "
                *ngFor="let data of planDetail; let j = index">
                <!-- {{data.plat}} -->
                {{ getLang == 'en' ? data.plat : data.plat_ar }}
              </li>
        
            </ul>
          </div>
        </div>
        <!-- <div class="table-auto">
          <table>
            <tr>
              <th>Benefits</th>
              <th><span class="circle-green"></span>Salamah Flex</th>
              <th><span class="circle-yellow"></span>Salamah Plus</th>
              <th><span class="circle-blue"></span>Salamah Lux</th>
            </tr>
            <tr *ngFor="let data of planDetail; let j = index">
              <td>
                {{ data.benefits }}
                <span class="text-grey d-block">{{
                  data.benefits_desc
                  }}</span>
              </td>
              <td>{{ data.third_party }}</td>
              <td>{{ data.flexi }}</td>
              <td>{{ data.gold }}</td>
              <td>{{ data.plat }}</td>
            </tr>
          </table>
        </div> -->
      </div>
      <div id="documents" class="mt-5">
        <div class="grid lg:grid-cols-2 mt-10">
          <div>
            <div class="flex pt-10">
              <span class=""><svg class="sm:h-[30px] sm:w-[30px] md:h-[40px] md:w-[40px] lg:h-[40px] lg:w-[40px]"
                  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2768_47354)">
                    <path
                      d="M6.66668 9.99967H3.33334V33.333C3.33334 35.1663 4.83334 36.6663 6.66668 36.6663H30V33.333H6.66668V9.99967ZM33.3333 3.33301H13.3333C11.5 3.33301 10 4.83301 10 6.66634V26.6663C10 28.4997 11.5 29.9997 13.3333 29.9997H33.3333C35.1667 29.9997 36.6667 28.4997 36.6667 26.6663V6.66634C36.6667 4.83301 35.1667 3.33301 33.3333 3.33301ZM33.3333 26.6663H13.3333V6.66634H33.3333V26.6663ZM16.6667 14.9997H30V18.333H16.6667V14.9997ZM16.6667 19.9997H23.3333V23.333H16.6667V19.9997ZM16.6667 9.99967H30V13.333H16.6667V9.99967Z"
                      fill="#84919A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2768_47354">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </span>
              <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl  sm:text-xl ltr:ml-2 rtl:mr-2">
                {{getLang == 'en' ? "Policy Specifications" : "تفاصيل الوثيقة"}}
              </p>
            </div>
            <div class="mt-5">
              <ul class="list-none">
                <li class="text-Dark-Gray-2 font-normal text-[16px] mt-3"
                  *ngFor="let document of this.product!.data[0].attributes.documents ">
                  <a [href]="this.strapiApi.apiURL + document.document.data.attributes.url" target="_blank">
                    <div class="p-5 block w-full max-w-[336px] border border-Light-Gray-3 rounded-md">
                      <div class="flex justify-between align-middle items-center">
                        <div class="flex items-center">
                          <span class=""><svg width="30" height="40" viewBox="0 0 30 40" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
                                fill="white" stroke="#E5E9EB" />
                              <path
                                d="M5.77156 24V16.7273H8.77582C9.32033 16.7273 9.79026 16.8338 10.1856 17.0469C10.5833 17.2576 10.8899 17.5523 11.1054 17.9311C11.3208 18.3075 11.4285 18.7455 11.4285 19.245C11.4285 19.7469 11.3184 20.1861 11.0983 20.5625C10.8805 20.9366 10.5691 21.2266 10.1643 21.4325C9.75949 21.6385 9.2789 21.7415 8.72255 21.7415H6.86886V20.3565H8.39585C8.661 20.3565 8.88235 20.3104 9.05991 20.218C9.23984 20.1257 9.37596 19.9967 9.46829 19.831C9.56062 19.6629 9.60679 19.4676 9.60679 19.245C9.60679 19.0201 9.56062 18.826 9.46829 18.6626C9.37596 18.4969 9.23984 18.3691 9.05991 18.2791C8.87999 18.1892 8.65863 18.1442 8.39585 18.1442H7.52937V24H5.77156ZM14.9232 24H12.235V16.7273H14.9197C15.6607 16.7273 16.2987 16.8729 16.8337 17.1641C17.3711 17.4529 17.7854 17.8696 18.0766 18.4141C18.3678 18.9562 18.5134 19.6049 18.5134 20.3601C18.5134 21.1177 18.3678 21.7687 18.0766 22.3132C17.7878 22.8577 17.3747 23.2756 16.8373 23.5668C16.2999 23.8556 15.6618 24 14.9232 24ZM13.9928 22.5014H14.8557C15.2629 22.5014 15.6074 22.4328 15.8891 22.2955C16.1732 22.1558 16.3875 21.9297 16.5319 21.6172C16.6787 21.3023 16.752 20.8833 16.752 20.3601C16.752 19.8369 16.6787 19.4202 16.5319 19.1101C16.3851 18.7976 16.1685 18.5727 15.882 18.4354C15.5979 18.2957 15.2475 18.2259 14.8309 18.2259H13.9928V22.5014ZM19.4797 24V16.7273H24.4442V18.1548H21.2375V19.6463H24.1281V21.0774H21.2375V24H19.4797Z"
                                fill="#F76659" />
                            </svg>

                          </span>
                          <div class="grid grid-rows-2 grid-cols-1 gap-1 rtl:mr-1 ltr:ml-1"
                            [ngClass]="getLang == 'ar' ? 'pr-3' : ''">
                            <p class="font-medium text-Dark-Gray-2 text-sm ltr:ml-2 rtl:mr-2">
                              {{getLang == 'en' ? document.title_en : document.title_ar}}
                            </p>
                            <p class="font-medium text-Mid-Gray-3 text-xs ltr:ml-2 rtl:mr-2">
                              {{document.document.data.attributes.size}} kb
                            </p>
                          </div>
                        </div>
                        <div>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="#252C32" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </a>

                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="md:w-2/3 sm:w-full text-justify mt-14" id="notice" [ngClass]="getLang == 'en' ? 'block' : 'hidden'">
      <p class="text-sm text-Mid-Gray-1">
        Note: <br />
        Solidarity Bahrain B.S.C., hereinafter referred to as “Solidarity”, is
        as diligent as possible in compiling and updating the information on its
        website. However, Solidarity does not guarantee the completeness of the
        information provided on its website. Equally, Solidarity does not
        guarantee that this information is up to date. For questions concerning
        our insurance policies, their specifications and their utilization,
        please read the policy specification document carefully. The policy
        specifications documents can change without notice, from time to time at
        Solidarity’s sole discretion. Solidarity will post regular amendments
        when deemed necessary to the website, benefits, exclusions and policy
        specification sections.
      </p>
    </div> -->
    <!-- <div class="md:w-2/3 sm:w-full text-justify mt-14" id="notice" [ngClass]="getLang == 'ar' ? 'block' : 'hidden'">
      <p class="text-sm text-Mid-Gray-1">
        ملاحظة: <br />
        سوليدرتي البحرين ش.م.ب. المشار إليها بسوليدرتي، فإن سوليدرتي لا تضمن اكتمال المعلومات المقدمة على موقعها
        الإلكتروني. وبالمثل، لا تضمن سوليدرتي تحديث هذه المعلومات. للأسئلة المتعلقة بوثيقة التأمين لدينا ومواصفاتها
        ومزاياها، يرجى قراءة وثيقة مواصفات التأمين بعناية. يمكن تغيير وثائق مواصفات التأمين دون إشعار، من وقت لآخر وفقًا
        لتقدير سوليدرتي وحدها. ستنشر سوليدرتي تعديلات منتظمة عند الضرورة على الموقع الإلكتروني وعلى أقسام المزايا
        والاستثناءات ومواصفات وثيقة التأمين. </p>
    </div> -->
  </div>
</div>

<app-lower-banner></app-lower-banner>
<app-footer></app-footer>