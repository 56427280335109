<app-header>
</app-header>
<div class="container max-w-7xl px-4 mx-auto" dir="auto">
    <p class="lg:text-[80px] md:text-6xl sm:text-[40px] pt-14">
        {{getLang == 'en' ? "Privacy & Security" :
        "الخصوصية والأمان"}}
    </p>
    <p class="pt-[50px] lg:text-3xl md:text-3xl sm:text-xl">
        {{getLang == 'en' ? privacyAndSecurity.privacy_details.privacy_details_title_en :
        privacyAndSecurity.privacy_details.privacy_details_title_ar}}</p>
    <p class="pt-[21px] text-sm text-Mid-Gray-1">{{getLang == 'en' ?
        privacyAndSecurity.privacy_details.privacy_details_en : privacyAndSecurity.privacy_details.privacy_details_ar}}
    </p>

    <p class="pt-[50px] lg:text-3xl md:text-3xl sm:text-xl">
        {{getLang == 'en' ? privacyAndSecurity.processing_details.processing_title_en :
        privacyAndSecurity.processing_details.processing_title_ar}}</p>
    <p class="pt-[21px] text-sm text-Mid-Gray-1">{{getLang == 'en' ?
        privacyAndSecurity.processing_details.processing_details_en :
        privacyAndSecurity.processing_details.processing_details_ar}}</p>
    <div>
        <div class="grid grid-cols-2 pt-12 pb-4  border border-x-0 border-t-0 border-Light-Gray-3">
            <p class="text-sm text-Dark-Gray-2 font-normal">{{getLang == 'en' ? "Purpose" : "الغرض"}}</p>
            <p class="text-sm text-Dark-Gray-2 font-normal">{{getLang == 'en' ? "Lawfulness Basis" : "أساس المشروعية"}}
            </p>
        </div>
        <div class="grid grid-cols-2  pt-7 pb-4 border border-x-0 border-t-0 border-Light-Gray-3"
            *ngFor="let p of privacyAndSecurity.processing_details.purpose_and_lawfulness">
            <p class="lg:max-w-[360px] md:max-w-[298px] sm:max-w-[136px] text-Mid-Gray-1 text-sm">{{getLang == 'en' ?
                p.purpose_en : p.purpose_ar}}</p>
            <p class="lg:max-w-[360px] md:max-w-[298px] sm:max-w-[125px] text-Mid-Gray-1 text-sm">{{getLang == 'en' ?
                p.lawfulness_en : p.lawfulness_ar}}
            </p>
        </div>
    </div>
    <div *ngFor="let other of privacyAndSecurity.other_details">
        <p class="pt-[50px] lg:text-3xl md:text-3xl sm:text-xl">
            {{getLang == 'en' ? other.other_title_en :
            other.other_title_ar}}</p>
        <p class="pt-[21px] text-sm text-Mid-Gray-1 max-w-[840px]">{{getLang == 'en' ? other.other_details_en :
            other.other_details_ar}}</p>
    </div>
    <p class="pt-[50px] lg:text-3xl md:text-3xl sm:text-xl">{{getLang == 'en' ?
        privacyAndSecurity.your_rights.rights_title_en : privacyAndSecurity.your_rights.rights_title_ar}}</p>
    <p class="pt-[21px] text-sm text-Mid-Gray-1 max-w-[840px]">
        {{getLang == 'en' ? "You have the right to Request Solidarity:" :
        "لديك الحق لتطالب بالتالي:"}}</p>
    <p class="text-sm text-Mid-Gray-1 max-w-[840px]"
        *ngFor="let right of privacyAndSecurity.your_rights.list_of_rights">
        {{getLang == 'en' ? right.data_en :
        right.data_ar}}</p>
    <div *ngFor="let p of privacyAndSecurity.update_and_contacts">
        <p class="pt-[50px] lg:text-3xl md:text-3xl max-w-[840px] sm:text-xl">{{getLang == 'en' ?
            p.update_contact_title_en : p.update_contact_title_ar}}</p>
        <p class="pt-[21px] text-sm text-Mid-Gray-1 max-w-[840px]">{{getLang == 'en' ? p.update_contact_details_en :
            p.update_contact_details_ar}}</p>
    </div>
    <a href="https://content.solidarity.bh/uploads/Solidarity_Subject_Rights_Request_Form_d42372c2ce.pdf"
        target="_blank">
        <button type="button"
            class="button mt-10 text-sm lg:min-w-[263px] md:min-w-[263px] lg:w-auto md:w-auto sm:w-full  items-center rounded-md border border-transparent bg-primary-500 px-4 py-2 font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {{getLang == 'en' ? "Subject Rights Request Form" :
            "نموذج حقوق الطلبات"}}
        </button>
    </a>
</div>
<app-footer></app-footer>