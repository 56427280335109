import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { News, Doc } from 'src/app/models/shareholder-news';
@Component({
  selector: 'app-shareholdernews',
  templateUrl: './shareholdernews.component.html',
  styleUrls: ['./shareholdernews.component.css']
})
export class ShareholdernewsComponent implements OnInit {


  shareholderNews: Array<News> = [];

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute,) { }

  async ngOnInit() {
    this.route.data.subscribe(async param => {
      this.shareholderNews = param['data'].data.attributes.shareholder_news;

    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }

}
