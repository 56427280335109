import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { Validations } from 'src/app/models/validations';
import { StrapiApiService } from '../strapi-api.service';

@Injectable({
  providedIn: 'root'
})
export class BasicValidationResolver  {
  constructor(private strapiAPi: StrapiApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.strapiAPi.getValidations().pipe(catchError((error: any)=> {console.log(error); return of('No Data')}));;
    
  }
}
