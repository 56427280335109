import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Cover } from 'src/app/models/cover';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-seefmallbranch',
  templateUrl: './seefmallbranch.component.html',
  styleUrls: ['./seefmallbranch.component.css']
})
export class SeefmallbranchComponent implements OnInit {

  covers: Array<Cover> = [];
  PolicyCovers: Array<Cover> = [];
  currentStep: number = 0;
  cprNumber: string = '';
  registrationNumber: string = '';
  ngForm: any;
  step: number = 1;
  isLoading: boolean = false;

  isRenewable = false;
  isRenewed = false;
  Model: string = '';
  Make: string = '';
  Year: string = '';
  ErrorMessage: string = '';
  PlanName: string = '';
  PlateNumber: string = '';

  ToDateStr: string = '';
  FromDateStr: string = '';
  inputValue: string = '';

  policyNumber: string = '';

  inputField1: string = '';
  inputField2: string = '';

  input1 = '';
  input2 = '';
  activeInput: number | null = null;

  //renewal details
  quoteNumber: string = '';
  sumInsured: number = 0;
  totalPrem: number = 0;
  prem: number = 0;
  tax: number = 0;
  isChecked: boolean = false;

  selectedDay: string = '';
  selectedCarSize: string = '';
  selectedArea: string = '';

  coverChanged: Boolean = false;

  paymentLink: string = '';

  imgLoading: Boolean = true;

  countdownValue: number = 60;
  countdownSubscription: Subscription | undefined;
  interval: any;

  //errors
  searchPolicyError: string = '';

  //customer details
  fname: string = '';
  lname: string = '';

  @ViewChild('myVideo') myVideo: ElementRef | undefined;
  @ViewChild('myVideo1') myVideo1: ElementRef | undefined;



  constructor(public webapi: WebapiService) { }

  startCountdown() {

    this.interval = setInterval(() => {
      if (this.countdownValue > 0) {
        this.countdownValue -= 1;
      } else {
        this.countdownValue = 0;
        this.onCountdownEnd();
        this.countdownSubscription?.unsubscribe();
        this.refreshPage();
      }
    }, 1000); // Update the countdown every 1000ms (1 second)


  }

  onCountdownEnd() {
    console.log('Countdown reached 0. Calling the function.');
    // Call your function here
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks when the component is destroyed
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  resetCountdown() {
    if (this.step != 1) {
      clearInterval(this.interval);
      this.countdownValue = 60; // Reset the countdown value to 30 seconds
      this.startCountdown(); // Start the countdown again
    }
    else {
      this.countdownValue = 60; // Reset the countdown value to 30 seconds

    }

  }
  check() {
    console.log(this.selectedDay);
  }

  CoverPressed() {
    this.countdownValue = 60;
    this.coverChanged = true;
    this.paymentLink = '';
    console.log("Pressed: " + this.coverChanged);
  }

  refreshPage() {
    location.reload();
  }
  addValue(value: string) {
    if (this.activeInput === 1) {
      this.input1 += value;
    } else if (this.activeInput === 2) {
      this.input2 += value;
    }
    // Handle updates for other input fields as needed
  }
  onInput1Change() {
    if (this.activeInput === 1) {
      // Handle input changes for input1
    }
  }

  onInput2Change() {
    if (this.activeInput === 2) {
      // Handle input changes for input2
    }
  }

  updateInput() {
    if (this.activeInput === 1) {
      this.input1 += event;
    } else if (this.activeInput === 2) {
      this.input2 += event;
    }
    // Handle updates for other input fields as needed
  }
  appendKey(value: string) {
    if (this.inputField1) {
      this.inputField1 += value;
      console.log(value);
    }
    if (this.inputField2) {
      this.inputField2 += value;
    }


  }

  clearInput() {
    if (this.activeInput === 1) {
      this.input1 = '';
    } else if (this.activeInput === 2) {
      this.input2 = '';
    }
    // Handle clearing other input fields as needed
  }

  backspace() {
    if (this.activeInput === 1) {
      this.input1 = this.input1.slice(0, -1);
    } else if (this.activeInput === 2) {
      this.input2 = this.input2.slice(0, -1);
    }
    // Handle backspacing in other input fields as needed
  }

  nextStep() {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  prevStep(stepCount: number) {
    this.resetCountdown();
    if (stepCount == 2) {
      this.step = 1;
    }
    else if (stepCount == 3) {
      this.step = 2;
    }
    else if (stepCount == 4) {
      this.step = 3;
    }

  }

  replayVideo() {
    // Get a reference to the video element and replay it
    const videoElement: HTMLVideoElement = this.myVideo!.nativeElement;
    videoElement.currentTime = 0;
    videoElement.play();
  }

  // getCovers(){
  //   this.webapi.getCovers().subscribe((response) =>{
  //     var tempCovers = response;
  //     for(let cover of tempCovers){
  //       if(cover.coverCode != 'CMOT18'){
  //         this.covers.push(cover);
  //       }
  //       if(this.isCoverDisabled(cover)){
  //         cover.Selected = true;
  //       }
  //       else{
  //         cover.Selected = false;
  //       }
  //     }
  //     console.log(this.covers[0].selected);
  //   });
  // }

  stopCountdown() {
    this.countdownValue = 60;
    clearInterval(this.interval);
  }
  CalculatePremium() {
    this.stopCountdown();
    this.isLoading = true;
    this.covers = [];
    this.webapi.premium(this.policyNumber, 'web.solidarity', false, this.PlateNumber, []).subscribe((response) => {
      this.quoteNumber = response.PolicyNumber;
      this.totalPrem = response.TotalPremium ?? 0;
      this.prem = response.Premium ?? 0;
      this.sumInsured = response.SumInsured ?? 0;
      this.tax = response.Tax ?? 0;
      this.PolicyCovers = response.Covers;
      this.webapi.getCovers().subscribe((response) => {
        var tempCovers = response;

        for (let cover of tempCovers) {
          if (cover.coverCode != 'CMOT18' && cover.coverCode != 'CMOT32' && cover.coverCode != 'CMOT17') {
            this.covers.push(cover);
          }
          if (this.isCoverDisabled(cover)) {
            cover.Selected = true;
          }
          else {
            cover.Selected = false;
          }
        }
        this.isLoading = false;
        this.step = 3;
        this.startCountdown();
        console.log(this.covers[0].selected);
      });

    });
  }

  submitFinal() {

    this.isLoading = true;

    var addedCovers = [];

    for (let cover of this.covers) {

      if (cover.coverCode == 'CMOT16' && this.covers[0].Selected) {
        cover.Selected = false;
      }

      if (!this.isCoverDisabled(cover)) {

        if (cover.Selected) {
          if (cover.coverCode == 'CMOT28') {

            var tempCover = cover;

            tempCover.coverProperty.simpleProperty[0].Name = "Days";
            tempCover.coverProperty.simpleProperty[0].Value = this.selectedDay;

            tempCover.coverProperty.simpleProperty[1].Name = "CarSize";
            tempCover.coverProperty.simpleProperty[1].Value = this.selectedCarSize;


            addedCovers.push(tempCover);
          }
          else if (cover.coverCode == 'CMOT24') {
            var tempCover = cover;

            tempCover.coverProperty.simpleProperty[0].Name = "Area";
            tempCover.coverProperty.simpleProperty[0].Value = this.selectedArea;
            addedCovers.push(tempCover);

          }
          else {
            addedCovers.push(cover);
          }

        }
      } else if (this.isCoverDisabled(cover) && cover.coverCode == 'CMOT28' && this.selectedDay != '' || this.isCoverDisabled(cover) && cover.coverCode == 'CMOT28' && this.selectedCarSize != '') {
        var tempCover = cover;

        tempCover.coverProperty.simpleProperty[0].Name = "Days";
        tempCover.coverProperty.simpleProperty[0].Value = this.selectedDay;

        tempCover.coverProperty.simpleProperty[1].Name = "CarSize";
        tempCover.coverProperty.simpleProperty[1].Value = this.selectedCarSize;


        addedCovers.push(tempCover);
      }
      else if (this.isCoverDisabled(cover) && cover.coverCode == 'CMOT24' && this.selectedArea != '') {
        var tempCover = cover;

        tempCover.coverProperty.simpleProperty[0].Name = "Area";
        tempCover.coverProperty.simpleProperty[0].Value = this.selectedArea;
        addedCovers.push(tempCover);
      }
    }
    this.webapi.premium(this.policyNumber, 'web.solidarity', false, this.PlateNumber, addedCovers).subscribe(
      (response) => {
        this.startCountdown();
        this.quoteNumber = response.PolicyNumber;
        this.totalPrem = response.TotalPremium ?? 0;
        this.prem = response.Premium ?? 0;
        this.sumInsured = response.SumInsured ?? 0;
        this.tax = response.Tax ?? 0;
        this.coverChanged = false;
        this.webapi.getCovers().subscribe((response) => {

          this.isLoading = false;
          // this.step = 3;
          console.log(this.covers[0].selected);
        });
      }
    )
  }

  onCprInputChange(value: string) {
    if (value.length === 9) {
      // Move focus to the second input field
      document.getElementById('plateNumber')?.focus();
    }
  }

  submitandPurchase() {
    this.stopCountdown();
    if (this.coverChanged) {
      this.submitFinal();
    }
    else {
      this.stopCountdown();
      this.isLoading = true;
      if (this.paymentLink == '') {

        this.webapi.PolicyPay(this.quoteNumber, 'web.solidarity', this.totalPrem).subscribe((response) => {
          //this.paymentLink = response[0];

          this.paymentLink = this.generateQRCode(response[0]);

          const img = new Image();
          img.src = this.paymentLink;
          img.onload = () => {
            this.imgLoading = false;
            this.step = 4;
            this.isLoading = false;
            this.startCountdown();
          };



        });
      }
      else {
        this.startCountdown();
        this.isLoading = false;
        this.step = 4;
      }


    }
  }

  generateQRCode(fileUrl: string): string {
    const qrCode = encodeURIComponent(fileUrl); // your content to encode
    const qrCodeUrl = `https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=${qrCode}`;
    return qrCodeUrl;
  }
  // isCoverSelected(cover: any, covers: any): boolean{
  //   if(covers[0].Selected == true){
  //     covers[1].Selected = false;
  //   }
  //   return cover.Selected;
  // }

  isCoverDisabled(cover: any): boolean {
    // Assuming policyCovers is an array of cover names or cover objects
    return this.PolicyCovers.some(policyCover =>
      policyCover.coverCode === cover.coverCode

    );
  }

  disabledRoadAssist(cover: any): boolean {
    if (cover[0].Selected && cover[1].Selected) {
      //cover[1].Selected = false;
      return true;
    }
    else {
      return false;
    }
  }
  searchPolicies() {
    if (this.ngForm.get('cpr').value != '' || this.ngForm.get('plateNumber').value != '') {
      this.isLoading = true
      this.webapi.searchPolicy(

        this.ngForm.get('cpr').value,
        this.ngForm.get('plateNumber').value,

      ).subscribe((response) => {
       // debugger
        console.log(response);
        if (response.length == 0) {
          var errorMsg = 'There is no policy with the given details';
          this.timeOutError('There is no policy with the given details');
        }
        else {
          this.policyNumber = response[0].PolicyNumber;
          this.isRenewable = response[0].IsRenewable;
          this.isRenewed = response[0].IsRenewed;
          this.Model = response[0].Model;
          this.Year = response[0].ModelYear;
          this.Make = response[0].Make;
          this.ErrorMessage = response[0].ErrorMessage ?? '';
          this.PlanName = response[0].PlanName;
          this.PlateNumber = response[0].RegistrationNumber;
          this.ToDateStr = response[0].ToDateStr;
          this.FromDateStr = response[0].FromDateStr;
          this.fname = response[0].Party.FirstName;
          this.lname = response[0].Party.LastName;
          this.searchPolicyError = '';
          this.isLoading = false;
          if (this.policyNumber != '' || this.policyNumber != null) {
            this.step = 2;
            this.startCountdown();
          }
          console.log('Error' + this.ErrorMessage);
        }



      });
    }

  }

  timeOutError(error: string){
    this.isLoading = false;
    this.searchPolicyError = error;
    setTimeout(() => {
      this.searchPolicyError = '';
    }, 5000);
  }


  ngOnInit(): void {

    //this.getCovers();
    this.ngForm = new FormGroup({
      cpr: new FormControl(''),
      plateNumber: new FormControl(''),
    });

    if (this.myVideo1) {
      // Get a reference to the video element
      const videoElement = this.myVideo1.nativeElement;

      // Mute the video
      videoElement.muted = true;

      // Autoplay the video
      videoElement.play()
        .then(() => {
          // Autoplay succeeded
          console.log('Video autoplayed successfully.');
        })
        .catch((error: any) => {
          // Autoplay failed, handle the error
          console.error('Video autoplay failed:', error);
        });
    }
  }

  onImageLoad() {
    console.log('Image loaded');
    this.imgLoading = false;

  }
  onImageError() {
    console.log('Image error');
    this.imgLoading = false;
  }

}
