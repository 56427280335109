<div class="w-full container my-9 mx-4">
  <p class="font-medium text-2xl">Quick Renewal</p>
  <p class="mt-2">Fill in your information, and we’ll take care of the rest</p>

  <div class="mt-10 flex items-center">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2514_46014)">
        <path
          d="M12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2ZM18 11.09C18 15.09 15.45 18.79 12 19.92C8.55 18.79 6 15.1 6 11.09V6.39L12 4.14L18 6.39V11.09Z"
          fill="#00719A" />
      </g>
      <defs>
        <clipPath id="clip0_2514_46014">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <p class="ml-4 text-primary-500 font-medium">SELECT A POLICY</p>
  </div>
  <p class="mt-5">Select a policy that you would like to renew</p>
  <div class="grid grid-cols-2 gap-4">

    <app-select-policy-box></app-select-policy-box>
    <app-select-policy-box></app-select-policy-box>
    <app-select-policy-box></app-select-policy-box>
    <app-select-policy-box></app-select-policy-box>
    <app-select-plan-box></app-select-plan-box>
  </div>

  <div class="mt-10">
    <button class="button w-[130px] h-10 bg-primary-500 text-Light-Gray-5 py-2 rounded-md text-center text-sm">
      Next
    </button>
  </div>
</div>