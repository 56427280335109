<div>
  <p class="text-Mid-Gray-1 lg:text-lg md:text-base sm:text-sm  py-8 lg:w-[648px] md:w-[648px]">
    {{getLang == 'en' ? financialReports.desc_en : financialReports.desc_ar}}</p>
  <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-4 sm:grid-cols-1 pb-10">
    <div *ngFor="let doc of financialReports.reports"
      class="relative flex items-center space-x-3 rounded-lg border border-Light-Gray-3 bg-white px-6 py-5  focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
      <div class="flex-shrink-0">
        <svg width="20" height="24" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
            fill="white" stroke="#E5E9EB" />
          <path
            d="M5.77156 24V16.7273H8.77582C9.32033 16.7273 9.79026 16.8338 10.1856 17.0469C10.5833 17.2576 10.8899 17.5523 11.1054 17.9311C11.3208 18.3075 11.4285 18.7455 11.4285 19.245C11.4285 19.7469 11.3184 20.1861 11.0983 20.5625C10.8805 20.9366 10.5691 21.2266 10.1643 21.4325C9.75949 21.6385 9.2789 21.7415 8.72255 21.7415H6.86886V20.3565H8.39585C8.661 20.3565 8.88235 20.3104 9.05991 20.218C9.23984 20.1257 9.37596 19.9967 9.46829 19.831C9.56062 19.6629 9.60679 19.4676 9.60679 19.245C9.60679 19.0201 9.56062 18.826 9.46829 18.6626C9.37596 18.4969 9.23984 18.3691 9.05991 18.2791C8.87999 18.1892 8.65863 18.1442 8.39585 18.1442H7.52937V24H5.77156ZM14.9232 24H12.235V16.7273H14.9197C15.6607 16.7273 16.2987 16.8729 16.8337 17.1641C17.3711 17.4529 17.7854 17.8696 18.0766 18.4141C18.3678 18.9562 18.5134 19.6049 18.5134 20.3601C18.5134 21.1177 18.3678 21.7687 18.0766 22.3132C17.7878 22.8577 17.3747 23.2756 16.8373 23.5668C16.2999 23.8556 15.6618 24 14.9232 24ZM13.9928 22.5014H14.8557C15.2629 22.5014 15.6074 22.4328 15.8891 22.2955C16.1732 22.1558 16.3875 21.9297 16.5319 21.6172C16.6787 21.3023 16.752 20.8833 16.752 20.3601C16.752 19.8369 16.6787 19.4202 16.5319 19.1101C16.3851 18.7976 16.1685 18.5727 15.882 18.4354C15.5979 18.2957 15.2475 18.2259 14.8309 18.2259H13.9928V22.5014ZM19.4797 24V16.7273H24.4442V18.1548H21.2375V19.6463H24.1281V21.0774H21.2375V24H19.4797Z"
            fill="#F76659" />
        </svg>
      </div>
      <div class="min-w-0 flex-1">
        <a [href]="strapiApi.getImage(doc.pdf.data?.attributes?.url!)" target="_blank" class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true"></span>
          <p class="text-sm font-normal text-Dark-Gray-2 lg:w-[250px] md:w-[250px]">
            {{getLang == 'en' ? doc.title_en : doc.title_ar}}</p>
        </a>
      </div>
    </div>
  </div>
</div>