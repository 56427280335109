import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { Term } from 'src/app/models/terms-of-use';
import { ThreelinesMemebrs } from 'src/app/models/threelines-memebrs';

@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.css']
})
export class TermsofuseComponent implements OnInit {
  terms: Array<Term> = [];
  title: string = '';
  title_ar: string = '';

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute,) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      this.title = params['data'].data.attributes.termsTitle;
      this.title_ar = params['data'].data.attributes.termsTitle_ar;
      this.terms = params['data'].data.attributes.terms;
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
