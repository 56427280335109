<header>
  <link rel="stylesheet" href="./footer.component.css">

</header>

<footer class="w-full bg-white" [attr.dir]="getLang === 'en' ? 'ltr' : 'rtl'">
  <div class=" container mx-auto pt-12 lg:flex lg:flex-wrap pb-10 " id="footer_content">
 
    <footer class="flex footer-section">
      <div class="container mx-auto footer-flex">
          <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1" [attr.dir]="getLang === 'en' ? 'ltr' : 'rtl'">
              <div class="w-/5 md:w-5/5 lg:w-5/5 xl:w-5/5">
                  <div class="logo-footer-box"><img src="../../../assets/imgs/Logo.0c4504d701daaa792a9e8f7e90cc0b18.svg" alt="logo"/></div>
                  <div class="apple-logo-box flex">
                      <a href="https://apps.apple.com/bh/app/solidarity/id1585138622" target="_blank" rel="noopener noreferrer" [attr.class]="getLang === 'en' ? 'sm:pr-2' : 'sm:pl-2'">
                          <img class="applelogoimg" src="../../../assets/imgs/Apple.b2d31ec232dded279e4ca0fded495a5b.svg" alt="Apple logo" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=bh.solidarity.app&amp;hl=en&amp;gl=US" target="_blank" rel="noopener noreferrer">
                          <img class="applelogoimg" src="../../../assets/imgs/Google.a71168bcf71c64a9ad1750d418017d9e.svg" alt="Google logo" />
                      </a>
                  </div>
                  <br>
                  <div class="share-buttons-container flex space-x-4 items-center socialLinks py-4" dir="auto">
                    <a href="https://www.facebook.com/Insuringbahrain/" target="_blank" rel="noopener noreferrer" class="copy-link-button flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden p-1 rounded-[64px] bg-[#eef0f2]">
                        <img src="../../../assets/imgs/Facebook_583f0f377f.svg" alt="Facebook.svg" />
                    </a>
                    <a href="https://www.instagram.com/insuringbahrain/" target="_blank" rel="noopener noreferrer" class="copy-link-button flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden p-1 rounded-[64px] bg-[#eef0f2]">
                        <img src="../../../assets/imgs/instagram_78f5ed419d.svg" alt="instagram.svg" />
                    </a>
                    <a href="https://www.youtube.com/@solidaritybahrain3545/featured" target="_blank" rel="noopener noreferrer" class="copy-link-button flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden p-1 rounded-[64px] bg-[#eef0f2]">
                        <img src="../../../assets/imgs/youtube_02b01b39a1.svg" alt="youtube.svg" />
                    </a>
                    <a href="https://www.linkedin.com/company/insuringbahrain/" target="_blank" rel="noopener noreferrer" class="copy-link-button flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden p-1 rounded-[64px] bg-[#eef0f2]">
                        <img src="../../../assets/imgs/Linked_In_0acd5b3500.svg" alt="LinkedIn.svg" />
                    </a>
                </div>
              </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
              <div class="columns">
                  <div class="footer-list-box columns Insurance-Policies">
                      <h4>{{getLang == 'en' ? 'Insurance Policies' : ' برامج التأمين الاخرى'}}</h4>
                      <ul>
                          <li><a href="/product/motor" rel="noopener noreferrer">{{getLang == 'en' ? 'Motor Insurance' : 'التأمين على المركبات'}}</a></li>
                          <li><a href="/product/home" rel="noopener noreferrer">{{getLang == 'en' ? 'Home Insurance' : 'تأمين المنزل'}}</a></li>
                          <li><a href="/product/travel" rel="noopener noreferrer">{{getLang == 'en' ? 'Travel Insurance' : 'التأمين السفر'}}</a></li>
                          <li><a href="/product/domestic" rel="noopener noreferrer">{{getLang == 'en' ? 'Domestic Helper' : 'تأمين العمالة المنزلية'}}</a></li>
                          <li><a href="/product/personal" rel="noopener noreferrer">{{getLang == 'en' ? 'Personal Accident' : 'تأمين الحوادث الشخصية'}}</a></li>
                          <li><a href="/product/medical" rel="noopener noreferrer">{{getLang == 'en' ? 'Medical Insurance' : 'التأمين الصحي'}}</a></li>
                          <li><a href="/product/expat" rel="noopener noreferrer">{{getLang == 'en' ? 'Expat Medical Visa' : 'التأمين الطبي للإقامة'}}</a></li>
                          <li><a href="/product/cyber" rel="noopener noreferrer">{{getLang == 'en' ? 'Personal Cyber' : 'التأمين السيبراني'}}</a></li>
                          <li><a href="/product/smartdevice" rel="noopener noreferrer">{{getLang == 'en' ? 'Smart Device' : 'تأمين الأجهزة الذكية'}}</a></li>
                          <li><a href="/product/bupa" rel="noopener noreferrer">{{getLang == 'en' ? 'BUPA' : 'BUPA (بوبا)'}}</a></li>
                          <li><a href="/forbusinesses" rel="noopener noreferrer">{{getLang == 'en' ? 'For Businesses' : 'لللأعمال'}}</a></li>
                      </ul>
                  </div>
                  <div class="footer-list-box w-full columns Claims hidden md:block">
                      <h4>{{getLang == 'en' ? 'Claims' : 'المطالبات'}}</h4>
                      <ul>
                          <li><a href="https://online.solidarity.bh/claims" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Motor & Other Claims' : 'مطالبات المركبات أو غيرها'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/file-a-claim">{{getLang == 'en' ? 'Life Claims' : 'مطالبات التأمين على الحياة'}}</a></li>
                          <!-- <li><a href="/claims" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Other Claims' : 'مطالبات أخرى'}}</a></li> -->
                      </ul>
                  </div>
              </div>
              <div class="columns">
                  <div class="footer-list-box columns Savings-Protection-Plans">
                      <h4>{{getLang == 'en' ? 'Savings & Protection Plans' : 'برامج الادخار والحماية'}}</h4>
                      <ul>
                          <li><a href="https://life.solidarity.com.bh/product/savings-and-protection-plans">{{getLang == 'en' ? 'Overview' : 'نظرة عامة'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/savings-and-protection-plans/roya-education-insurance">{{getLang == 'en' ? "Ro'ya Education" : 'برنامج رؤية للتعليم'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/savings-and-protection-plans/roya-future">{{getLang == 'en' ? "Ro'ya Future" : 'برنامج رؤية للمستقبل'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/savings-and-protection-plans/roya-whole-life-insurance">{{getLang == 'en' ? "Ro'ya Whole of Life" : 'برنامج رؤية لمدى الحياة'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/savings-and-protection-plans/infinity-shield">{{getLang == 'en' ? 'Infinity Shield' : 'برنامج درع الحماية'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/savings-and-protection-plans/flexible-savings-plan">{{getLang == 'en' ? 'Flexible Savings' : 'برنامج الإدخار المرن'}}</a></li>
                      </ul>
                  </div>
                  <div class="footer-list-box w-full columns Protection-Plans hidden md:block">
                      <h4>{{getLang == 'en' ? 'Protection Plans' : 'برامج الحماية'}}</h4>
                      <ul>
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans">{{getLang == 'en' ? 'Overview' : 'نظرة عامة'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/weqaya">{{getLang == 'en' ? 'Weqaya' : 'برنامج وقاية'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/cancer-care-insurance">{{getLang == 'en' ? 'Weqaya Cancer Care' : 'برنامج وقاية Cancer Care'}}</a></li>
                          <!-- <li><a href="https://life.solidarity.com.bh/product/protection-plans/weqaya-total-care">{{getLang == 'en' ? 'Weqaya Total Care' : 'وقاية توتال كير'}}</a></li> -->
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/ultimate-care-insurance">{{getLang == 'en' ? 'Ultimate Care Plan' : 'برنامج الرعاية القصوى'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/vantage-life-plan">{{getLang == 'en' ? 'Vantage Life' : 'برنامج Vantage Life'}}</a></li>
                          <!-- <li><a href="https://life.solidarity.com.bh/product/protection-plans/hemaya">{{getLang == 'en' ? 'Hemaya' : 'حماية'}}</a></li> -->
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/protection-shield-plan">{{getLang == 'en' ? 'Protection Shield Plan' : 'برنامج درع الحماية'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/reayaa-personal-accident-insurance">{{getLang == 'en' ? 'Reayaa Personal Accident' : 'برنامج رعاية للحوادث الشخصية'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/protection-plans/decreasing-term-assurance">{{getLang == 'en' ? 'Finance Protection' : 'برنامج درع التمويل'}}</a></li>
                      </ul>
                  </div>
              </div>
              <div class="column block md:hidden">
                  <div class="footer-list-box w-full columns Protection-Plans">
                      <h4>{{getLang == 'en' ? 'Protection Plans' : 'برامج الحماية'}}</h4>
                      <ul>
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans">{{getLang == 'en' ? 'Overview' : 'نظرة عامة'}}</a></li>
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/weqaya">{{getLang == 'en' ? 'Weqaya' : 'برنامج وقاية'}}</a></li>
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/cancer-care-insurance">{{getLang == 'en' ? 'Weqaya Cancer Care' : 'برنامج وقاية Cancer Care'}}</a></li>
                        <!-- <li><a href="https://life.solidarity.com.bh/product/protection-plans/weqaya-total-care">{{getLang == 'en' ? 'Weqaya Total Care' : 'وقاية توتال كير'}}</a></li> -->
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/ultimate-care-insurance">{{getLang == 'en' ? 'Ultimate Care Plan' : 'برنامج الرعاية القصوى'}}</a></li>
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/vantage-life-plan">{{getLang == 'en' ? 'Vantage Life' : 'برنامج Vantage Life'}}</a></li>
                        <!-- <li><a href="https://life.solidarity.com.bh/product/protection-plans/hemaya">{{getLang == 'en' ? 'Hemaya' : 'حماية'}}</a></li> -->
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/protection-shield-plan">{{getLang == 'en' ? 'Protection Shield Plan' : 'برنامج درع الحماية'}}</a></li>
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/reayaa-personal-accident-insurance">{{getLang == 'en' ? 'Reayaa Personal Accident' : 'برنامج رعاية للحوادث الشخصية'}}</a></li>
                        <li><a href="https://life.solidarity.com.bh/product/protection-plans/decreasing-term-assurance">{{getLang == 'en' ? 'Finance Protection' : 'برنامج درع التمويل'}}</a></li>
                      </ul>
                  </div>
              </div>
              <div class="columns">
                  <!-- <div class="footer-list-box columns Buy-Online-Plans">
                      <h4>{{getLang == 'en' ? 'Buy Online Plans' : 'شراء الخطط عبر الإنترنت'}}</h4>
                      <ul>
                          <li><a href="https://life.solidarity.com.bh/product/online-plans">{{getLang == 'en' ? 'Overview' : 'نظرة عامة'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/online-plans/iCare-Cardiac">{{getLang == 'en' ? 'iCare Cardiac Shield' : 'درع القلب من آي كير'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/online-plans/iCare-Cancer">{{getLang == 'en' ? 'iCare Cancer Shield' : 'درع آي كير للسرطان'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/online-plans/hemaya-lite">{{getLang == 'en' ? 'Hemaya Lite' : 'حماية لايت'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/online-plans/online-reayaa-personal-accident">{{getLang == 'en' ? 'Reayaa Personal Accident' : 'ريا حادث شخصي'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/product/online-plans/express-shield">{{getLang == 'en' ? 'Express Shield' : 'اكسبريس شيلد'}}</a></li>
                      </ul>
                  </div> -->
                  <div class="footer-list-box columns Buy-Online-Plans hidden md:block">
                      <h4>{{getLang == 'en' ? 'Tools' : 'الأدوات'}}</h4>
                      <ul>
                          <li><a href="https://life.solidarity.com.bh/child-education-goal-calculator">{{getLang == 'en' ? 'University Calculator' : 'حاسبة التغطية الجامعية'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/insurance-coverage-calculator">{{getLang == 'en' ? 'Coverage Calculator' : 'حاسبة التغطية'}}</a></li>
                          <!-- <li><a href="https://life.solidarity.com.bh/learning-center">{{getLang == 'en' ? 'Learning Center' : 'مركز التعلم'}}</a></li> -->
                      </ul>
                  </div>
                  <div class="footer-list-box More">
                    <h4>{{getLang == 'en' ? 'More' : 'اخرى'}}</h4>
                    <ul>
                      <li><a href="/aboutus" rel="noopener noreferrer">{{getLang == 'en' ? 'About Us' : 'نبذة عنا'}}</a></li>
                      <li><a href="/news" rel="noopener noreferrer">{{getLang == 'en' ? 'News' : 'الأخبار'}}</a></li>
                      <li><a href="/careers" rel="noopener noreferrer">{{getLang == 'en' ? 'Careers' : 'الوظائف'}}</a></li>
                      <li><a href="/contactus" rel="noopener noreferrer">{{getLang == 'en' ? 'Contact Us' : 'تواصل معنا'}}</a></li>
                      <li><a href="/investorrelations" rel="noopener noreferrer">{{getLang == 'en' ? 'Investor Relations' : 'علاقات المستثمرين'}}</a></li>
                        <!-- <li><a href="https://life.solidarity.com.bh/-">{{getLang == 'en' ? 'Site Map' : 'خارطة الموقع'}}</a></li> -->
                    </ul>
                </div>
              </div>
              <div class="columns block md:hidden">
                  <div class="footer-list-box w-full columns Claims">
                      <h4>{{getLang == 'en' ? 'Claims' : 'المطالبات'}}</h4>
                      <ul>
                          <li><a href="https://online.solidarity.bh/claims" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Motor & Other Claims' : 'مطالبات المركبات أو غيرها'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/file-a-claim">{{getLang == 'en' ? 'Life Claims' : 'مطالبات التأمين على الحياة'}}</a></li>
                          <!-- <li><a href="/claims" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Other Claims' : 'مطالبات أخرى'}}</a></li> -->
                      </ul>
                  </div>
              </div>
              <div class="columns block md:hidden">
                  <div class="footer-list-box columns Resources">
                      <h4>{{getLang == 'en' ? 'Resources' : 'موارد'}}</h4>
                      <ul>
                          <li><a href="https://life.solidarity.com.bh/child-education-goal-calculator">{{getLang == 'en' ? 'University Calculator' : 'حاسبة التغطية الجامعية'}}</a></li>
                          <li><a href="https://life.solidarity.com.bh/insurance-coverage-calculator">{{getLang == 'en' ? 'Coverage Calculator' : 'حاسبة التغطية'}}</a></li>
                          <!-- <li><a href="https://life.solidarity.com.bh/learning-center">{{getLang == 'en' ? 'Learning Center' : 'مركز التعلم'}}</a></li> -->
                      </ul>
                  </div>
              </div>
              <div class="columns">
                  <div class="footer-list-box columns Support">
                      <h4>{{getLang == 'en' ? 'Support' : 'الدعم'}}</h4>
                      <ul>
                          <!-- <li><a href="https://life.solidarity.com.bh/learning-center">{{getLang == 'en' ? 'Learning Center' : 'مركز التعلم'}}</a></li> -->
                          <li><a href="/video">{{getLang == 'en' ? 'Video Call Insurance' : 'التأمين عبر الفيديو'}}</a></li>
                          <li><a href="https://api.whatsapp.com/send/?phone=97317130000&amp;text&amp;type=phone_number&amp;app_absent=0">{{getLang == 'en' ? 'Whatsapp' : 'الواتس اب'}}</a></li>
                          <li><a href="tel:17130000">{{getLang == 'en' ? 'Call Us +973 17130000' : ' اتصل بنا ١٧١٣٠٠٠٠ ٩٧٣+'}} </a></li>
                          <li><a href="/complaints">{{getLang == 'en' ? 'Complaints' : ' الشكاوي و الاقتراحات'}}</a></li>
                      </ul>
                  </div>
                  <!-- <div class="footer-list-box More hidden md:block">
                      <h4>{{getLang == 'en' ? 'More' : 'أكثر'}}</h4>
                      <ul>
                          <li><a href="/aboutus" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'About Us' : 'أكثر'}}</a></li>
                          <li><a href="/news" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'News' : 'أخبار'}}</a></li>
                          <li><a href="/careers" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Careers' : 'وظائف'}}</a></li>
                          <li><a href="/contactus" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Contact Us' : 'اتصل بنا'}}</a></li>
                          <li><a href="/investorrelations" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Investor Relations' : 'علاقات المستثمرين'}}</a></li>
                      </ul>
                  </div> -->
              </div>
              <!-- <div class="columns block md:hidden">
                  <div class="footer-list-box More">
                      <h4>{{getLang == 'en' ? 'More' : 'أكثر'}}</h4>
                      <ul>
                        <li><a href="/aboutus" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'About Us' : 'أكثر'}}</a></li>
                        <li><a href="/news" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'News' : 'أخبار'}}</a></li>
                        <li><a href="/careers" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Careers' : 'وظائف'}}</a></li>
                        <li><a href="/contactus" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Contact Us' : 'اتصل بنا'}}</a></li>
                        <li><a href="/investorrelations" target="_blank" rel="noopener noreferrer">{{getLang == 'en' ? 'Investor Relations' : 'علاقات المستثمرين'}}</a></li>
                      </ul>
                  </div>
              </div> -->
          </div>
      </div>
      <div class="flex footer-bottom-section">
          <h5 class="mb-2">
            <a href="/termsofuse">{{getLang == 'en' ? 'Terms of use' : 'شروط الاستخدام'}}</a> |<a href="/privacyandsecurity"> {{getLang == 'en' ? 'Privacy and Security' : 'الخصوصية و الأمان'}}</a> |
            <a href="https://content.solidarity.bh/uploads/solidarity_fees_and_charges_493d5a6b2e.pdf" target="_blank"> {{getLang == 'en' ? 'Fees & Charges' : 'الرسوم و المصاريف'}}</a>
        </h5>
          <h6>
            {{getLang == 'en' ? 'Copyright ©2024 Solidarity Bahrain B.S.C. All rights reserved. Solidarity Bahrain is a Subsidiary of Solidarity Group Holding. Licensed and regulated by the Central Bank of Bahrain as a locally incorporated Takaful Firm – Category four' 
            : 'حقوق الطبع والنشر ©2024 شركة سوليدرتي البحرين ش.م.ب. جميع الحقوق محفوظة. سوليدرتي لايف هي شركة تابعة لمجموعة سوليدرتي القابضة. مرخصة ومنظمة من قبل مصرف البحرين المركزي كشركة تكافل مؤسسة محليًا - الفئة الرابعة'}}

        </h6>
      </div>
      <div dir="ltr">
          <div dir="rtl"></div>
      </div>
  </footer>


  </div>

  <hr class="text-Light-Gray-4" />
  <!-- <div class="container mx-auto py-10" id="footer_content">
    <div class="flex md:flex-row sm:flex-wrap items-center justify-center">
      <div><a href="/termsofuse" class="text-Mid-Gray-3 text-center text-xs">
          {{getLang == 'en' ? "Terms of use" :
          "شروط الاستخدام"}}</a></div>
      <div>
        <p class="text-Mid-Gray-3 text-center text-xs mx-1">|</p>
      </div>
      <div>
        <a href="/privacyandsecurity" class="text-Mid-Gray-3 text-center text-xs">
          {{getLang == 'en' ? "Privacy and Security" : "الخصوصية والأمان"}}</a>
      </div>
      <div>
        <p class="text-Mid-Gray-3 text-center text-xs mx-1">|</p>
      </div>
      <div>
        <a href="https://content.solidarity.bh/uploads/solidarity_fees_and_charges_493d5a6b2e.pdf" target="_blank"
          class="text-Mid-Gray-3 text-center text-xs"> {{getLang == 'en' ? "Fees & Charges" :
          "الرسوم والأسعار"}}</a>
      </div>
    </div>
    <p class="text-Mid-Gray-3 text-center text-xs mt-4" [ngClass]="getLang == 'en' ? 'block' : 'hidden'">
      Copyright ©2023 Solidarity Bahrain B.S.C. All rights reserved. Solidarity
      Bahrain is a Subsidiary of Solidarity Group Holding. Licensed and
      regulated by the Central Bank of Bahrain as a locally incorporated Takaful
      Firm – Category four
    </p>
    <p class="text-Mid-Gray-3 text-center text-xs mt-4" [ngClass]="getLang == 'ar' ? 'block' : 'hidden'">
      © 2023 سوليدرتي البحرين | جميع الحقوق محفوظة | مرخصة من قبل مصرف البحرين المركزي كشركة تكافل محلية - الفئة الرابعة
    </p>
  </div> -->
</footer>