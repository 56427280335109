import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-domestic-inputs',
  templateUrl: './domestic-inputs.component.html',
  styleUrls: ['./domestic-inputs.component.css']
})
export class DomesticInputsComponent implements OnInit {
  isSubmitted = false;
  validations!: Validations;
  domesticForm: any;
  loading: boolean = false;
  accepted: boolean = false;
  captcharesolved = false;

  // AppType: Array<string> = ['New Application', 'Visa Renewal', 'Interim'];
  public AppType: any[] = [
    { code: '1', name: 'New Application' },
    { code: '2', name: 'Visa Renewal' },
    { code: '3', name: 'Interim' },
  ];
  public employmentType: any[] = [
    { code: 'Direct', name: 'Direct Sourcing' },
    { code: 'Office', name: 'Visa Employment Office' },
  ];
  public limits: any[] = [
    { code: '500', name: 'BHD 500' },
    { code: '1000', name: 'BHD 1000' },
    { code: '1500', name: 'BHD 1500' },
  ];
  public periods: any[] = [
    { code: '1', name: '1 Year' },
    { code: '2', name: '2 Year' },
  ];
  selectedLimit: string = '';
  selectedEmpType: string = '';
  selectedPeriod: string = '';
  selectedPeriodValue: string = '';
  selectedAppType: string = '';
  selectedAppTypeValue: string = '';

  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.domesticForm = new FormGroup({
      name: new FormControl(''),
      phone: new FormControl(''),
      date: new FormControl(''),
      dateForinterim: new FormControl(''),
      endDate: new FormControl(this.today())
    });
  }

  sendLead() {
    this.isSubmitted = true;
    this.loading = true;
    // console.log("Limit Chosen: " + this.selectedLimit);
    if (this.domesticForm.valid) {
      this.webApi.sendProductLead(
        this.domesticForm.get('name').value.toString(),
        this.domesticForm.get('phone').value.toString(),
        'GENERAL ACCIDENT',
        'New Insurance - Domestic helper',
        '',
        1,
        1,
        'New DHI Insurance Lead',
        '',
        null
      ).subscribe((s) => {
        this.encryptValuesAndSendToPortal();

      });

    }
  }

  encryptValuesAndSendToPortal() {
    const secretKey = 'Solid@abu1999DomesticInsurance';
    // Get the form data
    const formData = this.domesticForm.value;

    Object.keys(formData).forEach(key => {
      if (formData[key] === null || formData[key] === undefined) {
        formData[key] = '';
      }
    });
    formData['applicationType'] = this.selectedAppTypeValue ?? '';
    formData['employmentType'] = this.selectedEmpType ?? '';
    formData['policyPeriodCode'] = this.selectedPeriodValue ?? '';
    formData['LimitForAbsence'] = this.selectedLimit ?? '';
    let endDate = new Date(this.domesticForm.get('endDate').value);
    endDate.setFullYear(endDate.getFullYear() + Number(this.selectedPeriodValue));
    formData['endDate'] = this.dateToString(endDate);
    const jsonString = JSON.stringify(formData);

    // Encrypt the JSON string with DES
    const encrypted = CryptoJS.DES.encrypt(jsonString, secretKey).toString();
    var queryString = Object.keys(formData)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(encrypted)}`)
      .join('&');

    var url = environment.portalUrl + `/insurance-policies/domestic-helper/?${queryString}`;
    window.location.href = url;
    // console.log('Data: ' + jsonString);
  }

  selectLimit(limit: any) {
    this.selectedLimit = limit;
  }

  selectEmploymentType(type: any) {
    this.selectedEmpType = type;
    // console.log(type);
  }
  selectPolicyPeriod(period: any) {
    this.selectedPeriod = period;
    if (period == "1 Year" || period == "سنة واحدة") {
      this.selectedPeriodValue = '1';
    } else {
      this.selectedPeriodValue = '2';
    }
    // console.log(period);
  }
  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }
  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }
  selectApplicationType(type: any) {
    this.selectedAppTypeValue = type;
    if (type == 'New Application') {
      this.selectedAppType = 'new';
    }
    else if (type == 'Visa Renewal') {
      this.selectedAppType = 'renewal';
    }
    else if (type == 'Interim') {
      this.selectedAppType = 'interim';
    }
    else {
      this.selectedAppType = '';
    }
  }
  today() {
    let today = new Date();
    return this.dateToString(today);
  }

  dateToString(date: any) {
    const toTwoDigits = (num: string | number) => ((+num) < 10 ? "0" + num : num);
    let year = date.getFullYear();
    let month = toTwoDigits(date.getMonth() + 1);
    let day = toTwoDigits(date.getDate());
    return `${year}-${month}-${day}`;
  }

  afterMonth() {
    let today = new Date();
    today.setMonth(today.getMonth() + 3);
    return this.dateToString(today);
  }

  afterYear() {
    let today = new Date(this.domesticForm.get('endDate').value);
    today.setFullYear(today.getFullYear() + 2);
    return this.dateToString(today);
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
