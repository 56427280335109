import { Component, Input, OnInit } from '@angular/core';
import { Plan } from 'src/app/models/plan';

@Component({
  selector: 'app-plans-screen',
  templateUrl: './plans-screen.component.html',
  styleUrls: ['./plans-screen.component.css']
})
export class PlansScreenComponent implements OnInit {

  collapsing: boolean = true;
  @Input() plans?:Plan[];
  selectedPlan?:Plan;
  premium: Number = 0.000;
  totalPremium: Number = 0.000;
  tax : number = 0.000;
  @Input() callbackFunction?: (args: any) => void;

  constructor() { }

  ngOnInit(): void {
  }
  planSelectedCallbackFunction = (args: any): void => {
    this.selectedPlan = args;
    this.premium = this.selectedPlan?.Quote.Premium!;
    this.tax = this.selectedPlan?.Quote.Tax!;
    this.totalPremium = this.selectedPlan?.Quote.TotalPremium!;
    }
    nextClicked(){
      this.callbackFunction!(this.selectedPlan);

    }

 
}
