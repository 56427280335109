import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoPage } from 'src/app/models/video-page';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Validations } from 'src/app/models/validations';
declare var window: any; // Declare window as any to access global objects

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  video!: VideoPage;
  videoForm: any;
  isSubmitted = false;
  validations!: Validations;

  constructor(
    public strapiApi: StrapiApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      // this.title = params['data'].data.attributes.termsTitle;
      //this.terms = params['data'].data.attributes.terms;
      this.video = params['data'];
    });
    const script = document.createElement('script');
    // script.setAttribute('data-entry-id', 'cLQgcJirSQ2vCtoZFhiyUQ');
    script.setAttribute('data-env', 'us01');
    // script.setAttribute('data-apikey', '94NjPSW_RaC-qGF9bmJyQQ');
    script.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/video-client.js';
    document.head.appendChild(script);

    this.route.data.subscribe(async (params) => {
      this.validations = params['val'];
    });
    this.videoForm = new FormGroup({
      name: new FormControl(''),
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
  // Get the button that opens the modal
  showModal = false;
  showHighModal = false;

  // When the user clicks on the button, open the modal
  openModal() {
    this.showModal = true;
    // console.log(this.showModal);    
  }

  // When the user clicks on <span> (x), close the modal
  closeModal() {
    this.showModal = false;
    this.showHighModal = false;
  }


  startVideoCall() {
    this.isSubmitted = true;
    if (this.videoForm.valid) {
      this.closeModal();
      const entryId = 'cLQgcJirSQ2vCtoZFhiyUQ'; // Replace with actual entry ID
      const videoClient = new window.VideoClient(); // Access VideoClient via window

      videoClient.init({
        entryId,
        name: this.videoForm.get('name').value.toString(),
        // lang: this.getLang(),
        userName: this.videoForm.get('name').value.toString(),

      }).then(() => {
        videoClient.startVideo();
        videoClient.on('video-end', () => {
          console.log('video ended');
          // Add any additional actions when the video ends
        });
      }).catch((error: any) => {
        if (error instanceof Error) {
          console.error('Error initializing video client:', error.message);
        } else {
          console.error('An unknown error occurred:', error);
        }
      });
    }
  }

}
