import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Plan } from 'src/app/models/plan';
import { PolicyPremium } from 'src/app/models/policy-premium';

@Component({
  selector: 'app-motor-quote',
  templateUrl: './motor-quote.component.html',
  styleUrls: ['./motor-quote.component.css']
})
export class MotorQuoteComponent implements OnInit {

  selectedQuote: PolicyPremium | undefined;
  step: number = 1;
  plans: Array<Plan> = [];
  constructor(private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {
    let nav = this.router.getCurrentNavigation()?.extras.state;
    if (nav != undefined) {
      this.plans = nav["plans"];
    }
  }

  ngOnInit(): void {
  }
  planSelectedCallbackFunction = (args: Plan): void => {
    this.selectedQuote = args.Quote;
    this.step = 2;
    this.cdr.detectChanges();
    //callback code here
  }
}
