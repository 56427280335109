<main>

    <body style="align-items: center; align-content: center;">
        <div class="container items-center" style="align-items: center;">
            <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center"
                style="align-items: center; align-content: center;">
                <img width="50%" style="align-items: center;display: inline; align-content: center;"
                    src=" assets/imgs/not-found.svg" class="img-fluid" alt="Page Not Found" />
                <br><br><br>
                <h1 class="error-code">{{ 404 }}</h1>
                <h2 class="error-message">The page you are looking for doesn't exist.</h2>
                <button
                    class="button w-[150px] font-medium bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-sm justify-evenly"
                    style="align-items: center;" routerLink="/">Back to
                    home</button>
            </section>
        </div>
    </body>
</main>