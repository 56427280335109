import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { Attributes } from 'src/app/models/finincial-reports'

@Component({
  selector: 'app-financialreports',
  templateUrl: './financialreports.component.html',
  styleUrls: ['./financialreports.component.css']
})
export class FinancialreportsComponent implements OnInit {
  financialReports!: Attributes;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute,) { }

  async ngOnInit() {
    this.route.data.subscribe(async param => {
      this.financialReports = param['data2'].data.attributes;

    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
}