import { Component, Input, OnInit } from '@angular/core';
import { Plan } from 'src/app/models/plan';

@Component({
  selector: 'app-select-plan-box',
  templateUrl: './select-plan-box.component.html',
  styleUrls: ['./select-plan-box.component.css']
})
export class SelectPlanBoxComponent implements OnInit {
  @Input() plan?: Plan ;
  @Input() callbackFunction?: (args: any) => void;

  constructor() { }

  ngOnInit(): void {
  }

  palnClicked(){
    this.callbackFunction!(this.plan);
  }

}
