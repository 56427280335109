import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactusComponent implements OnInit {
  isSubmitted = false;
  validateEmail = true;
  validations!: Validations;
  ngForm: any;
  btnLoading: boolean = false;
  sentMessage: boolean = false;
  captcharesolved = false;
  sumInsured: string | undefined | null;
  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }
  options: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false
  };
  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.ngForm = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      msg: new FormControl(''),
      phone: new FormControl(''),
      subject: new FormControl(''),
    });
  }
  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 4;

  moveMap(event: google.maps.MapMouseEvent) { }

  move(event: google.maps.MapMouseEvent) { }

  async SendContactUsForm(form: NgForm): Promise<void> {
    this.isSubmitted = true;
    this.btnLoading = true;


    // console.log("Form Info: ");
    // console.log(this.ngForm.get('email').value, this.ngForm.get('phone').value, this.ngForm.get('name').value, this.ngForm.get('msg').value, this.ngForm.get('subject').value);

    if (form.valid) {
      // console.log("Form to put into ContactUS API:", form);
      this.webApi.contactUs(this.ngForm.get('name').value, this.ngForm.get('phone').value, this.ngForm.get('email').value,
        this.ngForm.get('subject').value, this.ngForm.get('msg').value, null).subscribe((success) => {
          this.router.navigate(['contactus'], { state: { quote: success } });
          this.sentMessage = true;
          this.btnLoading = false;
          this.resetForm();
        });
    }
    else {
      this.btnLoading = false;
    }
    // this.resetForm();


  }

  resetForm() {
    // this.ngForm.reset();
    // this.ngForm.markAsUntouched();
    window.location.reload();
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
