<form #myform="ngForm" [formGroup]="travelForm" (ngSubmit)="sendLead()">

  <div class="lg:flex md:flex mt-5">

    <div class="lg:mx-2 md:mx-2 lg:w-1/2 md:w-1/2" [ngClass]="getLang == 'ar' ? 'lg:pl-4 md:pl-4' : 'lg:pr-4 md:pr-4'">
      <label for="email" class="block text-sm font-normal ml-1 mb-2">
        {{getLang == 'en' ? "Email address" :
        "البريد الإلكتروني"}}</label>
      <div class="relative">
        <input id="email" type="email" name="email" formControlName="email" [email]="validateEmail"
          class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
          [ngClass]="myform.form.controls['email'].errors?.['email'] || myform.form.controls['email'].errors?.['required'] && isSubmitted ? 'errorInputed errorInput' : ''"
          placeholder="e.g name@example.com" required aria-describedby="email-error" />
      </div>
      <mat-error *ngIf="myform.form.controls['email']?.errors?.['email']">
        {{getLang == 'en' ? validations.data[0].attributes.error_en : validations.data[0].attributes.error_ar}}
      </mat-error>
      <mat-error *ngIf="myform.form.controls['email']?.errors?.['required'] && isSubmitted">
        {{getLang == 'en' ? validations.data[0].attributes.required_en : validations.data[0].attributes.required_ar}}
      </mat-error>
    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:pt-0 md:pt-0 sm:pt-4">
      <label for="mat-input-0" class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Mobile Number"
        : "رقم الهاتف"}}</label>
      <div class="relative flex">
        <ngx-mat-intl-tel-input
          class="h-10 py-2 px-4 country-selector block border border-Light-Gray-2   rounded-md text-sm  w-full"
          inputPlaceholder=""
          [ngClass]="myform.form.controls['phone'].errors?.['validatePhoneNumber'] || travelForm.get('phone').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
          formControlName="phone" [enablePlaceholder]="true" [enableSearch]="true"
          [preferredCountries]="['bh', 'sa', 'ae', 'kw']" name="phone" formControlName="phone">
        </ngx-mat-intl-tel-input>

      </div>
      <mat-error *ngIf="travelForm.get('phone').value == '' && isSubmitted"> {{getLang == 'en' ?
        validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
      <mat-error *ngIf="myform.form.controls['phone']?.errors?.['validatePhoneNumber']">{{getLang == 'en' ?
        validations.data[1].attributes.error_en : validations.data[1].attributes.error_ar}}</mat-error>
    </div>
  </div>
  <div class="lg:flex md:flex lg:mt-14 md:mt-14 sm:pt-4">
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2" [ngClass]="getLang == 'ar' ? 'lg:pl-2 md:pl-2' : 'lg:pr-2 md:pr-2'">
      <label for="from" class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Traveling From"
        : "السفر من"}}</label>
      <div class="relative">
        <input [ngClass]="travelForm.get('from').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
          type="date" id="from" name="from"
          class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm  " required
          aria-describedby="email-error" formControlName="from" [min]="tomorrow()" [max]="nextThreeMonths()" />
      </div>
      <mat-error *ngIf="travelForm.get('from').value == '' && isSubmitted"> {{getLang == 'en' ?
        validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2 lg:pt-0 md:pt-0 sm:pt-4"
      [ngClass]="travelForm.get('from').value == '' ? 'hidden' : 'block'">
      <label for="to" class="block text-sm font-normal ml-1 mb-2 ">{{getLang == 'en' ? "To" : "إلى"}}</label>
      <div class="relative">
        <input [ngClass]="travelForm.get('to').value == '' && isSubmitted ? 'errorInputed errorInput' : ''" type="date"
          id="to" name="to" class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm" required
          aria-describedby="email-error" formControlName="to" [min]="afterThisDate(travelForm.get('from').value)"
          [max]="oneYearFromThisDate(travelForm.get('from').value)" [disabled]="isDisabled">
      </div>
      <mat-error *ngIf="travelForm.get('to').value == '' && isSubmitted"> {{getLang == 'en' ?
        validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2 lg:pt-0 md:pt-0 sm:pt-4"
      [ngClass]="travelForm.get('from').value == '' ? 'block' : 'hidden'">
      <label for="to" class="block text-sm font-normal ml-1 mb-2 text-Mid-Gray-4">{{getLang == 'en' ? "To"
        :"إلى"}}</label>
      <div class="relative">

        <input class="h-10 py-3 px-4 block w-full border-1 text-Mid-Gray-4 border-Light-Gray-2 rounded-md text-sm"
          type="date" disabled>
      </div>
    </div>

  </div>
  <div class="flex mt-5 px-2 pt-6">
    <input type="checkbox" name="under25" id="under25"
      class="rounded form-checkbox h-5 w-5 text-primary-500 bg-Light-Gray-5 border border-Mid-Gray-5 focus:ring-primary-300 focus:ring-4 ring-offset-0"
      value="accepted" (change)="onCheckboxChangeAccepted($event)" />

    <label for="under25" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
      [ngClass]="getLang == 'en' ? 'block' : 'hidden'">I agree to the <a href="termsofuse"
        class="underline underline-offset-2 font-medium">Terms of Use</a> and <a href="privacyandsecurity"
        class="underline underline-offset-2 font-medium">Privacy Policy</a>
    </label>
    <label for="under25" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
      [ngClass]="getLang == 'ar' ? 'block' : 'hidden'"> أوافق على <a href="termsofuse"
        class="underline underline-offset-2 font-medium">شروط الاستخدام </a> و <a href="privacyandsecurity"
        class="underline underline-offset-2 font-medium">سياسة الخصوصية</a>
    </label>
  </div><br>
  <div class="flex mt-2 px-2 items-center justify-center">
    <re-captcha (resolved)="resolved($event)"></re-captcha>
  </div>
  <div class="flex mt-14">
    <div class="flex-1">
      <div class="w-full px-3 mb-5">
        <button [disabled]="this.loading == true"
          class="button flex w-full enabled:bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg text-center justify-center"
          type="submit"
          [disabled]="(this.travelForm.get('email').value == '' && this.travelForm.get('email').valid) || (this.travelForm.get('phone').value == '') || 
                                      this.travelForm.get('from').value == '' || this.travelForm.get('to').value == '' || !accepted || !captcharesolved">
          <span *ngIf="!this.loading">{{getLang == 'en' ? "View Prices" : "عرض الأسعار"}}</span>
          <div role="status" *ngIf="this.loading" class="">
            <svg aria-hidden="true" class="mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"></path>
            </svg>

            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</form>