import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplaintsSection } from 'src/app/models/complaints-section';
import { Validations } from 'src/app/models/validations';
import { FilesapiService } from 'src/app/services/filesapi.service';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.css']
})
export class ComplaintsComponent implements OnInit {
  validations!: Validations;
  ngForm: any;
  isSubmitted = false;
  captcharesolved = false;
  loading = false;
  imageUrl: string = '';
  btnLoading = false;
  selectedProd: string = '';
  sentMessage = false;
  base64File = '';
  file_name = '';
  file_link = '';
  item_ID: string = '';
  documentToken: string = '';
  file_id: number = 0;
  product!: ComplaintsSection;

  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute, public filesAPi: FilesapiService, public strapiApi: StrapiApiService) { }

  options: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  };

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
      this.product = params['data2'];
    });
    this.ngForm = new FormGroup({
      fname: new FormControl(''),
      lname: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      claimNumber: new FormControl(''),
      polNumber: new FormControl(''),
      subject: new FormControl(''),
      msg: new FormControl(''),
      attachment: new FormControl(''),
      vNumber: new FormControl('')
    });

  }
  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 4;

  moveMap(event: google.maps.MapMouseEvent) { }

  move(event: google.maps.MapMouseEvent) { }

  checking() {
    this.isSubmitted = true;
  }
  selectProduct(product: any) {
    this.selectedProd = product;
    console.log(this.selectedProd);
  }
  messageSent() {
    this.sentMessage = false;
    this.selectedProd = '';
    this.isSubmitted = false;
    this.imageUrl = '';
    this.ngForm.reset();
    location.reload();
  }
  async Complaint(form: NgForm): Promise<void> {
    // console.log(form);
    this.isSubmitted = true;
    this.btnLoading = true;
    if (form.valid) {
      console.log(form);
      // await this.GenerateLink();
      await
        // console.log(this.file_link);
        this.webApi.sendComplaint(this.ngForm.get('fname').value,
          this.ngForm.get('phone').value,
          this.ngForm.get('lname').value,
          this.ngForm.get('email').value,
          'Complaint - ' + this.ngForm.get('subject').value,
          this.selectedProd,
          this.ngForm.get('msg').value + ' Link: ' + this.file_link,
          this.ngForm.get('claimNumber').value,
          this.ngForm.get('polNumber').value,
          this.ngForm.get('vNumber').value, null).subscribe((s) => {
            this.router.navigate(['complaints'], { state: { quote: s } });
            this.sentMessage = true;
            this.btnLoading = false;
          });
    } else {
      this.btnLoading = false;
    }

  }

  // Handling document upload, making it as a link by using the APIs in three steps
  // Getting uploaded document info
  onFileUploaded(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files || inputElement.files.length === 0) {
      return;
    }
    const file = inputElement.files[0];
    this.file_name = file.name;
    const reader = new FileReader();

    this.loading = true;

    reader.onload = () => {
      this.loading = false;
      this.imageUrl = reader.result as string;
      // Extract the base64 data from the data URL
      this.base64File = this.imageUrl.split(',')[1];
      // console.log(this.file_name);

      // console.log(this.base64File);
      //this.imageLink();
    };

    reader.readAsDataURL(file);

    this.getToken();

  }

  // 1. Request token
  getToken() {

    this.item_ID = "[COMPLIANT]" + this.ngForm.get('phone').value + "/" + this.file_name;
    // console.log(this.item_ID);

    this.filesAPi.getToken(this.item_ID, "Complaint").subscribe((success) => {
      this.documentToken = (success as any).data.token;

      // console.log("Document token is: ", this.documentToken);
      this.uploadFile();

    })


  }

  // 2. Upload file
  uploadFile() {
    this.filesAPi.uploadFile(this.documentToken, this.file_name, this.base64File).subscribe((success) => {
      this.file_id = (success as any).data.file_id
      this.requestFileLink();
    })
  }

  // 3. Request link
  requestFileLink() {
    this.filesAPi.requestLink(this.file_id).subscribe((success) => {
      this.file_link = (success as any).data.link;

      // console.log("Final Link: ", this.file_link);

    })
  }
  // GenerateLink() {
  //   this.filesAPi.getToken(this.ngForm.get('phone').value, 'Complaint').subscribe(
  //     (response: any) => {
  //       // handle the data returned by the API endpoint
  //       console.log(response['data']['token']);
  //       var token = response['data']['token'];
  //       this.uploadFile(token);

  //     },
  //     (error) => {
  //       // handle the error if the API request fails
  //       console.error(error);
  //     }
  //   );
  //   //console.log('Token: ' + token);
  // }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  // requestFileLink(fileId: number) {
  //   this.filesAPi.requestLink(fileId).subscribe(
  //     (response3: any) => {
  //       this.file_link = response3['data']['link'];
  //       console.log("Link: " + this.file_link);
  //     },
  //     (error3) => {
  //       console.error(error3);
  //     }
  //   );
  // }

  // uploadFile(token: string) {
  //   this.filesAPi.uploadFile(token, this.file_name, this.base64File).subscribe(
  //     async (response: any) => {
  //       console.log(response['data']['file_id']);
  //       var fileId = response['data']['file_id'];
  //       await this.requestFileLink(fileId);

  //     },
  //     (error2) => {
  //       console.error(error2);
  //     }
  //   );
  // }



  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.files || inputElement.files.length === 0) {
      return;
    }
    const file = inputElement.files[0];
    this.file_name = file.name;
    const reader = new FileReader();

    this.loading = true;

    reader.onload = () => {
      this.loading = false;
      this.imageUrl = reader.result as string;
      // Extract the base64 data from the data URL
      this.base64File = this.imageUrl.split(',')[1];
      //console.log(base64Data);
      //this.imageLink();
    };

    reader.readAsDataURL(file);
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  phoneNumber: any; // You need to declare a property to bind to ngModel

  onPhoneNumberChange(event: any) {
    console.log('Phone number changed:', this.ngForm.get('phone').value);
    // You can perform any additional logic with the phone number here
  }

}
