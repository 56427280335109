<app-header bg="bg-white bg-primary-50"></app-header>

<div class="lg:absolute md:absolute inset-0 lg:bottom-4 md:bottom-72 ">
  <img class="lg:h-[800px] md:h-[700px] sm:h-[350px] sm:pt-20 w-full lg:object-cover sm:object-cover md:object-cover"
    [src]="strapiApi.getImage(bgImage)" alt="">
</div>

<div *ngIf="!changeView">
  <div class="p-2 w-full lg:min-h-[610px] mid:min-h-[610px] items-center lg:pt-36 md:pt-20 sm:pt-3" dir="auto">
    <p dir="auto"
      class="sm:text-[40px] font-medium text-black px-3 {{getLang == 'en' ? '' : 'text-right'}} lg:hidden md:hidden sm:block"
      [ngClass]="getLang == 'en' ? 'text-left' : 'ml-24'">
      <br>
      {{ getLang == "en" ? forBusinesses.title_en :
      forBusinesses.title_ar }}
    </p>
    <br>
    <p class="px-3 text-2xl text-black {{getLang == 'en' ? '' : 'text-right'}} pr-2 lg:hidden md:hidden sm:block sm:pb-4">{{ getLang
      ==
      "en" ? forBusinesses.desc_en : forBusinesses.desc_ar }}</p>
    <div
      class="lg:block md:block sm:hidden lg:max-w-3xl md:max-w-2xl sm:max-w-md  mx-auto absolute inset-x-0 z-10 px-6 container pt-96">
      <div class="p-6 bg-white rounded-[10px] shadow-lg items-center " dir="auto">
        <div class="w-full text-center">
          <p class="text-5xl font-medium text-black">{{ getLang == "en" ? forBusinesses.title_en :
            forBusinesses.title_ar
            }}</p>
          <br><br>
          <p class="text-2xl text-black">{{ getLang == "en" ? forBusinesses.desc_en : forBusinesses.desc_ar }}</p>
          <br>
        </div>
      </div>
    </div>
    <div class="lg:block md:block sm:hidden">
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>
  </div>
  <!-- <div class="p-2 w-full min-h-[610px]  items-center lg:pt-36 md:pt-20 sm:pt-3" dir="auto" *ngIf="changeView">
  <p dir="auto"
    class="sm:text-[40px] font-medium text-black sm:pt-80 pr-4 {{getLang == 'en' ? '' : 'text-right'}} lg:hidden md:hidden sm:block"
    [ngClass]="getLang == 'en' ? 'text-left' : 'ml-24'"> {{ getLang == "en" ? forBusinesses.title_en :
    forBusinesses.title_ar }}</p>
  <br>
  <p class="text-2xl text-black {{getLang == 'en' ? '' : 'text-right'}} pr-2 lg:hidden md:hidden sm:block">{{ getLang ==
    "en" ? forBusinesses.desc_en : forBusinesses.desc_ar }}</p>
  <div class="lg:max-w-3xl md:max-w-2xl sm:max-w-md  mx-auto absolute inset-x-0 z-10 px-6 container pt-96">
    <div class="p-6 bg-white rounded-[10px] shadow-lg items-center " dir="auto">
      <div class="w-full text-center">
        <p class="text-5xl font-medium text-black">{{ getLang == "en" ? forBusinesses.title_en : forBusinesses.title_ar
          }}</p>
        <br><br>
        <p class="text-2xl text-black">{{ getLang == "en" ? forBusinesses.desc_en : forBusinesses.desc_ar }}</p>
        <br>
      </div>
    </div>
  </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br> -->


  <!-- <div class="absolute inset-0 lg:bottom-0 md:bottom-72">
  <img
    class="lg:h-[750px] md:h-[700px] sm:h-[320px] w-full lg:object-cover md:lg:object-cover sm:object-cover lg:object-center md: sm:object-right"
    [src]="strapiApi.getImage(bgImage)" alt="">
</div> -->
  <!-- <div class="" dir="auto"> -->
  <!-- <div
    class="p-2 w-full items-center lg:min-h-[650px] md:min-h-[596px] sm:min-h-[348px] max-w-7xl relative container px-4 mx-auto lg:pr-">
    <div class="w-full lg:mt-10 md:mt-16 px-4 lg:block md:block sm:hidden">
      <div class="lg:text-7xl font-medium text-Dark-Gray-2 sm:text-4xl md:text-6xl">
        {{ getLang == "en" ? forBusinesses.title_en : forBusinesses.title_ar }}
      </div>
      <p class="lg:text-xl lg:w-3/4 md:text-base sm:text-sm md:w-4/5 text-Dark-Gray-2 pt-6">
        {{ getLang == "en" ? forBusinesses.desc_en : forBusinesses.desc_ar }}
      </p>
    </div>
    <div class="w-full lg:mt-10 md:mt-16 px-4 lg:hidden md:hidden sm:block pt-72">
      <div class="lg:text-7xl font-medium text-Dark-Gray-2 sm:text-4xl md:text-6xl">
        {{ getLang == "en" ? forBusinesses.title_en : forBusinesses.title_ar }}
      </div>
      <p class="lg:text-xl lg:w-3/4 md:text-base sm:text-sm md:w-4/5 text-Dark-Gray-2 pt-6">
        {{ getLang == "en" ? forBusinesses.desc_en : forBusinesses.desc_ar }}
      </p>
    </div> -->

  <!-- <div
      class="lg:block md:block sm:hidden mx-auto lg:-bottom-1/2 md:-bottom-[10%] sm:-bottom-[20%] inset-x-0 container px-4 sm:max-w-xs lg:max-w-4xl md:max-w-xl md:mt-14 lg:mt-10 sm:mt-5 z-10">
      <img class="mx-auto w-full h-full" [src]="strapiApi.getImage(bgImage)" id="box" />
    </div> -->

  <!-- <div class="lg:hidden md:hidden sm:block pt-8 flex">
      <img class="mx-auto w-full h-full" [src]="strapiApi.getImage(bgImage)" id="box" />
    </div> -->
  <!-- </div>
</div> -->

  <div class="mx-auto max-w-7xl px-4 sm:px-6 container lg:pt-px-100 md:pt-24 pb-12" dir="auto">
    <div class="lg:hidden md:hidden" dir="auto">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" #teams name="tabs"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        (change)="onSelected(teams.value)">
        <option value="property" selected *ngIf="showproperty">
          {{ getLang == "en" ? "Property" : "الممتلكات" }}
        </option>
        <option value="property" *ngIf="!showproperty">
          {{ getLang == "en" ? "Property" : "الممتلكات" }}
        </option>
        <option value="engineering" selected *ngIf="showEng">
          {{ getLang == "en" ? "Engineering" : "المشاريع" }}
        </option>
        <option value="engineering" *ngIf="!showEng">
          {{ getLang == "en" ? "Engineering" : "المشاريع" }}
        </option>
        <option value="general" selected *ngIf="showGen">
          {{ getLang == "en" ? "General Accident" : "الحوادث العامة" }}
        </option>
        <option value="general" *ngIf="!showGen">
          {{ getLang == "en" ? "General Accident" : "الحوادث العامة" }}
        </option>
        <option value="liability" selected *ngIf="showLiability">
          {{ getLang == "en" ? "Liability" : "المسؤولية" }}
        </option>
        <option value="liability" *ngIf="!showLiability">
          {{ getLang == "en" ? "Liability" : "المسؤولية" }}
        </option>
        <option value="marine" selected *ngIf="showMarine">
          {{ getLang == "en" ? "Marine" : "البضائع و السفن" }}
        </option>
        <option value="marine" *ngIf="!showMarine">
          {{ getLang == "en" ? "Marine" : "البضائع و السفن" }}
        </option>
        <option value="medical" selected *ngIf="showMedical">
          {{ getLang == "en" ? "Medical & Life" : "الصحة و الحياة" }}
        </option>
        <option value="medical" *ngIf="!showMedical">
          {{ getLang == "en" ? "Medical & Life" : "الصحة و الحياة" }}
        </option>

        <!-- <option value="news" selected *ngIf="showNews">Shareholder News</option>
      <option value="news" *ngIf="!showNews">Shareholder News</option>
      <option value="query" selected *ngIf="showQuery">Shareholder Query</option>
      <option value="query" *ngIf="!showQuery">Shareholder Query</option>


      <option value="reports" selected *ngIf="showReports">Finincial Reports</option>
      <option value="reports"  *ngIf="!showReports">Finincial Reports</option> -->
      </select>
    </div>
    <div class="sm:hidden lg:block md:block" dir="auto">
      <div class="border-b border-Light-Gray-3">
        <nav class="-mb-px flex space-x-6" aria-label="Tabs">
          <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <button class="button" [ngClass]="
            showproperty
              ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
              : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
          " (click)="selection('property')">
            {{ getLang == "en" ? "Property" : "الممتلكات" }}
          </button>

          <button class="button" [ngClass]="
            showEng
              ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg pr-4'
              : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg pr-4'
          " (click)="selection('engineering')">
            {{ getLang == "en" ? "Engineering" : "المشاريع" }}
          </button>

          <button class="button" [ngClass]="
            showGen
              ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
              : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
          " (click)="selection('general')">
            {{ getLang == "en" ? "General Accident" : "الحوادث العامة" }}
          </button>
          <button class="button" [ngClass]="
            showLiability
              ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
              : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
          " (click)="selection('liability')">
            {{ getLang == "en" ? "Liability" : "المسؤولية" }}
          </button>
          <button class="button" [ngClass]="
            showMarine
              ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
              : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
          " (click)="selection('marine')">
            {{ getLang == "en" ? "Marine" : "البضائع و السفن" }}
          </button>
          <button class="button" [ngClass]="
            showMedical
              ? 'border-indigo-500 text-primary-500  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
              : 'border-transparent text-Mid-Gray-3 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pt-4 px-1 border-b-2 font-medium lg:text-[26px] md:text-lg'
          " (click)="selection('medical')">
            {{ getLang == "en" ? "Medical & Life" : "الصحة و الحياة" }}
          </button>
        </nav>
      </div>
    </div>
    <div [ngClass]="showproperty ? 'block' : 'hidden'" class="pt-8">
      <p class="max-w-[648px] text-lg text-Mid-Gray-1 pb-4">
        {{
        getLang == "en"
        ? forBusinesses.property.property_desc_en
        : forBusinesses.property.property_desc_ar
        }}
      </p>
      <div *ngFor="let property of forBusinesses.property.points">
        <div class="flex border border-t-0 border-r-0 border-l-0 max-w-[552px] border-Light-Gray-3 py-4">
          <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5L8 14.5L12 10.5" stroke="#252C32" />
          </svg>

          <button class="button pl-4 pr-2" (click)="showPoint(property.point_en)">
            {{ getLang == "en" ? property.point_en : property.point_ar }}
          </button>
        </div>

        <p class="py-4 max-w-[552px] text-Mid-Gray-1" [ngClass]="point == property.point_en ? 'block' : 'hidden'">
          {{ getLang == "en" ? property.point_desc_en : property.point_desc_ar }}
        </p>
      </div>
    </div>
    <div [ngClass]="showEng ? 'block' : 'hidden'" class="pt-8">
      <p class="max-w-[648px] text-lg text-Mid-Gray-1 pb-4">
        {{
        getLang == "en"
        ? forBusinesses.Engineering.property_desc_en
        : forBusinesses.Engineering.property_desc_ar
        }}
      </p>
      <div *ngFor="let property of forBusinesses.Engineering.points">
        <div class="flex border border-t-0 border-r-0 border-l-0 max-w-[552px] border-Light-Gray-3 py-4">
          <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5L8 14.5L12 10.5" stroke="#252C32" />
          </svg>

          <button class="button pl-4 pr-2" (click)="showPoint(property.point_en)">
            {{ getLang == "en" ? property.point_en : property.point_ar }}
          </button>
        </div>

        <p class="py-4 max-w-[552px] text-Mid-Gray-1" [ngClass]="point == property.point_en ? 'block' : 'hidden'">
          {{ getLang == "en" ? property.point_desc_en : property.point_desc_ar }}
        </p>
      </div>
    </div>
    <div [ngClass]="showGen ? 'block' : 'hidden'" class="pt-8">
      <p class="max-w-[648px] text-lg text-Mid-Gray-1 pb-4">
        {{
        getLang == "en"
        ? forBusinesses.GeneralAccident.property_desc_en
        : forBusinesses.GeneralAccident.property_desc_ar
        }}
      </p>
      <div *ngFor="let property of forBusinesses.GeneralAccident.points">
        <div class="flex border border-t-0 border-r-0 border-l-0 max-w-[552px] border-Light-Gray-3 py-4">
          <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5L8 14.5L12 10.5" stroke="#252C32" />
          </svg>

          <button class="button pl-4 pr-2" (click)="showPoint(property.point_en)">
            {{ getLang == "en" ? property.point_en : property.point_ar }}
          </button>
        </div>

        <p class="py-4 max-w-[552px] text-Mid-Gray-1" [ngClass]="point == property.point_en ? 'block' : 'hidden'">
          {{ getLang == "en" ? property.point_desc_en : property.point_desc_ar }}
        </p>
      </div>
    </div>
    <div [ngClass]="showLiability ? 'block' : 'hidden'" class="pt-8">
      <p class="max-w-[648px] text-lg text-Mid-Gray-1 pb-4">
        {{
        getLang == "en"
        ? forBusinesses.Liability.property_desc_en
        : forBusinesses.Liability.property_desc_ar
        }}
      </p>
      <div *ngFor="let property of forBusinesses.Liability.points">
        <div class="flex border border-t-0 border-r-0 border-l-0 max-w-[552px] border-Light-Gray-3 py-4">
          <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5L8 14.5L12 10.5" stroke="#252C32" />
          </svg>

          <button class="button pl-4 pr-2" (click)="showPoint(property.point_en)">
            {{ getLang == "en" ? property.point_en : property.point_ar }}
          </button>
        </div>

        <p class="py-4 max-w-[552px] text-Mid-Gray-1" [ngClass]="point == property.point_en ? 'block' : 'hidden'">
          {{ getLang == "en" ? property.point_desc_en : property.point_desc_ar }}
        </p>
      </div>
    </div>
    <div [ngClass]="showMarine ? 'block' : 'hidden'" class="pt-8">
      <p class="max-w-[648px] text-lg text-Mid-Gray-1 pb-4">
        {{
        getLang == "en"
        ? forBusinesses.Marine.property_desc_en
        : forBusinesses.Marine.property_desc_ar
        }}
      </p>
      <div *ngFor="let property of forBusinesses.Marine.points">
        <div class="flex border border-t-0 border-r-0 border-l-0 max-w-[552px] border-Light-Gray-3 py-4">
          <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5L8 14.5L12 10.5" stroke="#252C32" />
          </svg>

          <button class="button pl-4 pr-2" (click)="showPoint(property.point_en)">
            {{ getLang == "en" ? property.point_en : property.point_ar }}
          </button>
        </div>

        <p class="py-4 max-w-[552px] text-Mid-Gray-1" [ngClass]="point == property.point_en ? 'block' : 'hidden'">
          {{ getLang == "en" ? property.point_desc_en : property.point_desc_ar }}
        </p>
      </div>
    </div>
    <div [ngClass]="showMedical ? 'block' : 'hidden'" class="pt-8">
      <p class="max-w-[648px] text-lg text-Mid-Gray-1 pb-4">
        {{
        getLang == "en"
        ? forBusinesses.MedicalAndLife.property_desc_en
        : forBusinesses.MedicalAndLife.property_desc_ar
        }}
      </p>
      <div *ngFor="let property of forBusinesses.MedicalAndLife.points">
        <div class="flex border border-t-0 border-r-0 border-l-0 max-w-[552px] border-Light-Gray-3 py-4">
          <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5L8 14.5L12 10.5" stroke="#252C32" />
          </svg>

          <button class="button pl-4 pr-2" (click)="showPoint(property.point_en)">
            {{ getLang == "en" ? property.point_en : property.point_ar }}
          </button>
        </div>

        <p class="py-4 max-w-[552px] text-Mid-Gray-1" [ngClass]="point == property.point_en ? 'block' : 'hidden'">
          {{ getLang == "en" ? property.point_desc_en : property.point_desc_ar }}
        </p>
      </div>
    </div>
  </div>
  <form #myform="ngForm" [formGroup]="ngForm" (ngSubmit)="sendForm(ngForm)">
    <div class="bg-Light-Gray-5" dir="auto">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 container">
        <p class="lg:text-6xl md:text-6xl sm:text-[30px] font-medium text-center">
          {{
          getLang == "en" ? "Insure your business" : "قم بتأمين مشروعك التجاري"
          }}
        </p>
        <p class="text-Mid-Gray-1 py-4 text-center">
          {{
          getLang == "en"
          ? "Use the form below to express your interest in Solidarity for Business"
          : "قم بملء الاستمارة التالية للتعرف على مزايا برامج سوليدرتي لتأمين الشركات."
          }}
        </p>
        <div
          class="pt-4 my-4 lg:max-w-3xl md:max-w-2xl sm:max-w-2xl mx-auto bg-white rounded-[10px] shadow-lg items-center inset-x-0 z-10 px-6 container"
          id="box">
          <div class="w-full">
            <p class="lg:text-3xl md:text-3xl sm:text-2xl font-medium text-Dark-Gray-2">
              {{ getLang == "en" ? "Get in touch" : "تواصل معنا" }}
            </p>
          </div>
          <div class="lg:flex md:flex mt-5">
            <div class="mr-2 lg:w-1/2 md:w-1/2" [ngClass]="getLang == 'ar' ? 'pl-4' : 'pr-2'">
              <label for="name" class="block text-sm font-normal ml-1 mb-2">{{
                getLang == "en" ? "Name" : "الاسم"
                }}</label>
              <div class="relative">
                <input type="text" id="name" name="name" placeholder="e.g. John Doe" formControlName="name"
                  [ngClass]="ngForm.get('name').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
                  class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm focus:border-Mid-Gray-1 focus:ring-Mid-Gray-1"
                  required aria-describedby="email-error" />
              </div>
              <mat-error *ngIf="myform.form.controls['name']?.errors?.['required'] && isSubmitted">
                {{getLang == 'en' ? validations.data[1].attributes.required_en :
                validations.data[1].attributes.required_ar}}
              </mat-error>
            </div>
            <div class="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 sm:mt-5">
              <label for="email" class="block text-sm font-normal ml-1 mb-2">{{
                getLang == "en" ? "Mobile Number" : "رقم الهاتف"
                }}</label>
              <div class="relative" [ngClass]="getLang == 'ar' ? 'pl-4 pr-2' : 'pr-4'">
                <ngx-mat-intl-tel-input
                  class="h-10 py-3 px-4 country-selector block border border-Light-Gray-2  rounded-md text-sm  w-full"
                  inputPlaceholder=""
                  [ngClass]="myform.form.controls['phone'].errors?.['validatePhoneNumber'] || ngForm.get('phone').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
                  formControlName="phone" [enablePlaceholder]="true" [enableSearch]="true"
                  [preferredCountries]="['bh', 'sa', 'ae', 'kw']" name="phone" formControlName="phone">
                </ngx-mat-intl-tel-input>
              </div>
              <mat-error *ngIf="ngForm.get('phone').value == '' && isSubmitted"> {{getLang == 'en' ?
                validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
              <mat-error *ngIf="myform.form.controls['phone']?.errors?.['validatePhoneNumber']">{{getLang == 'en' ?
                validations.data[1].attributes.error_en : validations.data[1].attributes.error_ar}}</mat-error>
            </div>
          </div>
          <div class="lg:flex md:flex mt-5">
            <div class="mr-2 lg:w-1/2 md:w-1/2">
              <label for="email" class="block text-sm font-normal ml-1 mb-2">{{
                getLang == "en" ? "Email Address" : "البريد الإلكتروني"
                }}</label>
              <div class="relative" [ngClass]="getLang == 'ar' ? 'pl-4' : 'pr-2'">
                <input type="email" id="email" name="email" formControlName="email"
                  [ngClass]="myform.form.controls['email'].errors?.['email'] || myform.form.controls['email'].errors?.['required'] && isSubmitted ? 'errorInputed errorInput' : ''"
                  [email]="validateEmail"
                  class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                  placeholder="e.g name@example.com" aria-describedby="email-error" />
              </div>
              <mat-error *ngIf="myform.form.controls['email']?.errors?.['email']">
                {{getLang == 'en' ? validations.data[0].attributes.error_en : validations.data[0].attributes.error_ar}}
              </mat-error>
              <mat-error *ngIf="myform.form.controls['email']?.errors?.['required'] && isSubmitted">
                {{getLang == 'en' ? validations.data[0].attributes.required_en :
                validations.data[0].attributes.required_ar}}
              </mat-error>
            </div>
            <!-- <div class="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 sm:mt-5">
            <label for="email" class="block text-sm font-normal ml-1 mb-2">{{
              getLang == "en" ? "Product" : "المنتج"
              }}</label>
            <div class="relative flex">
              <div class="relative w-full">
                <select #prod id="product" name="model" (change)="selectProduct(prod.value)"
                  class="py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm" 
                  [ngClass]="getLang == 'en' ? 'block' : 'hidden'">
                  <option value="" disabled selected class="text-Mid-Gray-4">
                    Select
                  </option>
                  <option value="Motor">
                    <p>Motor</p>
                  </option>
                  <option value="Travel Insurance">
                    <p>Travel</p>
                  </option>
                  <option value="">
                    <p>Home</p>
                  </option>
                  <option value="">
                    <p>Domestic helper</p>
                  </option>
                  <option value="">
                    <p>Medical</p>
                  </option>
                </select>
                <select id="product" name="model"
                  class="py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm" 
                  [ngClass]="getLang == 'ar' ? 'block' : 'hidden'">
                  <option value="" disabled selected class="text-left text-Mid-Gray-4">
                    الإختيار
                  </option>
                  <option value="">
                    <p>مركبات</p>
                  </option>
                  <option value="">
                    <p>سفر</p>
                  </option>
                  <option value="">
                    <p>منازل</p>
                  </option>
                  <option value="">
                    <p>عمالة المنزلية</p>
                  </option>
                  <option value="">
                    <p>طبي</p>
                  </option>
                </select>
              </div>
            </div>
          </div> -->
          </div>

          <div class="flex mt-5 px-2 pt-6">
            <input type="checkbox" name="under25" id="under25"
              class="rounded form-checkbox h-5 w-5 text-primary-500 bg-Light-Gray-5 border border-Mid-Gray-5 focus:ring-primary-300 focus:ring-4 ring-offset-0" />

            <label for="under25" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
              [ngClass]="getLang == 'en' ? 'block' : 'hidden'">I agree to the
              <a href="termsofuse" class="underline underline-offset-2 font-medium">Terms of Use</a>
              and
              <a href="privacyandsecurity" class="underline underline-offset-2 font-medium">Privacy Policy</a>
            </label>
            <label for="under25" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
              [ngClass]="getLang == 'ar' ? 'block' : 'hidden'">
              أوافق على
              <a href="termsofuse" class="underline underline-offset-2 font-medium">شروط الاستخدام
              </a>
              و
              <a href="privacyandsecurity" class="underline underline-offset-2 font-medium">سياسة الخصوصية</a>
            </label>
          </div>
          <br>
          <re-captcha (resolved)="resolved($event)"></re-captcha>
          <div class="flex mt-12">

            <div class="flex-1">
              <div class="w-full mb-5">
                <button type="submit" [disabled]="btnLoading || !captcharesolved"
                  class="button flex w-full bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg text-center justify-center">
                  {{ !this.btnLoading ? getLang == "en" ? "Send Message" : "إرسال رسالة": ""}}
                  <div role="status" *ngIf="this.btnLoading" class="flex justify-center items-center">
                    <svg aria-hidden="true" class=" mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"></path>
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="bg-primary-50" dir="auto">
    <div class="lg:hidden md:hidden sm:block object-center w-full mx-auto sm:ml-0">
      <div></div>
      <img class="lg:hidden md:hidden sm:block max-h-[500px] w-full" src="assets/imgs/A1_09000.jpg" alt="">
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 container lg:pb-0 md:pt-0 sm:pb-7 pt-14">
      <div class="lg:flex lg:justify-between md:flex md:justify-between">
        <div>
          <p class="lg:text-6xl md:text-[40px] sm:text-[30px] lg:pt-10 md:pt-10">
            {{
            getLang == "en"
            ? "Explore the portal"
            : "اكتشف مزايا منصتنا الإكترونية"
            }}
          </p>
          <p class="text-Mid-Gray-1 lg:max-w-[456px] md:max-w-[500px] lg:text-lg md:text-base pt-4">
            {{getLang == "en" ? "The great prices, fast claims, and quick renewals are just the beginning. Get the full
            benefits by signing up with Solidarity."
            : "الأسعار التنافسية وفتح المطالبات وتجديد الوثائق في وقت قياسي والمزيد من المزايا. احصل على المزايا الكامل
            من خلال انشاء حساب جديد."
            }}
          </p>
          <a href="https://online.solidarity.bh/sign-up">
            <button
              class="button h-10 w-60 mt-10 mr-3 bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-sm justify-around">
              <div class="flex justify-center">
                <p class="pr-3">
                  {{ getLang == "en" ? "Sign up" : "إنشاء حساب جديد" }}
                </p>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="getLang == 'ar' ? 'scale-x-[-1]' : ''">
                  <path d="M20.5 12H4.5H20.5Z" fill="white" />
                  <path d="M16.5 8L20.5 12M20.5 12L16.5 16M20.5 12H4.5" stroke="white" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </button>
          </a>
        </div>
        <div class="flex justify-between pt-8 lg:block md:block sm:hidden">
          <div></div>
          <img class="lg:block md:block sm:hidden lg:w-[700px] lg:h-[480px] md:w-[550px] sm:w-[288px] lg:pb-8"
            src="assets/imgs/A1_09000.jpg" alt="">
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
  <div class="overlay" *ngIf="sentMessage">
    <div class="popup">
      <h3 class="popup-title">Message sent successfully!</h3>
      <!-- <div class="popup-message">
      <p>Your message:</p>
      
    </div> -->
      <button class="popup-button" (click)="messageSent()">OK</button>
    </div>
  </div>