<div>
    <p class="pt-[42px] grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 font-medium text-sm text-[#5B6871]">
        {{getLang == 'en' ? this.creditReport?.reviewDateEn
        : this.creditReport?.reviewDateAr}}
    </p>
    <p class="pt-4 text-black text-lg font-normal lg:w-[648px] md:w-[648px]">
        {{getLang == 'en' ?
        this.creditReport?.firstParagraphEn :
        this.creditReport?.firstParagraphAr}}
    </p>

    <div class="pt-6 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 ">
        <div
            class="relative flex items-center space-x-3 rounded-lg border border-Light-Gray-3 bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
            <div class="flex-shrink-0 size-12">
                <img class="credit-best"
                    src="{{this.strapiApi.apiURL + this.creditReport?.photoUrl?.data?.attributes?.url}}"
                    alt="Credit Best" />
            </div>
            <div class="min-w-0 flex-1">
                <a href="{{this.strapiApi.apiURL + this.creditReport?.fileUrl?.data?.attributes?.url}}" target="_blank"
                    class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium lg:w-[250px] md:w-[250px] pr-3">
                        {{getLang == 'en' ? this.creditReport?.fileUrl?.data?.attributes?.caption
                        : "تقرير التصنيف الائتماني من بيست"}}</p>
                </a>
            </div>
        </div>
    </div>

    <p class="pt-6 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 text-[#5B6871]">
        {{getLang == 'en' ? this.creditReport?.midParagraphEn : this.creditReport?.midParagraphAr}}
    </p>

    <p class="pt-4 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 text-[#5B6871]">
        <span *ngIf="getLang == 'en'">
            {{this.creditReport?.bottomParagraphEn}}
            <a href="{{this.creditReport?.referenceUrl}}" target="_blank"
                class="text-blue-600 underline">{{this.creditReport?.referenceUrl}}</a>.
        </span>
        <span *ngIf="getLang != 'en'">
            {{this.creditReport?.bottomParagraphAr}}
            <a href="{{this.creditReport?.referenceUrl}}" target="_blank"
                class="text-blue-600 underline">{{this.creditReport?.referenceUrl}}</a>.
        </span>
    </p>

</div>