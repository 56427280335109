import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { QuickRenewal } from 'src/app/models/quick-renewal';
import { StrapiApiService } from 'src/app/services/strapi-api.service';

@Component({
  selector: 'app-quick-renewal-screen',
  templateUrl: './quick-renewal-screen.component.html',
  styleUrls: ['./quick-renewal-screen.component.css']
})
export class QuickRenewalScreenComponent implements OnInit {
  quick!: QuickRenewal;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute) { }
  renewalForm: any;
  isSubmitted = false;
  async ngOnInit() {
    this.route.data.subscribe(async params => {

      this.quick = params['renewal'];
    });
    this.renewalForm = new FormGroup({
      cpr: new FormControl(''),
      plateOrPhone: new FormControl('')
    });
  }

  submitted() {
    this.isSubmitted = true;

    if (this.renewalForm.get('plateOrPhone').value.toString().length == 8) {
      // console.log('Phone');
    }
    else {
      // console.log('plate');
    }
  }
  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
