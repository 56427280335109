<div
  class="p-6 bg-white rounded-[10px] shadow-lg items-center " dir="auto"
  >
  <div class="w-full text-center" *ngIf="this.product == 'home' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Home Insurance" : 'تأمين المنازل '}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'motor' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Motor Insurance" : 'تأمين المركبات'}}</p>
    <p class="text-2xl  text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'travel' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Travel Insurance" : 'تأمين السفر'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'domestic' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Domestic Insurance" : 'تأمين العمالة المنزلية'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'personal' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Personal Insurance" : 'تأمين الحوادث الشخصية'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'medical' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Medical Insurance" : 'التأمين الطبي'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'expat' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Expat Insurance" : 'التأمين الطبي للإقامة الذهبية'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'bupa' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "BUPA Global" : 'بوبا غلوبال'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'cyber' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Personal Cyber" : 'التأمین ضد الأخطار الإلكترونیة الشخصیة '}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <div class="w-full text-center" *ngIf="this.product == 'life' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Life Insurance" : 'تأمين الحياة'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <!-- <div class="w-full text-center lg:hidden md:hidden sm:block" *ngIf="this.product == 'cyber' " >
    <p class="text-2xl font-medium text-black">{{getLang == 'en' ? "Personal Cyber" : 'التأمین ضد الأخطار الإلكترونیة الشخصیة '}}</p>
    <p class="text-xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div> -->
  <div class="w-full text-center" *ngIf="this.product == 'smartdevice' " >
    <p class="text-5xl font-medium text-black">{{getLang == 'en' ? "Smart Device" : 'الأجهزة الذكية'}}</p>
    <p class="text-2xl text-black">{{getLang == 'en' ? "Get a Quote" : 'احصل على تسعيرة'}}</p>
    <br>
  </div>
  <ng-container [ngTemplateOutlet]="this.product == 'home' ? home : this.product == 'motor' ? motor : this.product == 'travel' ? travel : this.product == 'domestic' ? domestic : this.product == 'personal' ? pa : this.product == 'medical' ? medical : this.product == 'expat' ? expat : this.product == 'smartdevice' ? smart : def">
    <ng-template #home>
      <app-home-inputs></app-home-inputs>
    </ng-template>
    <ng-template #motor>
      <app-motor-inputs></app-motor-inputs>
    </ng-template>
    <ng-template #travel>
      <app-travel-inputs></app-travel-inputs>
    </ng-template>
    <ng-template #domestic>
      <app-domestic-inputs></app-domestic-inputs>
    </ng-template>
    <ng-template #pa>
      <app-pa-inputs></app-pa-inputs>
    </ng-template>
    <ng-template #medical>
      <app-medical-inputs></app-medical-inputs>
    </ng-template>
    <ng-template #expat>
      <app-expat-inputs></app-expat-inputs>
    </ng-template>
    <ng-template #smart>
      <app-smart-device></app-smart-device>
    </ng-template>
    <ng-template #def>
      <app-def-inputs [product]="this.product"></app-def-inputs>
    </ng-template>
  </ng-container>
</div>