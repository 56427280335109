import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StrapiApiService } from '../strapi-api.service';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComplaintsSectionResolver {
  constructor(private strapiAPi: StrapiApiService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.strapiAPi.getComplaintsSection().pipe(catchError((error) => { console.log(error); return of('No Data') }));
  }
}
