import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash-es';
import { Branches, Daum } from 'src/app/models/branches';
import { StrapiApiService } from 'src/app/services/strapi-api.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})
export class BranchesComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  center!: google.maps.LatLngLiteral;
  arad!: google.maps.LatLngLiteral;
  seef!: google.maps.LatLngLiteral;
  label = { color: 'red', text: 'ssssggggggggggggggggggg' }
  positions: google.maps.LatLngLiteral[] = [];
  animations = { animation: google.maps.Animation.BOUNCE };
  branches: Array<Daum> = [];
  mark!: MapMarker;
  gmarkers = [];

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute) { }
  options: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false
  };

  async ngOnInit() {
    //navigator.geolocation.getCurrentPosition((position) => {

    this.center = {
      lat: 26.168864449099196,
      lng: 50.53620386422848,

    }



    //})

    this.route.data.subscribe(async params => {
      //this.handlePermission(this.center);
      this.branches = params['data'].data;



    });
    for (let i = 0; i < this.branches.length; i++) {

      this.branches[i].attributes.latlngTest = {
        lat: +this.branches[i].attributes.latitude,
        lng: +this.branches[i].attributes.longitude,
      }


    }

  }

  zoom = 11;

  moveMap(event: google.maps.MapMouseEvent) { }

  move(event: google.maps.MapMouseEvent) { }


  // asssigEachPosition(list: any){
  //   this.branches.forEach(function(branch){
  //     list.push(+branch.attributes.latitude);
  //   })
  // }

  openInfoWindow(marker: MapMarker, branches: Daum) {
    // var infoWindoww = new google.maps.InfoWindow;
    this.infoWindow.infoWindow?.setContent(
      "<div class='p-2 max-w-[250px]'>" +
      "<div class='text-base font-bold flex justify-start'>"
      + branches.attributes.name +
      "</div>" +
      "</br>" +
      "<p class='font-bold flex justify-start'>Address:</p>" +
      "<p class='flex justify-start pt-2'>" + branches.attributes.address + "</p>" +
      "</br>" +
      "<p class='font-bold flex justify-start'>Business Hours:</p>" +
      "<p class='flex justify-start pt-2'>" + branches.attributes.businessHours + "</p>" +
      "</br>" +
      "<p class='font-bold flex justify-start'>Services</p>" +
      "<p class='flex justify-start pt-2'>" + branches.attributes.services + "</p>" +
      "</br>" +
      "<a href='" + branches.attributes.direction_link + "'>Directions link</a>" +
      "</div>"
    );
    this.infoWindow.open(marker);

    //this.animations;
  }


  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }



}
