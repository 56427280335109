import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']
})
export class ImageSliderComponent implements OnInit {

  images: string[] = [
    '/assets/imgs/insureandwin.png',
    '/assets/imgs/insureandwin.png',
    '/assets/imgs/insureandwin.png'
  ];
  currentIndex = 0;

  constructor() { }

  ngOnInit(): void {
    // Automatically advance the slider
    setInterval(() => {
      this.changeSlide((this.currentIndex + 1) % this.images.length);
    }, 3000); // Change slide every 3 seconds (adjust as needed)
  }

  changeSlide(index: number): void {
    this.currentIndex = index;
  }

}
