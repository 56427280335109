import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, catchError } from 'rxjs';
import { TermsOfUse } from 'src/app/models/terms-of-use';
import { StrapiApiService } from '../strapi-api.service';


@Injectable({
  providedIn: 'root'
})
export class TermsOfUseResolver  {
  constructor(private strapiAPi: StrapiApiService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.strapiAPi.getTerms().pipe(catchError((error)=> {console.log(error); return of('No Data')}));
  }
}
