<app-header bg="bg-primary-50"></app-header>
<div class="w-full bg-primary-50 pb-10 pt-10" dir="auto">
  <div class="container w-full mx-auto items-center text-center pt-10">
    <div class="mx-auto w-full text-center">
      <div class="sm:text-5xl md:text-6xl lg:text-7xl text-Dark-Gray-2 font-medium">
        {{getLang == 'en' ? claims.data.attributes.title_en : claims.data.attributes.title_ar}}
      </div>

      <p class="sm:text-base md:text-lg lg:text-lg text-center mx-auto text-Mid-Gray-1 pt-4">
        {{getLang == 'en' ? claims.data.attributes.desc_en : claims.data.attributes.desc_ar}}
      </p>
    </div>
    <div class="block md:hidden mx-auto mt-16 max-w-2xl sm:mt-20 sm:columns-1 md:columns-4 lg:mt-24 lg:max-w-none lg:columns-4">
      <div *ngFor="let singleClaim of claims.data.attributes.details"
        class="sm:w-full sm:p-10 md:p-0  items-center justify-items-center text-center"> 
        <div class="w-full justify-items-center items-center text-center">
          <img class="mx-auto" width="80" height="80" [src]="strapiApi.getImage(singleClaim.icon.data.attributes.url)" alt="">

        </div>
        <div class="mt-10">
          <p class="lg:text-xl md:text-base font-medium text-Dark-Gray-2 mt-2">
            {{getLang == 'en' ? singleClaim.title_en : singleClaim.title_ar}}
          </p>
          <p class="lg:text-md md:text-base font-normal text-Mid-Gray-2 mt-2">
            {{getLang == 'en' ? singleClaim.desc_en : singleClaim.desc_ar}}
          </p>
        </div>
      </div>
    </div>

    <div class="hidden md:block container lg:mx-auto md:mx-auto">
      <div class="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 lg:gap-32 md:gap-14 my-8">
        <div *ngFor="let singleClaim of claims.data.attributes.details">
          <img [src]="strapiApi.getImage(singleClaim.icon.data.attributes.url)" alt="" class="lg:w-22 lg:h-22 md:w-20 md:h-20 mx-auto">
          <h3 class="lg:text-xl md:text-base font-medium text-Dark-Gray-2 mt-2">{{getLang == 'en' ? singleClaim.title_en : singleClaim.title_ar}}</h3>
          <p class="lg:text-base md:text-sm font-normal text-Mid-Gray-2 mt-2">{{getLang == 'en' ? singleClaim.desc_en : singleClaim.desc_ar}}</p>
        </div>
      </div>
    </div>    
  </div>
</div>



<app-claim-section></app-claim-section>
<app-channel-guides></app-channel-guides>
<app-coverd-section></app-coverd-section>

<app-footer></app-footer>