import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from "@angular/router";
import { NgxPaginationModule } from 'ngx-pagination';
import { RECAPTCHA_SETTINGS, RecaptchaModule } from 'ng-recaptcha';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddonsScreenComponent } from './dynamic_screens/addons-screen/addons-screen.component';
import { FinalQuoteLoginCallComponent } from './dynamic_screens/final-quote-login-call/final-quote-login-call.component';
import { PlansScreenComponent } from './dynamic_screens/plans-screen/plans-screen.component';
import { ProductComponent } from './dynamic_screens/product/product.component';
import { ReviewScreenComponent } from './dynamic_screens/review-screen/review-screen.component';
import { SelectPolicyScreenComponent } from './dynamic_screens/select-policy-screen/select-policy-screen.component';
import { HomeQuoteComponent } from './quotes/home-quote/home-quote.component';
import { MotorQuoteComponent } from './quotes/motor-quote/motor-quote.component';
import { BranchesComponent } from './reusable/branches/branches.component';
import { ClaimSectionComponent } from './reusable/claim-section/claim-section.component';
import { AccordionModule } from './reusable/components/accordion/accordion.module';
import { BackgroundAnimationComponent } from './reusable/components/background-animation/background-animation.component';
import { ChannelGuidesComponent } from './reusable/components/channel-guides/channel-guides.component';
import { DefInputsComponent } from './reusable/components/def-inputs/def-inputs.component';
import { DomesticInputsComponent } from './reusable/components/domestic-inputs/domestic-inputs.component';
import { ExpatInputsComponent } from './reusable/components/expat-inputs/expat-inputs.component';
import { FaturesBoxComponent } from './reusable/components/fatures-box/fatures-box.component';
import { FileBoxComponent } from './reusable/components/file-box/file-box.component';
import { FinancialreportsComponent } from './reusable/components/financialreports/financialreports.component';
import { HomeInputsComponent } from './reusable/components/home-inputs/home-inputs.component';
import { MedicalInputsComponent } from './reusable/components/medical-inputs/medical-inputs.component';
import { MotorInputsComponent } from './reusable/components/motor-inputs/motor-inputs.component';
import { PaInputsComponent } from './reusable/components/pa-inputs/pa-inputs.component';
import { PaginationComponent } from './reusable/components/pagination/pagination.component';
import { SelectPolicyBoxComponent } from './reusable/components/policy-box/select-policy-box.component';
import { ProductFormComponent } from './reusable/components/product-form/product-form.component';
import { SelectPlanBoxComponent } from './reusable/components/select-plan-box/select-plan-box.component';
import { ShareholdernewsComponent } from './reusable/components/shareholdernews/shareholdernews.component';
import { SmartDeviceComponent } from './reusable/components/smart-device/smart-device.component';
import { TravelInputsComponent } from './reusable/components/travel-inputs/travel-inputs.component';
import { CoverdSectionComponent } from './reusable/coverd-section/coverd-section.component';
import { FooterComponent } from './reusable/footer/footer.component';
import { HeaderComponent } from './reusable/header/header.component';
import { LowerBannerComponent } from './reusable/lower-banner/lower-banner.component';
import { AboutusComponent } from './screens/aboutus/aboutus.component';
import { BeyonMoneyComponent } from './screens/beyon-money/beyon-money.component';
import { CareersComponent } from './screens/careers/careers.component';
import { ClaimComponent } from './screens/claim/claim.component';
import { ComplaintsComponent } from './screens/complaints/complaints.component';
import { ContactusComponent } from './screens/contactus/contactus.component';
import { ForbusinessesComponent } from './screens/forbusinesses/forbusinesses.component';
import { HomeComponent } from './screens/home/home.component';
import { InvestorRelationsComponent } from './screens/investorrelations/investorrelations.component';
import { NewsComponent } from './screens/news/news.component';
import { PrivacyandsecurityComponent } from './screens/privacyandsecurity/privacyandsecurity.component';
import { QuickRenewalScreenComponent } from './screens/quick-renewal-screen/quick-renewal-screen.component';
import { TermsofuseComponent } from './screens/termsofuse/termsofuse.component';
import { VideoComponent } from './screens/video/video.component';
import { MatPaginatorModule } from '@angular/material/paginator';
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from "@sentry/angular-ivy";
import { ErrorComponent } from './error/error.component';
import { CookieService } from './services/cookie.service';
import { RewardsComponent } from './screens/rewards/rewards.component';
import { SeefmallbranchComponent } from './screens/seefmallbranch/seefmallbranch.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { FilterPipe } from './filter.pipe';
import { CreditReportComponent } from './reusable/components/credit-report/credit-report.component';


@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    ClaimComponent,
    ContactusComponent,
    AboutusComponent,
    NewsComponent,
    FooterComponent,
    HeaderComponent,
    FileBoxComponent,
    FaturesBoxComponent,
    HomeComponent,
    LowerBannerComponent,
    CoverdSectionComponent,
    ClaimSectionComponent,
    MotorQuoteComponent,
    HomeQuoteComponent,
    PlansScreenComponent,
    AddonsScreenComponent,
    QuickRenewalScreenComponent,
    ReviewScreenComponent,
    SelectPolicyBoxComponent,
    SelectPolicyScreenComponent,
    SelectPlanBoxComponent,
    ProductFormComponent,
    HomeInputsComponent,
    DefInputsComponent,
    FinalQuoteLoginCallComponent,
    MotorInputsComponent,
    ComplaintsComponent,
    TermsofuseComponent,
    InvestorRelationsComponent,
    ShareholdernewsComponent,
    FinancialreportsComponent,
    PrivacyandsecurityComponent,
    PaginationComponent,
    ForbusinessesComponent,
    TravelInputsComponent,
    ChannelGuidesComponent,
    BranchesComponent,
    VideoComponent,
    BackgroundAnimationComponent,
    CareersComponent,
    DomesticInputsComponent,
    PaInputsComponent,
    MedicalInputsComponent,
    ExpatInputsComponent,
    BeyonMoneyComponent,
    SmartDeviceComponent,
    ErrorComponent,
    RewardsComponent,
    SeefmallbranchComponent,
    ImageSliderComponent,
    FilterPipe,
    CreditReportComponent,
  ],
  imports: [
    AccordionModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RecaptchaModule,
    NgxMatIntlTelInputComponent,
    MatInputModule,
    ScrollingModule, 
    NgxPaginationModule,
    MatPaginatorModule
  ],

  providers: [PaginationComponent, NewsComponent, CookieService, {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      //production
      siteKey: '6Lc-md0UAAAAADywrSaeURrUQM7aeIOsHMbhCVmm',
      //dev
      // siteKey: '6LepmhwmAAAAANmadm2yrfvb9xYRaEStZlHQ8G2N',
    }
  }, {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
