import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-expat-inputs',
  templateUrl: './expat-inputs.component.html',
  styleUrls: ['./expat-inputs.component.css']
})
export class ExpatInputsComponent implements OnInit {
  options = ['1', '2'];
  selectedOption!: string;
  isSubmitted = false;
  validateEmail = true;
  accepted: boolean = false;
  captcharesolved = false;
  validations!: Validations;
  ngForm: any;
  loading: boolean = false;
  sumInsured: string | undefined | null;
  period: string = '';
  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.ngForm = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      phone: new FormControl(''),
      age: new FormControl(null),
      date: new FormControl(''),


    });
  }
  checking() {
    this.isSubmitted = true;
  }

  sendLead() {
    this.isSubmitted = true;
    this.loading = true;
    // console.log("Limit Chosen: " + this.selectedLimit);
    if (this.ngForm.valid) {
      this.webApi.sendProductLead(
        this.ngForm.get('name').value.toString(),
        this.ngForm.get('phone').value.toString(),
        'Medical',
        'New Insurance - Expat Medical Visa',
        '',
        1,
        1,
        'New Expat Insurance Lead',
        '',
        null
      ).subscribe((s) => {
        var url = environment.portalUrl + `/insurance-policies/expat-medical-visa`;
        window.location.href = url;

      });

    }
  }

  selectPeriod(period: any) {
    this.period = period;
    // console.log("Period: " + this.period);
  }

  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  maxAge() {
    let today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var newDate = new Date(year - 90, month, day)
    return this.dateToString(newDate);
  }
  minAge() {
    let today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var newDate = new Date(year, month, day)
    return this.dateToString(newDate);
  }

  dateToString(date: any) {
    const toTwoDigits = (num: string | number) => ((+num) < 10 ? "0" + num : num);
    let year = date.getFullYear();
    let month = toTwoDigits(date.getMonth() + 1);
    let day = toTwoDigits(date.getDate());
    return `${year}-${month}-${day}`;
  }



  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
