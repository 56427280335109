import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Box, MovingImg, SecondBox, TopImg } from 'src/app/models/career';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  title_en: string = "";
  title_ar: string = "";
  desc_en: string = "";
  desc_ar: string = "";
  btn_txt_en: string = "";
  btn_txt_ar: string = "";
  second_title_en: string = "";
  second_title_ar: string = "";
  second_desc_en: string = "";
  second_desc_ar: string = "";
  third_title_en: string = "";
  third_title_ar: string = "";
  third_desc_en: string = "";
  third_desc_ar: string = "";
  fourth_title_en: string = "";
  fourth_title_ar: string = "";
  fourth_desc_en: string = "";
  fourth_desc_ar: string = "";
  top_imgs: Array<TopImg> = [];
  boxes: Array<Box> = [];
  second_boxes: Array<SecondBox> = [];
  moving_imgs: Array<MovingImg> = [];
  
  constructor(public strapiApi: StrapiApiService, public router: Router, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    this.route.data.subscribe(async params => {
      this.title_en = params['data'].data.attributes.title_en;
      this.title_ar = params['data'].data.attributes.title_ar;
      this.desc_en = params['data'].data.attributes.desc_en;
      this.desc_ar = params['data'].data.attributes.desc_ar;
      this.btn_txt_en = params['data'].data.attributes.btn_txt_en;
      this.btn_txt_ar = params['data'].data.attributes.btn_txt_ar;
      this.second_title_en = params['data'].data.attributes.second_title_en;
      this.second_title_ar = params['data'].data.attributes.second_title_ar;
      this.second_desc_en = params['data'].data.attributes.second_desc_en;
      this.second_desc_ar = params['data'].data.attributes.second_desc_ar;
      this.third_title_en = params['data'].data.attributes.third_title_en;
      this.third_title_ar = params['data'].data.attributes.third_title_ar;
      this.third_desc_en = params['data'].data.attributes.third_desc_en;
      this.third_desc_ar = params['data'].data.attributes.third_desc_ar;
      this.fourth_title_en = params['data'].data.attributes.fourth_title_en;
      this.fourth_title_ar = params['data'].data.attributes.fourth_title_ar;
      this.fourth_desc_en = params['data'].data.attributes.fourth_desc_en;
      this.fourth_desc_ar = params['data'].data.attributes.fourth_desc_ar;
      this.top_imgs = params['data'].data.attributes.top_imgs;
      this.boxes = params['data'].data.attributes.boxes;
      this.second_boxes = params['data'].data.attributes.second_boxes;
      this.moving_imgs = params['data'].data.attributes.moving_imgs;
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  GetStrapi(url: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.strapiUrl}` + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
