<form #myform="ngForm" [formGroup]="domesticForm" (ngSubmit)="sendLead()">

  <div class="lg:flex md:flex mt-5">

    <div class="lg:mx-2 md:mx-2 lg:w-1/2 md:w-1/2" [ngClass]="getLang == 'ar' ? 'lg:pl-2 md:pl-2' : 'lg:pr-2 md:pr-2'">
      <label for="name" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ? "Name" :
        "الاسم"}}</label>

      <div class="relative">
        <input type="text" id="name" name="name" formControlName="name"
          class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
          [ngClass]="myform.form.controls['name'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
          placeholder="e.g John Doe" required aria-describedby="message-error" />
      </div>
      <mat-error *ngIf="myform.form.controls['name']?.errors?.['required'] && isSubmitted">
        {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
      </mat-error>
    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:pt-0 md:pt-0 sm:pt-4">
      <label class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Mobile Number"
        : "رقم الهاتف"}}</label>
      <div class="relative flex">
        <ngx-mat-intl-tel-input
          class="h-10 py-2 px-4 country-selector block border border-Light-Gray-2   rounded-md text-sm  w-full"
          inputPlaceholder=""
          [ngClass]="myform.form.controls['phone'].errors?.['validatePhoneNumber'] || domesticForm.get('phone').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
          formControlName="phone" [enablePlaceholder]="true" [enableSearch]="true"
          [preferredCountries]="['bh', 'sa', 'ae', 'kw']" name="phone" formControlName="phone">
        </ngx-mat-intl-tel-input>
      </div>
      <mat-error *ngIf="domesticForm.get('phone').value == '' && isSubmitted"> {{getLang == 'en' ?
        validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
      <mat-error *ngIf="myform.form.controls['phone']?.errors?.['validatePhoneNumber']">{{getLang == 'en' ?
        validations.data[1].attributes.error_en : validations.data[1].attributes.error_ar}}</mat-error>
    </div>
  </div>

  <div class="lg:flex md:flex lg:mt-14 md:mt-14 lg:pt-0 md:pt-0 sm:pt-4">
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2">
      <label for="type" class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Application Type" :
        "نوع الطلب"}}</label>
      <div class="relative">
        <select #appType id="type" name="type"
          class="text-left py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm"
          (change)="selectApplicationType(appType.value)">
          <option value="">{{getLang == 'en' ? "Select" : "اختيار"}}</option>

          <!-- <option *ngFor="let app of this.AppType">{{ app }}</option> -->
          <option> {{getLang == 'en' ? "New Application" : "جديد"}} </option>
          <option> {{getLang == 'en' ? "Visa Renewal" : "تجديد"}} </option>
          <option> {{getLang == 'en' ? "Interim" : "ساري"}} </option>

        </select>
      </div>
      <mat-error *ngIf="myform.form.controls['sumInsured']?.errors?.['required'] && isSubmitted">
        {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
      </mat-error>

    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2 lg:pt-0 md:pt-0 sm:pt-4"
      [ngClass]="selectedAppType == 'interim' ? 'hidden' : 'block'">
      <label for="policy-period" class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Policy Period" :
        "فترة الوثيقة"}}</label>
      <div class="relative">
        <select #policyPeriod id="policy-period" name="year" (change)="selectPolicyPeriod(policyPeriod.value)"
          class="text-left py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm">
          <option>{{getLang == 'en' ? "Select" : "اختيار"}}</option>
          <!-- <option *ngFor="let period of this.periods">{{ period }}</option> -->
          <option id="1"> {{getLang == 'en' ? "1 Year" : "سنة واحدة"}} </option>
          <option id="2"> {{getLang == 'en' ? "2 Year" : "سنتين"}} </option>

        </select>
      </div>
      <mat-error *ngIf="myform.form.controls['age']?.errors?.['required'] && isSubmitted">
        {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
      </mat-error>
    </div>
    <div class="w-1/2 md:w-1/2 lg:pt-0 md:pt-0 sm:pt-4" [ngClass]="selectedAppType != 'interim' ? 'hidden' : 'block'">
      <label for="email" class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Policy End Date" :
        "تاريخ إنتهاء الوثيقة"}}</label>
      <div class="relative">
        <input type="date" id="email" name="from"
          class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm  " required
          aria-describedby="email-error" formControlName="endDate" [min]="domesticForm.get('endDate').value"
          [max]="afterYear()" />
      </div>
      <mat-error *ngIf="myform.form.controls['age']?.errors?.['required'] && isSubmitted">
        {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
      </mat-error>
    </div>

  </div>
  <div class="lg:flex md:flex lg:mt-14 md:mt-14 sm:pt-4">
    <div class="lg:mx-2 md:mx-2 lg:w-1/2 md:w-1/2 " [ngClass]="selectedAppType == '' ? 'hidden' : 'block'">
      <label for="name" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{selectedAppType == 'new' ?
        'Esitimated arrival date' : selectedAppType == 'renewal' ? 'Effective start date of the renewed work permit' :
        selectedAppType == 'interim' ? 'Policy inception date' : ''}}</label>

      <div class="relative">
        <input [ngClass]="selectedAppType == '' ? 'disabled' : 'block'" type="date" id="email" name="from"
          class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm  " required
          aria-describedby="email-error" formControlName="date" [min]="selectedAppType == 'interim' ? today() : ''"
          [max]="selectedAppType == 'interim' ? afterMonth() : ''" />
      </div>
      <mat-error *ngIf="selectedLimit == '' && isSubmitted">
        {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
      </mat-error>
    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2 lg:pt-0 md:pt-0 sm:pt-4"
      [ngClass]="selectedAppType == '' ? 'block' : 'hidden'">
      <label for="email" class="block text-sm font-normal ml-1 mb-2 text-Mid-Gray-4">
        {{getLang == 'en' ? "Estimated arrival date" :
        "تاريخ الوصول المتوقع"}}</label>
      <div class="relative">

        <input class="h-10 py-3 px-4 block w-full border-1 text-Mid-Gray-4 border-Light-Gray-2 rounded-md text-sm"
          type="date" disabled>
      </div>
    </div>
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2 lg:pt-0 md:pt-0 sm:pt-4">
      <label for="email" class="block text-sm font-normal ml-1 mb-2 ">
        {{getLang == 'en' ? "Employment Type" : "نوع عقد العمل"}}</label>
      <div class="relative">
        <select #emptType id="employment-type" (change)="selectEmploymentType(emptType.value)"
          class="text-left py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm">
          <option>{{getLang == 'en' ? "Select" : "اختيار"}}</option>
          <option *ngFor="let empl of this.employmentType" [value]="empl.name">{{ empl.name }}</option>
          <!-- <option> {{getLang == 'en' ? "Direct Sourcing" : "استقدام مباشر"}} </option>
          <option> {{getLang == 'en' ? "Via Employmet Office" : "عن طريق مكتب توظيف مرخص"}} </option> -->
        </select>

      </div>
      <mat-error *ngIf="domesticForm.get('phone').value == '' && isSubmitted"> {{getLang == 'en' ?
        validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
      <mat-error *ngIf="myform.form.controls['phone']?.errors?.['validatePhoneNumber']">{{getLang == 'en' ?
        validations.data[1].attributes.error_en : validations.data[1].attributes.error_ar}}</mat-error>
    </div>
  </div>
  <div class="lg:flex md:flex lg:mt-14 md:mt-14 sm:pt-4">
    <div class="lg:w-1/2 md:w-1/2 lg:mx-2 md:mx-2 lg:pt-0 md:pt-0 sm:pt-4">
      <label for="name" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
        {{getLang == 'en' ? "Limit for Absence from work" :
        "الفترة المسموحة للغياب عن العمل"}}</label>
      <div class="relative">
        <select #limit id="year" name="year"
          class="text-left py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm"
          (change)="selectLimit(limit.value)">
          <option value="">{{getLang == 'en' ? "Select" : "اختيار"}}</option>
          <option *ngFor="let limit of this.limits" [value]="limit.name">{{ limit.name }} BHD</option>
        </select>
      </div>
      <mat-error *ngIf="selectedLimit == '' && isSubmitted">
        {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
      </mat-error>
    </div>
  </div>
  <div class="flex mt-5 px-2 pt-6">
    <input type="checkbox" name="terms" id="terms" value="accepted" (change)="onCheckboxChangeAccepted($event)"
      class="rounded form-checkbox h-5 w-5 text-primary-500 bg-Light-Gray-5 border border-Mid-Gray-5 focus:ring-primary-300 focus:ring-4 ring-offset-0" />
    <label for="terms" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
      [ngClass]="getLang == 'en' ? 'block' : 'hidden'">I agree to the <a href="termsofuse"
        class="underline underline-offset-2 font-medium">Terms of Use</a> and <a href="privacyandsecurity"
        class="underline underline-offset-2 font-medium">Privacy Policy</a>
    </label>
    <label for="terms" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
      [ngClass]="getLang == 'ar' ? 'block' : 'hidden'"> أوافق على <a href="termsofuse"
        class="underline underline-offset-2 font-medium">شروط الاستخدام </a> و <a href="privacyandsecurity"
        class="underline underline-offset-2 font-medium">سياسة الخصوصية</a>
    </label>
  </div><br>
  <div class="flex mt-2 px-2 items-center justify-center">
    <re-captcha (resolved)="resolved($event)"></re-captcha>
  </div>
  <div class="flex mt-14">
    <div class="flex-1">
      <div class="w-full px-3 mb-5">
        <button
          class="button flex w-full bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg text-center justify-center"
          type="submit" [disabled]="!accepted || !captcharesolved || this.loading == true">
          <span *ngIf="!this.loading">{{getLang == 'en' ? "View Prices" : "عرض الأسعار"}}</span>
          <div role="status" *ngIf="this.loading" class="">
            <svg aria-hidden="true" class="mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"></path>
            </svg>

            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</form>