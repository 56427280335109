import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-def-inputs',
  templateUrl: './def-inputs.component.html',
  styleUrls: ['./def-inputs.component.css']
})
export class DefInputsComponent implements OnInit {
  @Input() product: any;
  isSubmitted = false;
  validateEmail = true;
  validations!: Validations;
  ngForm: any;
  loading: boolean = false;
  sumInsured: string | undefined | null;
  btnLoading = false;
  sentMessage = false;
  accepted: boolean = false;
  captcharesolved = false;

  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.ngForm = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      sumInsured: new FormControl(null),
      phone: new FormControl(''),
      age: new FormControl(null),

    });
  }
  checking() {
    this.isSubmitted = true;
  }

  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  sendForm(form: NgForm): void {
    // console.log(form);
    this.isSubmitted = true;
    this.btnLoading = true;
    if (form.valid) {
      // console.log(form);
      this.webApi.sendLead(this.ngForm.get('name').value,
        this.ngForm.get('phone').value,
        '',
        this.product + ' lead',
        this.ngForm.get('email').value,
        1,
        1,
        this.product,
        null).subscribe((s) => {
          window.location.reload();
          this.sentMessage = true;
          this.btnLoading = false;
        });
    } else {
      this.btnLoading = false;
    }

  }

  getQuote(form: NgForm): void {
    // console.log(form);
    this.loading = true;
    if (form.valid) {
      // console.log(form);
      this.webApi.getHomeQuote(form.value["sumInsured"], "0", "0", "0", "0", "FALCON", null, null).subscribe((s) => {
        this.router.navigate(['quote/home'], { state: { quote: s } });
      });
    }

  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
