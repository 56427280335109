<app-header bg="bg-white"></app-header>
<div class="p-2 w-full  items-center lg:min-h-[610px] md:min-h-[596px] sm:min-h-[348px]  relative">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 container" dir="auto">
        <p class="lg:text-7xl sm:text-4xl md:text-6xl text-Dark-Gray-2 font-medium">Rewards
        </p>
        <div class="pt-14">
            <p class="lg:text-3xl md:text-3xl sm:text-xl font-medium">Family & Friends Rewards</p>
            <!-- <p class="pt-5 lg:w-2/3 sm:text-sm text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }}</p> -->
            <br>
            
          
            
            <p>We at Solidarity have enabled our customer to issue policies on behalf of their beloved ones whether it's family or friends. In return Solidarity is committed to payback the customer for using our Mobile App and issue policies on the go.</p>
            <br>
            <br>
            <p>That is why we built this table to showcase how we are calculating your rewards, it's very simple, the more your family or friends issue policies the more you can get rewarded with Points.</p>
            <br>
            <br>
            <p>Points can be redeemed in three different ways:</p>
            <br>
            <ul class="list-disc pl-6">
                <li class="text-Mid-Gray-1"> Gift Voucher. </li> <br>
                <li class="text-Mid-Gray-1"> Discount Voucher.</li> <br>
                <li class="text-Mid-Gray-1"> Gadgets (Coming Soon). </li> <br>
      
            </ul>

            <br>
            <br>
            <p>The below table is showing an example:</p>
            <br>
            <div class="max-w-screen-md mx-auto">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th class="bg-gray-100 border border-gray-300 py-2 px-4">Action</th>
                            <th class="bg-gray-100 border border-gray-300 py-2 px-4">Calculation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border border-gray-300">
                            <td class="py-2 px-4">F&F Payment (New Insurance)</td>
                            <td class="py-2 px-4">1 BHD = 1 Point, e.g., 160 BHD = 160 Points</td>
                        </tr>
                        <tr class="border border-gray-300">
                            <td class="py-2 px-4">Redeem Points (Gift Voucher)</td>
                            <td class="py-2 px-4">500 Points = 50 BHD</td>
                        </tr>
                        <tr class="border border-gray-300">
                            <td class="py-2 px-4">Redeem Points (Discount Voucher)</td>
                            <td class="py-2 px-4">500 Points = 62.5 BHD</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <br>
            <br>
            <p>Terms & Conditions:</p>
            <br>
            <ol>
                <li>1. The minimum amount that can be redeemed is 500 Points = 50 BHD. </li>
                <li>2. Unutilized points will expire after 365 days of the purchase date. </li>
                <li>3. This program is exclusively for Solidarity existing customers only. </li>
                <li>4. Points will be awarded only for the new business and not the renewal business. </li>
                <li>5. Points are not transferable to other customers. </li>
             

            </ol>
          
        </div>
    <br>
    
    
    
  </div>
<app-coverd-section> </app-coverd-section>
  <app-footer></app-footer>