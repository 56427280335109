<app-header bg="bg-transparent" class=""></app-header>
<div class="bg-no-repeat lg:bg-bottom md:bg-bottom lg:bgimage md:bgimage sm:bgimagesmall">
    <div class="absolute inset-0 lg:bottom-0 md:bottom-72 ">
        <img class="lg:h-[950px] md:h-[900px] sm:h-[500px] lg:pt-0 md:pt-0 sm:pt-20 w-full lg:object-cover md:lg:object-cover sm:object-cover   sm:object-center"
            src="assets/imgs/videoimage.webp" alt="" [attr.Style]="getLang == 'en' ? '' : 'transform: scaleX(-1);'">
    </div>
    <div>
        <div>
            <div class="pt-32 w-full items-center lg:min-h-[847px] md:min-h-[596px] sm:min-h-[348px] max-w-7xl relative container px-4 mx-auto "
                dir="auto">
                <div class=" w-full lg:mt-2 md:mt-16    px-4 ">
                    <div class="lg:text-7xl font-medium text-Dark-Gray-2 sm:text-3xl md:text-6xl max-w-[561px]">
                        {{getLang == 'en' ? video.data.attributes.title_en : video.data.attributes.title_ar}}</div>
                    <p
                        class="lg:text-xl lg:w-3/5 md:text-base sm:text-sm md:w-4/5   text-Mid-Gray-1 pt-11 max-w-[552px]">
                        {{getLang == 'en' ? video.data.attributes.desc_en : video.data.attributes.desc_ar}}
                    </p>
                    <button (click)="openModal()"
                        class="button lg:h-14 lg:w-[220px] md:w-[168px] mt-10 mr-3 bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-lg justify-around">
                        {{getLang == 'en' ? "Start video call" : "ابدأ مكالمة الفيديو"}}
                    </button>

                    <p class="pt-60 lg:max-w-xl md:max-w-xs text-sm text-Dark-Gray-1 lg:block md:block sm:hidden">
                        {{getLang == 'en' ? video.data.attributes.agreement_en : video.data.attributes.agreement_ar}}
                    </p>
                </div>
            </div>
        </div>

        <br>
        <br>
        <br>
    </div>
    <!-- The Modal -->
    <div id="myModal" class="custom-modal" [style.display]="showModal ? 'block' : 'none'">
        <!-- Modal content -->
        <div class="modal-content">
            <span class="{{ getLang == 'en' ? 'close' : 'closeAr' }}" (click)="closeModal()">&times;</span>
            <br />
            <span class="modal-header">
                {{
                getLang == "en"
                ? "Full name:"
                : "الاسم الثلاثي:"
                }}
            </span>
            <form #myform="ngForm" [formGroup]="videoForm" (ngSubmit)="startVideoCall()">

                <div class="relative">
                    <input type="text" id="name" name="name" formControlName="name"
                        class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                        [ngClass]="myform.form.controls['name'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
                        placeholder="e.g John Doe" required aria-describedby="message-error" />
                </div>
                <mat-error *ngIf="myform.form.controls['name']?.errors?.['required'] && isSubmitted">
                    {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
                </mat-error>

                <div style="margin-left: 10px"></div>
                <!-- Adjust the margin as needed -->
                <button
                    class="button flex w-full mt-10 mr-3 bg-primary-500 text-Light-Gray-5 py-2 rounded-md items-center text-lg justify-center"
                    (click)="startVideoCall()">
                    {{getLang == 'en' ? "Start video call" : "ابدأ مكالمة الفيديو"}}
                </button>
            </form>
        </div>
    </div>
</div>
<app-channel-guides></app-channel-guides>
<app-claim-section></app-claim-section>
<app-coverd-section></app-coverd-section>
<app-footer></app-footer>