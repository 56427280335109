<app-header bg="bg-primary-50"></app-header>

<div class="p-2 w-full bg-primary-50 items-center min-h-[600px]" dir="auto">
  <div class="mx-auto w-full text-center mt-16 md:mt-16 container">
    <div class="lg:text-7xl md:text-7xl sm:text-[40px] font-medium text-Dark-Gray-2">
      {{getLang == 'en' ? "Get in touch with us" : "تواصل معنا"}}
    </div>
    <p class="lg:text-xl md:text-xl sm:text-sm text-center lg:w-3/5 md:w-3/5 sm:w-full mx-auto text-Mid-Gray-1 pt-2"
      [ngClass]="getLang == 'en' ? '' : 'pt-5'">
      {{getLang == 'en' ?
      "Whether you’ve got a question, need to change your details, or want to share your feedback, we’re here to help!"
      : "تواصلوا معنا لأي استفسار أو اقتراح، أو لإجراء أي تغيير في الحساب"}}
    </p>
  </div>
  <form #myform="ngForm" [formGroup]="ngForm" (ngSubmit)="SendContactUsForm(ngForm)">
    <div
      class="p-6 mt-14 lg:max-w-3xl md:max-w-2xl  mx-auto bg-white rounded-[10px] shadow-lg items-center absolute inset-x-0 z-10 px-6 container"
      id="box">
      <div class="w-full">
        <p class="lg:text-3xl md:text-3xl sm:text-2xl font-medium text-Dark-Gray-2">
          {{getLang == 'en' ? "We’d love to hear from you" :
          "يسرنا تواصلكم معنا"}}
        </p>
      </div>
      <div class="lg:flex md:flex mt-5">
        <div class="w-full">
          <label for="name" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
            {{getLang == 'en' ? "Name" :
            "الاسم"}}</label>
          <div class="relative">
            <input type="text" id="name" s name="name"
              class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
              placeholder="e.g John Doe"
              [ngClass]="myform.form.controls['name'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
              required aria-describedby="name-error" formControlName="name" />
          </div>
          <mat-error *ngIf="myform.form.controls['name']?.errors?.['required'] && isSubmitted">
            {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
          </mat-error>
        </div>
      </div>
      <div class="lg:flex md:flex mt-5">
        <div class="flex-1 " [ngClass]="getLang == 'en' ? 'lg:pr-4 md:pr-4 sm:pr-0' : 'lg:pl-4 md:pl-4 sm:pl-0'">
          <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
            {{getLang == 'en' ? "Mobile Number" : "رقم الهاتف"}}</label>
          <div class="relative">
            <ngx-mat-intl-tel-input
              class="h-10 py-2 px-4 country-selector block border border-Light-Gray-2   rounded-md text-sm  w-full"
              inputPlaceholder=""
              [ngClass]="myform.form.controls['phone'].errors?.['validatePhoneNumber'] || ngForm.get('phone').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
              formControlName="phone" [enablePlaceholder]="true" [enableSearch]="true"
              [preferredCountries]="['bh', 'sa', 'ae', 'kw']" name="phone" formControlName="phone">
            </ngx-mat-intl-tel-input>
          </div>
          <mat-error *ngIf="ngForm.get('phone').value == '' && isSubmitted"> {{getLang == 'en' ?
            validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
          <mat-error *ngIf="myform.form.controls['phone']?.errors?.['validatePhoneNumber']">{{getLang == 'en' ?
            validations.data[1].attributes.error_en : validations.data[1].attributes.error_ar}}</mat-error>
        </div>
        <div class="lg:flex-1 md:flex-1 lg:pt-0 md:pt-0 sm:pt-4">
          <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
            {{getLang == 'en' ? "Email address" : "البريد الإلكتروني"}}</label>
          <div class="relative">
            <input type="email" name="email" formControlName="email" [email]="validateEmail"
              class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
              [ngClass]="myform.form.controls['email'].errors?.['email'] || myform.form.controls['email'].errors?.['required'] && isSubmitted ? 'errorInputed errorInput' : ''"
              placeholder="e.g name@example.com" required aria-describedby="email-error" />
          </div>
          <mat-error *ngIf="myform.form.controls['email']?.errors?.['email']">
            {{getLang == 'en' ? validations.data[0].attributes.error_en : validations.data[0].attributes.error_ar}}
          </mat-error>
          <mat-error *ngIf="myform.form.controls['email']?.errors?.['required'] && isSubmitted">
            {{getLang == 'en' ? validations.data[0].attributes.required_en :
            validations.data[0].attributes.required_ar}}
          </mat-error>
        </div>
      </div>
      <div class="lg:flex md:flex mt-5">
        <div class="w-full">
          <label for="subject" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ? "Subject" :
            "الموضوع"}}</label>
          <div class="relative">
            <input type="text" id="subject" name="subject"
              class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
              [ngClass]="myform.form.controls['subject'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
              aria-describedby="subject-error" placeholder="{{getLang == 'en' ? 'Title of what your message is about' :
               'موضوع الرسالة'}}" formControlName="subject" />
          </div>
          <mat-error *ngIf="myform.form.controls['subject']?.errors?.['required'] && isSubmitted">
            {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
          </mat-error>
        </div>
      </div>
      <div class="flex mt-14">
        <div class="w-full">
          <label for="message" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
            {{getLang == 'en' ? "Message" :
            "الرسالة"}}</label>
          <div class="relative">
            <textarea type="text" id="message" name="message"
              [ngClass]="myform.form.controls['msg'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
              class="py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm h-24" required
              aria-describedby="message-error" placeholder="{{getLang == 'en' ? 'Type your message here' : 
              'اكتب رسالتك هنا'}}" formControlName="msg"></textarea>
            <!-- <input
              type="text"
              id="message"
              name="message"
              class="py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
              required
              aria-describedby="message-error"
            /> -->
          </div>
          <mat-error *ngIf="myform.form.controls['msg']?.errors?.['required'] && isSubmitted">
            {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
          </mat-error>
        </div>
      </div><br>
      <div class="flex mt-2 px-2 items-center justify-center">
        <re-captcha (resolved)="resolved($event)"></re-captcha>
      </div>
      <div class="flex mt-12">
        <div class="flex-1">
          <div class="w-full px-3 mb-5">
            <button
              class="button block w-full bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg"
              [disabled]="!ngForm.valid || btnLoading || !captcharesolved">
              {{getLang == 'en' ? "Send Message" : "إرسال"}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>
<div class=" mx-auto pt-[30rem]" id="content" dir="auto">
  <div class="pb-20 w-full bg-white">
    <div class="mx-auto w-full text-center mt-28 lg:container md:container">
      <div class="lg:text-6xl md:text-6xl sm:text-3xl font-medium text-Dark-Gray-2">
        {{getLang == 'en' ? "Solidarity near you!" : "فرع سوليدرتي الأقرب لك!"}}
      </div>
      <p class="lg:text-xl md:text-xl sm:text-sm text-center lg:w-3/5 md:w-3/5 sm:w-4/5 mx-auto text-Mid-Gray-1 pt-4">
        {{getLang == 'en' ? "Want to visit a Solidarity branch? Use the map below to find the nearest one to you." :
        "تعرف على أقرب فرع لك عبر الاطلاع على الخارطة التالية:"}}
      </p>
    </div>
    <!--
      mx-auto w-full text-center mt-28 container border rounded-[10px] border-Light-Gray-2 py-4 pl-4 h-[530px]
      mx-auto w-full text-center mt-28 lg:container md:container sm:container sm:max-w-3xl  border rounded-[10px] border-Light-Gray-2 py-4 pl-4 lg:h-[530px] md:h-auto
      mx-auto w-full text-center mt-28  container sm:max-w-3xl lg:max-w-7xl   border rounded-[10px] border-Light-Gray-2 py-4 pl-4 lg:h-[530px] md:h-auto 
    -->
    <app-branches></app-branches>

  </div>
</div>

<app-coverd-section></app-coverd-section>
<app-footer></app-footer>