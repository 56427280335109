import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { Product } from '../../models/product';
import { StrapiApiService } from '../strapi-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductResolverService  {

  constructor(private strapiAPi: StrapiApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    
    return this.strapiAPi.getProduct( route.params ['name']!).pipe(catchError((error)=> {console.log(error); return of('No Data')}));
    
  }
}
