import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-travel-inputs',
  templateUrl: './travel-inputs.component.html',
  styleUrls: ['./travel-inputs.component.css']
})
export class TravelInputsComponent implements OnInit {
  isSubmitted = false;
  accepted: boolean = false;
  isDisabled = true;
  validateEmail = true;
  phoneNumberValidation = false;
  captcharesolved = false;
  validations!: Validations;
  travelForm: any;
  loading: boolean = false;
  sumInsured: string | undefined | null;
  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.travelForm = new FormGroup({
      email: new FormControl(''),
      phone: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl('')
    });
  }
  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }
  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  checking() {

    // this.isSubmitted = true;
    // var fromDate = new Date(this.travelForm.get('from').value);
    // var toDate = new Date(this.travelForm.get('to').value);
    // console.log('From: ' + this.toUNIXTimeframe(fromDate));
    // console.log('to: ' + this.toUNIXTimeframe(toDate));

    //console.log('email: ' + this.travelForm.email.value);

  }

  toUNIXTimeframe(date: any) {
    return Math.round((new Date(date)).getTime() / 1000)
  }

  dateToString(date: any) {
    const toTwoDigits = (num: string | number) => ((+num) < 10 ? "0" + num : num);
    let year = date.getFullYear();
    let month = toTwoDigits(date.getMonth() + 1);
    let day = toTwoDigits(date.getDate());
    return `${year}-${month}-${day}`;
  }
  tomorrow() {
    let today = new Date()
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return this.dateToString(tomorrow);
  }
  nextThreeMonths() {
    let today = new Date()
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 90)
    return this.dateToString(tomorrow);
  }
  afterThisDate(date: any) {
    let tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return this.dateToString(tomorrow);
  }
  oneYearFromThisDate(date: any) {
    let tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate() + 365)
    return this.dateToString(tomorrow);
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  sendLead() {
    this.loading = true;
    if (this.travelForm.valid) {
      this.webApi.sendProductLead(
        'Customer',
        this.travelForm.get('phone').value.toString(),
        'GENERAL ACCIDENT',
        'New Insurance - Travel',
        this.travelForm.get('email').value.toString(),
        1,
        1,
        'New Travel Insurance Lead',
        'Travel Details:\n' + 'From: ' + this.travelForm.get('from').value.toString() + '\n' + 'To: ' + this.travelForm.get('to').value.toString(),
        null
      ).subscribe((s) => {
        console.log(s);
        this.goToPortalTravel();

      });
    }
  }
  goToPortalTravel() {
    const inputEmail = this.travelForm.get('email').value;
    const inputPhone = this.travelForm.get('phone').value;
    const inputFrom = new Date(this.travelForm.get('from').value);
    const inputTo = new Date(this.travelForm.get('to').value);

    const data = {
      email: inputEmail,
      phone: inputPhone,
      from: inputFrom,
      to: inputTo
    }

    const secretKey = 'Solid@abu1999';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();

    const siteToGoTo = environment.portalUrl + '/insurance-policies/travel-insurance'
    window.location.href = `${siteToGoTo}/?data=${encodeURIComponent(encryptedData)}`;
  }

}
