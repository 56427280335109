import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, retry, catchError } from 'rxjs';

interface ResponseData {
  data: any;
  // other properties that may be returned by the server
}

@Injectable({
  providedIn: 'root'
})
export class FilesapiService {
  apiURL = 'https://files-api.solidarity.bh';
  channel = 'web.solidarity';
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  getToken(item_id: String, type: string){
    var dataToSend = {
      secret: "hello123",
      agent: this.channel,
      item_type: type,
      item_id: item_id,
      description: "some description goes here",
      source: "website"
    };

    return this.http
      .post(this.apiURL + '/tokens/request', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  uploadFile(token: string, file_name: string, file_base64: string){
    var dataToSend = {
      token: token,
      source: this.channel,
      file_name: file_name,
      file_base64: file_base64
    };
    return this.http
      .post(this.apiURL + '/files/upload', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  requestLink(file_id: Number){
    var dataToSend = {
      file_id: file_id,
      ip: "",
      validity: 7200,
      agent: this.channel,
      reference: "nothing"
    };
    return this.http
    .post(this.apiURL + '/links/request', dataToSend)
    .pipe(retry(1), catchError(this.handleError));
  }
}
