<div class="items-center justify-center bg-no-repeat bg-cover bg-top"
  style="background-image: url('../../../assets/imgs/linear_background.svg')">
  <app-header bg="bg-transparent bg-opacity-100 items-center justify-center">
  </app-header>
  <div class="flex-col items-center justify-center sm:pt-14 md:pt-16 p-2 text-center">
    <div class="lg:text-6xl md:text-7xl sm:text-[40px] font-bold text-Dark-Gray-2 text-center" dir="auto">
      {{ getLang == "en" ? title_en : title_ar }}
    </div>
    <p class="flex-col text-lg md:text-base sm:text-sm md:w-5/6 lg:w-3/5 text-Mid-Gray-1 px-5 pt-5 mx-auto md:max-w-[35%] lg:max-w-[35%] sm:max-w-[90%]"
      dir="auto">
      {{ getLang == "en" ? desc_en : desc_ar }}
    </p>
    <p class="p-8 flex-col items-center justify-center text-center">
      <a href="https://web.remoteapps.com/Public/63/JobList">
        <button dir="rtl" class="button mx-auto {{
            getLang == 'en' ? 'font-medium' : 'font-medium'
          }} bg-primary-500 text-Light-Gray-5 p-2 px-4 rounded-md items-center justify-between" dir="auto">
          <p class="text-md sm:text-[14px]">
            {{ getLang == "en" ? btn_txt_en : btn_txt_ar }}
          </p>
        </button>
      </a>
    </p>
    <div class="flex items-top justify-center mt-8 w-full lg:p-2 md:p-2 sm:p-0">
      <div class="md:rounded-lg sm:rounded-md md:px-2 sm:px-1">
        <img [src]="strapiApi.getImage(top_imgs[0].image.data.attributes.url)" alt="Image 1"
          class="md:max-h-[360px] sm:max-h-[210px] md:rounded-lg sm:rounded-md items-center justify-center bg-cover" />
      </div>

      <div class="md:rounded-lg sm:rounded-md items-center justify-center bg-cover md:px-2 sm:px-1">
        <img [src]="strapiApi.getImage(top_imgs[1].image.data.attributes.url)" alt="Image 2"
          class="md:max-h-[480px] sm:max-h-[280px] md:rounded-lg sm:rounded-md items-center justify-center bg-cover" />
      </div>

      <div class="md:rounded-lg sm:rounded-md max-h-32 md:px-2 sm:px-1">
        <img [src]="strapiApi.getImage(top_imgs[2].image.data.attributes.url)" alt="Image 3"
          class="md:max-h-[360px] sm:max-h-[210px] md:rounded-lg sm:rounded-md items-center justify-center bg-cover" />
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center p-2 sm:mx-2 md:pt-28 sm:pt-16">
    <div class="rtl md:text-6xl sm:text-[40px] font-medium text-Dark-Gray-2 text-center" dir="auto">
      {{ getLang == "en" ? second_title_en : second_title_ar }}
    </div>
    <p class="text-lg lg:w-5/6 md:w-5/6 md:text-base sm:text-sm text-Mid-Gray-1 md:max-w-[35%] lg:max-w-[35%] sm:max-w-[90%] text-center justify-center pt-5 px-2"
      dir="auto">
      {{ getLang == "en" ? second_desc_en : second_desc_ar }}
    </p>
    <div class="w-full relative lg:px-2 md:px-2 sm:px-0">
      <div class="lg:h-[300px] md:h-[300px] mx-auto justify-center text-center items-center lg:pt-10 sm:pt-6 md:pt-auto"
        [dir]="getLang == 'en' ? 'auto' : 'rtl'">
        <div
          class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-5 lg:absolute md:absolute inset-x-0 -right -left lg:-mt-2 lg:px-6 md:px-6 sm:px-0 mx-auto lg:max-w-6xl md:max-w-6xl">
          <div *ngFor="let singlebox of boxes">
            <app-fatures-box [height]="'h-[300px] md:w-4/5 sm:w-full'" class="" id="box"
              img="{{ strapiApi.getImage(singlebox.icon.data.attributes.url) }}" [first_line]="
                getLang == 'en'
                  ? singlebox.box_title_en
                  : singlebox.box_title_ar
              " [second_line]="
                getLang == 'en' ? singlebox.box_desc_en : singlebox.box_desc_ar
              " dir="auto">
            </app-fatures-box>
            <p class="lg:hidden md:hidden sm:block"><br /></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col items-center justify-center md:pt-28 sm:pt-16 sm:px-4">
    <div class="md:text-[60px] sm:text-[30px] font-medium text-Dark-Gray-2 text-center leading-tight" dir="auto">
      {{ getLang == "en" ? third_title_en : third_title_ar }}
    </div>
    <p class="text-lg md:text-base sm:text-sm md:max-w-[55%] lg:max-w-[40%] sm:max-w-[90%] text-Mid-Gray-1 pt-5 text-center leading-tight justify-center px-2"
      dir="auto">
      {{ getLang == "en" ? third_desc_en : third_desc_ar }}
    </p>

    <div
      class="md:grid sm:grid-cols-1 md:grid-cols-3 auto-rows-fr content-center lg:max-w-6xl md:max-w-6xl sm:w-full pt-4"
      [dir]="getLang == 'en' ? 'auto' : 'rtl'">
      <div
        class="bg-[#FFF1E0] px-8 rounded-[30px] sm:p-7 sm:m-0 md:m-2 md:mt-2 sm:mt-4 bg-no-repeat bg-right-bottom bg-[length:200px_200px] content-center"
        dir="auto" style="background-image: url({{
          'https://content.solidarity.bh' + second_boxes[0].icon.data.attributes.url }})">
        <div class="sm:px-5 sm:py-5 md:px-1 md:py-1">
          <div class="md:text-xl sm:text-[20px] font-bold text-Dark-Gray-2 max-w-full text-[#311D06]" dir="auto">
            {{
            getLang == "en"
            ? second_boxes[0].box_title_en
            : second_boxes[0].box_title_ar
            }}
          </div>
          <p class="text-lg text-base sm:text-sm text-Mid-Gray-1 pt-4 justify-center text-[#311D06]" dir="auto">
            {{
            getLang == "en"
            ? second_boxes[0].box_desc_en
            : second_boxes[0].box_desc_ar
            }}
          </p>
        </div>
      </div>
      <div
        class="col-span-2 bg-[#F6F8F9] rounded-[30px] sm:p-7 sm:m-0 md:m-2 md:mt-2 sm:mt-4 bg-no-repeat bg-right-bottom bg-[length:200px_200px] content-center"
        style="background-image: url({{
          'https://content.solidarity.bh' +
            second_boxes[1].icon.data.attributes.url
        }})">
        <div class="sm:px-5 sm:py-5 md:px-1 md:py-1" dir="auto">
          <div class="md:text-xl sm:text-[20px] font-bold text-Dark-Gray-2 max-w-full text-[#1A2024]">
            {{
            getLang == "en"
            ? second_boxes[1].box_title_en
            : second_boxes[1].box_title_ar
            }}
          </div>
          <p class="max-w-full text-lg text-base sm:text-sm md:w-4/5 text-Mid-Gray-1 pt-4 justify-center text-[#1A2024] content-center"
            dir="auto">
            {{
            getLang == "en"
            ? second_boxes[1].box_desc_en
            : second_boxes[1].box_desc_ar
            }}
          </p>
        </div>
      </div>
      <div
        class="col-span-2 bg-[#F1FBE5] rounded-[30px] sm:p-7 sm:m-0 md:m-2 md:mt-2 sm:mt-4 bg-no-repeat bg-right-bottom bg-[length:200px_200px] mx-auto content-center"
        dir="auto" style="background-image: url({{
          'https://content.solidarity.bh' +
            second_boxes[2].icon.data.attributes.url
        }})">
        <div class="sm:px-5 sm:py-5 md:px-1 md:py-1" dir="auto">
          <div class="md:text-xl sm:text-[20px] font-bold text-Dark-Gray-2 max-w-full text-[#2F4215]" dir="auto">
            {{ getLang == "en" ? "Ethical & Inclusive" : "أخلاقي وشامل" }}
          </div>
          <p class="text-base sm:text-sm text-Mid-Gray-1 pt-4 max-w-full justify-center text-[#2F4215]" dir="auto">
            {{
            getLang == "en"
            ? second_boxes[2].box_desc_en
            : second_boxes[2].box_desc_ar
            }}
          </p>
        </div>
      </div>
      <div
        class="bg-[#EDFAFF] rounded-[30px] md:p-7 sm:p-7 sm:m-0 md:m-2 md:mt-2 sm:mt-4 bg-no-repeat bg-right-bottom bg-[length:200px_200px] content-center"
        dir="auto" style="background-image: url('../../../assets/imgs/fingerprint.svg')">
        <div class="sm:px-5 sm:py-5 md:px-1 md:py-1 mx-auto">
          <div class="md:text-xl sm:text-[20px] font-bold text-Dark-Gray-2 max-w-full text-[#00384D]" dir="auto">
            {{
            getLang == "en"
            ? second_boxes[3].box_title_en
            : second_boxes[3].box_title_ar
            }}
          </div>
          <p class="text-base sm:text-sm text-Mid-Gray-1 pt-4 max-w-full text-[#00384D]" dir="auto">
            {{
            getLang == "en"
            ? second_boxes[3].box_desc_en
            : second_boxes[3].box_desc_ar
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center md:pt-48 sm:pt-16">
    <div
      class="md:text-[60px] sm:text-[30px] font-medium text-Dark-Gray-2 text-center md:max-w-[55%] lg:max-w-[40%] sm:max-w-[90%] leading-tight px-5"
      dir="auto">
      {{ getLang == "en" ? fourth_title_en : fourth_title_ar }}
    </div>
    <p class="md:text-base sm:text-sm md:w-5/6 lg:w-3/5 text-Mid-Gray-1 pt-5 px-5 md:max-w-[45%] lg:max-w-[45%] sm:max-w-[90%] text-center leading-tight justify-center mx-4"
      dir="auto">
      {{ getLang == "en" ? fourth_desc_en : fourth_desc_ar }}
    </p>
    <p class="pt-6 md:pb-20 sm:pb-10">
      <a href="https://web.remoteapps.com/Public/63/JobList">
        <button dir="rtl" class="button flex mx-auto {{
            getLang == 'en' ? 'font-medium' : 'font-medium'
          }} bg-primary-500 text-Light-Gray-5 p-2 rounded-md items-center justify-between" dir="auto">
          <p class="text-md sm:text-[14px]" dir="auto">
            {{ getLang == "en" ? btn_txt_en : btn_txt_ar }}
          </p>
        </button>
      </a>
    </p>
    <div class="w-full overflow-x-clip max-h-80 flex flex-row snap-x gap-2 justify-center items-end">
      <div class="snap-start">
        <div
          class="bg-cover bg-no-repeat bg-center snap-start scroll-ps-6 md:rounded-lg sm:rounded-md aspect-auto md:pr-3.5 sm:pr-2 md:h-60 md:w-64 sm:w-20 sm:h-16"
          style="background-image: url({{
            'https://content.solidarity.bh' +
              moving_imgs[0].image.data.attributes.url
          }})"></div>
      </div>
      <div class="snap-start">
        <div
          class="bg-cover bg-no-repeat bg-center snap-start scroll-ps-2 md:rounded-lg sm:rounded-md aspect-auto md:pr-3.5 sm:pr-2 md:w-64 md:h-80 sm:w-24 sm:h-28"
          style="background-image: url({{
            'https://content.solidarity.bh' +
              moving_imgs[1].image.data.attributes.url
          }})"></div>
      </div>
      <div class="snap-start">
        <div
          class="bg-cover bg-no-repeat bg-center snap-start scroll-ps-6 md:rounded-lg sm:rounded-md aspect-auto md:pr-3.5 sm:pr-2 md:w-72 md:h-48 sm:w-24 sm:h-16"
          style="background-image: url({{
            'https://content.solidarity.bh' +
              moving_imgs[2].image.data.attributes.url
          }})"></div>
      </div>
      <div class="snap-start">
        <div
          class="bg-cover bg-no-repeat bg-center snap-start scroll-ps-6 md:rounded-lg sm:rounded-md aspect-auto md:pr-3.5 sm:pr-2 md:w-48 md:h-64 sm:w-16 sm:h-20"
          style="background-image: url({{
            'https://content.solidarity.bh' +
              moving_imgs[3].image.data.attributes.url
          }})"></div>
      </div>
      <div class="snap-start">
        <div
          class="bg-cover bg-no-repeat bg-center snap-start scroll-ps-6 md:rounded-lg sm:rounded-md aspect-auto md:pr-3.5 sm:pr-2 md:w-72 md:h-80 sm:w-24 sm:h-28"
          style="background-image: url({{
            'https://content.solidarity.bh' +
              moving_imgs[4].image.data.attributes.url
          }})"></div>
      </div>
      <div class="snap-start">
        <div
          class="bg-cover bg-no-repeat bg-center snap-start scroll-ps-6 md:rounded-lg sm:rounded-md aspect-auto md:pr-3.5 sm:pr-2 md:w-64 md:h-56 sm:w-20 sm:h-16"
          style="background-image: url({{
            'https://content.solidarity.bh' +
              moving_imgs[5].image.data.attributes.url
          }})"></div>
      </div>
    </div>

    <br />
    <br />
  </div>

  <app-coverd-section></app-coverd-section>
</div>

<app-footer></app-footer>