import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-medical-inputs',
  templateUrl: './medical-inputs.component.html',
  styleUrls: ['./medical-inputs.component.css']
})
export class MedicalInputsComponent implements OnInit {
  options = ['Male', 'Female'];
  selectedOption!: string;
  isSubmitted = false;
  validateEmail = true;
  validations!: Validations;
  ngForm: any;
  loading: boolean = false;
  accepted: boolean = false;
  captcharesolved = false;
  sumInsured: string | undefined | null;
  gender: string = '';
  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.ngForm = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      sumInsured: new FormControl(null),
      phone: new FormControl(''),
      age: new FormControl(null),

    });
  }
  checking() {
    this.isSubmitted = true;
  }

  selectGender(gender: any) {
    this.gender = gender;
    // console.log("My gender is: " + this.gender);
  }

  getQuote(form: NgForm): void {
    // console.log(form);
    this.loading = true;
    if (form.valid) {
      // console.log(form);
      this.webApi.getHomeQuote(form.value["sumInsured"], "0", "0", "0", "0", "FALCON", null, null).subscribe((s) => {
        this.router.navigate(['quote/home'], { state: { quote: s } });
      });
    }

  }

  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  sendLead() {
    this.loading = true;
    if (this.ngForm.valid) {
      this.webApi.sendProductLead(
        this.ngForm.get('name').value.toString(),
        this.ngForm.get('phone').value.toString(),
        'Medical',
        'New Insurance - Medical',
        this.ngForm.get('email').value.toString(),
        1,
        1,
        'New Medical Insurance Lead',
        '',
        null
      ).subscribe((s) => {
        // console.log(s);
        this.goToPortalMedical();

      });
    }
  }
  goToPortalMedical() {
    const inputEmail = this.ngForm.get('email').value;
    const inputPhone = this.ngForm.get('phone').value;
    const inputName = this.ngForm.get('name').value;
    // const inputGender = this.ngForm.get('options').value;
    const inputGender = this.gender;

    const data = {
      email: inputEmail,
      phone: inputPhone,
      name: inputName,
      gender: inputGender
    }

    // console.log("Data: " + JSON.stringify(data));

    const secretKey = 'Solid@abu1999';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();

    const siteToGoTo = environment.angularUrl + '/insurance-policies/medical-insurance?react=true'
    window.location.href = `${siteToGoTo}/?data=${encodeURIComponent(encryptedData)}`;

  }
}
