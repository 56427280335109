import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { Attributes } from 'src/app/models/privacy-and-security'

@Component({
  selector: 'app-privacyandsecurity',
  templateUrl: './privacyandsecurity.component.html',
  styleUrls: ['./privacyandsecurity.component.css']
})
export class PrivacyandsecurityComponent implements OnInit {
  privacyAndSecurity!: Attributes;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute,) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      this.privacyAndSecurity = params['data'].data.attributes;
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
