import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { createPopper } from "@popperjs/core";
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { ActivatedRoute } from '@angular/router';
import { PlansDetail, Product } from 'src/app/models/product';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() bg?: string;
  dropdownPopoverShow = false;
  showMenu = false;
  dropdownPopoverShowSupport = false;
  dropdownPopoverShowLife = false; 
  dropdownPopoverShowClaims = false;

  @ViewChild("btnDropdownRef", { static: false })
  btnDropdownRef!: ElementRef;
  @ViewChild("btnDropdownRefSupport", { static: false })
  btnDropdownRefSupport!: ElementRef;
  @ViewChild("btnDropdownRefLife", { static: false })
  btnDropdownRefLife!: ElementRef;
  @ViewChild("btnDropdownRefClaims", { static: false })
  btnDropdownRefClaims!: ElementRef;
  
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef?: ElementRef;
  @ViewChild("popoverDropdownRefSupport", { static: false })
  popoverDropdownRefSupport?: ElementRef;
  currentLang: any;

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute, private http: HttpClient) {
    // console.log(route);
  }

  ngOnInit(): void {
    window.addEventListener('scroll', (ev) => this.handleScroll(this.bg!));
    document
      .getElementById('header')
      ?.setAttribute(
        'class',
        ' sticky w-full h-20 top-0 bg-white bg-opacity-70 items-center justify-between  py-2 z-20 ' +
        this.bg
      );
  }

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef?.nativeElement,
      {
        placement: "bottom-start",
      },
    );
    createPopper(
      this.btnDropdownRefSupport.nativeElement,
      this.popoverDropdownRefSupport?.nativeElement,
      {
        placement: "bottom-start",
      },
    );

    // this.initNavbarToggle();
  }

  setlang(language: string) {
    localStorage.setItem('currentLanguage', language);
    localStorage.getItem('currentLanguage');
    // console.log(this.currentLang);
  }

  toggleDropdown(event: any, type: string) {
    event.preventDefault();

    switch(type) {
      case "General":
        this.dropdownPopoverShow = !this.dropdownPopoverShow;
        this.dropdownPopoverShowSupport = false;
        this.dropdownPopoverShowLife = false;
        this.dropdownPopoverShowClaims = false;
        break;
      case "Life":
        this.dropdownPopoverShow = false;
        this.dropdownPopoverShowSupport = false;
        this.dropdownPopoverShowLife = !this.dropdownPopoverShowLife;
        this.dropdownPopoverShowClaims = false;
        break;
      case "Support":
        this.dropdownPopoverShow = false;
        this.dropdownPopoverShowSupport = !this.dropdownPopoverShowSupport;
        this.dropdownPopoverShowLife = false;
        this.dropdownPopoverShowClaims = false;
        break;
      case "Claims":
        this.dropdownPopoverShow = false;
        this.dropdownPopoverShowSupport = false;
        this.dropdownPopoverShowLife = false;
        this.dropdownPopoverShowClaims = !this.dropdownPopoverShowClaims;
        break;
      default:
        this.dropdownPopoverShow = false;
        this.dropdownPopoverShowSupport = false;
        this.dropdownPopoverShowLife = false;
        this.dropdownPopoverShowClaims = false;
    }
    // if (this.dropdownPopoverShow) {
    //   this.dropdownPopoverShow = false;
    // } else {
    //   this.dropdownPopoverShow = true;
    //   this.dropdownPopoverShowSupport = false;
    // }
  }

  toggleNavbar() {
    this.showMenu = !this.showMenu;
    // if(this.showMenu){
    //   document.body.classList.remove('overflow-y-visible')
    //   document.body.classList.add('overflow-y-hidden')
    // }
    // else{
    //   document.body.classList.remove('overflow-y-hidden')
    //   document.body.classList.add('overflow-y-visible')
    // }
  }

  toggleDropdownSupport(event: any) {
    console.log("Life drop down: ");
    console.log(event)
    console.log(event.preventDefault)

    event.preventDefault();
    if (this.dropdownPopoverShowSupport) {
      this.dropdownPopoverShowSupport = false;
    } else {
      this.dropdownPopoverShowSupport = true;
      this.dropdownPopoverShow = false;
    }
  }

  toggleLifeDropDown(event: any) {

  }

  toggleClaimDropdown() {

  }

  handleScroll(bg: string) {
    // when the user scrolls, check the scrollY
    if (window.scrollY > 0) {
      document
        .getElementById('header')
        ?.setAttribute(
          'class',
          ' header sticky w-full  h-20 top-0  items-center justify-between py-2 z-20 shadow-md bg-white border-b	border-Light-Gray-1'
        );
    } else {
      document
        .getElementById('header')
        ?.setAttribute(
          'class',
          'header sticky w-full  h-20 top-0 items-center justify-between py-2 z-20 ' +
          bg
        );
    }
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }

  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }

  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }

  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  showGeneralDropdown: boolean = true;
  showLifeDropdown: boolean = true;
  showClaimsDropdown: boolean = true;
  showSupportDropdown: boolean = true;

  toggleShowDropdown(type: string) {
    console.log('toggleShowDropdown');

    switch (type) {
      case 'general':
        this.showGeneralDropdown = !this.showGeneralDropdown;
        this.showLifeDropdown = true;
        this.showClaimsDropdown = true;
        this.showSupportDropdown = true;

        break;
      case 'life':
        this.showLifeDropdown = !this.showLifeDropdown;
        this.showGeneralDropdown = true;
        this.showClaimsDropdown = true;
        this.showSupportDropdown = true;

        break;
      case 'claims':
        this.showClaimsDropdown = !this.showClaimsDropdown;
        this.showGeneralDropdown = true;
        this.showLifeDropdown = true;
        this.showSupportDropdown = true;

        break;
      case 'support':
        this.showSupportDropdown = !this.showSupportDropdown;
        this.showGeneralDropdown = true;
        this.showLifeDropdown = true;
        this.showClaimsDropdown = true

        break;
    }


  }

  GetStrapi(url: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.strapiUrl}` + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

}
