<div class="h-[100vh] lg:block sm:hidden md:hidden">
  <div class="flex min-h-full">
    <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <svg class="h-14 w-auto" width="153" height="40" viewBox="0 0 153 40" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_85)">
              <path
                d="M31.88 0.5H9.04002C4.58002 0.5 0.960022 4.12 0.960022 8.58V31.42C0.960022 35.88 4.58002 39.5 9.04002 39.5H31.88C36.34 39.5 39.96 35.88 39.96 31.42V8.58C39.96 4.12 36.34 0.5 31.88 0.5ZM30.53 8.66C31.62 9.2 32.38 9.87 33.03 10.89C33.58 11.81 33.79 12.61 33.7 13.48C32.69 13.1 31.34 13.15 28.81 14.24C27.7 12.1 26.47 10.1 25.11 8.3C27.92 7.7 29.35 8.1 30.54 8.67L30.53 8.66ZM23.63 7.17C25.58 9.61 27.97 13.34 29.74 17.26C28.3 18.01 26.53 18.84 24.91 19.63L22.81 20.66C20.66 16.09 18.61 11.89 17.2 9.18C16.22 7.42 15.21 6.54 13.94 6.37C13.52 6.31 13.12 6.34 12.69 6.44C18.47 2.63 22.53 5.79 23.64 7.17H23.63ZM8.84002 10.15C9.38002 9.06 10.04 8.31 11.07 7.65C11.99 7.1 12.79 6.89 13.65 6.98C13.27 8 13.33 9.34 14.42 11.87C12.27 12.98 10.26 14.23 8.46002 15.58C7.86002 12.78 8.26002 11.34 8.84002 10.15ZM7.34002 17.04C9.78002 15.08 13.52 12.71 17.43 10.94C18.19 12.37 19.01 14.15 19.81 15.77L20.83 17.87C16.27 20.02 12.07 22.06 9.36002 23.47C7.60002 24.46 6.72002 25.46 6.54002 26.74C6.50002 27.16 6.51002 27.57 6.63002 28C2.81002 22.22 5.96002 18.15 7.34002 17.04ZM10.33 31.84C9.23002 31.3 8.49002 30.63 7.83002 29.61C7.27002 28.69 7.08002 27.88 7.17002 27.02C8.18002 27.4 9.54002 27.35 12.06 26.26C13.17 28.41 14.42 30.41 15.77 32.21C12.97 32.81 11.54 32.41 10.34 31.84H10.33ZM17.23 33.33C15.28 30.9 12.9 27.16 11.13 23.24C12.56 22.49 14.33 21.67 15.96 20.87L18.06 19.84C20.21 24.41 22.25 28.6 23.66 31.32C24.64 33.08 25.65 33.96 26.92 34.14C27.34 34.19 27.76 34.16 28.19 34.06C22.4 37.86 18.34 34.72 17.22 33.33H17.23ZM32.04 30.34C31.49 31.44 30.82 32.18 29.81 32.84C28.88 33.39 28.08 33.6 27.21 33.5C27.6 32.49 27.55 31.15 26.45 28.61C28.6 27.51 30.6 26.26 32.4 24.91C33.01 27.71 32.61 29.15 32.04 30.34ZM33.53 23.44C31.1 25.4 27.36 27.78 23.45 29.55C22.69 28.12 21.86 26.35 21.07 24.71L20.05 22.61C24.62 20.46 28.81 18.42 31.53 17.02C33.29 16.03 34.16 15.02 34.35 13.75C34.4 13.32 34.37 12.91 34.27 12.49C38.08 18.27 34.93 22.33 33.54 23.44H33.53Z"
                fill="#00A0D9" />
              <path
                d="M52.14 29.15L50.69 28.25C50.02 27.84 49.15 27.08 49.15 26.03C49.15 25.2 49.53 24.03 51.3 24.03C53.07 24.03 53.52 25.2 53.75 26.07C53.84 26.18 54.13 26.12 54.2 26.02C54.2 25.26 54.04 24.25 53.87 23.82C53.73 23.82 53.47 23.78 53.24 23.71C52.77 23.58 52.12 23.48 51.56 23.48C49.21 23.48 47.88 24.87 47.88 26.59C47.88 28.07 48.93 29.1 49.83 29.7L51.55 30.82C52.87 31.67 53.03 32.55 53.03 33.35C53.03 34.43 52.29 35.42 50.77 35.42C48.68 35.42 47.94 33.65 47.66 32.73C47.59 32.62 47.32 32.66 47.23 32.78C47.26 33.63 47.5 34.91 47.72 35.31C48.06 35.51 49.02 35.98 50.54 35.98C52.89 35.98 54.39 34.63 54.39 32.64C54.39 30.93 53.27 29.88 52.13 29.17L52.14 29.15Z"
                fill="#00A0D9" />
              <path
                d="M62.6 23.46C58.88 23.46 56.17 26.22 56.17 29.83C56.17 33.08 58.59 35.95 62.6 35.95C66.09 35.95 68.87 33.29 68.87 29.65C68.87 26.47 66.78 23.46 62.6 23.46ZM62.84 35.39C59.28 35.39 57.98 31.92 57.98 29.25C57.98 25.3 60.42 24.01 62.3 24.01C65.75 24.01 67.07 27.22 67.07 30.2C67.07 33.76 65.01 35.38 62.85 35.38L62.84 35.39Z"
                fill="#00A0D9" />
              <path
                d="M79.23 33.08C78.87 34 78.4 34.65 78.04 34.87C77.75 35.03 77.37 35.1 75.84 35.1C74.41 35.1 74.07 34.88 73.87 34.69C73.54 34.35 73.49 33.88 73.49 32.74V26.46C73.49 24.56 73.53 24.28 74.59 24.18L75.02 24.14C75.13 24.07 75.09 23.74 74.98 23.69C74.08 23.73 73.46 23.74 72.76 23.74C72.06 23.74 71.42 23.72 70.41 23.69C70.3 23.74 70.27 24.07 70.37 24.14L70.82 24.18C71.96 24.27 71.99 24.56 71.99 26.46V32.96C71.99 34.86 71.96 35.11 70.91 35.22L70.37 35.27C70.26 35.38 70.3 35.67 70.41 35.72C71.42 35.68 72 35.67 72.76 35.67H74.42C76.28 35.67 78 35.69 78.91 35.72C79.16 35.23 79.67 33.39 79.67 33.19C79.67 33.06 79.33 32.99 79.22 33.08H79.23Z"
                fill="#00A0D9" />
              <path
                d="M84.58 24.18L85.03 24.14C85.14 24.07 85.1 23.74 84.99 23.69C84.09 23.73 83.47 23.74 82.75 23.74C81.99 23.74 81.38 23.72 80.48 23.69C80.37 23.74 80.34 24.07 80.44 24.14L80.89 24.18C81.96 24.27 81.99 24.58 81.99 26.49V32.92C81.99 34.83 81.95 35.09 80.89 35.21L80.44 35.26C80.33 35.37 80.37 35.66 80.48 35.71C81.38 35.67 82 35.66 82.75 35.66C83.5 35.66 84.09 35.68 84.99 35.71C85.1 35.66 85.14 35.33 85.03 35.26L84.58 35.21C83.51 35.08 83.48 34.83 83.48 32.92V26.49C83.48 24.58 83.52 24.27 84.58 24.18Z"
                fill="#00A0D9" />
              <path
                d="M95.38 24.23C94.21 23.8 92.92 23.69 91.41 23.69C89.9 23.69 88.41 23.74 86.88 23.83C86.7 23.9 86.72 24.19 86.86 24.28L87.26 24.33C88.02 24.44 88.11 24.82 88.11 26.08V32.94C88.11 34.84 88.07 35.05 87.03 35.2L86.61 35.25C86.5 35.36 86.54 35.65 86.65 35.7C87.5 35.66 88.15 35.65 88.91 35.65C89.54 35.65 90.7 35.79 91.85 35.79C93.24 35.79 94.72 35.56 95.89 34.92C97.95 33.8 99.05 31.83 99.05 29.54C99.05 27.25 97.82 25.1 95.38 24.21V24.23ZM92.17 35.25C91.03 35.25 90.35 35.07 89.95 34.51C89.64 34.08 89.59 33.5 89.59 32.54V25.88C89.59 25.07 89.63 24.61 89.77 24.51C89.91 24.4 90.49 24.26 91.56 24.26C96.29 24.26 97.25 27.62 97.25 29.61C97.25 32.97 95.68 35.26 92.18 35.26L92.17 35.25Z"
                fill="#00A0D9" />
              <path
                d="M110.91 35.21C110.13 35.12 109.9 34.85 109.38 33.3L106.91 26.04C106.64 25.25 106.35 24.41 106.12 23.62C106.08 23.49 105.96 23.46 105.85 23.46C105.65 23.7 105.35 24.02 104.91 24.15C104.97 24.44 104.86 24.78 104.6 25.43L102.76 30.31C102 32.33 101.5 33.5 101.17 34.34C100.92 34.99 100.52 35.19 100.09 35.22L99.5 35.27C99.39 35.36 99.43 35.65 99.53 35.72C100.02 35.7 100.67 35.67 101.26 35.67C102.11 35.69 102.67 35.69 103.14 35.72C103.3 35.65 103.3 35.36 103.18 35.27L102.58 35.22C102.14 35.18 102.06 35.08 102.06 34.86C102.06 34.66 102.19 34.17 102.46 33.36L103.09 31.46C103.22 31.08 103.32 31.03 103.78 31.03H106.51C107.05 31.03 107.12 31.08 107.25 31.48L108.04 33.94C108.18 34.37 108.27 34.7 108.27 34.9C108.27 35.08 108.02 35.19 107.64 35.22L107.12 35.27C106.99 35.36 107.01 35.65 107.16 35.72C107.68 35.7 108.37 35.67 109.24 35.67C110.05 35.67 110.68 35.69 111.42 35.72C111.51 35.65 111.55 35.36 111.42 35.27L110.93 35.22L110.91 35.21ZM106.25 30.3H104.03C103.52 30.3 103.51 30.28 103.65 29.87L104.79 26.73C104.99 26.19 105.17 25.75 105.22 25.66H105.26C105.37 25.88 105.53 26.36 105.68 26.8L106.65 29.74C106.81 30.23 106.78 30.3 106.25 30.3Z"
                fill="#00A0D9" />
              <path
                d="M127.67 24.18L128.12 24.14C128.23 24.07 128.19 23.74 128.09 23.69C127.19 23.73 126.57 23.74 125.85 23.74C125.09 23.74 124.48 23.72 123.57 23.69C123.46 23.74 123.43 24.07 123.53 24.14L123.98 24.18C125.05 24.27 125.08 24.58 125.08 26.49V32.92C125.08 34.83 125.04 35.09 123.98 35.21L123.53 35.26C123.42 35.37 123.46 35.66 123.57 35.71C124.47 35.67 125.09 35.66 125.85 35.66C126.61 35.66 127.19 35.68 128.09 35.71C128.2 35.66 128.23 35.33 128.12 35.26L127.67 35.21C126.6 35.08 126.57 34.83 126.57 32.92V26.49C126.57 24.58 126.61 24.27 127.67 24.18Z"
                fill="#00A0D9" />
              <path
                d="M140.29 23.24C140.02 23.71 139.86 23.75 138.88 23.75H131.57C130.74 23.75 130.14 23.77 130.03 23.19C129.97 23.14 129.74 23.15 129.69 23.19C129.56 23.91 129.29 25.07 129 25.88C129.07 26.02 129.33 26.11 129.47 26.01C129.67 25.56 129.85 25.16 130.28 24.76C130.73 24.35 131.72 24.31 132.5 24.31H133.49C134.03 24.31 134.03 24.33 134.03 24.89V32.96C134.03 34.86 133.94 35.13 132.91 35.22L132.26 35.27C132.12 35.36 132.15 35.67 132.26 35.72C133.42 35.68 134.05 35.67 134.77 35.67C135.49 35.67 136.11 35.69 137.14 35.72C137.25 35.67 137.28 35.34 137.18 35.27L136.6 35.22C135.55 35.13 135.52 34.86 135.52 32.96V24.93C135.52 24.34 135.5 24.32 136.04 24.32H137.32C138.31 24.32 139 24.47 139.41 24.77C139.68 24.97 139.81 25.64 139.86 26.09C139.95 26.2 140.26 26.2 140.33 26.05C140.28 25.51 140.4 24.05 140.6 23.31C140.57 23.24 140.37 23.22 140.28 23.25L140.29 23.24Z"
                fill="#00A0D9" />
              <path
                d="M151.92 23.69C151.31 23.73 150.8 23.74 150.24 23.74C149.68 23.74 149.16 23.72 148.51 23.69C148.37 23.76 148.35 24.02 148.47 24.14L148.8 24.18C149.23 24.23 149.56 24.36 149.56 24.47C149.56 24.69 148.89 25.91 148.39 26.85C147.85 27.9 147.25 28.94 146.69 29.9C145.98 28.65 145.32 27.23 144.65 25.89C144.56 25.71 144.09 24.68 144.09 24.5C144.09 24.36 144.22 24.27 144.85 24.17L145.09 24.13C145.2 24.02 145.2 23.73 145.04 23.68C144.59 23.72 143.94 23.73 143.11 23.73C142.28 23.73 141.59 23.71 141.02 23.68C140.89 23.75 140.87 24.01 140.98 24.13L141.49 24.22C142.03 24.31 142.3 24.4 142.92 25.59L145.05 29.74C145.57 30.75 145.63 30.99 145.63 31.98V32.9C145.63 34.83 145.59 35.08 144.55 35.19L143.99 35.24C143.88 35.35 143.92 35.64 144.03 35.69C144.99 35.65 145.64 35.64 146.4 35.64C147.16 35.64 147.74 35.66 148.85 35.69C148.96 35.64 148.99 35.35 148.89 35.24L148.2 35.19C147.22 35.12 147.12 34.83 147.12 32.9V31.96C147.12 30.57 147.17 30.41 147.97 29.03L149.11 27.06C150.01 25.51 150.55 24.37 151.49 24.21L151.99 24.12C152.12 24.01 152.1 23.76 151.96 23.67L151.92 23.69Z"
                fill="#00A0D9" />
              <path
                d="M125.16 36.86C125.16 36.86 125.05 36.92 124.95 36.92C124.88 36.92 124.81 36.92 124.75 36.91C124.74 36.91 124.72 36.91 124.71 36.91C124.65 36.91 124.6 36.89 124.54 36.88C124.49 36.88 124.45 36.86 124.4 36.85C123.9 36.73 123.52 36.49 123.27 36.35C122.08 35.65 119.91 33.38 118.45 30.22C118.38 30.07 118.27 29.9 118.36 29.79C119.26 29.43 120.62 28.49 120.62 26.78C120.62 25.52 119.99 24.7 119.12 24.25C118.29 23.8 117.23 23.71 116.11 23.71C114.34 23.71 113.09 23.76 112.08 23.85C111.94 23.92 111.94 24.21 112.06 24.3L112.64 24.37C113.38 24.46 113.44 24.77 113.44 26.48V32.96C113.44 34.86 113.4 35.09 112.36 35.22L111.91 35.27C111.8 35.4 111.84 35.67 111.95 35.72C112.8 35.68 113.45 35.67 114.21 35.67C114.97 35.67 115.55 35.69 116.36 35.72C116.47 35.67 116.5 35.36 116.39 35.27L116.01 35.22C114.96 35.08 114.93 34.86 114.93 32.96V30.69C114.93 30.31 114.97 30.29 115.78 30.29C116.5 30.29 116.79 30.47 117.06 30.87C117.62 31.68 118.43 32.89 118.9 33.56C120.11 35.28 121.63 36.47 122.97 36.9C122.97 36.9 123.01 36.91 123.07 36.93C123.1 36.94 123.14 36.95 123.17 36.96C123.17 36.96 123.27 36.99 123.46 37.03C123.94 37.14 124.67 37.24 125.16 37.05C125.5 36.94 125.31 36.73 125.18 36.87L125.16 36.86ZM116.22 29.74C115.3 29.74 115.03 29.7 114.97 29.65C114.92 29.58 114.92 29.38 114.92 29.05V24.82C114.92 24.32 114.97 24.24 116.09 24.24C118.09 24.24 118.98 25.59 118.98 27.02C118.98 28.25 118.57 28.88 118.08 29.26C117.56 29.66 116.87 29.73 116.22 29.73V29.74Z"
                fill="#00A0D9" />
              <path
                d="M56.48 9.07001H53.25C53.25 8.46001 53.45 7.93001 53.85 7.50001C54.26 7.07001 54.77 6.85001 55.36 6.87001C56.19 6.87001 56.78 7.25001 57.16 8.02001C57.39 8.51001 57.54 9.28001 57.63 10.31C57.68 10.94 57.94 11.44 58.35 11.84C58.78 12.24 59.25 12.43 59.74 12.43H84.11C85.7 12.43 86.56 11.53 86.56 10.09C86.56 10.1 86.55 9.05001 86.52 6.94001C86.59 6.38001 86.78 6.24001 87.35 6.20001L87.7 6.17001C87.79 6.11001 87.76 5.86001 87.67 5.81001C87.2 5.83001 86.84 5.84001 86.48 5.85001H85.25C85.29 8.83001 85.3 10.21 85.3 10.05C85.3 10.86 84.78 11.28 84.07 11.28H59.94C59.42 11.28 58.86 10.77 58.81 10.14C58.68 8.93001 58.48 8.03001 58.21 7.47001C57.65 6.26001 56.7 5.67001 55.36 5.67001C53.07 5.67001 51.92 7.19001 51.92 10.21H55.27L55.31 11.24C55.31 12.3 54.97 13.21 54.27 13.96C53.57 14.72 52.74 15.09 51.75 15.09C50.76 15.09 49.93 14.71 49.23 13.97C48.53 13.23 48.18 12.33 48.18 11.27C48.16 10.17 48.14 9.43001 48.14 9.05001H46.9C46.56 9.05001 46.21 9.03001 45.78 9.01001C45.69 9.05001 45.67 9.31001 45.75 9.37001L46.1 9.40001C46.67 9.45001 46.87 9.58001 46.93 10.14C46.94 10.61 46.96 10.98 46.97 11.25C46.97 12.67 47.44 13.86 48.38 14.86C49.32 15.85 50.43 16.36 51.75 16.36C53.07 16.36 54.2 15.86 55.12 14.86C56.06 13.87 56.53 12.68 56.53 11.27L56.48 9.05001V9.07001Z"
                fill="#00A0D9" />
              <path d="M51.43 17.71H50.1V19.12H51.43V17.71Z" fill="#00A0D9" />
              <path d="M53.65 17.71H52.32V19.12H53.65V17.71Z" fill="#00A0D9" />
              <path d="M86.58 3H85.25V4.41H86.58V3Z" fill="#00A0D9" />
              <path d="M84.36 3H83.03V4.41H84.36V3Z" fill="#00A0D9" />
              <path
                d="M109.39 12.44H111.01C112 12.39 112.92 11.86 113.26 10.98C113.35 11.25 113.49 11.5 113.71 11.75C114.09 12.2 114.54 12.44 115.08 12.44H118.02H119.19C120.78 12.44 121.64 11.54 121.64 10.1C121.64 8.95001 121.62 7.40001 121.6 5.45001C121.6 4.05001 121.58 2.92001 121.57 2.07001C121.61 1.32001 121.78 1.16001 122.42 1.10001L122.78 1.07001C122.87 1.01001 122.84 0.760007 122.75 0.710007C122.28 0.730007 121.92 0.740007 121.56 0.750007H120.32C120.32 1.60001 120.34 3.15001 120.36 5.42001C120.38 7.37001 120.39 8.92001 120.39 10.05C120.39 10.86 119.87 11.28 119.17 11.28H118.37H114.95C114.61 11.28 114.26 11.12 113.96 10.79C113.6 10.41 113.42 9.91001 113.4 9.28001C113.39 8.55001 113.38 7.81001 113.38 7.07001C113.44 6.43001 113.62 6.29001 114.22 6.23001L114.58 6.20001C114.67 6.14001 114.64 5.89001 114.55 5.85001C114.09 5.87001 113.72 5.88001 113.37 5.89001C113.37 5.88001 113.37 5.87001 113.37 5.86001H112.13C112.18 8.93001 112.18 10.31 112.15 10.02C112.15 10.44 111.97 10.76 111.59 11.01C111.3 11.19 110.99 11.28 110.65 11.28H108.65H100.14C100 9.53001 99.54 8.18001 98.81 7.19001C98.14 6.31001 97.37 5.85001 96.45 5.85001V6.97001C97.04 6.97001 97.59 7.37001 98.09 8.18001C98.65 9.05001 98.94 10.07 98.97 11.28H94.05L93.67 12.43H109.39V12.44Z"
                fill="#00A0D9" />
              <path d="M112.47 13.72H111.12V15.11H112.47V13.72Z" fill="#00A0D9" />
              <path d="M114.67 13.72H113.34V15.11H114.67V13.72Z" fill="#00A0D9" />
              <path
                d="M126.77 12.44H128.39C128.12 13.29 127.65 13.95 127.02 14.46C126.35 14.98 125.56 15.24 124.66 15.24C124.89 15.76 125.07 16.12 125.18 16.36C127.47 16.16 129.11 14.68 129.67 12.45H132.02H142.03V11.64C142.25 11.98 142.5 12.23 142.8 12.4C143.14 12.58 143.63 12.67 144.23 12.67C145.4 12.67 146.16 12.2 146.48 11.24C146.75 11.78 147.08 12.16 147.47 12.39C147.81 12.59 148.3 12.7 148.91 12.7C150.3 12.7 151.31 11.75 151.31 10.32C151.31 10.19 151.29 8.71 151.27 5.87H150.03C150.07 8.5 150.08 9.98 150.08 10.32C150.08 11.11 149.5 11.54 148.8 11.54C148.15 11.54 147.63 11.38 147.25 11.05C146.83 10.71 146.62 10.2 146.6 9.53C146.58 8.11 146.58 7.06 146.58 6.38H145.34C145.38 8.62 145.39 9.93 145.39 10.31C145.39 11.12 144.87 11.53 144.17 11.53C142.78 11.53 142.08 10.86 142.04 9.53V6.38H140.83C140.47 6.38 140.1 6.36 139.64 6.34C139.55 6.38 139.53 6.64 139.61 6.7L139.97 6.73C140.58 6.78 140.76 6.93 140.81 7.58C140.81 7.91 140.81 8.21 140.81 8.48V11.29H132.01H129.86V8.68C129.86 6.79 128.92 5.83 127.05 5.83C126.33 5.83 125.68 6.06 125.12 6.55C124.42 7.16 124.02 8.05 124 9.22C124 10.1 124.22 10.88 124.74 11.51C125.26 12.14 125.93 12.45 126.76 12.45L126.77 12.44ZM125.96 7.28C126.3 6.87 126.7 6.67 127.18 6.67C128.1 6.67 128.62 7.21 128.62 8.27V11.28H127.09C126.03 11.28 125.36 10.32 125.36 9.12C125.36 8.34 125.58 7.73 125.96 7.28Z"
                fill="#00A0D9" />
              <path
                d="M87.11 16.53C87.11 16.53 87.18 16.51 87.21 16.5C87.27 16.48 87.31 16.47 87.31 16.47C87.59 16.38 87.88 16.25 88.17 16.09C89.01 15.64 89.72 15.25 90.66 14.03C91.3 13.2 91.51 11.71 91.51 10.78V10.41C91.41 10.61 91.3 10.8 91.19 11C91.3 10.81 91.4 10.62 91.51 10.41L91.48 6.89001C91.55 6.38001 91.75 6.26001 92.31 6.21001L92.66 6.18001C92.75 6.12001 92.72 5.87001 92.63 5.82001C92.16 5.84001 91.8 5.85001 91.44 5.86001V5.89001H90.23L90.25 10.97C90.25 11.88 90.03 12.69 89.61 13.38C89.19 14.3 87.48 15.62 86.99 15.9C86.9 15.95 86.79 16.01 86.67 16.08C86.72 16.19 86.77 16.28 86.81 16.35C86.77 16.28 86.72 16.18 86.67 16.08C86.45 16.2 86.18 16.33 85.86 16.41C85.82 16.42 85.77 16.43 85.72 16.44C85.67 16.45 85.61 16.46 85.55 16.47C85.54 16.47 85.52 16.47 85.51 16.47C85.44 16.47 85.38 16.48 85.3 16.48C85.21 16.48 85.13 16.46 85.09 16.42C84.96 16.28 84.77 16.49 85.11 16.6C85.6 16.8 86.33 16.69 86.81 16.58C86.99 16.55 87.1 16.51 87.1 16.51L87.11 16.53Z"
                fill="#00A0D9" />
            </g>
            <defs>
              <clipPath id="clip0_2_85">
                <rect width="153" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Renew Your Policy</h2>

        </div>

        <div class="mt-10">
          <div>
            <form action="#" method="POST" class="space-y-6">
              <div>
                <label for="email" class="block text-lg font-medium leading-6 text-gray-900">CPR</label>
                <div class="mt-2">
                  <input id="email" name="email" type="email" autocomplete="email" required
                    class="block w-[150%] rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div>
                <label for="password" class="block text-lg font-medium leading-6 text-gray-900">Plate Number</label>
                <div class="mt-2">
                  <input id="password" name="password" type="password" autocomplete="current-password" required
                    class="block w-[150%] rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>


              <br>
              <br>
              <div>
                <button type="submit"
                  class="flex w-full justify-center rounded-md bg-primary-400 px-3 py-1.5 text-lg font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Search</button>
              </div>
            </form>
          </div>


        </div>
      </div>
    </div>
    <div class="relative  w-0 flex-1 lg:block">
      <div style="position: relative; overflow: hidden; height: 100vh;">
        <video src="https://insureandwin.s3.me-south-1.amazonaws.com/insure-win-skiplino+(1).mp4" [autoplay]="true"
          [loop]="true" [controls]="false" [muted]="true" (ended)="replayVideo()" autoplay controls loop muted
          style="position: absolute; right: 0; top: 0; height: 100%;"></video>
      </div>
    </div>


  </div>

</div>
<div class="h-screen flex flex-col-reverse lg:flex-row lg:hidden">
  <div *ngIf="searchPolicyError != ''" class="fixed top-0 left-0 w-full bg-Red-700 text-white p-4 text-center fade-out">
   {{searchPolicyError}}
  </div>
  <div class="lg:flex-1">
    <!-- Video container -->
    <div class="relative h-[50vh] lg:h-[70vh]">
      <video type="video/mp4" src="https://insureandwin.s3.me-south-1.amazonaws.com/insure-win-skiplino+(1).mp4"
        [autoplay]="true" [loop]="true" [controls]="false" [muted]="true"
        class="w-full h-full object-cover absolute inset-0"></video>
    </div>
  </div>
  <button (click)="refreshPage()" class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md m-4 p-2" style="    text-align: -webkit-center;
    width: 10%;
    position: absolute;
    top: 5px;
    right: 5px;">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-clockwise"
      viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
    </svg>
  </button>
  <!-- <button class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md m-4 p-2" style="    text-align: -webkit-center;
    width: 10%;
    position: absolute;
    top: 5px;
    left: 5px;">
    
    {{countdownValue}}
  </button> -->
  <div class="lg:flex-1">
    <!-- Form container -->

    <div class="flex flex-col justify-center items-center h-[50vh] lg:h-[30vh] px-4 py-6 sm:px-6 lg:px-20 xl:px-24">
      <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 w-full">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm" *ngIf="step != 3 && step != 4">
          <svg class="h-14 w-auto" width="153" height="40" viewBox="0 0 153 40" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_85)">
              <path
                d="M31.88 0.5H9.04002C4.58002 0.5 0.960022 4.12 0.960022 8.58V31.42C0.960022 35.88 4.58002 39.5 9.04002 39.5H31.88C36.34 39.5 39.96 35.88 39.96 31.42V8.58C39.96 4.12 36.34 0.5 31.88 0.5ZM30.53 8.66C31.62 9.2 32.38 9.87 33.03 10.89C33.58 11.81 33.79 12.61 33.7 13.48C32.69 13.1 31.34 13.15 28.81 14.24C27.7 12.1 26.47 10.1 25.11 8.3C27.92 7.7 29.35 8.1 30.54 8.67L30.53 8.66ZM23.63 7.17C25.58 9.61 27.97 13.34 29.74 17.26C28.3 18.01 26.53 18.84 24.91 19.63L22.81 20.66C20.66 16.09 18.61 11.89 17.2 9.18C16.22 7.42 15.21 6.54 13.94 6.37C13.52 6.31 13.12 6.34 12.69 6.44C18.47 2.63 22.53 5.79 23.64 7.17H23.63ZM8.84002 10.15C9.38002 9.06 10.04 8.31 11.07 7.65C11.99 7.1 12.79 6.89 13.65 6.98C13.27 8 13.33 9.34 14.42 11.87C12.27 12.98 10.26 14.23 8.46002 15.58C7.86002 12.78 8.26002 11.34 8.84002 10.15ZM7.34002 17.04C9.78002 15.08 13.52 12.71 17.43 10.94C18.19 12.37 19.01 14.15 19.81 15.77L20.83 17.87C16.27 20.02 12.07 22.06 9.36002 23.47C7.60002 24.46 6.72002 25.46 6.54002 26.74C6.50002 27.16 6.51002 27.57 6.63002 28C2.81002 22.22 5.96002 18.15 7.34002 17.04ZM10.33 31.84C9.23002 31.3 8.49002 30.63 7.83002 29.61C7.27002 28.69 7.08002 27.88 7.17002 27.02C8.18002 27.4 9.54002 27.35 12.06 26.26C13.17 28.41 14.42 30.41 15.77 32.21C12.97 32.81 11.54 32.41 10.34 31.84H10.33ZM17.23 33.33C15.28 30.9 12.9 27.16 11.13 23.24C12.56 22.49 14.33 21.67 15.96 20.87L18.06 19.84C20.21 24.41 22.25 28.6 23.66 31.32C24.64 33.08 25.65 33.96 26.92 34.14C27.34 34.19 27.76 34.16 28.19 34.06C22.4 37.86 18.34 34.72 17.22 33.33H17.23ZM32.04 30.34C31.49 31.44 30.82 32.18 29.81 32.84C28.88 33.39 28.08 33.6 27.21 33.5C27.6 32.49 27.55 31.15 26.45 28.61C28.6 27.51 30.6 26.26 32.4 24.91C33.01 27.71 32.61 29.15 32.04 30.34ZM33.53 23.44C31.1 25.4 27.36 27.78 23.45 29.55C22.69 28.12 21.86 26.35 21.07 24.71L20.05 22.61C24.62 20.46 28.81 18.42 31.53 17.02C33.29 16.03 34.16 15.02 34.35 13.75C34.4 13.32 34.37 12.91 34.27 12.49C38.08 18.27 34.93 22.33 33.54 23.44H33.53Z"
                fill="#00A0D9" />
              <path
                d="M52.14 29.15L50.69 28.25C50.02 27.84 49.15 27.08 49.15 26.03C49.15 25.2 49.53 24.03 51.3 24.03C53.07 24.03 53.52 25.2 53.75 26.07C53.84 26.18 54.13 26.12 54.2 26.02C54.2 25.26 54.04 24.25 53.87 23.82C53.73 23.82 53.47 23.78 53.24 23.71C52.77 23.58 52.12 23.48 51.56 23.48C49.21 23.48 47.88 24.87 47.88 26.59C47.88 28.07 48.93 29.1 49.83 29.7L51.55 30.82C52.87 31.67 53.03 32.55 53.03 33.35C53.03 34.43 52.29 35.42 50.77 35.42C48.68 35.42 47.94 33.65 47.66 32.73C47.59 32.62 47.32 32.66 47.23 32.78C47.26 33.63 47.5 34.91 47.72 35.31C48.06 35.51 49.02 35.98 50.54 35.98C52.89 35.98 54.39 34.63 54.39 32.64C54.39 30.93 53.27 29.88 52.13 29.17L52.14 29.15Z"
                fill="#00A0D9" />
              <path
                d="M62.6 23.46C58.88 23.46 56.17 26.22 56.17 29.83C56.17 33.08 58.59 35.95 62.6 35.95C66.09 35.95 68.87 33.29 68.87 29.65C68.87 26.47 66.78 23.46 62.6 23.46ZM62.84 35.39C59.28 35.39 57.98 31.92 57.98 29.25C57.98 25.3 60.42 24.01 62.3 24.01C65.75 24.01 67.07 27.22 67.07 30.2C67.07 33.76 65.01 35.38 62.85 35.38L62.84 35.39Z"
                fill="#00A0D9" />
              <path
                d="M79.23 33.08C78.87 34 78.4 34.65 78.04 34.87C77.75 35.03 77.37 35.1 75.84 35.1C74.41 35.1 74.07 34.88 73.87 34.69C73.54 34.35 73.49 33.88 73.49 32.74V26.46C73.49 24.56 73.53 24.28 74.59 24.18L75.02 24.14C75.13 24.07 75.09 23.74 74.98 23.69C74.08 23.73 73.46 23.74 72.76 23.74C72.06 23.74 71.42 23.72 70.41 23.69C70.3 23.74 70.27 24.07 70.37 24.14L70.82 24.18C71.96 24.27 71.99 24.56 71.99 26.46V32.96C71.99 34.86 71.96 35.11 70.91 35.22L70.37 35.27C70.26 35.38 70.3 35.67 70.41 35.72C71.42 35.68 72 35.67 72.76 35.67H74.42C76.28 35.67 78 35.69 78.91 35.72C79.16 35.23 79.67 33.39 79.67 33.19C79.67 33.06 79.33 32.99 79.22 33.08H79.23Z"
                fill="#00A0D9" />
              <path
                d="M84.58 24.18L85.03 24.14C85.14 24.07 85.1 23.74 84.99 23.69C84.09 23.73 83.47 23.74 82.75 23.74C81.99 23.74 81.38 23.72 80.48 23.69C80.37 23.74 80.34 24.07 80.44 24.14L80.89 24.18C81.96 24.27 81.99 24.58 81.99 26.49V32.92C81.99 34.83 81.95 35.09 80.89 35.21L80.44 35.26C80.33 35.37 80.37 35.66 80.48 35.71C81.38 35.67 82 35.66 82.75 35.66C83.5 35.66 84.09 35.68 84.99 35.71C85.1 35.66 85.14 35.33 85.03 35.26L84.58 35.21C83.51 35.08 83.48 34.83 83.48 32.92V26.49C83.48 24.58 83.52 24.27 84.58 24.18Z"
                fill="#00A0D9" />
              <path
                d="M95.38 24.23C94.21 23.8 92.92 23.69 91.41 23.69C89.9 23.69 88.41 23.74 86.88 23.83C86.7 23.9 86.72 24.19 86.86 24.28L87.26 24.33C88.02 24.44 88.11 24.82 88.11 26.08V32.94C88.11 34.84 88.07 35.05 87.03 35.2L86.61 35.25C86.5 35.36 86.54 35.65 86.65 35.7C87.5 35.66 88.15 35.65 88.91 35.65C89.54 35.65 90.7 35.79 91.85 35.79C93.24 35.79 94.72 35.56 95.89 34.92C97.95 33.8 99.05 31.83 99.05 29.54C99.05 27.25 97.82 25.1 95.38 24.21V24.23ZM92.17 35.25C91.03 35.25 90.35 35.07 89.95 34.51C89.64 34.08 89.59 33.5 89.59 32.54V25.88C89.59 25.07 89.63 24.61 89.77 24.51C89.91 24.4 90.49 24.26 91.56 24.26C96.29 24.26 97.25 27.62 97.25 29.61C97.25 32.97 95.68 35.26 92.18 35.26L92.17 35.25Z"
                fill="#00A0D9" />
              <path
                d="M110.91 35.21C110.13 35.12 109.9 34.85 109.38 33.3L106.91 26.04C106.64 25.25 106.35 24.41 106.12 23.62C106.08 23.49 105.96 23.46 105.85 23.46C105.65 23.7 105.35 24.02 104.91 24.15C104.97 24.44 104.86 24.78 104.6 25.43L102.76 30.31C102 32.33 101.5 33.5 101.17 34.34C100.92 34.99 100.52 35.19 100.09 35.22L99.5 35.27C99.39 35.36 99.43 35.65 99.53 35.72C100.02 35.7 100.67 35.67 101.26 35.67C102.11 35.69 102.67 35.69 103.14 35.72C103.3 35.65 103.3 35.36 103.18 35.27L102.58 35.22C102.14 35.18 102.06 35.08 102.06 34.86C102.06 34.66 102.19 34.17 102.46 33.36L103.09 31.46C103.22 31.08 103.32 31.03 103.78 31.03H106.51C107.05 31.03 107.12 31.08 107.25 31.48L108.04 33.94C108.18 34.37 108.27 34.7 108.27 34.9C108.27 35.08 108.02 35.19 107.64 35.22L107.12 35.27C106.99 35.36 107.01 35.65 107.16 35.72C107.68 35.7 108.37 35.67 109.24 35.67C110.05 35.67 110.68 35.69 111.42 35.72C111.51 35.65 111.55 35.36 111.42 35.27L110.93 35.22L110.91 35.21ZM106.25 30.3H104.03C103.52 30.3 103.51 30.28 103.65 29.87L104.79 26.73C104.99 26.19 105.17 25.75 105.22 25.66H105.26C105.37 25.88 105.53 26.36 105.68 26.8L106.65 29.74C106.81 30.23 106.78 30.3 106.25 30.3Z"
                fill="#00A0D9" />
              <path
                d="M127.67 24.18L128.12 24.14C128.23 24.07 128.19 23.74 128.09 23.69C127.19 23.73 126.57 23.74 125.85 23.74C125.09 23.74 124.48 23.72 123.57 23.69C123.46 23.74 123.43 24.07 123.53 24.14L123.98 24.18C125.05 24.27 125.08 24.58 125.08 26.49V32.92C125.08 34.83 125.04 35.09 123.98 35.21L123.53 35.26C123.42 35.37 123.46 35.66 123.57 35.71C124.47 35.67 125.09 35.66 125.85 35.66C126.61 35.66 127.19 35.68 128.09 35.71C128.2 35.66 128.23 35.33 128.12 35.26L127.67 35.21C126.6 35.08 126.57 34.83 126.57 32.92V26.49C126.57 24.58 126.61 24.27 127.67 24.18Z"
                fill="#00A0D9" />
              <path
                d="M140.29 23.24C140.02 23.71 139.86 23.75 138.88 23.75H131.57C130.74 23.75 130.14 23.77 130.03 23.19C129.97 23.14 129.74 23.15 129.69 23.19C129.56 23.91 129.29 25.07 129 25.88C129.07 26.02 129.33 26.11 129.47 26.01C129.67 25.56 129.85 25.16 130.28 24.76C130.73 24.35 131.72 24.31 132.5 24.31H133.49C134.03 24.31 134.03 24.33 134.03 24.89V32.96C134.03 34.86 133.94 35.13 132.91 35.22L132.26 35.27C132.12 35.36 132.15 35.67 132.26 35.72C133.42 35.68 134.05 35.67 134.77 35.67C135.49 35.67 136.11 35.69 137.14 35.72C137.25 35.67 137.28 35.34 137.18 35.27L136.6 35.22C135.55 35.13 135.52 34.86 135.52 32.96V24.93C135.52 24.34 135.5 24.32 136.04 24.32H137.32C138.31 24.32 139 24.47 139.41 24.77C139.68 24.97 139.81 25.64 139.86 26.09C139.95 26.2 140.26 26.2 140.33 26.05C140.28 25.51 140.4 24.05 140.6 23.31C140.57 23.24 140.37 23.22 140.28 23.25L140.29 23.24Z"
                fill="#00A0D9" />
              <path
                d="M151.92 23.69C151.31 23.73 150.8 23.74 150.24 23.74C149.68 23.74 149.16 23.72 148.51 23.69C148.37 23.76 148.35 24.02 148.47 24.14L148.8 24.18C149.23 24.23 149.56 24.36 149.56 24.47C149.56 24.69 148.89 25.91 148.39 26.85C147.85 27.9 147.25 28.94 146.69 29.9C145.98 28.65 145.32 27.23 144.65 25.89C144.56 25.71 144.09 24.68 144.09 24.5C144.09 24.36 144.22 24.27 144.85 24.17L145.09 24.13C145.2 24.02 145.2 23.73 145.04 23.68C144.59 23.72 143.94 23.73 143.11 23.73C142.28 23.73 141.59 23.71 141.02 23.68C140.89 23.75 140.87 24.01 140.98 24.13L141.49 24.22C142.03 24.31 142.3 24.4 142.92 25.59L145.05 29.74C145.57 30.75 145.63 30.99 145.63 31.98V32.9C145.63 34.83 145.59 35.08 144.55 35.19L143.99 35.24C143.88 35.35 143.92 35.64 144.03 35.69C144.99 35.65 145.64 35.64 146.4 35.64C147.16 35.64 147.74 35.66 148.85 35.69C148.96 35.64 148.99 35.35 148.89 35.24L148.2 35.19C147.22 35.12 147.12 34.83 147.12 32.9V31.96C147.12 30.57 147.17 30.41 147.97 29.03L149.11 27.06C150.01 25.51 150.55 24.37 151.49 24.21L151.99 24.12C152.12 24.01 152.1 23.76 151.96 23.67L151.92 23.69Z"
                fill="#00A0D9" />
              <path
                d="M125.16 36.86C125.16 36.86 125.05 36.92 124.95 36.92C124.88 36.92 124.81 36.92 124.75 36.91C124.74 36.91 124.72 36.91 124.71 36.91C124.65 36.91 124.6 36.89 124.54 36.88C124.49 36.88 124.45 36.86 124.4 36.85C123.9 36.73 123.52 36.49 123.27 36.35C122.08 35.65 119.91 33.38 118.45 30.22C118.38 30.07 118.27 29.9 118.36 29.79C119.26 29.43 120.62 28.49 120.62 26.78C120.62 25.52 119.99 24.7 119.12 24.25C118.29 23.8 117.23 23.71 116.11 23.71C114.34 23.71 113.09 23.76 112.08 23.85C111.94 23.92 111.94 24.21 112.06 24.3L112.64 24.37C113.38 24.46 113.44 24.77 113.44 26.48V32.96C113.44 34.86 113.4 35.09 112.36 35.22L111.91 35.27C111.8 35.4 111.84 35.67 111.95 35.72C112.8 35.68 113.45 35.67 114.21 35.67C114.97 35.67 115.55 35.69 116.36 35.72C116.47 35.67 116.5 35.36 116.39 35.27L116.01 35.22C114.96 35.08 114.93 34.86 114.93 32.96V30.69C114.93 30.31 114.97 30.29 115.78 30.29C116.5 30.29 116.79 30.47 117.06 30.87C117.62 31.68 118.43 32.89 118.9 33.56C120.11 35.28 121.63 36.47 122.97 36.9C122.97 36.9 123.01 36.91 123.07 36.93C123.1 36.94 123.14 36.95 123.17 36.96C123.17 36.96 123.27 36.99 123.46 37.03C123.94 37.14 124.67 37.24 125.16 37.05C125.5 36.94 125.31 36.73 125.18 36.87L125.16 36.86ZM116.22 29.74C115.3 29.74 115.03 29.7 114.97 29.65C114.92 29.58 114.92 29.38 114.92 29.05V24.82C114.92 24.32 114.97 24.24 116.09 24.24C118.09 24.24 118.98 25.59 118.98 27.02C118.98 28.25 118.57 28.88 118.08 29.26C117.56 29.66 116.87 29.73 116.22 29.73V29.74Z"
                fill="#00A0D9" />
              <path
                d="M56.48 9.07001H53.25C53.25 8.46001 53.45 7.93001 53.85 7.50001C54.26 7.07001 54.77 6.85001 55.36 6.87001C56.19 6.87001 56.78 7.25001 57.16 8.02001C57.39 8.51001 57.54 9.28001 57.63 10.31C57.68 10.94 57.94 11.44 58.35 11.84C58.78 12.24 59.25 12.43 59.74 12.43H84.11C85.7 12.43 86.56 11.53 86.56 10.09C86.56 10.1 86.55 9.05001 86.52 6.94001C86.59 6.38001 86.78 6.24001 87.35 6.20001L87.7 6.17001C87.79 6.11001 87.76 5.86001 87.67 5.81001C87.2 5.83001 86.84 5.84001 86.48 5.85001H85.25C85.29 8.83001 85.3 10.21 85.3 10.05C85.3 10.86 84.78 11.28 84.07 11.28H59.94C59.42 11.28 58.86 10.77 58.81 10.14C58.68 8.93001 58.48 8.03001 58.21 7.47001C57.65 6.26001 56.7 5.67001 55.36 5.67001C53.07 5.67001 51.92 7.19001 51.92 10.21H55.27L55.31 11.24C55.31 12.3 54.97 13.21 54.27 13.96C53.57 14.72 52.74 15.09 51.75 15.09C50.76 15.09 49.93 14.71 49.23 13.97C48.53 13.23 48.18 12.33 48.18 11.27C48.16 10.17 48.14 9.43001 48.14 9.05001H46.9C46.56 9.05001 46.21 9.03001 45.78 9.01001C45.69 9.05001 45.67 9.31001 45.75 9.37001L46.1 9.40001C46.67 9.45001 46.87 9.58001 46.93 10.14C46.94 10.61 46.96 10.98 46.97 11.25C46.97 12.67 47.44 13.86 48.38 14.86C49.32 15.85 50.43 16.36 51.75 16.36C53.07 16.36 54.2 15.86 55.12 14.86C56.06 13.87 56.53 12.68 56.53 11.27L56.48 9.05001V9.07001Z"
                fill="#00A0D9" />
              <path d="M51.43 17.71H50.1V19.12H51.43V17.71Z" fill="#00A0D9" />
              <path d="M53.65 17.71H52.32V19.12H53.65V17.71Z" fill="#00A0D9" />
              <path d="M86.58 3H85.25V4.41H86.58V3Z" fill="#00A0D9" />
              <path d="M84.36 3H83.03V4.41H84.36V3Z" fill="#00A0D9" />
              <path
                d="M109.39 12.44H111.01C112 12.39 112.92 11.86 113.26 10.98C113.35 11.25 113.49 11.5 113.71 11.75C114.09 12.2 114.54 12.44 115.08 12.44H118.02H119.19C120.78 12.44 121.64 11.54 121.64 10.1C121.64 8.95001 121.62 7.40001 121.6 5.45001C121.6 4.05001 121.58 2.92001 121.57 2.07001C121.61 1.32001 121.78 1.16001 122.42 1.10001L122.78 1.07001C122.87 1.01001 122.84 0.760007 122.75 0.710007C122.28 0.730007 121.92 0.740007 121.56 0.750007H120.32C120.32 1.60001 120.34 3.15001 120.36 5.42001C120.38 7.37001 120.39 8.92001 120.39 10.05C120.39 10.86 119.87 11.28 119.17 11.28H118.37H114.95C114.61 11.28 114.26 11.12 113.96 10.79C113.6 10.41 113.42 9.91001 113.4 9.28001C113.39 8.55001 113.38 7.81001 113.38 7.07001C113.44 6.43001 113.62 6.29001 114.22 6.23001L114.58 6.20001C114.67 6.14001 114.64 5.89001 114.55 5.85001C114.09 5.87001 113.72 5.88001 113.37 5.89001C113.37 5.88001 113.37 5.87001 113.37 5.86001H112.13C112.18 8.93001 112.18 10.31 112.15 10.02C112.15 10.44 111.97 10.76 111.59 11.01C111.3 11.19 110.99 11.28 110.65 11.28H108.65H100.14C100 9.53001 99.54 8.18001 98.81 7.19001C98.14 6.31001 97.37 5.85001 96.45 5.85001V6.97001C97.04 6.97001 97.59 7.37001 98.09 8.18001C98.65 9.05001 98.94 10.07 98.97 11.28H94.05L93.67 12.43H109.39V12.44Z"
                fill="#00A0D9" />
              <path d="M112.47 13.72H111.12V15.11H112.47V13.72Z" fill="#00A0D9" />
              <path d="M114.67 13.72H113.34V15.11H114.67V13.72Z" fill="#00A0D9" />
              <path
                d="M126.77 12.44H128.39C128.12 13.29 127.65 13.95 127.02 14.46C126.35 14.98 125.56 15.24 124.66 15.24C124.89 15.76 125.07 16.12 125.18 16.36C127.47 16.16 129.11 14.68 129.67 12.45H132.02H142.03V11.64C142.25 11.98 142.5 12.23 142.8 12.4C143.14 12.58 143.63 12.67 144.23 12.67C145.4 12.67 146.16 12.2 146.48 11.24C146.75 11.78 147.08 12.16 147.47 12.39C147.81 12.59 148.3 12.7 148.91 12.7C150.3 12.7 151.31 11.75 151.31 10.32C151.31 10.19 151.29 8.71 151.27 5.87H150.03C150.07 8.5 150.08 9.98 150.08 10.32C150.08 11.11 149.5 11.54 148.8 11.54C148.15 11.54 147.63 11.38 147.25 11.05C146.83 10.71 146.62 10.2 146.6 9.53C146.58 8.11 146.58 7.06 146.58 6.38H145.34C145.38 8.62 145.39 9.93 145.39 10.31C145.39 11.12 144.87 11.53 144.17 11.53C142.78 11.53 142.08 10.86 142.04 9.53V6.38H140.83C140.47 6.38 140.1 6.36 139.64 6.34C139.55 6.38 139.53 6.64 139.61 6.7L139.97 6.73C140.58 6.78 140.76 6.93 140.81 7.58C140.81 7.91 140.81 8.21 140.81 8.48V11.29H132.01H129.86V8.68C129.86 6.79 128.92 5.83 127.05 5.83C126.33 5.83 125.68 6.06 125.12 6.55C124.42 7.16 124.02 8.05 124 9.22C124 10.1 124.22 10.88 124.74 11.51C125.26 12.14 125.93 12.45 126.76 12.45L126.77 12.44ZM125.96 7.28C126.3 6.87 126.7 6.67 127.18 6.67C128.1 6.67 128.62 7.21 128.62 8.27V11.28H127.09C126.03 11.28 125.36 10.32 125.36 9.12C125.36 8.34 125.58 7.73 125.96 7.28Z"
                fill="#00A0D9" />
              <path
                d="M87.11 16.53C87.11 16.53 87.18 16.51 87.21 16.5C87.27 16.48 87.31 16.47 87.31 16.47C87.59 16.38 87.88 16.25 88.17 16.09C89.01 15.64 89.72 15.25 90.66 14.03C91.3 13.2 91.51 11.71 91.51 10.78V10.41C91.41 10.61 91.3 10.8 91.19 11C91.3 10.81 91.4 10.62 91.51 10.41L91.48 6.89001C91.55 6.38001 91.75 6.26001 92.31 6.21001L92.66 6.18001C92.75 6.12001 92.72 5.87001 92.63 5.82001C92.16 5.84001 91.8 5.85001 91.44 5.86001V5.89001H90.23L90.25 10.97C90.25 11.88 90.03 12.69 89.61 13.38C89.19 14.3 87.48 15.62 86.99 15.9C86.9 15.95 86.79 16.01 86.67 16.08C86.72 16.19 86.77 16.28 86.81 16.35C86.77 16.28 86.72 16.18 86.67 16.08C86.45 16.2 86.18 16.33 85.86 16.41C85.82 16.42 85.77 16.43 85.72 16.44C85.67 16.45 85.61 16.46 85.55 16.47C85.54 16.47 85.52 16.47 85.51 16.47C85.44 16.47 85.38 16.48 85.3 16.48C85.21 16.48 85.13 16.46 85.09 16.42C84.96 16.28 84.77 16.49 85.11 16.6C85.6 16.8 86.33 16.69 86.81 16.58C86.99 16.55 87.1 16.51 87.1 16.51L87.11 16.53Z"
                fill="#00A0D9" />
            </g>
            <defs>
              <clipPath id="clip0_2_85">
                <rect width="153" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h2 class="mt-10  text-2xl font-bold leading-9 tracking-tight text-gray-900">Renew Your Policy</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full ">
          <main>
            <div id="popup" class="overlay" *ngIf="countdownValue < 31 && step != 1">
              <div class="bg-white p-8 rounded shadow w-1/2" style="    position: absolute;
                  top: 20%;">
                <h2 class="text-2xl font-bold mb-4">{{this.fname + ' ' + this.lname}}</h2>
                <p class="text-center">If you want to continue please click the button</p>
                <div class="text-Red-500 text-center text-3xl py-4">
                  {{countdownValue}}
                </div>
                <div class="text-center">
                  <button id="closePopup"
                    class="mt-4 mx-auto bg-primary-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    (click)="resetCountdown()">
                    Continue
                  </button>
                </div>

              </div>
            </div>
            <div id="step1" *ngIf="step == 1" class="sm:max-w-sm  mx-auto">
              <form class="space-y-6" #myform="ngForm" (ngSubmit)="searchPolicies()" [formGroup]="ngForm">
                <div>
                  <label for="cpr" class="block text-3xl font-normal leading-6 text-gray-900 text-Blue-400">CPR</label>
                  <div class="mt-2">
                    <input style="font-size: 25px;" [(ngModel)]="input1" (focus)="activeInput = 1" maxlength="9" (ngModelChange)="onCprInputChange($event)"
                      (input)="onInput1Change()" id="cpr" name="cpr" type="text" formControlName="cpr" required readonly
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-2xl" >
                  </div>
                </div>

                <div>
                  <label for="plateNumber"
                    class="block text-3xl font-normal leading-6 text-gray-900 text-Blue-400">Plate Number</label>
                  <div class="mt-2">
                    <input style="font-size: 25px;" [(ngModel)]="input2" (focus)="activeInput = 2" readonly
                      (input)="onInput2Change()" id="plateNumber" name="plateNumber" type="text"
                      formControlName="plateNumber" required
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                  </div>
                </div>

                <div class="">
                  <button [disabled]="isLoading" type="submit"
                    class="flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-2xl font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    {{!isLoading ? 'Search' : ''}}
                    <div role="status" *ngIf="this.isLoading" class="flex justify-center items-center">
                      <svg aria-hidden="true" class=" mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"></path>
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"></path>
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
              <br>
              <br>
              <div class="numeric-keyboard grid grid-cols-3 gap-2 max-w-lg mx-auto p-4">
                <button (click)="addValue('1')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">1</button>
                <button (click)="addValue('2')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">2</button>
                <button (click)="addValue('3')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">3</button>
                <button (click)="addValue('4')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">4</button>
                <button (click)="addValue('5')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">5</button>
                <button (click)="addValue('6')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">6</button>
                <button (click)="addValue('7')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">7</button>
                <button (click)="addValue('8')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">8</button>
                <button (click)="addValue('9')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">9</button>
                <button (click)="clearInput()"
                  class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md">Clear</button>
                <button (click)="addValue('0')"
                  class="bg-white rounded-lg  border-[3px] drop-shadow-md text-6xl text-Blue-400 ">0</button>
                <button (click)="backspace()"
                  class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md text-center "><svg
                    xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                    class="bi bi-backspace inline" viewBox="0 0 16 16">
                    <path
                      d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                    <path
                      d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                  </svg></button>
              </div>

              <!-- toggle-switch.component.html -->
              <!-- toggle-switch.component.html -->





              <!-- <div class="flex flex-wrap -mx-2">
                  <ng-container *ngFor="let cover of covers; let i = index">
                    <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 px-2">
                      <label [for]="'toggle' + i" class="flex cursor-pointer">
                        <div class="relative">
                          <input  type="checkbox" [id]="'toggle' + i" [(ngModel)]="cover.Selected" class="hidden" [disabled]="true" />
                          <div [ngClass]="cover.Selected ? 'bg-primary-500' : 'bg-Light-Gray-2'" class="toggle__line w-12 h-5 rounded-full shadow-inner"></div>
                          <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0" [style.transform]="cover.Selected ? 'translateX(100%)' : 'translateX(0)'"></div>
                        </div>
                        <span class="pl-2 text-sm">{{ cover.coverName }}</span>
                      </label>
                    </div>
                    <div *ngIf="cover.coverCode == 'CMOT28'">
                      <select name="" id="" class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Test</option>
                        <option value="">Test</option>

                      </select>
                    </div>
                    <br>
                    <br>
                  </ng-container>
                </div>   -->



              <!-- <div id="popup" class="overlay" *ngIf="countdownValue < 31">
                  <div class="bg-white p-8 rounded shadow w-1/2" style="    position: absolute;
                  top: 20%;">
                      <h2 class="text-2xl font-bold mb-4">Kindly close if you are still active</h2>
                      <p class="text-center">This page will reset in:</p>
                      <div class="text-Red-500 text-center text-3xl py-4">
                        {{countdownValue}}
                      </div>
                      <div class="text-center">
                        <button id="closePopup" class="mt-4 mx-auto bg-primary-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          Close
                      </button>
                      </div>
                      
                  </div>
              </div> -->

            </div>


            <div id="step2" *ngIf="step == 2" class="sm:max-w-[550px]  mx-auto">


              <div class="relative">
                <!-- Increase the image width and height -->
                <img class="w-full h-full md:w-auto md:h-auto" src="/assets/imgs/cardBackground.svg" alt="">
                <div class="absolute inset-0 flex items-center justify-center">
                  <!-- Add padding to create spacing around the text -->
                  <div class="text-white p-4 md:p-8">
                    <div>
                      <p class="text-xl text-white font-bold">Policy Number</p>
                      <p class="text-lg">{{policyNumber}}</p>
                    </div>
                    <br>
                    <div>
                      <p class="text-xl text-white font-bold">Plate Number</p>
                      <p class="text-lg">{{PlateNumber}}</p>
                    </div>
                    <br>
                    <div>
                      <p class="text-xl text-white font-bold">Plan</p>
                      <p class="text-lg">{{PlanName}}</p>
                    </div>
                  </div>
                  <div class="text-white p-4 md:p-8">
                    <div>
                      <p class="text-xl">{{Make + ' '}} - {{' ' + Model}} {{' ' + Year}}</p>
                    </div>
                    <br>
                    <div>
                      <p class="text-xl text-white font-bold">Start Date</p>
                      <p class="text-lg">{{FromDateStr}}</p>
                    </div>
                    <br>
                    <div>
                      <p class="text-xl text-white font-bold">End Date</p>
                      <p class="text-lg">{{ToDateStr}}</p>
                    </div>
                  </div>
                </div>
              </div>




              <br>
              <div *ngIf="this.ErrorMessage != '' " class="text-Red-600">
                {{'*' + ErrorMessage}}
              </div>
              <br>
              <div class="flex justify-center">
                <button (click)="prevStep(2)"  class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md m-4 p-2" style="    text-align: -webkit-center;
                        width: 10%;
                        position: absolute;
                        top: 5px;
                        left: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                      class="bi bi-arrow-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>

                    <!-- {{countdownValue}} -->
                  </button>
                
                <div *ngIf="isRenewable" class="w-full p-1">
                  <button (click)="CalculatePremium()" [disabled]="isLoading" type="submit"
                    class="flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-2xl font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    {{!isLoading ? 'Next' : ''}}
                    <div role="status" *ngIf="this.isLoading" class="flex justify-center items-center">
                      <svg aria-hidden="true" class=" mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"></path>
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"></path>
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
                <br>



              </div>


            </div>

            <div id="step3" *ngIf="step == 3" class="sm:max-w-[550px]  mx-auto">


              <div class="rounded-3xl p-8 ring-1 xl:p-10 ring-gray-200">
                <h3 id="tier-freelancer" class="text-lg font-medium leading-8 text-gray-900">TOTAL PREMIUM</h3>
                <p class="mt-4 text-sm leading-6 text-gray-600">Refrence Number {{this.quoteNumber}}</p>
                <p class="mt-6 flex items-baseline gap-x-1">
                  <!-- Price, update based on frequency toggle state -->
                  <span class="text-4xl font-bold tracking-tight text-gray-900">Total Price: {{totalPrem}}</span>
                  <!-- Payment frequency, update based on frequency toggle state -->

                  <span class="text-sm font-medium leading-6 text-gray-600">BHD </span>


                </p>
                <button (click)="submitandPurchase()" aria-describedby="tier-freelancer"
                  class="w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-primary-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600">{{this.isLoading
                  ? '' : coverChanged ? 'Get Quote' : 'Buy plan'}}

                  <div role="status" *ngIf="this.isLoading" class="flex justify-center items-center">
                    <svg aria-hidden="true" class=" mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"></path>
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>


                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-600">
                  <li class="flex gap-x-3">

                    <span class="pr-4 font-medium">Sum Insured </span> {{sumInsured}} BHD
                  </li>
                  <li class="flex gap-x-3">

                    <span class="pr-4 font-medium">Price</span> {{prem}} BHD
                  </li>
                  <li class="flex gap-x-3">

                    <span class="pr-4 font-medium"> VAT 10% </span> {{tax}} BHD
                  </li>

                </ul>
              </div>


              <br>
              <div class="flex flex-wrap -mx-2">
                <ng-container *ngFor="let cover of covers; let i = index">
                  <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 px-2 py-2">
                    <label *ngIf="PlanName != 'TPL'" [for]="'toggle' + i" class="flex cursor-pointer">
                      <div class="relative">
                        <input type="checkbox" [id]="'toggle' + i" [(ngModel)]="cover.Selected" class="hidden"
                          (change)="CoverPressed()"
                          [disabled]="isCoverDisabled(cover) || covers[0].Selected && cover.coverCode == 'CMOT16'" />
                        <div
                          [ngClass]="covers[1].Selected && covers[0].selected && cover.coverCode == 'CMOT16' ? 'bg-Light-Gray-1' : cover.Selected && !isCoverDisabled(cover) && !covers[0].Selected && !covers[1].Selected 
                            || cover.Selected && !isCoverDisabled(cover) && !covers[0].Selected && covers[1].Selected 
                            || cover.Selected && !isCoverDisabled(cover) && covers[0].Selected && covers[1].Selected && cover.coverCode != 'CMOT16' 
                            || cover.Selected && !isCoverDisabled(cover) && covers[0].Selected && !covers[1].Selected && cover.coverCode != 'CMOT16' ?  'bg-primary-500' : isCoverDisabled(cover) 
                            || covers[0].Selected && cover.coverCode == 'CMOT16' ? 'bg-Light-Gray-1' : 'bg-Light-Gray-5'"
                          class="toggle__line w-12 h-5 rounded-full shadow-inner"></div>
                        <div
                          [ngClass]="isCoverDisabled(cover) || covers[0].Selected && cover.coverCode == 'CMOT16' ?  'bg-Mid-Gray-5' : 'bg-white'"
                          class="toggle__dot absolute w-6 h-6  rounded-full shadow inset-y-0"
                          [style.transform]="cover.Selected ? 'translateX(100%)' : 'translateX(0)'"></div>
                      </div>
                      <span [ngClass]="isCoverDisabled(cover) ? 'text-Light-Gray-1' : ''" class="pl-2 text-sm">{{
                        cover.coverName }}</span>
                    </label>
                    <!-- For Third Party -->
                    <label *ngIf="PlanName == 'TPL'" [for]="'toggle' + i" class="flex cursor-pointer">
                      <div class="relative">
                        <input type="checkbox" [id]="'toggle' + i" [(ngModel)]="cover.Selected" class="hidden"
                          (change)="CoverPressed()"
                          [disabled]="isCoverDisabled(cover) || covers[0].Selected && cover.coverCode == 'CMOT16' || cover.coverCode != 'CMOT16' && cover.coverCode != 'CMOT34' && cover.coverCode != 'CMOT21' " />
                        <div
                          [ngClass]="covers[1].Selected && covers[0].selected && cover.coverCode == 'CMOT16' ? 'bg-Light-Gray-1' : cover.Selected && !isCoverDisabled(cover) && !covers[0].Selected && !covers[1].Selected 
                            || cover.Selected && !isCoverDisabled(cover) && !covers[0].Selected && covers[1].Selected 
                            || cover.Selected && !isCoverDisabled(cover) && covers[0].Selected && covers[1].Selected && cover.coverCode != 'CMOT16' 
                            || cover.Selected && !isCoverDisabled(cover) && covers[0].Selected && !covers[1].Selected && cover.coverCode != 'CMOT16' ?  'bg-primary-500' : isCoverDisabled(cover) 
                            || covers[0].Selected && cover.coverCode == 'CMOT16' ? 'bg-Light-Gray-1' : 'bg-Light-Gray-5'"
                            [ngClass]="cover.coverCode != 'CMOT16' && cover.coverCode != 'CMOT34' && cover.coverCode != 'CMOT21' ? 'bg-Light-Gray-1' : 'bg-Light-Gray-5'"
                          class="toggle__line w-12 h-5 rounded-full shadow-inner"></div>
                        <div
                          [ngClass]="isCoverDisabled(cover) || covers[0].Selected && cover.coverCode == 'CMOT16' ?  'bg-Mid-Gray-5' : 'bg-white'"
                          [ngClass]="cover.coverCode != 'CMOT16' && cover.coverCode != 'CMOT34' && cover.coverCode != 'CMOT21' ?  'bg-Mid-Gray-5' : 'bg-white'"

                          class="toggle__dot absolute w-6 h-6  rounded-full shadow inset-y-0"
                          [style.transform]="cover.Selected ? 'translateX(100%)' : 'translateX(0)'"></div>
                      </div>
                      <span [ngClass]="isCoverDisabled(cover) ? 'text-Light-Gray-1' : ''" class="pl-2 text-sm">{{
                        cover.coverName }}</span>
                    </label>
                  </div>

                  <div *ngIf="cover.coverCode == 'CMOT28' && cover.Selected" class="pr-2 pb-5">
                    <select (change)="CoverPressed()" [(ngModel)]="selectedDay" name="" id=""
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <option value="" disabled selected>No. of Days</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="30">30</option>


                    </select>
                  </div>

                  <div *ngIf="cover.coverCode == 'CMOT28' && cover.Selected">
                    <select [(ngModel)]="selectedCarSize" (change)="CoverPressed()" name="" id=""
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <option value="" disabled selected>Car Size</option>
                      <option value="SMALL">SMALL</option>
                      <option value="MEDIUM">MEDIUM</option>
                      <option value="LUXURY">LUXURY</option>

                    </select>
                  </div>

                  <div *ngIf="cover.coverCode == 'CMOT24' && cover.Selected" class="pr-2 pb-5">
                    <select [(ngModel)]="selectedArea" (change)="CoverPressed()" name="" id=""
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <option value="" disabled selected>Area</option>
                      <option value="KSA">KSA</option>
                      <option value="GCC">GCC</option>


                    </select>
                  </div>

                </ng-container>
              </div>



              <br>
              <br>

              <div>
                <!-- <button (click)="prevStep(3)" [disabled]="isLoading" type="submit"
                  class="flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-2xl font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Back
                </button> -->
                <button (click)="prevStep(3)" class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md m-4 p-2" style="    text-align: -webkit-center;
                        width: 10%;
                        position: absolute;
                        top: 5px;
                        left: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                      class="bi bi-arrow-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                    <!-- {{countdownValue}} -->
                  </button>
              </div>
            </div>

            <div id="step4" *ngIf="step == 4" class="sm:max-w-[550px]  mx-auto">


              <div class="rounded-3xl p-8 ring-1 xl:p-10 ring-gray-200">
                <h3 id="tier-freelancer" class="text-lg font-medium leading-8 text-gray-900">TOTAL PREMIUM</h3>
                <p class="mt-4 text-sm leading-6 text-gray-600">Refrence Number {{this.quoteNumber}}</p>
                <p class="mt-6 flex items-baseline gap-x-1">
                  <!-- Price, update based on frequency toggle state -->
                  <span class="text-4xl font-bold tracking-tight text-gray-900">Total Price: {{totalPrem}}</span>
                  <!-- Payment frequency, update based on frequency toggle state -->

                  <span class="text-sm font-medium leading-6 text-gray-600">BHD </span>


                </p>



                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-gray-600">
                  <li class="flex gap-x-3">

                    <span class="pr-4 font-medium">Sum Insured </span> {{sumInsured}} BHD
                  </li>
                  <li class="flex gap-x-3">

                    <span class="pr-4 font-medium">Price</span> {{prem}} BHD
                  </li>
                  <li class="flex gap-x-3">

                    <span class="pr-4 font-medium"> VAT 10% </span> {{tax}} BHD
                  </li>

                </ul>
              </div>
              <br>
              <!-- <div class="text-Red-500 text-center">
                This page will reset in:
              </div>
              <div class="text-Red-500 text-center text-xl">
                {{countdownValue}}
              </div> -->
              <br>
              <h2 class="mx-auto font-bold text-xl text-center">Scan to Pay</h2>
              <img *ngIf="!imgLoading" class="mx-auto" [src]="this.paymentLink" alt="QR Code" style="height: 200px;">
              <div role="status" *ngIf="imgLoading" class="flex justify-center items-center">
                <svg aria-hidden="true"
                  class=" mr-2 w-6 h-7 text-primary-500 animate-spin dark:text-primary-500 fill-primary-500"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"></path>
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <br>
              <div>
                <!-- <button (click)="prevStep(4)" [disabled]="isLoading" type="submit"
                  class="flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-2xl font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Back
                </button> -->

                <button (click)="prevStep(4)"  class="bg-primary-500 rounded-lg text-2xl text-white drop-shadow-md m-4 p-2" style="    text-align: -webkit-center;
                        width: 10%;
                        position: absolute;
                        top: 5px;
                        left: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                      class="bi bi-arrow-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                  </button>
              </div>
            </div>

          </main>


        </div>
      </div>
    </div>
  </div>
</div>