import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { WebapiService } from '../webapi.service';

@Injectable({
  providedIn: 'root'
})
export class MakemodelResolver  {
  constructor(private webAPI:WebapiService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return      this.webAPI.getMotorMake().pipe(catchError((error)=> {console.log(error); return of('No Data')}));

  }
}
