import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddonsScreenComponent } from './dynamic_screens/addons-screen/addons-screen.component';
import { PlansScreenComponent } from './dynamic_screens/plans-screen/plans-screen.component';
import { ProductComponent } from './dynamic_screens/product/product.component';
import { ReviewScreenComponent } from './dynamic_screens/review-screen/review-screen.component';
import { ErrorComponent } from './error/error.component';
import { HomeQuoteComponent } from './quotes/home-quote/home-quote.component';
import { MotorQuoteComponent } from './quotes/motor-quote/motor-quote.component';
import { AboutusComponent } from './screens/aboutus/aboutus.component';
import { BeyonMoneyComponent } from './screens/beyon-money/beyon-money.component';
import { CareersComponent } from './screens/careers/careers.component';
import { ClaimComponent } from './screens/claim/claim.component';
import { ComplaintsComponent } from './screens/complaints/complaints.component';
import { ContactusComponent } from './screens/contactus/contactus.component';
import { ForbusinessesComponent } from './screens/forbusinesses/forbusinesses.component';
import { HomeComponent } from './screens/home/home.component';
import { InvestorRelationsComponent } from './screens/investorrelations/investorrelations.component';
import { NewsComponent } from './screens/news/news.component';
import { PrivacyandsecurityComponent } from './screens/privacyandsecurity/privacyandsecurity.component';
import { QuickRenewalScreenComponent } from './screens/quick-renewal-screen/quick-renewal-screen.component';
import { TermsofuseComponent } from './screens/termsofuse/termsofuse.component';
import { VideoComponent } from './screens/video/video.component';
import { AboutUsResolverService } from './services/resolvers/about-us-resolver.service';
import { BasicValidationResolver } from './services/resolvers/basic-validation.resolver';
import { BranchesResolver } from './services/resolvers/branches.resolver';
import { ClaimSectionResolver } from './services/resolvers/claim-section.resolver';
import { ClaimsResolver } from './services/resolvers/claims.resolver';
import { FinancialReportsResolver } from './services/resolvers/financial-reports.resolver';
import { ForBusinessesResolver } from './services/resolvers/for-businesses.resolver';
import { GuidesResolver } from './services/resolvers/guides.resolver';
import { HomePageResolver } from './services/resolvers/home-page.resolver';
import { MakemodelResolver } from './services/resolvers/makemodel.resolver';
import { PrivacyandsecurityResolver } from './services/resolvers/privacyandsecurity.resolver';
import { ProductResolverService } from './services/resolvers/product-resolver.service';
import { QuickRenewalResolver } from './services/resolvers/quick-renewal.resolver';
import { ShareHolderNewsResolver } from './services/resolvers/share-holder-news.resolver';
import { TermsOfUseResolver } from './services/resolvers/terms-of-use.resolver';
import { VideoPageResolver } from './services/resolvers/video-page.resolver';
import { WinnersResolver } from './services/resolvers/winners.resolver';
import { RewardsComponent } from './screens/rewards/rewards.component';
import { SeefmallbranchComponent } from './screens/seefmallbranch/seefmallbranch.component';
import { CareerPageResolver } from './services/resolvers/career-page.resolver';
import { CreditReportResolver } from './services/resolvers/credit-report.resolver';
import { TapPaymentComponent } from './screens/tap-payment/tap-payment.component';
import { ComplaintsSectionResolver } from './services/resolvers/complaints-section.resolver';
//import { HomePageResolver } from './services/resolvers/home-page.resolver';



const routes: Routes = [
  { path: '', component: HomeComponent, resolve: { data: HomePageResolver, guides: GuidesResolver, claimSection: ClaimSectionResolver } },
  { path: 'product/:name', component: ProductComponent, resolve: { product: ProductResolverService, val: BasicValidationResolver } },


  { path: 'claims', component: ClaimComponent, resolve: { guides: GuidesResolver, claims: ClaimsResolver, claimSection: ClaimSectionResolver } },
  { path: 'investorrelations', component: InvestorRelationsComponent, resolve: { data: ShareHolderNewsResolver, data2: FinancialReportsResolver, data3: CreditReportResolver } },

  { path: 'complaints', component: ComplaintsComponent, resolve: { data: BranchesResolver, val: BasicValidationResolver, data2: ComplaintsSectionResolver} },
  { path: 'termsofuse', component: TermsofuseComponent, resolve: { data: TermsOfUseResolver } },

  { path: 'contactus', component: ContactusComponent, resolve: { data: BranchesResolver, val: BasicValidationResolver } },
  { path: 'aboutus', component: AboutusComponent, resolve: { data: AboutUsResolverService } },
  { path: 'news', component: NewsComponent },
  { path: 'quick', component: QuickRenewalScreenComponent, resolve: { claimSection: ClaimSectionResolver, renewal: QuickRenewalResolver } },
  { path: 'plan', component: PlansScreenComponent },
  { path: 'addons', component: AddonsScreenComponent },
  { path: 'forbusinesses', component: ForbusinessesComponent, resolve: { data: ForBusinessesResolver, val: BasicValidationResolver } },
  //{ path: 'homepage', component: HomeComponent, resolve: {data: HomePageResolver} },

  { path: 'review', component: ReviewScreenComponent },
  { path: 'careers', component: CareersComponent, resolve: { data: CareerPageResolver } },

  { path: 'video', component: VideoComponent, resolve: { data: VideoPageResolver, claimSection: ClaimSectionResolver, guides: GuidesResolver, } },
  { path: 'quote/home', component: HomeQuoteComponent },
  { path: 'quote/motor', component: MotorQuoteComponent },

  { path: 'privacyandsecurity', component: PrivacyandsecurityComponent, resolve: { data: PrivacyandsecurityResolver } },

  { path: 'beyon-money', component: BeyonMoneyComponent, resolve: { data: VideoPageResolver, claimSection: ClaimSectionResolver, guides: GuidesResolver, } },

  { path: 'rewards', component: RewardsComponent },
  { path: 'seef', component: SeefmallbranchComponent },
  { path: 'tap', component: TapPaymentComponent },



  { path: '404', component: ErrorComponent },
  { path: '500', component: ErrorComponent },
  { path: '**', redirectTo: '/404' } // Catch-all route for any other unknown route

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
