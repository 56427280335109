import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import disableScroll from 'disable-scroll';
import { HostListener } from '@angular/core';
import { Attributes, Box, Product } from 'src/app/models/home-page'
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChild('sliderContent', { static: false }) sliderContent: ElementRef | undefined;
  @ViewChild('sliderButtons', { static: false }) sliderButtons: ElementRef | undefined;



  public currentIndex = 0;

  public slideInterval: NodeJS.Timer | undefined;
  images: string[] = [
    '/assets/imgs/insureandwin.png',
    '/assets/imgs/insureandwin.png',
    '/assets/imgs/insureandwin.png'
  ];

  slides = [
    //{ src: '/assets/imgs/Ramadan24.webp', smallImg: '/assets/imgs/Ramadan24.webp', alt: 'DEALS MADE FOR YOU', altAr: 'صفقات صُممت خصيصاً لك', title: 'EXCITING INSURANCE', titleAr: 'تأمين مشوق' },
    { src: '/assets/imgs/caroption2.webp', smallImg: '/assets/imgs/caroption2.webp', alt: 'Insure your car with Solidarity’s motor insurance offer.', altAr: 'أمّن سيارتك مع سوليدرتي واستمتع بعروضنا على تأمين المركبات', title: 'Drive with Confidence!', titleAr: ' !القيادة بثقة    ' },
  ];


  products: Array<Product> = [];
  homeDetails!: Attributes;
  boxes: Array<Box> = [];
  //channelGuides!: ChannelGuides;
  firstLines: Array<string> = ['Super-Simple Claims', 'The Fastest Services', 'Loved by Customers'];
  secondLines: Array<string> = ['No hidden clauses, no long-words, just simple documents and forms.', 'Self-initiated web or mobile services gets you a new policy within minutes!', 'We are trusted by over 200,000 customers and 500 businesses.'];

  paddingValue: number = 0;
  @ViewChild('#box')
  box?: ElementRef;
  // first = true;
  // second = false;
  // third = false;
  // firstSelected = true;
  // secondSelecetd = false;
  // thirdSelected = false;

  currentPosition = window.scrollY;

  TopScroll = window.scrollY || document.documentElement.scrollTop;
  LeftScroll = window.scrollY || document.documentElement.scrollLeft;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute, private router: Router, private cookieService: CookieService, private renderer: Renderer2) { }

  async ngOnInit() {

    // console.log(this.getCurrentMonth());


    setInterval(() => this.nextSlide(), 5000);
    var height = document.getElementById("box")?.offsetHeight;
    if (height != undefined) {
      this.paddingValue = height! - 20;
    }
    // window.addEventListener('scroll', (ev) => this.handleScroll());

    this.route.data.subscribe(async params => {
      if (params['data'].data.attributes != null) {
        this.homeDetails = params['data'].data.attributes;
        this.boxes = params['data'].data.attributes.boxes;
        this.products = params['data'].data.attributes.products;
      }
    });

    // Show the cookies notification ribbon if the user hasn't accepted the cookies
    const cookiesAccepted = document.cookie.includes('cookiesAccepted=true');
    if (!cookiesAccepted) {
      const cookiesRibbon = document.querySelector('.cookies-ribbon') as HTMLElement;
      cookiesRibbon.style.display = 'block';
    }
    else {
      const cookiesRibbon = document.querySelector('.cookies-ribbon') as HTMLElement;
      cookiesRibbon.style.display = 'none';
    }

    // Add event listener to the accept button
    const acceptBtn = document.getElementById('acceptCookiesBtn');
    if (acceptBtn) {
      acceptBtn.addEventListener('click', this.acceptCookies);
    }


  }

  gotoRenewal() {

    this.router.navigateByUrl('quick');


  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  // Cookie consent logic
  setCookie(name: any, value: any, days: any) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }

  acceptCookies() {
    this.setCookie('cookiesAccepted', 'true', 30);
    const cookiesRibbon = document.querySelector('.cookies-ribbon') as HTMLElement;
    cookiesRibbon.style.display = 'none';
    console.log("Cookies Accepted...");

  }


  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.slides.length;
  }

  getCurrentMonth() {
    return new Date().getMonth() + 1; // Adding 1 to get the month from 1 to 12
  }

  // ngAfterViewInit(): void {
  //   // Start the automatic slider
  //   this.startAutoSlide();
  // }

  // ngOnDestroy(): void {
  //   // Clear the interval when the component is destroyed to prevent memory leaks
  //   clearInterval(this.slideInterval);
  // }

  // private updateSlider(): void {
  //   const translateX = -this.currentIndex * 100;
  //   this.renderer.setStyle(this.sliderContent!.nativeElement, 'transform', `translateX(${translateX}%)`);

  //   // Update active button
  //   const buttons = this.sliderButtons!.nativeElement.querySelectorAll('.slider-button');
  //   buttons.forEach((button: any, index: any) => {
  //     if (index === this.currentIndex) {
  //       button.classList.add('active');
  //     } else {
  //       button.classList.remove('active');
  //     }
  //   });
  // }

  // private startAutoSlide(): void {
  //   this.slideInterval = setInterval(() => {
  //     this.currentIndex = (this.currentIndex + 1) % this.images.length;
  //     this.updateSlider();
  //   }, 3000);
  // }

  // onSlideButtonClick(index: number): void {
  //   this.currentIndex = index;
  //   this.updateSlider();
  //   clearInterval(this.slideInterval); // Stop the automatic slider on button click
  // }

}
