<app-header bg="bg-white" class=""></app-header>

<div class="bg-no-repeat lg:bg-bottom md:bg-bottom lg:bgimage md:bgimage sm:bgimagesmall">
    <div class="absolute inset-0 lg:bottom-0 md:bottom-72 ">
        <img class="lg:h-[950px] md:h-[900px] sm:h-[500px] lg:pt-0 md:pt-0 sm:pt-20 w-full lg:object-cover md:lg:object-cover sm:object-cover   sm:object-center"
            src="assets/imgs/videoimage.webp" alt="" [ngClass]="getLang == 'en' ? '' : 'transform scale-x-[-1]'">
    </div>
    <div class="bg-no-repeat bg-right-bottom bg-gradient-to-r pb-3
from-pink-500
via-red-500
to-yellow-500
background-animate relative">
        <div>
            <div class="pt-32 w-full items-center lg:min-h-[847px] md:min-h-[596px] sm:min-h-[348px] max-w-7xl relative container px-4 mx-auto "
                dir="auto">
                <div class=" w-full lg:mt-2 md:mt-16    px-4 ">
                    <div class="lg:text-7xl font-medium text-Dark-Gray-2 sm:text-3xl md:text-6xl max-w-[561px]">
                        {{getLang == 'en' ? video.data.attributes.title_en : video.data.attributes.title_ar}}</div>
                    <p
                        class="lg:text-xl lg:w-3/5 md:text-base sm:text-sm md:w-4/5   text-Mid-Gray-1 pt-11 max-w-[552px]">
                        {{getLang == 'en' ? 'We provide you with peace of mind at every '
                        +'stage of your life, offering a wide range of insurance '
                        +'products and superior customer service.' :
                        'نحن نوفر لك راحة البال في كل جانب من '
                        +'جوانب الحياة مع مجموعة متنوعة من منتجات التأمين وخدمة العملاء المميزة'}}
                    </p>
                    <a href="https://online.solidarity.bh/">
                        <button
                            class="button lg:w-[220px] md:w-[168px] mt-10 mr-3 bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-lg justify-around">
                            {{getLang == 'en' ? "Avail your cash-back now!!" : "استفد من العرض الآن"}}
                        </button>
                    </a>
                    <p class="pt-60 lg:max-w-xl md:max-w-xs text-sm text-Dark-Gray-1 lg:block md:block sm:hidden">
                        {{getLang == 'en' ? video.data.attributes.agreement_en : video.data.attributes.agreement_ar}}
                    </p>
                </div>
            </div>
            <!-- <img  class="w-[-webkit-fill-available] lg:hidden md:hidden sm:block" src="assets/imgs/video.png" alt="" [ngClass]="getLang == 'ar' ? 'scale-x-[-1]' : ''"> -->
        </div>
        <!-- <div class="" [ngClass]="getLang == 'en' ? 'block' : 'hidden' ">
    <app-background-animation class="lg:block md:block sm:hidden"></app-background-animation>
    
    <img  class="max-w-[50%] absolute bottom-0 right-0 lg:block md:block sm:hidden " src="assets/imgs/video.png" alt="">
 
</div> -->
        <!-- <div class="" [ngClass]="getLang == 'ar' ? 'block' : 'hidden' ">
    <app-background-animation class="lg:block md:block sm:hidden pr-96"></app-background-animation>
    
    <img  class="max-w-[50%] absolute bottom-0 left-0 lg:block md:block sm:hidden scale-x-[-1] " src="assets/imgs/video.png" alt="">
 
</div> -->
    </div>
</div>
<app-channel-guides></app-channel-guides>
<app-claim-section></app-claim-section>
<app-coverd-section></app-coverd-section>
<app-footer></app-footer>