import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of,catchError } from 'rxjs';
import { FinancialReports } from 'src/app/models/finincial-reports';
import { StrapiApiService } from '../strapi-api.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportsResolver  {
  constructor(private strapiAPi: StrapiApiService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.strapiAPi.getFinancialReports().pipe(catchError((error)=> {console.log(error); return of('No Data')}));
  }
}
