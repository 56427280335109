import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (typeof item === 'string') {
        // Handle filtering for string items
        return item.toLowerCase().includes(searchText);
      } else if (typeof item === 'object') {
        if (item.attributes != null) {
          // Check if the property you want to filter is undefined or null
          // Adjust the property name according to your data structure
          const title = item.attributes.title;
          const body = item.attributes.body;
          const titleAR = item.attributes.title_ar;
          const bodyAR = item.attributes.body_ar;
          if (title === undefined || title === null) {
            return false;
          }

          // Ensure propertyValue is a string before applying toLowerCase()
          if (typeof title === 'string') {
            return title.toLowerCase().includes(searchText) || body.toLowerCase().includes(searchText) || titleAR.toLowerCase().includes(searchText) || bodyAR.toLowerCase().includes(searchText);
          }
        }
      }
      return false;
    });
  }
}
