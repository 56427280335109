import { Component, OnInit } from '@angular/core';
import { forEach } from 'lodash-es';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-investorrelations',
  templateUrl: './investorrelations.component.html',
  styleUrls: ['./investorrelations.component.css']
})
export class InvestorRelationsComponent implements OnInit {
  showNews = true;
  showQuery = false;
  showReports = false;
  showCredit = false;
  selectedTeam = '';
  isSubmitted = false;
  btnLoading: boolean = false;
  sentMessage: boolean = false;
  captcharesolved = false;
  ngForm: any;

  // invests = [
  //   {id: 0, viewValue: 'Shareholder News'},
  //   {id: 1, viewValue: 'Shareholder Query'},
  //   {id: 2, viewValue: 'Finincial Reports'},
  // ];

  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.ngForm = new FormGroup({
      email: new FormControl(''),
      fname: new FormControl(''),
      lname: new FormControl(''),
      // msg: new FormControl(''),
      phone: new FormControl(''),
      subject: new FormControl(''),
      query: new FormControl('')
    });
  }

  onSelected(value: string): void {
    this.selectedTeam = value;
    if (this.selectedTeam == 'news') {
      this.ShowNews();
    }
    else if (this.selectedTeam == 'query') {
      this.ShowQuery();
    }
    else if (this.selectedTeam == 'reports') {
      this.ShowReports();
    } 
    else if (this.selectedTeam == 'credit') {
      this.ShowCredits();
    }
  }

  ShowNews() {
    this.showNews = true;
    this.showQuery = false;
    this.showReports = false;
    this.showCredit = false;
    this.selectedTeam = 'news';
  }

  ShowQuery() {
    this.showQuery = true;
    this.showNews = false;
    this.showReports = false;
    this.showCredit = false;
    this.selectedTeam = 'query';
  }

  ShowReports() {
    this.showReports = true;
    this.showNews = false;
    this.showQuery = false;
    this.showCredit = false;
    this.selectedTeam = 'reports';
  }

  ShowCredits() {
    this.showCredit = true;
    this.showReports = false;
    this.showNews = false;
    this.showQuery = false;
    this.selectedTeam = 'credit';
  }

  async SendInvestorRelation(form: NgForm): Promise<void> {
    this.isSubmitted = true;
    this.btnLoading = true;


    // console.log("Form Info: ");
    // console.log(this.ngForm.get('email').value, this.ngForm.get('phone').value, this.ngForm.get('name').value, this.ngForm.get('msg').value, this.ngForm.get('subject').value);

    if (form.valid) {
      // console.log("Form to put into ContactUS API:", form);
      this.webApi.investorRelations(this.ngForm.get('fname').value, this.ngForm.get('lname').value, this.ngForm.get('phone').value,
        this.ngForm.get('email').value, this.ngForm.get('subject').value, this.ngForm.get('query').value, null).subscribe((success) => {
          this.router.navigate(['contactus'], { state: { quote: success } });
          this.sentMessage = true;
          this.btnLoading = false;
          this.resetForm();
        });
    }
    else {
      this.btnLoading = false;
    }

    // console.log(this.ngForm.get('fname').value);
    // console.log(this.ngForm.get('lname').value);
    // console.log(this.ngForm.get('email').value);
    // console.log(this.ngForm.get('phone').value);
    // console.log(this.ngForm.get('subject').value);
    // console.log(this.ngForm.get('query').value);
  }

  resetForm() {
    // this.ngForm.reset();
    // this.ngForm.markAsUntouched();
    window.location.reload();
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}