<app-header bg="bg-white"></app-header>
<div class="mx-auto max-w-7xl px-4 sm:px-6 container" dir="auto">
    <p class="lg:text-7xl sm:text-4xl md:text-6xl text-Dark-Gray-2 font-medium">{{getLang == 'en' ? title : title_ar}}
    </p>
    <div class="pt-14" *ngFor="let t of terms; let i = index">
        <p class="lg:text-3xl md:text-3xl sm:text-xl font-medium">{{getLang == 'en' ? t.termName : t.termName_ar}}</p>
        <!-- <p class="pt-5 lg:w-2/3 sm:text-sm text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }}</p> -->
        <br>
        <ol *ngIf="i == 0">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
        <ol *ngIf="i == 1">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br>
        </ol>
        <ol *ngIf="i == 2">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
        <ol *ngIf="i == 3">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
        <ol *ngIf="i == 4">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
        <ol *ngIf="i == 5">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
        <ol *ngIf="i == 6">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
        <ol *ngIf="i == 7">
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc : t.termDesc_ar }} </li> <br>
            <!-- <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_2 : t.termDesc_ar_2 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_3 : t.termDesc_ar_3 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_4 : t.termDesc_ar_4 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_5 : t.termDesc_ar_5 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_6 : t.termDesc_ar_6 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_7 : t.termDesc_ar_7 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_8 : t.termDesc_ar_8 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_9 : t.termDesc_ar_9 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_10 : t.termDesc_ar_10 }} </li> <br>
            <li class="text-Mid-Gray-1"> {{getLang == 'en' ? t.termDesc_11 : t.termDesc_ar_11 }} </li> <br> -->
        </ol>
    </div>

    <div class="pb-44">

    </div>

    <app-footer></app-footer>