import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoPage } from 'src/app/models/video-page';
import { StrapiApiService } from 'src/app/services/strapi-api.service';


@Component({
  selector: 'app-beyon-money',
  templateUrl: './beyon-money.component.html',
  styleUrls: ['./beyon-money.component.css']
})
export class BeyonMoneyComponent implements OnInit {
  video!: VideoPage;

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute,) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      // this.title = params['data'].data.attributes.termsTitle;
      //this.terms = params['data'].data.attributes.terms;
      this.video = params['data'];
    });
  }
  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
