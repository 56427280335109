import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { AboutUs } from 'src/app/models/about-us';
import { StrapiApiService } from '../strapi-api.service';

@Injectable({
  providedIn: 'root'
})
export class AboutUsResolverService  {

  constructor(private strapiAPi: StrapiApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.strapiAPi.getAboutUs().pipe(catchError((error)=> {console.log(error); return of('No Data')}));;
    
  }
}
