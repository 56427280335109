<div
  class="mx-auto w-full text-center mt-28  container  sm:max-w-sm lg:max-w-7xl md:max-w-2xl  lg:border rounded-[10px] md:border rounded-[10px] border-Light-Gray-2 py-4 pl-4 pr-4 lg:h-[530px] md:h-auto ">
  <div class="lg:flex lg:columns-2 md:columns-1 lg:gap-2 lg:h-full">
    <div class="border rounded-[5px] border-Light-Gray-2  lg:w-2/3 rounded-md ">
      <google-map class="w-full " [center]="center" [zoom]="zoom" [options]="options" width="100%"
        style="border-radius: 10px">
        <map-marker *ngFor="let branch of branches" #mark="mapMarker" [position]="branch.attributes.latlngTest"
          icon="assets/imgs/solid.png" (mapClick)="openInfoWindow(mark, branch)">
          <div>
            <map-info-window>
            </map-info-window>
          </div>
        </map-marker>
      </google-map>
    </div>
    <div
      class="lg:w-2/5 rounded-md h-full overflow-y-scroll scrollbar-thin  scroll-mt-4 scrollbar-thumb-Light-Gray-2  ">

      <div class="flex flex-col gap-2 lg:mr-4 md:mt-2 md:h-[530px] sm:mt-2 sm:h-[430px]">
        <div *ngFor="let branch of branches">
          <div class="flex p-4 border border-Light-Gray-3 rounded-md">
            <div class="w-1/3">
              <!-- <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="100" rx="6" fill="#EEF0F2" />
                <rect x="33" y="33" width="34" height="34" rx="4" fill="#DDE2E4" />
                <path d="M56.6768 63H39C37.8954 63 37 62.1046 37 61V56.8548L44.5 49L56.6768 63Z" fill="#9AA6AC" />
                <circle cx="55.5" cy="44.5" r="3.5" fill="#9AA6AC" />
                <path d="M63 60.6603V61C63 62.1046 62.1046 63 61 63H49L56.8331 55L63 60.6603Z" fill="#6E7C87" />
              </svg> -->
              <img class="rounded" style="contain: size;" height="100" width="100"
                [src]="strapiApi.getImage(branch.attributes.image.data.attributes.url)" alt="">
            </div>
            <div class="w-3/5 flex flex-col h-full items-stretch" [ngClass]="getLang == 'ar' ? 'mr-4' : ''">
              <div class="mt-2 flex text-start">
                <p class="text-start sm:text-xs">{{getLang == 'en' ? branch.attributes.name :
                  branch.attributes.name_ar}}</p>
              </div>
              <div class="mt-2 flex text-start">
                <p class="text-start sm:text-[10px]">{{getLang == 'en' ? branch.attributes.address :
                  branch.attributes.address_ar}}</p>
              </div>
              <div class="mt-2 flex flex-1 items-end">
                <div class="flex">
                  <div class="flex items-center pl-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.5 3L20.34 3.03L15 5.1L9 3L3.36 4.9C3.15 4.97 3 5.15 3 5.38V20.5C3 20.78 3.22 21 3.5 21L3.66 20.97L9 18.9L15 21L20.64 19.1C20.85 19.03 21 18.85 21 18.62V3.5C21 3.22 20.78 3 20.5 3ZM10 5.47L14 6.87V18.53L10 17.13V5.47ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM19 17.54L16 18.55V6.86L19 5.7V17.54Z"
                        fill="#84919A" />
                    </svg>
                    <a href="{{branch.attributes.direction_link}}"
                      class="text-Dark-Gray-2 lg:text-sm md:text-sm sm:text-xs pr-1">
                      {{getLang == 'en' ? "Show on Map" : "أظهر على الخريطة"}}</a>
                  </div>
                  <div class="flex items-center ml-5">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
                        fill="#84919A" />
                    </svg>
                    <a href="tel:{{branch.attributes.phone_number}}"
                      class="text-Dark-Gray-2 lg:text-sm md:text-sm sm:text-xs pr-1">{{branch.attributes.phone_number}}</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>