import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClaimSection } from 'src/app/models/claim-section';
import { StrapiApiService } from 'src/app/services/strapi-api.service';

@Component({
  selector: 'app-claim-section',
  templateUrl: './claim-section.component.html',
  styleUrls: ['./claim-section.component.css']
})
export class ClaimSectionComponent implements OnInit {
  claimSection!: ClaimSection;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {


      this.claimSection = params['claimSection'];
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
