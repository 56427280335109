import { Component, Input, OnInit } from '@angular/core';
import { PolicyPremium } from 'src/app/models/policy-premium';

@Component({
  selector: 'app-final-quote-login-call',
  templateUrl: './final-quote-login-call.component.html',
  styleUrls: ['./final-quote-login-call.component.css']
})
export class FinalQuoteLoginCallComponent implements OnInit {

  @Input() quoteType?: string ;
  @Input() quote?: PolicyPremium ;
  premium: Number = 0.000;
  totalPremium: Number = 0.000;
  tax : number = 0.000;
  constructor() { 
 
  }

  ngOnInit(): void {   
    // console.log(this.quote);
    if(this.quote != undefined){
      this.premium = this.quote!.Premium;
      this.totalPremium = this.quote!.TotalPremium;
      this.tax = this.quote!.Tax;
    }
  }

}
