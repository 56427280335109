<div class="w-full container my-9 mx-4">
  <p class="font-medium text-2xl">Quick Renewal</p>
  <p class="mt-2">Fill in your information, and we’ll take care of the rest</p>
  <div class="flex w-full">
    <div class="w-8/12">
      <div class="mt-10 flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2514_47952)">
            <path
              d="M19 3H5C3.9 3 3.01 3.9 3.01 5L3 19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM10.5 17H13.5V13.5H17V10.5H13.5V7H10.5V10.5H7V13.5H10.5V17Z"
              fill="#00719A" />
          </g>
          <defs>
            <clipPath id="clip0_2514_47952">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p class="ml-4 text-primary-700 font-medium text-sm">SELECT ADD-ONS</p>
      </div>
      <div class="grid  grid-cols-1 gap-2 mt-5">
        <div class="flex items-center">
          <input type="checkbox"
            class="form-checkbox rounded focus:ring-primary-300 focus:ring-4 text-primary-500 focus:border-primary-500 focus:ring-offset-0 focus:bg-primary-500 checked:bg-primary-500 h-4 w-4 text-indigo-600 border-Mid-Gray-5 bg-Light-Gray-5" />
          <p class="ml-2 text-sm text-Dark-Gray-2">GCC Geographical Extension</p>
        </div>
        <div class="flex items-center mt-4">
          <input type="checkbox"
            class="form-checkbox rounded focus:ring-primary-300 focus:ring-4 text-primary-500 focus:border-primary-500 focus:ring-offset-0 focus:bg-primary-500 checked:bg-primary-500 h-4 w-4 text-indigo-600 border-Mid-Gray-5 bg-Light-Gray-5" />
          <p class="ml-2 mr-3 text-sm text-Dark-Gray-2">GCC Geographical Extension</p>
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2514_47957)">
              <path
                d="M7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 10.625C7.15625 10.625 6.875 10.3438 6.875 10V7.5C6.875 7.15625 7.15625 6.875 7.5 6.875C7.84375 6.875 8.125 7.15625 8.125 7.5V10C8.125 10.3438 7.84375 10.625 7.5 10.625ZM8.125 5.625H6.875V4.375H8.125V5.625Z"
                fill="#84919A" />
            </g>
            <defs>
              <clipPath id="clip0_2514_47957">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div>
      </div>
      <div class="mt-10">
        <button class="button w-[130px] h-10 bg-primary-500 text-Light-Gray-5 py-2 rounded-md text-center text-sm">
          Next
        </button>
      </div>
    </div>
    <div class="w-4/12">
      <div class="p-8 w-full text-center border rounded-md border-Light-Gray-4 shadow-2xl divide-y divide-Light-Gray-3">
        <div class="mb-4">
          <p class="font-medium text-sm text-Dark-Gray-2">
            Total Price (BHD)
          </p>
          <p class="font-medium text-4xl text-Mid-Gray-1 mt-4">{{this.totalPremium}}</p>
        </div>
        <div class="pt-4">
          <div class="flex justify-center">
            <p class="text-xs text-Dark-Gray-2 mr-3">Price (BHD)</p>
            <p class="text-xs text-Dark-Gray-2">{{this.premium}}</p>
          </div>

          <div class="flex justify-center mt-2">
            <p class="text-xs text-Dark-Gray-2 mr-3">VAT 10%</p>
            <p class="text-xs text-Dark-Gray-2">{{this.tax}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>