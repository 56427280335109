import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-inputs',
  templateUrl: './home-inputs.component.html',
  styleUrls: ['./home-inputs.component.css']
})
export class HomeInputsComponent implements OnInit {
  isSubmitted = false;
  validations!: Validations;
  HomeForm: any;
  loading: boolean = false;
  accepted: boolean = false;
  captcharesolved = true;
  sumInsured: string | undefined | null;
  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.HomeForm = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      sumInsured: new FormControl(null),
      phone: new FormControl(''),
      age: new FormControl(null),

    });
  }
  encryptValuesAndSendToPortal() {
    const secretKey = 'Solid@abu1999HomeInsurance';
    // Get the form data
    const formData = this.HomeForm.value;

    Object.keys(formData).forEach(key => {
      if (formData[key] === null || formData[key] === undefined) {
        formData[key] = '';
      }
    });

    const jsonString = JSON.stringify(formData);

    // Encrypt the JSON string with DES
    const encrypted = CryptoJS.DES.encrypt(jsonString, secretKey).toString();
    var queryString = Object.keys(formData)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(encrypted)}`)
      .join('&');

    var url = environment.portalUrl + `/insurance-policies/home-insurance/?${queryString}`;
    window.location.href = url;
    // console.log('Data: ' + jsonString);
  }

  checking() {
    this.isSubmitted = true;
    // console.log("test: " + this.HomeForm.get('name').value);
    this.loading = true;
    if (this.HomeForm.valid) {
      this.webApi.sendProductLead(
        'Customer',
        this.HomeForm.get('phone').value.toString(),
        'PROPERTY',
        'New Insurance - Home',
        this.HomeForm.get('email').value.toString(),
        1,
        1,
        'New Home Insurance Lead',
        'Home Details:\n' + 'Sum Insured: ' + this.HomeForm.get('sumInsured').value.toString() + '\n' + 'Home age: ' + this.HomeForm.get('age').value.toString(),
        null
      ).subscribe((s) => {
        // console.log(s);
        this.encryptValuesAndSendToPortal();

      });
    }
  }
  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  getQuote(form: NgForm): void {
    // console.log(form);
    this.loading = true;
    if (form.valid) {
      // console.log(form);
      this.webApi.getHomeQuote(form.value["sumInsured"], "0", "0", "0", "0", "FALCON", null, null).subscribe((s) => {
        this.router.navigate(['quote/home'], { state: { quote: s } });
      });
    }
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
