import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Attributes } from 'src/app/models/fot-businesses';
import { Validations } from 'src/app/models/validations';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-forbusinesses',
  templateUrl: './forbusinesses.component.html',
  styleUrls: ['./forbusinesses.component.css']
})
export class ForbusinessesComponent implements OnInit {
  bgImage: string = '';
  forBusinesses!: Attributes;
  showproperty = true;
  showEng = false;
  showGen = false;
  showLiability = false;
  showMarine = false;
  showMedical = false;
  point: string = '';
  selectedList = 'property';
  validations!: Validations;
  ngForm: any;
  isSubmitted = false;
  loading = false;
  imageUrl: string = '';
  btnLoading = false;
  selectedProd: string = '';
  sentMessage = false;
  validateEmail = true;
  captcharesolved = false;
  changeView = false;

  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute, public strapiApi: StrapiApiService) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {
      this.validations = params['val'];
      this.forBusinesses = params['data'].data.attributes;
      this.bgImage = params['data'].data.attributes.background_image.data.attributes.url;
    });

    this.ngForm = new FormGroup({
      name: new FormControl(''),
      phone: new FormControl(''),
      subject: new FormControl(''),
      email: new FormControl(''),
    });
  }

  showPoint(point: string) {
    this.point = point;
    if (point == this.point) {
      // console.log("This " + this.point)
    }
  }

  selectProduct(product: any) {
    this.selectedProd = product;
    // console.log(this.selectedProd);
  }

  onSelected(choice: string): void {
    this.selectedList = choice;
    if (choice == 'property') {
      this.showproperty = true;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = false;
      this.selectedList = 'property';
    }
    else if (choice == 'engineering') {
      this.showproperty = false;
      this.showEng = true;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = false;
      this.selectedList = 'engineering';

    }
    else if (choice == 'general') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = true;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = false;
      this.selectedList = 'general';

    }
    else if (choice == 'liability') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = true;
      this.showMarine = false;
      this.showMedical = false;
      this.selectedList = 'liability';

    }
    else if (choice == 'marine') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = true;
      this.showMedical = false;
      this.selectedList = 'marine';

    }
    else if (choice == 'medical') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = true;
      this.selectedList = 'medical';
    }
  }

  selection(choice: string) {
    if (choice == 'property') {
      this.showproperty = true;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = false;
    }
    else if (choice == 'engineering') {
      this.showproperty = false;
      this.showEng = true;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = false;
    }
    else if (choice == 'general') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = true;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = false;
    }
    else if (choice == 'liability') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = true;
      this.showMarine = false;
      this.showMedical = false;
    }
    else if (choice == 'marine') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = true;
      this.showMedical = false;
    }
    else if (choice == 'medical') {
      this.showproperty = false;
      this.showEng = false;
      this.showGen = false;
      this.showLiability = false;
      this.showMarine = false;
      this.showMedical = true;
    }
  }

  messageSent() {
    this.sentMessage = false;
    this.selectedProd = '';
    this.isSubmitted = false;
    this.imageUrl = '';
    this.ngForm.reset();
  }
  sendForm(form: NgForm): void {
    console.log(form);
    this.isSubmitted = true;
    this.btnLoading = true;
    if (form.valid) {
      // console.log(form);
      this.webApi.sendLead(this.ngForm.get('name').value,
        this.ngForm.get('phone').value,
        this.selectedProd,
        'business lead',
        this.ngForm.get('email').value,
        2,
        1,
        '',
        null).subscribe((s) => {
          this.router.navigate(['forbusinesses'], { state: { quote: s } });
          this.sentMessage = true;
          this.btnLoading = false;
        });
    } else {
      this.btnLoading = false;
    }

  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
