import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Attributes, Attributes2, Board, Management, Sharia } from 'src/app/models/about-us';
import { ThreelinesMemebrs } from 'src/app/models/threelines-memebrs';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css'],
})
export class AboutusComponent implements OnInit {
  board: Array<Board> = [];
  managment: Array<Management> = [];
  sharia: Array<Sharia> = [];
  bio: string = '';
  aboutUsPic: string = '';
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute, private http: HttpClient) {
  }
  companyBio!: Attributes;




  async ngOnInit() {
    this.route.data.subscribe(async params => {
      this.bio = params['data'].data.attributes;
      this.board = params['data'].data.attributes.board;
      this.managment = params['data'].data.attributes.management;
      this.sharia = params['data'].data.attributes.sharia;
      this.companyBio = params['data'].data.attributes
    });

  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  GetStrapi(url: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.strapiUrl}` + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
