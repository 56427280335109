<!--
      <div class="mx-auto pt-20 ">
        <div class="flex justify-center items-center">
          <span class="border-transparent rounded text-Dark-Gray-2 mr-1 py-1 px-2 text-sm">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.70703 0.292787C5.8945 0.480314 5.99982 0.734622 5.99982 0.999786C5.99982 1.26495 5.8945 1.51926 5.70703 1.70679L2.41403 4.99979L5.70703 8.29279C5.88919 8.48139 5.98998 8.73399 5.9877 8.99619C5.98543 9.25838 5.88026 9.5092 5.69485 9.6946C5.50944 9.88001 5.25863 9.98518 4.99643 9.98746C4.73423 9.98974 4.48163 9.88894 4.29303 9.70679L0.293031 5.70679C0.10556 5.51926 0.000244141 5.26495 0.000244141 4.99979C0.000244141 4.73462 0.10556 4.48031 0.293031 4.29279L4.29303 0.292787C4.48056 0.105316 4.73487 0 5.00003 0C5.26519 0 5.5195 0.105316 5.70703 0.292787V0.292787Z"
                fill="#252C32" />
            </svg>
          </span>

          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 hover:bg-Light-Gray-4 py-1 px-2 text-sm">
            1
          </a>
          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 bg-Light-Gray-3 py-1 px-2 text-sm">
            2
          </a>
          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 hover:bg-Light-Gray-4 py-1 px-2 text-sm">
            3
          </a>
          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 py-1 px-2 text-sm">
            ...
          </a>
          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 hover:bg-Light-Gray-4 py-1 px-2 text-sm">
            8
          </a>
          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 hover:bg-Light-Gray-4 py-1 px-2 text-sm">
            9
          </a>
          <a href="#" class="border-transparent rounded text-Dark-Gray-2 mr-1 hover:bg-Light-Gray-4 py-1 px-2 text-sm">
            10
          </a>
          <span class="border-transparent rounded text-Dark-Gray-2 mr-1 py-1 px-2 text-sm">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.293031 9.70692C0.10556 9.51939 0.000244141 9.26508 0.000244141 8.99992C0.000244141 8.73475 0.10556 8.48045 0.293031 8.29292L3.58603 4.99992L0.293031 1.70692C0.110873 1.51832 0.0100779 1.26571 0.0123563 1.00352C0.0146347 0.741321 0.119804 0.490509 0.305212 0.305101C0.49062 0.119692 0.741433 0.0145233 1.00363 0.0122448C1.26583 0.00996641 1.51843 0.110761 1.70703 0.292919L5.70703 4.29292C5.8945 4.48045 5.99982 4.73475 5.99982 4.99992C5.99982 5.26508 5.8945 5.51939 5.70703 5.70692L1.70703 9.70692C1.5195 9.89439 1.26519 9.99971 1.00003 9.99971C0.734866 9.99971 0.480558 9.89439 0.293031 9.70692Z"
                fill="#252C32" />
            </svg>
          </span>
        </div>
      </div>


      <div class="card text-center m-3">
        
        <div class="card-body">
            <div *ngFor="let item of pageOfItems">{{item.name}}</div>
        </div>
        <div>
            <div class="card-footer pb-0 pt-3">
                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
        </div>
        
    </div>
    -->
<!-- <div class="card-body">
        <div *ngFor="let item of pageOfItems">{{item.attributes.title}}</div>
    </div> -->
<div class="mx-auto py-20 ">
  <div class="flex justify-center items-center">
    <span class="border-transparent rounded text-Dark-Gray-2 mr-1 py-1 px-2 text-sm">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5.70703 0.292787C5.8945 0.480314 5.99982 0.734622 5.99982 0.999786C5.99982 1.26495 5.8945 1.51926 5.70703 1.70679L2.41403 4.99979L5.70703 8.29279C5.88919 8.48139 5.98998 8.73399 5.9877 8.99619C5.98543 9.25838 5.88026 9.5092 5.69485 9.6946C5.50944 9.88001 5.25863 9.98518 4.99643 9.98746C4.73423 9.98974 4.48163 9.88894 4.29303 9.70679L0.293031 5.70679C0.10556 5.51926 0.000244141 5.26495 0.000244141 4.99979C0.000244141 4.73462 0.10556 4.48031 0.293031 4.29279L4.29303 0.292787C4.48056 0.105316 4.73487 0 5.00003 0C5.26519 0 5.5195 0.105316 5.70703 0.292787V0.292787Z"
          fill="#252C32" />
      </svg>
    </span>
    <div>
      <button (click)="previousPage()" [disabled]="currentPage === 1">Previous</button>
      <ng-container *ngFor="let page of getPagesArray()">
        <button (click)="setPage(page)" [class.active]="currentPage === page">{{ page }}</button>
      </ng-container>
      <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
    </div>
    <span class="border-transparent rounded text-Dark-Gray-2 mr-1 py-1 px-2 text-sm">
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.293031 9.70692C0.10556 9.51939 0.000244141 9.26508 0.000244141 8.99992C0.000244141 8.73475 0.10556 8.48045 0.293031 8.29292L3.58603 4.99992L0.293031 1.70692C0.110873 1.51832 0.0100779 1.26571 0.0123563 1.00352C0.0146347 0.741321 0.119804 0.490509 0.305212 0.305101C0.49062 0.119692 0.741433 0.0145233 1.00363 0.0122448C1.26583 0.00996641 1.51843 0.110761 1.70703 0.292919L5.70703 4.29292C5.8945 4.48045 5.99982 4.73475 5.99982 4.99992C5.99982 5.26508 5.8945 5.51939 5.70703 5.70692L1.70703 9.70692C1.5195 9.89439 1.26519 9.99971 1.00003 9.99971C0.734866 9.99971 0.480558 9.89439 0.293031 9.70692Z"
          fill="#252C32" />
      </svg>
    </span>
  </div>
</div>