import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coverd-section',
  templateUrl: './coverd-section.component.html',
  styleUrls: ['./coverd-section.component.css']
})
export class CoverdSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
