<app-header bg="bg-primary-50"></app-header>
<div class="relative z-30  " [ngClass]="newsOpened ? 'block' : 'hidden'" aria-labelledby="slide-over-title"
  role="dialog" aria-modal="true">
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div class="fixed inset-0 "></div>

  <div class="fixed inset-0 overflow-hidden   ">
    <div class="absolute inset-0 overflow-hidden  ">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16  ">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->

        <div class="pointer-events-auto w-screen max-w-xl   ">
          <div class="flex h-[100%]  flex-col  bg-white shadow-2xl    ">
            <div
              class="h-0 flex-1 overflow-y-auto px-9 py-4  scrollbar-thin  scroll-mt-4  scrollbar-thumb-Light-Gray-2 ">

              <div class="-mr-2 text-right">
                <button type="button"
                  class="button items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  (click)="openNews(dataNews)">
                  <span class="sr-only">Close menu</span>

                  <svg class=" w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <p class="mt-5 text-sm text-Mid-Gray-1">{{dataNews.attributes.published}}</p>
              <p class="mt-3 text-2xl text-Dark-Gray-3 font-medium">
                {{dataNews.attributes.title}}
              </p>
              <div class="mt-4">
                <p class="text-justify text-base text-Mid-Gray-1">
                  {{dataNews.attributes.body}}
                </p>
              </div>
              <img class="w-full mt-10 bg-transparent border-none rounded-[10px] shadow-sm"
                [src]="this.strapiApi.getImage(dataNews.attributes.image.data.attributes.url)" />
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<div class="sm: pb-[3600px]"
  [ngClass]="pageOfItems.length < 10 ? 'lg:pb-[1400px] md:pb-[1900px]' : pageOfItems.length < 7 ? 'lg:pb-[500px]' : pageOfItems.length < 4 ? 'lg:pb-[300px]' : 'lg:pb-[100px]'">
  <div class="p-2 w-full bg-primary-50 items-center lg:min-h-[600px] md:min-h-[550px]  sm:min-h-[450px] sm:p-6 "
    dir="auto">
    <div class="sm:absolute sm:inset-x-4 sm:z-10 ">
      <div class="mx-auto w-full text-center mt-16 lg:container md:container sm:container">
        <div class="lg:text-7xl md:text-7xl sm:text-5xl font-medium text-Dark-Gray-2">
          {{getLang == 'en' ? "Solidarity Bahrain News" : " أخبار سوليدرتي البحرين"}}</div>
        <p class="lg:text-lg md:text-lg sm:text-sm text-center lg:w-3/5 mx-auto text-Mid-Gray-1 pt-4"
          [ngClass]="getLang == 'ar' ? 'pt-8' : ''">
          {{getLang == 'en' ?
          "Read the latest news, stay up to date on product launches, and never miss important announcements." :
          "تابع أخبارنا باستمرار، وكن على اطلاع دائم بأحدث منتجاتنا وخدماتنا."}}
        </p>

        <!-- SEARCH Functionality -->
        <div class="w-full bg-primary-200 rounded-[10px] lg:p-8 md:px-8 
          md:py-8 lg:flex md:flex items-end justify-between mt-14 
          py-8 px-4 shadow sm:rounded-lg sm:px-4 bg-white">
          <div class="lg:w-6/12 md:w-6/12 flex flex-col flex-grow"> <!-- Flex container with flex-grow -->
            <label for="name" class="block text-sm text-start font-normal text-Dark-Gray-2 mb-1">
              {{getLang == 'en' ?
              "Search" : "ابحث"}}</label>
            <div class="relative ">
              <input type="text" id="search1" s name="search1"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm" required
                placeholder="{{getLang == 'en' ? 'Enter your search terms here' : 'ادخل مصطلح البحث هنا'}}"
                aria-describedby="name-error" [(ngModel)]="val" (input)="onSubmit($event)" />
            </div>
          </div>
          <!-- <div class="lg:w-3/12 md:w-3/12">
            <label for="year"
              class="block text-sm text-start font-normal text-Dark-Gray-2 mb-1 dark:text-white sm:pt-5 md:pt-0">
              {{getLang == 'en' ? "Year" : "العام"}}</label>
            <div class="relative">
              <select id="year" name="year"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm"></select>
            </div>
          </div> -->
        </div>
      </div>
      <!--
        To do: fix grid must show properly on each size of page
      -->
      <div class="mt-10 container mx-auto ">
        <div class="">
          <div class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 ">
            <div class="w-full cursor-pointer" *ngFor="let news of firstLine | paginate: { 
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: totalItems }| filter:this.searchtxt" (click)="
              openNews(news)">
              <img class="w-full bg-transparent border-none rounded-[10px] shadow-sm h-74 object-cover"
                [src]="this.strapiApi.getImage(news.attributes.image.data.attributes.url)" />
              <p class="mt-5 text-sm text-Mid-Gray-1">{{news.attributes.published}}</p>
              <p class="mt-3 text-2xl sm:text-xl text-Dark-Gray-3 font-medium">
                {{news.attributes.title}}
              </p>
            </div>
          </div>
        </div>
        <br><br>
        <div class="flex justify-center">
          <pagination-controls previousLabel="" nextLabel="" (pageChange)="onPageChange($event)">
          </pagination-controls>
        </div>
      </div>
      <br>
      <app-footer></app-footer>
    </div>

  </div>
</div>