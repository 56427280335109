import { Component, OnInit } from '@angular/core';
import { Datum, News } from 'src/app/models/news';
import { ThreelinesMemebrs } from 'src/app/models/threelines-memebrs';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { PaginationComponent } from 'src/app/reusable/components/pagination/pagination.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  //pagination!: PaginationComponent;
  pageOfItems: Array<any> = [];
  pageSize: number = 6; // 3 items per row, so 3x3 = 9
  firstLine: Array<Datum> = [];
  otherLines: Array<Datum> = [];
  page: number = 1;
  year?: string = undefined;
  searchTerm?: string = undefined;
  newsOpened = false;
  dataNews!: Datum;
  searchtxt: string = '';
  s: string = '';
  val: string = '';
  totalItems: number = 0; // Total number of items
  displayedItems: any[] = []; // Items to display on the current page
  filteredNews: any[] = []; // Initialize filteredNews array

  //dateFormat = require("dateformat"); 
  //w!: number;
  constructor(public strapiApi: StrapiApiService, public pagination: PaginationComponent) {
    //this.onResize();
    this.pageOfItems = pagination.getPagesArray();
  }


  // @HostListener('window:resize', ['$event'])
  // onResize(){
  //   this.w = window.innerWidth;
  //   console.log(this.w);
  // }
  ngOnInit(): void {
    this.strapiApi.getNews().subscribe((data) => {
      this.firstLine = data.data;
      this.totalItems = this.firstLine.length;
      this.page = 1;
      this.dataNews = data.data[0];
    });
  }

  // Function to handle page change event
  onPageChange(event: any) {
    this.page = event;
    // this.updateDisplayedItems();
  }

  openNews(data: Datum) {
    this.newsOpened = !this.newsOpened;
    this.dataNews = data;
  }

  onSubmit(event: any) {
    const value = (event.target as HTMLInputElement).value;
    if (value != undefined) {
      if (value.length > 3) {
        // Call your method here or perform any desired action
        this.searchtxt = this.val;
        this.page = 1;
        this.filteredNews = this.firstLine.filter((news) => {
          return news.attributes.title.toLowerCase().includes(this.searchtxt.toLowerCase())
            || news.attributes.title_ar?.toLowerCase().includes(this.searchtxt.toLowerCase())
            || news.attributes.body?.toLowerCase().includes(this.searchtxt.toLowerCase())
            || news.attributes.body_ar?.toLowerCase().includes(this.searchtxt.toLowerCase());
        });

        // Update totalItems based on the filteredNews length
        this.totalItems = this.filteredNews.length;
      } else {
        this.searchtxt = "";
        this.totalItems = this.firstLine.length;
      }
    }
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
