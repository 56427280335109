import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fatures-box',
  templateUrl: './fatures-box.component.html',
  styleUrls: ['./fatures-box.component.css']
})
export class FaturesBoxComponent implements OnInit {
  @Input() img?: string = '' ;
  @Input() first_line?: string ;
  @Input() second_line?: string ;
  @Input() height: string = 'h-auto';
  constructor() { }

  ngOnInit(): void {
  }

}
