import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { Attributes } from 'src/app/models/credit-report';

@Component({
  selector: 'app-credit-report',
  templateUrl: './credit-report.component.html',
  styleUrls: ['./credit-report.component.css']
})

export class CreditReportComponent implements OnInit {
  creditReport: Attributes | undefined;

  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.data.subscribe(async param => {
      this.creditReport = param['data3'].data.attributes;
      // console.log(this.creditReport);
    });
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
}
