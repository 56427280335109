<div 
[class]="''+ height +' sm:w-full md:w-full lg:w-full p-10 border-gray-100 rounded-[10px] bg-white shadow-sm shadow-Mid-Gray-4 items-center justify-items-center text-center'" 
>
<div class="w-full justify-items-center items-center text-center">
  <img class="block mx-auto"  [ngClass]="img == '' ? 'hidden' : 'block'" [src]="img" alt="" width="80"
  height="80">
  <svg [ngClass]="img == '' ? 'block' : 'hidden'"
    width="80"
    height="80"
    class="block mx-auto"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M73.75 31.25C73.75 34 73 36.625 71.625 38.875C71.125 39.625 70.625 40.375 70.125 41C68.875 42.5 67.375 43.625 65.625 44.5C64.875 44.875 64.125 45.25 63.25 45.5C61.875 46 60.375 46.25 58.75 46.25C50.5 46.25 43.75 39.5 43.75 31.25C43.75 29.625 44 28.125 44.5 26.625C44.75 25.75 45.125 25 45.5 24.25C48 19.5 53 16.125 58.875 16.125C67 16.25 73.75 23 73.75 31.25Z"
        fill="#F7931D"
      />
    </g>
    <path
      d="M52.25 67.5H12.5C10.375 67.5 8.75 65.875 8.75 63.75V26.125C8.875 24 10.5 22.5 12.5 22.5H55C57.125 22.5 58.75 24.125 58.75 26.25V41.625C58.75 42.125 58.375 42.625 57.875 42.875L48.75 45.375V55.125C48.75 57.5 49.375 59.875 50.5 62C51.125 63.25 52 64.375 53 65.25C53.375 65.625 53.5 66.125 53.25 66.625C53.25 67.25 52.75 67.5 52.25 67.5ZM12.5 25C11.875 25 11.25 25.5 11.25 26.25V63.75C11.25 64.5 11.75 65 12.5 65H49.5C49.125 64.5 48.75 63.875 48.375 63.25C47 60.75 46.25 58 46.25 55.125V44.375C46.25 43.875 46.625 43.375 47.125 43.125L56.25 40.5V26.25C56.25 25.5 55.75 25 55 25H12.5Z"
      fill="#303940"
    />
    <path
      d="M57.5 27.5C56.75 27.5 56.25 27 56.25 26.25C56.25 25.5 55.75 25 55 25H12.5C11.875 25 11.25 25.5 11.25 26.25C11.25 26.875 10.625 27.375 10 27.5C9.375 27.5 8.75 26.875 8.75 26.25C8.75 23.75 9.125 21.125 9.875 18.75L12 11.5C12.5 9.875 14 8.75 15.625 8.75H51.875C53.5 8.75 55 9.875 55.5 11.375L57.75 18.75C58.375 21.25 58.75 23.75 58.75 26.25C58.75 27 58.25 27.5 57.5 27.5ZM15.625 11.25C15.125 11.25 14.625 11.625 14.375 12.125L12.25 19.375C11.875 20.5 11.625 21.5 11.5 22.625C11.875 22.5 12.125 22.5 12.5 22.5H55C55.375 22.5 55.625 22.5 56 22.625C55.875 21.625 55.625 20.625 55.25 19.625L53 12.25C52.875 11.75 52.375 11.375 51.75 11.375H15.625V11.25Z"
      fill="#303940"
    />
    <path
      d="M38.75 25H28.75C28 25 27.5 24.5 27.5 23.75V10C27.5 9.25 28 8.75 28.75 8.75H38.75C39.5 8.75 40 9.25 40 10V23.75C40 24.5 39.5 25 38.75 25ZM30 22.5H37.5V11.25H30V22.5Z"
      fill="#F7931D"
    />
    <path
      d="M28.75 36.25C28.5 36.25 28.25 36.25 28.125 36.125C27.75 35.875 27.5 35.375 27.5 35V23.75C27.5 23 28 22.5 28.75 22.5H38.75C39.5 22.5 40 23 40 23.75V35C40 35.375 39.75 35.875 39.375 36.125C39 36.375 38.5 36.375 38.125 36.125L36.25 35.125L34.25 36.125C33.875 36.25 33.5 36.25 33.125 36.125L31.25 35.125L29.375 36.125C29.125 36.25 29 36.25 28.75 36.25ZM31.25 32.5C31.5 32.5 31.625 32.5 31.75 32.625L33.75 33.625L35.75 32.625C36.125 32.5 36.5 32.5 36.875 32.625L37.5 33V25H30V33L30.625 32.625C30.75 32.5 31 32.5 31.25 32.5Z"
      fill="#F7931D"
    />
    <path
      d="M57.5 60C57.125 60 56.875 59.875 56.625 59.625L53.5 56.5C53 56 53 55.25 53.5 54.75C54 54.25 54.75 54.25 55.25 54.75L57.5 57L62.875 51.625C63.375 51.125 64.125 51.125 64.625 51.625C65.125 52.125 65.125 52.875 64.625 53.375L58.375 59.625C58.125 59.875 57.875 60 57.5 60Z"
      fill="#F7931D"
    />
    <path
      d="M58.75 71.25C58.625 71.25 58.5 71.25 58.375 71.25C56.25 70.625 54.25 69.625 52.5 68.25C52.125 68 51.75 67.625 51.25 67.25C50.125 66.125 49.125 64.75 48.25 63.375C46.875 60.875 46.125 58.125 46.125 55.25V44.5C46.125 44 46.5 43.5 47 43.25L58.25 40C58.5 40 58.75 40 58.875 40L70.125 43.25C70.625 43.375 71 43.875 71 44.5V55.25C71 62.625 66 69.25 58.875 71.25C59 71.25 58.875 71.25 58.75 71.25ZM48.75 45.375V55.125C48.75 57.5 49.375 59.875 50.5 62C51.125 63.25 52 64.375 53 65.25C53.375 65.5 53.625 65.875 54 66.125C55.375 67.25 57 68.125 58.625 68.625C64.5 66.75 68.625 61.25 68.625 55.125V45.375L58.75 42.5L48.75 45.375Z"
      fill="#303940"
    />
    <path
      d="M26.25 55H16.25C15.5 55 15 54.5 15 53.75C15 53 15.5 52.5 16.25 52.5H26.25C27 52.5 27.5 53 27.5 53.75C27.5 54.5 27 55 26.25 55Z"
      fill="#F7931D"
    />
    <path
      d="M26.25 61.25H16.25C15.5 61.25 15 60.75 15 60C15 59.25 15.5 58.75 16.25 58.75H26.25C27 58.75 27.5 59.25 27.5 60C27.5 60.75 27 61.25 26.25 61.25Z"
      fill="#F7931D"
    />
  </svg>
</div>
<div>
  <p class="text-lg font-medium text-black mt-2">
    {{first_line}}
  </p>
</div>
<div>
  <p class="text-sm font-normal text-black mt-2">
    {{second_line}}
  </p>
</div>
</div>
