<div class="lg:w-full bg-white lg:p-20 md:p-20 sm:pt-[60px] ">
  <div class="container lg:max-w-4xl  md:max-w-2xl sm:max-w-md mx-auto justify-center text-center items-center">
    <p class="lg:text-6xl md:text-[40px] sm:text-3xl  font-medium" dir="auto">{{getLang == 'en' ?
      claimSection.data.attributes.title_en : claimSection.data.attributes.title_ar}} </p>
    <p class="mx-auto lg:text-lg md:text-base sm:text-xs text-Mid-Gray-1 text-center mt-4 max-w-[288px]" dir="auto">
      {{getLang == 'en' ? claimSection.data.attributes.desc_en : claimSection.data.attributes.desc_ar}}
    </p>
    <a href="https://online.solidarity.bh/file-a-claim">
      <button
        class="button w-[168px] mt-14 mr-3 bg-primary-500 text-Light-Gray-5 py-2 px-4 rounded-md items-center text-sm justify-around">
        <p>{{getLang == 'en' ? "File a claim" : "فتح مطالبة"}}</p>
      </button>
    </a>
    <div class="flex w-full lg :w-2/3 mx-auto mt-11">
      <div class="w-1/2 border-r border-Light-Gray-3 lg:p-10 sm:p-5 md:p-10">
        <div class="w-full justify-items-center items-center text-center">
          <img class="mx-auto" width="80" height="80"
            [src]="this.strapiApi.getImage(claimSection.data.attributes.claims.motor_icon.data.attributes.url)"
            alt="Car icon">
        </div>
        <div>
          <p class="lg:text-lg md:text-base sm:text-sm font-medium text-black mt-2">
            {{getLang == 'en' ? claimSection.data.attributes.claims.motor_title_en :
            claimSection.data.attributes.claims.motor_title_ar}}
          </p>
        </div>
        <div>
          <p class="lg:text-md md:text-base sm:text-xs  font-normal text-black mt-2">
            {{getLang == 'en' ? claimSection.data.attributes.claims.motor_desc_en :
            claimSection.data.attributes.claims.motor_desc_ar}}
          </p>
        </div>
      </div>
      <div class="w-1/2 lg:p-10 md:p-10  sm:p-5">
        <div class="w-full justify-items-center items-center text-center">
          <img class="mx-auto" width="80" height="80"
            [src]="this.strapiApi.getImage(claimSection.data.attributes.claims.other_claims.data.attributes.url)"
            alt="Car icon">


        </div>
        <div>
          <p class="lg:text-lg md:text-base sm:text-sm font-medium text-black mt-2">
            {{getLang == 'en' ? claimSection.data.attributes.claims.other_title_en :
            claimSection.data.attributes.claims.other_title_ar}}

          </p>
        </div>
        <div>
          <p class="lg:text-md md:text-base sm:text-xs   font-normal text-black mt-2">
            {{getLang == 'en' ? claimSection.data.attributes.claims.other_desc_en :
            claimSection.data.attributes.claims.other_desc_ar}}

          </p>
        </div>
      </div>
    </div>
  </div>
</div>