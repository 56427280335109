import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { Make } from 'src/app/models/make';
import { Model } from 'src/app/models/model';
import { Plan } from 'src/app/models/plan';
import { Validations } from 'src/app/models/validations';
import { MakemodelResolver } from 'src/app/services/resolvers/makemodel.resolver';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-motor-inputs',
  templateUrl: './motor-inputs.component.html',
  styleUrls: ['./motor-inputs.component.css'],
})
export class MotorInputsComponent implements OnInit {
  isSubmitted = false;
  validateEmail = true;
  accepted: boolean = false;
  captcharesolved = false;
  motorForm: any;
  loading: boolean = false;
  makes: Array<Make> = [];
  models: Array<Model> = [];
  validations!: Validations;
  years: Array<string> = [new Date().getFullYear().toString(), (new Date().getFullYear() - 1).toString(), (new Date().getFullYear() - 2).toString(), (new Date().getFullYear() - 3).toString(), (new Date().getFullYear() - 4).toString(), (new Date().getFullYear() - 5).toString(), (new Date().getFullYear() - 6).toString()];
  selectedMake: Make | undefined = undefined;
  selectedModel: string = '';
  selectedYear: string = (new Date().getFullYear()).toString();
  Image: string = '';

  constructor(
    private webApi: WebapiService,
    private router: Router,
    private route: ActivatedRoute,
    public strapiApi: StrapiApiService,
    public makeRes: MakemodelResolver
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {
      this.makeRes.resolve(this.route.snapshot, this.router.routerState.snapshot).subscribe((data) => {
        this.makes = data;
      });
      //this.makes = ;
      this.validations = params['val'];
      //this.Image = params['data'].attributes.Image.data.attributes.url;
    });
    this.motorForm = new FormGroup({
      email: new FormControl(''),
      sumInsured: new FormControl(''),
      phone: new FormControl(''),
      make: new FormControl(this.selectedMake),
      model: new FormControl(this.selectedModel),
    });
    // console.log("test " + this.motorForm.get('sumInsured').value)
    this.motorForm.reset();
  }

  checking() {
    this.isSubmitted = true;
    // console.log("checking " + this.motorForm.get('sumInsured').value);
    // console.log("makes " + this.makes);
    // console.log("makes selected " + this.selectedMake?.Make);
    // console.log("model selected " + this.selectedModel);
    this.loading = true;
    var ye = new Date().getFullYear().toString();
    var ya = (new Date().getFullYear() - 1).toString();
    // console.log(ye);
    // console.log(ya);
    if (this.motorForm.valid) {
      this.webApi.sendProductLead(
        'Customer',
        this.motorForm.get('phone').value.toString(),
        'Motor',
        'New Insurance - Motor',
        this.motorForm.get('email').value.toString(),
        1,
        1,
        'New Motor Insurance Lead',
        'Care Details:\n' + this.selectedMake?.Make + '\n' + this.selectedModel + '\n' + this.selectedYear + '\n' + 'Sum Insured: ' + this.motorForm.get('sumInsured').value.toString(),
        null
      ).subscribe((s) => {
        console.log(s);
        this.encryptValuesAndSendToPortal();

      });
    }
  }

  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }



  encryptValuesAndSendToPortal() {
    // const secretKey = 'Solid@abu1999MotorInsurance';
    // // Get the form data
    // const formData = this.motorForm.value;

    // Object.keys(formData).forEach(key => {
    //   if (formData[key] === null || formData[key] === undefined) {
    //     formData[key] = '';
    //   }
    // });
    // if (this.selectedMake != undefined) {
    //   formData['make'] = this.selectedMake.Code;
    // } else {
    //   formData['make'] = '';
    // }
    // formData['sumInsured'] = this.motorForm.get('sumInsured').value.toString();
    // formData['model'] = this.selectedModel.toString();
    // formData['year'] = this.selectedYear.toString();
    // // Convert the form data to a JSON string
    // const jsonString = JSON.stringify(formData);

    // // Encrypt the JSON string with DES
    // const encrypted = CryptoJS.DES.encrypt(jsonString, secretKey).toString();
    // var queryString = Object.keys(formData)
    //   .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(encrypted)}`)
    //   .join('&');
    //const queryString = `data=${encodeURIComponent(encrypted)}`;
    var url = environment.portalUrl + `/new-motor-policy?w=true`;
    window.location.href = url;
    // console.log('Data: ' + jsonString);
  }

  async getQuote(form: NgForm): Promise<void> {
    let party = 'FALCON';
    let plans: Array<Plan> = [];
    // console.log(form);
    if (form.valid) {
      this.loading = true;
      // console.log(form);
      if (form.value['under25'] == true) {
        party = 'UNDERAGE';
      }
      //TP
      if (form.value['loan'] != true) {
        // this.webApi
        //   .getMotorQuote(
        //     form.value['make'],
        //     form.value['model'],
        //     form.value['year'],
        //     form.value['sumInsured'],
        //     'TP',
        //     party,
        //     null,
        //     null
        //   );
        let tpQ = await lastValueFrom(this.webApi
          .getMotorQuote(
            form.value['make'],
            form.value['model'],
            form.value['year'],
            form.value['sumInsured'],
            'TP',
            party,
            null,
            null
          ));
        let tp: Plan = {
          Name: 'Flexi',
          Code: 'FL',
          Icon: 'flexi icon',
          Descreption: 'flexi is flexible',
          Color: 'bg-Primary-200',
          Quote: tpQ,
          Benefits: [
            { Title: 'Agency Repair', Descreption: '', Value: '' },
            { Title: 'Loss or Damage of Vehicle', Descreption: '', Value: '' },
            { Title: 'Third Party Property Damage', Descreption: '', Value: '' },
            { Title: 'Third Party Bodily Injury', Descreption: '', Value: '' },
            { Title: 'Emergency Treatment Cover', Descreption: '', Value: '' },
            { Title: 'Towing Cover', Descreption: '', Value: '' },
            { Title: 'Depreciation on Parts', Descreption: '', Value: '' },
            { Title: 'Personal Accident Benefit to Driver', Descreption: '', Value: '' },
            { Title: 'Car Replacement', Descreption: '', Value: '' },
            { Title: 'Geographical Extention', Descreption: '', Value: '' },
            { Title: 'Roadside Assistance', Descreption: '', Value: '' },
            { Title: 'Act of God', Descreption: '', Value: '' },
            { Title: 'VIP', Descreption: '', Value: '' },
            { Title: 'Windscreen', Descreption: '', Value: '' },
            { Title: 'Riot, Strike and Malicious Damage', Descreption: '', Value: '' },
            { Title: 'Self Repair Authority', Descreption: '', Value: '' },
            { Title: 'Life Insurance', Descreption: '', Value: '' },
          ],
        };
        plans.push(tp);
      }
      // PL
      if (form.value['year'] > (moment(moment.now()).year() - 5)) {
        let plQ = await lastValueFrom(this.webApi
          .getMotorQuote(
            form.value['make'],
            form.value['model'],
            form.value['year'],
            form.value['sumInsured'],
            'PL',
            party,
            null,
            null
          ));

        let pl: Plan = {
          Name: 'Plat',
          Code: 'PL',
          Icon: 'plat icon',
          Descreption: 'plat is plat',
          Color: 'bg-Primary-200',
          Quote: plQ,
          Benefits: [
            { Title: 'Agency Repair', Descreption: '', Value: '' },
            { Title: 'Loss or Damage of Vehicle', Descreption: '', Value: '' },
            { Title: 'Third Party Property Damage', Descreption: '', Value: '' },
            { Title: 'Third Party Bodily Injury', Descreption: '', Value: '' },
            { Title: 'Emergency Treatment Cover', Descreption: '', Value: '' },
            { Title: 'Towing Cover', Descreption: '', Value: '' },
            { Title: 'Depreciation on Parts', Descreption: '', Value: '' },
            { Title: 'Personal Accident Benefit to Driver', Descreption: '', Value: '' },
            { Title: 'Car Replacement', Descreption: '', Value: '' },
            { Title: 'Geographical Extention', Descreption: '', Value: '' },
            { Title: 'Roadside Assistance', Descreption: '', Value: '' },
            { Title: 'Act of God', Descreption: '', Value: '' },
            { Title: 'VIP', Descreption: '', Value: '' },
            { Title: 'Windscreen', Descreption: '', Value: '' },
            { Title: 'Riot, Strike and Malicious Damage', Descreption: '', Value: '' },
            { Title: 'Self Repair Authority', Descreption: '', Value: '' },
            { Title: 'Life Insurance', Descreption: '', Value: '' },


          ],
        };
        plans.push(pl);
      }
      // GO
      if (form.value['year'] > (moment(moment.now()).year() - 5)) {
        let goQ = await lastValueFrom(this.webApi
          .getMotorQuote(
            form.value['make'],
            form.value['model'],
            form.value['year'],
            form.value['sumInsured'],
            'GO',
            party,
            null,
            null
          ));

        let go: Plan = {
          Name: 'Gold',
          Code: 'GO',
          Icon: 'gold icon',
          Descreption: 'gold is gold',
          Color: 'bg-Primary-200',
          Quote: goQ,
          Benefits: [
            { Title: 'Agency Repair', Descreption: '', Value: '' },
            { Title: 'Loss or Damage of Vehicle', Descreption: '', Value: '' },
            { Title: 'Third Party Property Damage', Descreption: '', Value: '' },
            { Title: 'Third Party Bodily Injury', Descreption: '', Value: '' },
            { Title: 'Emergency Treatment Cover', Descreption: '', Value: '' },
            { Title: 'Towing Cover', Descreption: '', Value: '' },
            { Title: 'Depreciation on Parts', Descreption: '', Value: '' },
            { Title: 'Personal Accident Benefit to Driver', Descreption: '', Value: '' },
            { Title: 'Car Replacement', Descreption: '', Value: '' },
            { Title: 'Geographical Extention', Descreption: '', Value: '' },
            { Title: 'Roadside Assistance', Descreption: '', Value: '' },
            { Title: 'Act of God', Descreption: '', Value: '' },
            { Title: 'VIP', Descreption: '', Value: '' },
            { Title: 'Windscreen', Descreption: '', Value: '' },
            { Title: 'Riot, Strike and Malicious Damage', Descreption: '', Value: '' },
            { Title: 'Self Repair Authority', Descreption: '', Value: '' },
            { Title: 'Life Insurance', Descreption: '', Value: '' },


          ],
        };
        plans.push(go);

      }
      //FL
      if (form.value['year'] >= (moment(moment.now()).year() - 10)) {
        let flQ = await lastValueFrom(this.webApi
          .getMotorQuote(
            form.value['make'],
            form.value['model'],
            form.value['year'],
            form.value['sumInsured'],
            'FL',
            party,
            null,
            null
          ));

        let fl: Plan = {
          Name: 'Flexi',
          Code: 'FL',
          Icon: 'flexi icon',
          Descreption: 'flexi is flexible',
          Color: 'bg-Primary-200',
          Quote: flQ,
          Benefits: [
            { Title: 'Agency Repair', Descreption: '', Value: '' },
            { Title: 'Loss or Damage of Vehicle', Descreption: '', Value: '' },
            { Title: 'Third Party Property Damage', Descreption: '', Value: '' },
            { Title: 'Third Party Bodily Injury', Descreption: '', Value: '' },
            { Title: 'Emergency Treatment Cover', Descreption: '', Value: '' },
            { Title: 'Towing Cover', Descreption: '', Value: '' },
            { Title: 'Depreciation on Parts', Descreption: '', Value: '' },
            { Title: 'Personal Accident Benefit to Driver', Descreption: '', Value: '' },
            { Title: 'Car Replacement', Descreption: '', Value: '' },
            { Title: 'Geographical Extention', Descreption: '', Value: '' },
            { Title: 'Roadside Assistance', Descreption: '', Value: '' },
            { Title: 'Act of God', Descreption: '', Value: '' },
            { Title: 'VIP', Descreption: '', Value: '' },
            { Title: 'Windscreen', Descreption: '', Value: '' },
            { Title: 'Riot, Strike and Malicious Damage', Descreption: '', Value: '' },
            { Title: 'Self Repair Authority', Descreption: '', Value: '' },
            { Title: 'Life Insurance', Descreption: '', Value: '' },


          ],
        };
        plans.push(fl);

      }
      this.router.navigate(['quote/motor'], { state: { plans: plans } });

    }
  }
  makeSelected(value: string) {
    this.selectedMake = this.makes.find((f) => f.Code == value);
    // console.log("SELECTED MAKE ");
    // console.log(this.selectedMake);
    this.selectedModel = '';
    this.motorForm.make = this.selectedMake?.Code;
  }

  modelSelected(value: string) {
    this.selectedModel = value;
    // console.log("value is: " + this.selectedModel);
  }

  yearSelected(value: string) {
    this.selectedYear = value;
    // console.log("value is: " + this.selectedYear);
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}


export interface Image {
  data: Data2
}

export interface Data2 {
  id: number
  attributes: Attributes2
}

export interface Attributes2 {
  name: string
  alternativeText: string
  caption: string
  width: number
  height: number
  formats: Formats
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  previewUrl: any
  provider: string
  provider_metadata: any
  createdAt: string
  updatedAt: string
}

export interface Formats {
  large: Large
  small: Small
  medium: Medium
  thumbnail: Thumbnail
}

export interface Large {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
}

export interface Small {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
}

export interface Medium {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
}

export interface Thumbnail {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
}
