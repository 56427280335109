import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlansDetail, Product } from 'src/app/models/product';
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, AfterViewInit {

  benefitsDropDown = false;
  exDropDown = false;
  ex2DropDown = false;
  planDetail: PlansDetail[] = [];
  planName: string = "";
  view = true;
  bTitle1 = "";
  bTitle2 = "";
  bTitle3 = "";
  bTitle1_ar = "";
  bTitle2_ar = "";
  bTitle3_ar = "";


  paddingValue: number = 0;
  @ViewChild('#box')
  box?: ElementRef;
  product?: Product;
  productId?: string;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute, private http: HttpClient) {
    // console.log(route);
  }

  benefitsDrop() {
    this.benefitsDropDown = !this.benefitsDropDown;
  }
  exDrop() {
    this.exDropDown = !this.exDropDown;
  }

  ex2Drop() {
    this.ex2DropDown = !this.ex2DropDown;
  }

  ngAfterViewInit(): void {

    // var height = document.getElementById("box")?.offsetHeight;
    // this.paddingValue = height??0;
    // console.log('Height: ' + height);
    var height = document.getElementById("box")?.offsetHeight;
    if (height != undefined) {
      this.paddingValue = height! - 118;

    }
    // console.log('Height: ' + height);
    // console.log('padding: ' + this.paddingValue);

  }



  async ngOnInit() {
    this.route.data.subscribe(async params => {
      this.product = params['product'];
      this.productId = this.product?.data[0].attributes.identifier;
      console.log(this.productId);
      // console.log('Length: ' + this.product!.data[0].attributes.key_features.length);
    });

    if (this.product!.data[0].attributes.key_descreption == "" || this.product!.data[0].attributes.key_descreption == null) {
      // console.log("NULL: Product is " + this.product!.data[0].attributes.key_descreption);
      document.getElementById("Features_Section")?.classList.add("hidden");
    } else {
      // console.log("NOT: Product is " + this.product!.data[0].attributes.key_descreption);
      document.getElementById("Features_Section")?.classList.remove("hidden");
    }


    if (this.productId?.toLowerCase() == 'motor') {
      this.bTitle1 = "Flexi";
      this.bTitle2 = "Gold";
      this.bTitle3 = "Platinum";

      this.bTitle1_ar = "المرن";
      this.bTitle2_ar = "الذهبي";
      this.bTitle3_ar = "البلاتيني";
    }
    else if (this.productId?.toLowerCase() == 'travel') {
      this.bTitle1 = "Silver";
      this.bTitle2 = "Gold";
      this.bTitle3 = "Platinum";

      this.bTitle1_ar = "الفضي";
      this.bTitle2_ar = "الذهبي";
      this.bTitle3_ar = "البلاتيني";
    }
    else if (this.productId?.toLowerCase() == 'medical') {
      this.bTitle1 = "Salamah Flex";
      this.bTitle2 = "Salamh Plus";
      this.bTitle3 = "Salamah Lux";

      this.bTitle1_ar = "سلامة فليكس";
      this.bTitle2_ar = "سلامة بلس";
      this.bTitle3_ar = "سلامة لوكس";
    }

    // console.log(this.getPlans("motor"));
    if (this.productId != undefined) {
      this.getPlans(this.productId).subscribe((data) => {
        this.planDetail = data;
        console.log("Plan is", this.planDetail);
      });
    }

  }

  get getLang(): any {
    return localStorage?.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  changeView() {
    // alert("Changing View...");
    this.view = !this.view;
  }

  getPlans(type: string) {
    return this.GetStrapi('/api/plans?populate=deep').pipe(map((data) => {
      var list: PlansDetail[] = [];
      var plan = data.data.find(
        (z: { attributes: { identifier: string; }; }) => z.attributes.identifier.toLowerCase() == type.toLowerCase()
      );
      if (plan) {
        var locale = plan.attributes.locale;
        for (var i = 0; i < plan.attributes.plan_details.length; i++) {
          var item = plan.attributes.plan_details[i];
          list.push({
            benefits: item['benefits_' + locale],
            benefits_desc: item['benefits_desc' + locale],
            plat: item['plat_' + locale],
            gold: item['gold_' + locale],
            third_party: item['third_party_' + locale],
            flexi: item['flexi_' + locale],

            benefits_ar: item['benefits_ar'],
            benefits_desc_ar: item['benefits_desc_ar'],
            plat_ar: item['plat_ar'],
            gold_ar: item['gold_ar'],
            flexi_ar: item['flexi_ar'],
            third_party_ar: item['third_party_ar'],
          });
        }
      }
      console.log("List Returned is:", list);

      return list;
    }))
  }
  GetStrapi(url: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.strapiUrl}` + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
