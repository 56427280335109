import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyPremium } from 'src/app/models/policy-premium';

@Component({
  selector: 'app-home-quote',
  templateUrl: './home-quote.component.html',
  styleUrls: ['./home-quote.component.css']
})
export class HomeQuoteComponent implements OnInit {

  step: number = 1;
  quote:PolicyPremium | undefined = undefined;
  constructor(private route: ActivatedRoute, private router:Router) {
    let nav = this.router.getCurrentNavigation()?.extras.state;
    if( nav!= undefined){
      this.quote = nav["quote"];
      // console.log(nav["quote"]);
      // console.log("hi i'm here")
    }
   
   }

  ngOnInit(): void {
  }

}
