import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { Branches } from 'src/app/models/branches';
import { StrapiApiService } from '../strapi-api.service';

@Injectable({
  providedIn: 'root'
})
export class BranchesResolver  {
  constructor(private strapiAPi: StrapiApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.strapiAPi.getBranches().pipe(catchError((error)=> {console.log(error); return of('No Data')}));;
    
  }
}
