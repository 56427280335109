<div class="w-full container my-9 mx-4">
  <p class="font-medium text-2xl">Quick Renewal</p>
  <p class="mt-2">Fill in your information, and we’ll take care of the rest</p>
  <div class="flex w-full">
    <div class="w-8/12">
      <div class="mt-10 flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2514_46014)">
            <path
              d="M12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2ZM18 11.09C18 15.09 15.45 18.79 12 19.92C8.55 18.79 6 15.1 6 11.09V6.39L12 4.14L18 6.39V11.09Z"
              fill="#00719A" />
          </g>
          <defs>
            <clipPath id="clip0_2514_46014l">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p class="ml-4 text-primary-500 font-medium">SELECT A PLAN</p>
      </div>
      <div class="grid grid-rows-1 grid-cols-5 gap-2 mt-5">
        <app-select-plan-box *ngFor="let plan of this.plans" [plan]="plan"
          [callbackFunction]="this.planSelectedCallbackFunction"></app-select-plan-box>
      </div>

      <div>
        <div class="w-full flex mt-10">
          <accordion [collapsing]="collapsing">
            <accordion-item>
              <ng-template accordionTitle>
                <p class="font-medium text-sm text-Dark-Gray-2">
                  Compare Plans
                </p>
              </ng-template>
              <ng-template accordionContent>
                <div class="bg-Light-Gray-5 p-5">
                  <p class="text-sm text-black mb-5">
                    See how our plans compare with one another
                  </p>
                  <!-- This example requires Tailwind CSS v2.0+ -->

                  <div class="mt-5 border border-Light-Gray-2 rounded-md overflow-hidden">
                    <table class="w-full divide-y divide-Light-Gray-3 rounded-md border-none  border-Mid-Gray-2">
                      <thead class="bg-white">
                        <tr>
                          <th scope="col"
                            class="py-3 pl-4 pr-3 text-left text-sm font-medium uppercase w-full max-w-[30%] ">
                            Benefits
                          </th>
                          <th scope="col" class="px-3 py-3 text-left text-sm font-medium  text-Dark-Gray-2">
                            <div class="flex items-center">
                              <svg class="" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="9" fill="#B0BABF" />
                              </svg>
                              <p class="ml-3">Third Party</p>
                            </div>
                          </th>
                          <th scope="col"
                            class="px-3 py-3 text-left text-sm font-medium tracking-wide text-Dark-Gray-2">
                            <div class="flex items-center">
                              <svg class="" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="9" fill="#B2D97E" />
                              </svg>
                              <p class="ml-3">Flexi</p>
                            </div>
                          </th>
                          <th scope="col"
                            class="px-3 py-3 text-left text-sm font-medium tracking-wide text-Dark-Gray-2">
                            <div class="flex items-center">
                              <svg class="" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="9" fill="#F8C51B" />
                              </svg>
                              <p class="ml-3">Gold</p>
                            </div>
                          </th>
                          <th scope="col"
                            class="px-3 py-3 text-left text-sm font-medium tracking-wide text-Dark-Gray-2">
                            <div class="flex items-center">
                              <svg class="" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="9" fill="#55C6EF" />
                              </svg>
                              <p class="ml-3">Platinium</p>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-Light-Gray-3  bg-white">
                        <tr>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            Agency Repair
                            <p class="text-Mid-Gray-3 text-xs">
                              (from the first date of registration in the
                              General Directorate of Traffic)
                            </p>
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            -
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            3 Years
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            5 Years
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            10 Years
                          </td>
                        </tr>
                        <tr>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            Agency Repair
                            <p class="text-Mid-Gray-3 text-xs">
                              (from the first date of registration in the
                              General Directorate of Traffic)
                            </p>
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            -
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            3 Years
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            5 Years
                          </td>
                          <td class="py-4 pl-4 pr-3 text-sm text-Dark-Gray-2">
                            10 Years
                          </td>
                        </tr>

                        <!-- More people... -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-template>
            </accordion-item>
            <accordion-item>
              <ng-template accordionTitle>
                <p class="font-medium text-sm text-Dark-Gray-2">
                  Policy Benefits & exclusions
                </p>
              </ng-template>
              <ng-template accordionContent>
                <div class="bg-Light-Gray-5 p-5">
                  <p class="text-sm text-black mb-5 w-2/3">
                    Motor insurance offers great value and a piece of mind a for you. We have devised a number of
                    options for you to choose from as summarized in the benefits and exclusion below.
                  </p>
                  <!-- This example requires Tailwind CSS v2.0+ -->

                  <div class="mt-5">

                  </div>
                </div>
              </ng-template>
            </accordion-item>
          </accordion>
        </div>
      </div>

      <div class="mt-10">
        <button class="button w-[130px] h-10 bg-primary-500 text-Light-Gray-5 py-2 rounded-md text-center text-sm"
          (click)="nextClicked()">
          Next
        </button>
      </div>
    </div>
    <div class="w-4/12">
      <div class="p-8 w-full text-center border rounded-md border-Light-Gray-4 shadow-2xl divide-y divide-Light-Gray-3">
        <div class="mb-4">
          <p class="font-medium text-sm text-Dark-Gray-2">
            Total Price (BHD)
          </p>
          <p class="font-medium text-4xl text-Mid-Gray-1 mt-4">{{this.totalPremium}}</p>
        </div>
        <div class="pt-4">
          <div class="flex justify-center">
            <p class="text-xs text-Dark-Gray-2 mr-3">Price (BHD)</p>
            <p class="text-xs text-Dark-Gray-2">{{this.premium}}</p>
          </div>
          <div class="flex justify-center mt-2">
            <p class="text-xs text-Dark-Gray-2 mr-3">VAT 10%</p>
            <p class="text-xs text-Dark-Gray-2">{{this.tax}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>