<div class="border rounded border-Light-Gray-2 p-4 max-w-sm">
    <div class="w-full text-right">
      <input
        id=""
        name="plan"
        type="radio"
        (change)="this.palnClicked()"
        class="form-radio focus:ring-primary-300 focus:ring-4 text-primary-500 focus:border-primary-500 focus:ring-offset-0 focus:bg-primary-500 checked:bg-primary-500 h-4 w-4 text-indigo-600 border-Mid-Gray-5 bg-Light-Gray-5"
      />
    </div>
    <div class="w-full flex justify-center">
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M38.959 35.6074C38.959 37.498 38.4434 39.3027 37.498 40.8496C37.1543 41.3652 36.8105 41.8809 36.4668 42.3105C35.6074 43.3418 34.5762 44.1152 33.373 44.7168C32.8574 44.9746 32.3418 45.2324 31.7402 45.4043C30.7949 45.7481 29.7637 45.9199 28.6465 45.9199C22.9746 45.9199 18.334 41.2793 18.334 35.6074C18.334 34.4902 18.5059 33.459 18.8496 32.4277C19.0215 31.8262 19.2793 31.3105 19.5371 30.7949C21.2559 27.5293 24.6934 25.209 28.7324 25.209C34.3184 25.2949 38.959 29.9355 38.959 35.6074Z" fill="#9AA6AC"/>
            <path d="M48.5491 47.0904L47.9742 48.128L50.5712 49.5572L51.1461 48.5195L48.5491 47.0904ZM9.03633 45.6612L6.4393 47.0959L7.01422 48.1336L9.61125 46.6989L9.03633 45.6612ZM43.355 44.2265L42.7857 45.2642L45.3767 46.6933L45.9516 45.6556L43.355 44.2265ZM14.2304 42.7978L11.6334 44.2325L12.2083 45.2702L14.8053 43.8355L14.2304 42.7978ZM38.167 41.3566L37.5916 42.4003L40.1887 43.8294L40.758 42.7918L38.167 41.3566ZM19.4184 39.9339L16.8274 41.363L17.3968 42.4068L19.9938 40.972L19.4184 39.9339ZM32.9729 38.4927L32.3976 39.5364L34.9946 40.9656L35.57 39.9279L32.9729 38.4927ZM24.6125 37.07L22.0155 38.4992L22.5908 39.5429L25.1879 38.1082L24.6125 37.07ZM30.3759 37.064L29.8066 38.1017L27.1696 36.6429L26.907 36.7852V35.06H28.093V35.8012L30.3759 37.064ZM27.5 3.63477L0 51.3653H55L27.5 3.63477ZM4.37594 49.5864L4.41762 49.5628L3.84828 48.5251L3.61711 48.6557L26.907 8.22984V8.37809H28.093V8.22984L51.9226 49.5864H4.37594ZM26.907 29.1307V32.0955H28.093V29.1307H26.907ZM26.907 23.2014V26.1663H28.093V23.2014H26.907ZM26.907 17.2722V20.237H28.093V17.2722H26.907ZM26.907 11.3429V14.3078H28.093V11.3429H26.907Z" fill="#303940"/>
            </svg>
            
    </div>
    <p class="mt-5 text-Dark-Gray-2 text-lg font-medium text-center">{{this.plan?.Name}}</p>
    <p class="mt-[10px] text-Mid-Gray-3 text-xs font-normal text-center">
        {{this.plan?.Descreption}}
    </p>
  </div>
  