import { Component, OnInit } from '@angular/core';
import disableScroll from 'disable-scroll';
import { HostListener } from '@angular/core';
import { Attributes, Box, Product } from 'src/app/models/home-page'
import { StrapiApiService } from 'src/app/services/strapi-api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ChannelGuides } from 'src/app/models/guide';
import { Claim } from 'src/app/models/claim';
import { ClaimSection } from 'src/app/models/claim-section';


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css']
})
export class ClaimComponent implements OnInit {
  channelGuides!: ChannelGuides;
  claims!: Claim;
  claimsSection!: ClaimSection;
  constructor(public strapiApi: StrapiApiService, private route: ActivatedRoute, private router: Router) { }

  async ngOnInit() {
    this.route.data.subscribe(async params => {

      this.claims = params['claims']

    });
  }
  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

}
