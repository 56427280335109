import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { VideoPage } from 'src/app/models/video-page';
import { StrapiApiService } from '../strapi-api.service';

@Injectable({
  providedIn: 'root'
})
export class VideoPageResolver  {
  constructor(private strapiAPi: StrapiApiService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.strapiAPi.getVidepPages().pipe(catchError((error)=> {console.log(error); return of('No Data')}));
  }
}
