<div class="w-full container my-9 mx-4">
  <p class="font-medium text-2xl">{{ this.quoteType }} Quote</p>
  <p class="mt-2">Fill in your information, and we’ll take care of the rest</p>
  <div class="flex w-full">
    <div class="w-8/12">
      <div class="mt-10 flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2514_46014)">
            <path
              d="M12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2ZM18 11.09C18 15.09 15.45 18.79 12 19.92C8.55 18.79 6 15.1 6 11.09V6.39L12 4.14L18 6.39V11.09Z"
              fill="#00719A" />
          </g>
          <defs>
            <clipPath id="clip0_2514_46014l">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p class="ml-4 text-primary-700 font-medium">GET ISNURANCE POLICY</p>
      </div>
      <div class="mt-5">
        <p class="text-sm text-justify">
          If you want to proceed with the Motor Insurance Policy,<br />
          you will need to login or create an account.
        </p>
      </div>
      <div class="mt-10 flex">
        <button class="button w-[130px] h-10 bg-primary-500 text-Light-Gray-5 py-2 rounded-md text-center text-sm">
          Login
        </button>
        <button
          class="button w-[170px] h-10 bg-white ml-3 text-Dark-Gray-2 py-2 rounded-md border text-center text-sm border-Light-Gray-2">
          Create an account
        </button>
      </div>
      <div class="mt-14">
        <p class="text-sm text-justify">
          Or if you would like a sales member to contact you, enter your email
          below and we will get back to you.
        </p>
      </div>
      <div class="flex mt-5">
        <div class="flex-1 mr-2">
          <label for="email" class="block text-sm font-normal ml-1 mb-2">Name</label>
          <div class="relative">
            <input type="email" id="email" name="mobile"
              class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm focus:border-Mid-Gray-1 focus:ring-Mid-Gray-1"
              required aria-describedby="email-error" />
          </div>
        </div>
        <div class="flex-1">
          <label for="email" class="block text-sm font-normal ml-1 mb-2">Mobile Number</label>
          <div class="relative">
            <input type="email" id="email" name="mobile"
              class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm focus:border-Mid-Gray-1 focus:ring-Mid-Gray-1"
              required aria-describedby="email-error" />
          </div>
        </div>
      </div>
      <div class="flex mt-14">
        <div class="flex-1">
          <label for="email" class="block text-sm font-normal ml-1 mb-2">Email address</label>
          <div class="relative">
            <input type="email" id="email" name="email"
              class="h-10 py-3 px-4 block w-full border-1 border-Light-Gray-2 rounded-md text-sm focus:border-Mid-Gray-1 focus:ring-Mid-Gray-1"
              required aria-describedby="email-error" />
          </div>
        </div>
      </div>
      <div class="mt-10">
        <button class="button w-[130px] h-10 bg-primary-500 text-Light-Gray-5 py-2 rounded-md text-center text-sm">
          Contact Me
        </button>
      </div>
    </div>

    <div class="w-4/12">
      <div class="p-8 w-full text-center border rounded-md border-Light-Gray-4 shadow-2xl divide-y divide-Light-Gray-3">
        <div class="mb-4">
          <p class="font-medium text-sm text-Dark-Gray-2">
            Total Price (BHD)
          </p>
          <p class="font-medium text-4xl text-Mid-Gray-1 mt-4">{{this.totalPremium}}</p>
        </div>
        <div class="pt-4">
          <div class="flex justify-center">
            <p class="text-xs text-Dark-Gray-2 mr-3">Price (BHD)</p>
            <p class="text-xs text-Dark-Gray-2">{{this.premium}}</p>
          </div>
          <div class="flex justify-center mt-2">
            <p class="text-xs text-Dark-Gray-2 mr-3">VAT 10%</p>
            <p class="text-xs text-Dark-Gray-2">{{this.tax}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>