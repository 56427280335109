<div class="border rounded border-Light-Gray-2 p-4 max-w-sm">
  <div class="w-full text-right">
    <input
      id=""
      name="policy"
      type="radio"
      class="form-radio focus:ring-primary-300 focus:ring-4 text-primary-500 focus:border-primary-500 focus:ring-offset-0 focus:bg-primary-500 checked:bg-primary-500 h-4 w-4 text-indigo-600 border-Mid-Gray-5 bg-Light-Gray-5"
    />
  </div>
  <div>
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M38.6719 14.6094C38.6719 16.5 38.1562 18.3047 37.2109 19.8516C36.8672 20.3672 36.5234 20.8828 36.1797 21.3125C35.3203 22.3438 34.2891 23.1172 33.0859 23.7188C32.5703 23.9766 32.0547 24.2344 31.4531 24.4063C30.5078 24.75 29.4766 24.9219 28.3594 24.9219C22.6875 24.9219 18.0469 20.2813 18.0469 14.6094C18.0469 13.4922 18.2188 12.4609 18.5625 11.4297C18.7344 10.8281 18.9922 10.3125 19.25 9.79688C20.9687 6.53125 24.4062 4.21094 28.4453 4.21094C34.0312 4.29688 38.6719 8.9375 38.6719 14.6094Z"
          fill="#FA8D7F"
        />
      </g>
      <path
        d="M15.2109 46.4062H11.3438C9.79688 46.4062 8.50781 45.1172 8.50781 43.5703V38.6719C8.50781 38.1562 8.85156 37.8125 9.36719 37.8125H17.1016C17.6172 37.8125 17.9609 38.1562 17.9609 38.6719V43.5703C18.0469 45.1172 16.7578 46.4062 15.2109 46.4062ZM10.3125 39.5312V43.5703C10.3125 44.1719 10.8281 44.6875 11.4297 44.6875H15.2969C15.8984 44.6875 16.4141 44.1719 16.4141 43.5703V39.5312H10.3125Z"
        fill="black"
      />
      <path
        d="M35.75 39.5312H33.5156C33.2578 39.5312 33.0859 39.4453 32.9141 39.2734L32.3125 38.6719H22.6875L22.0859 39.2734C21.9141 39.4453 21.7422 39.5312 21.4844 39.5312H9.45312C7.5625 39.5312 6.01562 37.9844 6.01562 36.0938V27.8438C6.01562 26.8984 6.35938 25.9531 6.96094 25.1797L9.53906 21.9141L12.6328 12.7188C13.4922 10.2266 15.7266 8.59375 18.3047 8.59375H36.6953C39.2734 8.59375 41.5937 10.2266 42.3672 12.7188L45.4609 21.9141L48.0391 25.1797C48.4688 25.6953 48.8125 26.3828 48.8984 27.0703C48.9844 27.3281 48.8125 27.6719 48.6406 27.8438C48.3828 28.0156 48.125 28.1016 47.8672 28.0156L42.9687 26.6406L36.0937 28.6172V35.3203C36.0937 36.3516 36.2656 37.3828 36.6094 38.3281C36.6953 38.5859 36.6094 38.8438 36.5234 39.1016C36.4375 39.3594 36.0078 39.5312 35.75 39.5312ZM33.8594 37.8125H34.6328C34.4609 37.0391 34.375 36.1797 34.375 35.3203V27.9297C34.375 27.5859 34.6328 27.2422 34.9766 27.0703L42.7109 24.8359C42.8828 24.75 43.0547 24.75 43.1406 24.8359L46.3203 25.7812L43.9141 22.7734C43.8281 22.6875 43.8281 22.6016 43.7422 22.5156L40.6484 13.1484C40.0469 11.4297 38.4141 10.2266 36.6094 10.2266H18.3047C16.4141 10.2266 14.7812 11.4297 14.2656 13.1484L11.1719 22.5156C11.1719 22.6016 11.0859 22.6875 11 22.7734L8.33594 26.125C7.90625 26.7266 7.73437 27.2422 7.73437 27.8438V36.0938C7.73437 37.0391 8.50781 37.8125 9.45312 37.8125H21.1406L21.7422 37.2109C21.9141 37.0391 22.0859 36.9531 22.3437 36.9531H32.6562C32.9141 36.9531 33.0859 37.0391 33.2578 37.2109L33.8594 37.8125Z"
        fill="black"
      />
      <path
        d="M17.1875 35.2344H12.0312C10.5703 35.2344 9.45312 34.1172 9.45312 32.6562V30.9375C9.45312 29.4766 10.5703 28.3594 12.0312 28.3594H12.8906H12.9766L17.4453 29.0469C18.7344 29.2187 19.6797 30.3359 19.6797 31.625V32.6562C19.7656 34.1172 18.6484 35.2344 17.1875 35.2344ZM12.0312 30.0781C11.5156 30.0781 11.1719 30.4219 11.1719 30.9375V32.6562C11.1719 33.1719 11.5156 33.5156 12.0312 33.5156H17.1875C17.7031 33.5156 18.0469 33.1719 18.0469 32.6562V31.625C18.0469 31.1953 17.7031 30.8516 17.2734 30.7656L12.8047 30.0781H12.0312Z"
        fill="#FA8D7F"
      />
      <path
        d="M31.9685 35.2344H23.031C22.1716 35.2344 21.4841 34.6328 21.3122 33.8594L20.7966 31.2813C20.7107 30.7656 20.7966 30.25 21.1404 29.8203C21.4841 29.3906 21.9138 29.2188 22.4294 29.2188H32.3982C32.9138 29.2188 33.4294 29.4766 33.6872 29.8203C34.031 30.25 34.1169 30.7656 34.031 31.2813L33.5154 33.8594C33.5154 34.6328 32.7419 35.2344 31.9685 35.2344ZM31.9685 33.5156V34.375V33.5156ZM22.5154 30.9375L23.031 33.5156H31.9685L32.4841 30.9375H22.5154Z"
        fill="#FA8D7F"
      />
      <path
        d="M38.671 25.7813H16.3273C15.2101 25.7813 14.2648 25.2656 13.5773 24.4063C12.8898 23.5469 12.8038 22.3438 13.1476 21.3125L15.9835 13.6641C16.3273 12.6328 17.2726 11.9453 18.3898 11.9453H36.5226C37.6397 11.9453 38.5851 12.6328 38.9288 13.6641L41.7647 21.2266C42.1944 22.4297 41.9366 23.8047 41.0772 24.6641C40.4757 25.4375 39.6163 25.7813 38.671 25.7813ZM18.3898 13.75C18.046 13.75 17.7023 14.0078 17.6163 14.3516L14.7804 22C14.6085 22.5156 14.6085 23.1172 14.9523 23.4609C15.296 23.8906 15.8116 24.1484 16.3273 24.1484H38.671C39.1007 24.1484 39.5304 23.9766 39.8741 23.6328C40.3038 23.2031 40.3897 22.6016 40.1319 22L37.296 14.4375C37.2101 14.0078 36.9522 13.75 36.6085 13.75H18.3898Z"
        fill="black"
      />
      <path
        d="M48.5547 23.2031H46.8359C45.2031 23.2031 43.8281 21.8281 43.8281 20.1953C43.8281 18.5625 45.2031 17.1875 46.8359 17.1875H48.5547C50.1875 17.1875 51.5625 18.5625 51.5625 20.1953C51.5625 21.8281 50.1875 23.2031 48.5547 23.2031ZM46.8359 18.9062C46.1484 18.9062 45.5469 19.5078 45.5469 20.1953C45.5469 20.8828 46.1484 21.4844 46.8359 21.4844H48.5547C49.2422 21.4844 49.8438 20.8828 49.8438 20.1953C49.8438 19.5078 49.2422 18.9062 48.5547 18.9062H46.8359Z"
        fill="black"
      />
      <path
        d="M8.16406 23.2031H6.44531C4.8125 23.2031 3.4375 21.8281 3.4375 20.1953C3.4375 18.5625 4.8125 17.1875 6.44531 17.1875H8.16406C9.79688 17.1875 11.1719 18.5625 11.1719 20.1953C11.1719 21.8281 9.79688 23.2031 8.16406 23.2031ZM6.44531 18.9062C5.75781 18.9062 5.15625 19.5078 5.15625 20.1953C5.15625 20.8828 5.75781 21.4844 6.44531 21.4844H8.16406C8.85156 21.4844 9.45312 20.8828 9.45312 20.1953C9.45312 19.5078 8.85156 18.9062 8.16406 18.9062H6.44531Z"
        fill="black"
      />
      <path
        d="M42.1094 38.6719C41.8516 38.6719 41.6797 38.5859 41.5078 38.4141L39.3594 36.2656C39.0156 35.9219 39.0156 35.4062 39.3594 35.0625C39.7031 34.7187 40.2188 34.7187 40.5625 35.0625L42.1094 36.6094L45.8047 32.9141C46.1484 32.5703 46.6641 32.5703 47.0078 32.9141C47.3516 33.2578 47.3516 33.7734 47.0078 34.1172L42.7109 38.4141C42.5391 38.5859 42.3672 38.6719 42.1094 38.6719Z"
        fill="#FA8D7F"
      />
      <path
        d="M42.9687 46.4063C42.8828 46.4063 42.7969 46.4063 42.7109 46.4063C41.25 45.9766 39.875 45.2891 38.6719 44.3438C38.1562 43.9141 37.6406 43.3984 37.125 42.8828C36.6094 42.2813 36.1797 41.6797 35.8359 40.9922C35.4922 40.3047 35.1484 39.7031 34.9766 39.0156C34.5469 37.8125 34.375 36.6094 34.375 35.3203V27.9297C34.375 27.5859 34.6328 27.2422 34.9766 27.0703L42.7109 24.8359C42.8828 24.75 43.0547 24.75 43.1406 24.8359L50.875 27.0703C51.2188 27.1563 51.4766 27.5 51.4766 27.9297V35.3203C51.4766 39.4453 49.2422 43.3125 45.6328 45.2891C44.8594 45.7188 44 46.0625 43.1406 46.3203C43.1406 46.4063 43.0547 46.4063 42.9687 46.4063ZM36.0937 28.6172V35.3203C36.0937 36.3516 36.2656 37.3828 36.6094 38.3281C36.7812 38.9297 37.0391 39.4453 37.3828 40.0469C37.7266 40.6484 38.0703 41.1641 38.5 41.6797C38.9297 42.1953 39.3594 42.625 39.7891 42.9688C40.7344 43.7422 41.8516 44.3438 42.9687 44.6875C43.6562 44.5156 44.2578 44.1719 44.8594 43.9141C47.9531 42.1953 49.8437 38.9297 49.8437 35.4063V28.7031L42.9687 26.6406L36.0937 28.6172Z"
        fill="black"
      />
    </svg>
  </div>
  <p class="mt-2 text-Dark-Gray-2 text-lg font-medium">38886</p>
  <p class="mt-2 text-Dark-Gray-2 text-xs font-normal">
    01 May 2022 - 30 April 2023
  </p>
</div>
