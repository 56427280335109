<app-header bg="bg-primary-50"></app-header>
<div class="p-2 w-full bg-primary-50 items-center min-h-[600px] pb-3" dir="auto">
  <div class="mx-auto w-full text-center mt-16 container">
    <div class="text-7xl font-medium text-Dark-Gray-2">
      {{getLang == 'en' ? quick.data.attributes.title_en : quick.data.attributes.title_ar}}
    </div>
    <p class="text-xl text-center w-3/5 mx-auto text-Mid-Gray-1 pt-2">
      {{getLang == 'en' ? quick.data.attributes.desc_en : quick.data.attributes.desc_ar}}
    </p>
  </div>
  <form #myform="ngForm" [formGroup]="renewalForm" (ngSubmit)="submitted()">
    <div class="p-6 mt-14 max-w-3xl mx-auto bg-white rounded-[10px] shadow-lg items-center absolute inset-x-0 z-10 px-6"
      id="box">
      <div class="w-full">
        <p class="text-3xl font-medium text-Dark-Gray-2">
          {{getLang == 'en' ? quick.data.attributes.form_title_en : quick.data.attributes.form_title_ar}}
        </p>
      </div>

      <div class="flex mt-9">
        <div class="flex-1 mr-4">
          <label for="cpr" class="block text-sm font-normal text-Dark-Gray-2 mb-2">{{getLang == 'en' ?
            quick.data.attributes.cpr_text_en : quick.data.attributes.cpr_text_ar}}</label>

          <div class="relative">
            <input type="number" id="cpr" s name="cpr"
              class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
              [ngClass]="myform.form.controls['cpr'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
              required aria-describedby="cpr-error"
              placeholder="{{getLang == 'en' ? 'Enter CPR number or CR number' : 'أدخل الرقم الشخصي أو السجل التجاري'}}"
              formControlName="cpr" />
          </div>
          <mat-error *ngIf="myform.form.controls['cpr'].errors?.['required'] && isSubmitted">
            {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
          </mat-error>
        </div>
        <div class="flex-1">
          <label for="plate" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ?
            quick.data.attributes.mobile_text_en :quick.data.attributes.mobile_text_ar }}</label>
          <div class="relative">
            <input type="number" id="plate" name="plateOrPhone"
              class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm {{getLang == 'ar' ? 'mr-4' : ''}} "
              [ngClass]="myform.form.controls['plateOrPhone'].errors?.['required'] && isSubmitted ? 'errorInput errorInputed' : ''"
              required aria-describedby="plate-error"
              placeholder="{{getLang == 'en' ? 'Enter plate or mobile number' : 'أدخل رقم المركبة أو رقم الهاتف'}}"
              formControlName="plateOrPhone" />
          </div>
          <mat-error *ngIf="myform.form.controls['plateOrPhone'].errors?.['required'] && isSubmitted">
            {{getLang == 'en' ? "Required Field" : "حقل مطلوب"}}
          </mat-error>
        </div>

      </div>
      <div class="flex mt-5 px-2 pt-6">
        <input type="checkbox" name="under25" id="under25"
          class="rounded form-checkbox h-5 w-5 text-primary-500 bg-Light-Gray-5 border border-Mid-Gray-5 focus:ring-primary-300 focus:ring-4 ring-offset-0" />

        <label for="under25" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
          [ngClass]="getLang == 'en' ? 'block' : 'hidden'">I agree to the <a href="termsofuse"
            class="underline underline-offset-2 font-medium">Terms of Use</a> and <a href="privacyandsecurity"
            class="underline underline-offset-2 font-medium">Privacy Policy</a>
        </label>
        <label for="under25" class="block text-sm font-normal ml-2 mb-2 text-Dark-Gray-2 pr-2"
          [ngClass]="getLang == 'ar' ? 'block' : 'hidden'"> أوافق على <a href="termsofuse"
            class="underline underline-offset-2 font-medium">شروط الاستخدام </a> و <a href="privacyandsecurity"
            class="underline underline-offset-2 font-medium">سياسة الخصوصية</a>
        </label>
      </div>
      <div class="flex mt-12">
        <div class="flex-1">
          <div class="w-full px-3 mb-5">
            <button type="submit"
              class="button block w-full bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg">
              {{getLang == 'en' ? "Find My Policies" : "ابحث عن البوليصة "}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="pb-28">

</div>
<app-claim-section></app-claim-section>
<app-coverd-section></app-coverd-section>

<app-footer></app-footer>