import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, catchError } from 'rxjs';
import { ShareholderNews } from 'src/app/models/shareholder-news';
import { StrapiApiService } from '../strapi-api.service';


@Injectable({
  providedIn: 'root'
})
export class ShareHolderNewsResolver  {
  constructor(private strapiAPi: StrapiApiService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.strapiAPi.getShareholderNews().pipe(catchError((error)=> {console.log(error); return of('No Data')}));
  }
}
