import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../models/country';
import { Cover } from '../models/cover';
import { Make } from '../models/make';
import { Nationality } from '../models/nationality';
import { Policy } from '../models/policy';
import { PolicyPremium } from '../models/policy-premium';
@Injectable({
  providedIn: 'root',
})
export class WebapiService {
  apiURL = 'https://api.solidarity.bh';
  // apiURL = 'https://api.staging.solidarity.bh';

  channel = 'web.solidarity';
  constructor(private http: HttpClient) { }
  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  public getImage(url: string): string {
    return environment.apiUrl + url;
  }



  applyPromo(policyNumber: String, code: String): Observable<PolicyPremium> {
    var dataToSend = {
      QuoteNumber: policyNumber,
      Channel: this.channel,
      PromoCode: code,
    };

    return this.http
      .post<PolicyPremium>(environment.apiUrl + '/v1.0/policy/applypromo', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  searchPolicy(cpr: String, plateNumber: String): Observable<Array<Policy>> {
    var dataToSend = {
      CPR: cpr,
      PlateNumber: plateNumber,
    };

    return this.http
      .post<Array<Policy>>(environment.apiUrl + '/v1.0/policy', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  premium(PolicyNumber: String, Channel: String, DefaultCovers: boolean, RegistrationNumber: String, Covers: Cover[]): Observable<Policy> {
    var dataToSend = {
      PolicyNumber: PolicyNumber,
      DefaultCovers: DefaultCovers,
      Channel: Channel,
      RegistrationNumber: RegistrationNumber,
      Covers: Covers
    };

    return this.http
      .post<Policy>(environment.apiUrl + '/v1.0/policy/premium', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  PolicyPay(PolicyNumber: String, Channel: String, Premium: number): Observable<Array<string>> {
    var dataToSend = {
      PolicyNumber: PolicyNumber,
      Premium: Premium,
      Channel: Channel
    };

    return this.http
      .post<Array<string>>(environment.apiUrl + '/v1.0/policy/pay', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  getCovers(): Observable<Array<Cover>> {


    return this.http
      .post<Array<Cover>>(environment.apiUrl + '/v1.0/constants/covers', {})
      .pipe(retry(1), catchError(this.handleError));
  }

  sendCareers(
    Department: String | null,
    Name: String | null,
    AgeRange: String | null,
    MobileNumber: String | null,
    Email: String | null,
    Address: String | null,
    CVLink: String | null,
    dataToSend: Record<string, any> | null | undefined
  ) {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        Department: Department,
        Name: Name,
        AgeRange: AgeRange,
        MobileNumber: MobileNumber,
        Email: Email,
        Address: Address,
        CVLink: CVLink,
        FormType: "Careers"
        // ToEmail: "careers@solidarity.com.bh" // Concerned email for Careers forms
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    return this.http.post<PolicyPremium>(environment.apiUrl + '/v1.0/MailToCompany/emailToCompany', dataToSend).pipe(retry(1), catchError(this.handleError));
    // return this.http.post<PolicyPremium>(this.apiURL + '/v1.0/MailToCompany/emailToCompany', dataToSend).pipe(retry(1), catchError(this.handleError));
  }
  sendComplaint(
    FirstName: String | null,
    PhoneNumber: String | null,
    LastName: String | null,
    Email: String | null,
    Subject: String | null,
    Product: String | null,
    Descreption: String | null,
    ClaimNumber: String | null,
    PolicyNumber: String | null,
    VNumber: String | null,
    dataToSend: Record<string, any> | null | undefined
  ) {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        FirstName: FirstName,
        PhoneNumber: PhoneNumber,
        LastName: LastName,
        Email: Email,
        Subject: Subject,
        Descreption: Descreption,
        Product: Product,
        ClaimNumber: ClaimNumber,
        PolicyNumber: PolicyNumber,
        VNumber: VNumber,
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    return this.http
      .post<PolicyPremium>(environment.apiUrl + '/v1.0/case/complaint', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  contactUs(
    Name: String | null,
    PhoneNumber: String | null,
    Email: String | null,
    Subject: String | null,
    Message: String | null,
    dataToSend: Record<string, any> | null | undefined
  ) {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        Name: Name,
        MobileNumber: PhoneNumber,
        Email: Email,
        Subject: Subject,
        Message: Message,
        FormType: "ContactUs",
        // ToEmail: "customer.service@solidarity.com.bh" // Concerned email for Contact Us forms
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    return this.http
      .post<PolicyPremium>(environment.apiUrl + '/v1.0/MailToCompany/emailToCompany', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  investorRelations(
    FName: String | null,
    LName: String | null,
    PhoneNumber: String | null,
    Email: String | null,
    Subject: String | null,
    Message: String | null,
    dataToSend: Record<string, any> | null | undefined
  ) {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        ToTitle: 'Shareholder Query',
        ToMessage:
          "We have received a shareholder query from our website's investor's relations page. Please find the details below.",
        ToDepartment: 'Compliance',
        FirstName: FName,
        LastName: LName,
        MobileNumber: PhoneNumber,
        Email: Email,
        Subject: Subject,
        Message: Message,
        FormType: 'Plain',
        attachmentLink: [],
        // ToEmail: "legal@solidarity.com.bh" // Concerned email for Contact Us forms
        // ToEmail: 'abdulrahman.janahi@solidarity.com.bh'
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    // return this.http
    //   .post<PolicyPremium>(environment.apiUrl + '/v1.0/MailToCompany/emailToCompany', dataToSend)
    //   .pipe(retry(1), catchError(this.handleError));
    return this.http.post<PolicyPremium>(environment.apiUrl + '/v1.0/MailToCompany/emailToCompany', dataToSend).pipe(retry(1), catchError(this.handleError));

  }

  sendLead(
    FirstName: String | null,
    PhoneNumber: String | null,
    Product: String | null,
    subject: String | null,
    email: String | null,
    customerType: number | null,
    status: number | null,
    type: String | null,
    dataToSend: Record<string, any> | null | undefined
  ) {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        FirstName: FirstName,
        PhoneNumber: PhoneNumber,
        subject: subject,
        Product: Product,
        email: email,
        customerType: customerType,
        status: status,
        productType: type
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    return this.http
      .post<any>(environment.apiUrl + '/v1.0/lead/createlead', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendProductLead(
    FirstName: String | null,
    PhoneNumber: String | null,
    Product: String | null,
    subject: String | null,
    email: String | null,
    customerType: number | null,
    status: number | null,
    type: String | null,
    Info: string | null,
    dataToSend: Record<string, any> | null | undefined
  ) {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        FirstName: FirstName,
        PhoneNumber: PhoneNumber,
        subject: subject,
        Product: Product,
        email: email,
        customerType: customerType,
        status: status,
        productType: type,
        Info: Info
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    return this.http
      .post<any>(environment.apiUrl + '/v1.0/lead/createlead', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  getHomeQuote(
    suminsured: String | null,
    fixValue: String | null,
    contentValue: String | null,
    rentValue: String | null,
    personalValue: String | null,
    party: String | null,
    token: String | null,
    dataToSend: Record<string, any> | null | undefined
  ): Observable<PolicyPremium> {
    if (dataToSend == undefined || dataToSend == null) {
      dataToSend = {
        Channel: this.channel,
        Name: 'Dummy',
        Building: '0000',
        Road: '0000',
        Block: '0000',
        Description: 'Dummy',
        Location: 'Dummy',
        Age: 5,
        SumInsured: Number(suminsured!),
        FixValue: Number(fixValue!),
        ContentValue: Number(contentValue!),
        PersonalValue: Number(personalValue!),
        RentValue: Number(rentValue!),
        FromDate: moment.now() / 1000,
        PartyCode: party,
      };
    } else {
      dataToSend['Channel'] = this.channel;
    }
    return this.http
      .post<PolicyPremium>(environment.apiUrl + '/v1.0/home/quote', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMotorMake(): Observable<Array<Make>> {

    return this.http
      .post<Array<Make>>(environment.apiUrl + '/v1.0/constants/cars', {})
      .pipe(retry(1), catchError(this.handleError));


  }
  getBanks(): Observable<Array<string>> {
    return this.http
      .post<Array<string>>(environment.apiUrl + '/v1.0/constants/banks"', {})
      .pipe(retry(1), catchError(this.handleError));
  }

  getCountries(): Observable<Array<Country>> {
    return this.http
      .post<Array<Country>>(environment.apiUrl + '/v1.0/constants/countries"', {})
      .pipe(retry(1), catchError(this.handleError));
  }

  getNationalities(): Observable<Array<Nationality>> {
    return this.http
      .post<Array<Nationality>>(environment.apiUrl + '/v1.0/constants/nationalities"', {})
      .pipe(retry(1), catchError(this.handleError));
  }


  getMotorQuote(
    make: String | null,
    model: String | null,
    year: String | null,
    sum: String | null,
    plan: String | null,
    party: String | null,
    token: String | null,
    dataToSend: Record<string, any> | null | undefined): Observable<PolicyPremium> {

    if (dataToSend == null) {
      dataToSend = {
        "Channel": this.channel,
        "QuoteNumber": '',
        "FromDate": moment().unix(),
        "ToDate": moment().add(1, 'year').unix(),
        "SumInsured": Number(sum),
        "ModelYear": year,
        "MakeCode": make,
        "ModelCode": model,
        "ChasissNumber": "12345678901234567",
        "NCDFactor": "BRANDNEW",
        "Specification": "NORMAL",
        "Seats": 5,
        "PlateCategory": "PVT",
        "EngineSize": "2000",
        "VehicleCategory": "FMVHCL",
        "RegistrationNumber": "",
        "EngineNumber": "",
        "Color": "",
        "Cylinder": "",
        "Age": "0",
        "TypeCode": "P",
        "LenderCode": "",
        "BodyTypeCode": "SEDAN",
        "ModeOfBusiness": "NEW",
        "PlanName": plan,
        "IsBrandNew": true,
        "IsNCDApplicable": false,
        "IsModified": false,
        "IsMortgaged": false,
        "IsCoverNotePresent": false,
        "IsDeletedRisk": false,
        "IsExported": false,
        "IsCurrentlyInsured": false,
        "IsTPBreakInsurance": false,
        "IsBreakInsurance": false,
        "PreviousPolicyNumber": '',
        "PreviousPolicyToDate": '',
        "FirstRegistrationDate": '',
        "Covers": [],
        "PartyCode": party,
        "AllCovers": false
      };
    } else {
      dataToSend["QuoteNumber"] = "";

      dataToSend["Channel"] = this.channel;
      dataToSend["Specification"] = "NORMAL";
      dataToSend["Seats"] = 5;
      dataToSend["PlateCategory"] = "PVT";
      dataToSend["VehicleCategory"] = "FMVHCL";
      dataToSend["EngineNumber"] = "";
      dataToSend["Color"] = "";
      dataToSend["Cylinder"] = "";
      dataToSend["Age"] = "0";
      dataToSend["TypeCode"] = "P";
      dataToSend["BodyTypeCode"] = "SEDAN";
      dataToSend["IsNCDApplicable"] = false;
      dataToSend["IsModified"] = false;
      dataToSend["IsCoverNotePresent"] = false;
      dataToSend["IsDeletedRisk"] = false;
      dataToSend["IsExported"] = false;
      dataToSend["IsTPBreakInsurance"] = false;
      dataToSend["IsBreakInsurance"] = false;
    }

    return this.http.post<PolicyPremium>(environment.apiUrl + '/v1.0/motor/quote', dataToSend)
      .pipe(retry(1), catchError(this.handleError));
  }

}
