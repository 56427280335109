<app-header bg="bg-primary-50"></app-header>
<div class="w-full bg-primary-50 items-center min-h-[600px] " dir="auto">
  <div class="mx-auto w-full text-center mt-16 md:mt-16 container">
    <div class="lg:text-7xl md:text-7xl sm:text-[40px]  font-medium text-Dark-Gray-2">
      {{getLang == 'en' ? "Complaints" : "الشكاوي"}}
    </div>
    <p class="lg:text-xl md:text-xl sm:text-sm text-center lg:w-3/5 md:w-3/5 sm:w-full mx-auto text-Mid-Gray-1 pt-2"
      [ngClass]="getLang == 'ar' ? 'pt-11' : 'pt-11'">
      {{getLang == 'en' ?
      "We value our customers the most! But if you feel our service has fell short of your expectations,"
      +" tell us all about it by filling and submitting the form below."
      :
      "آراؤكم وملاحظاتكم تهمنا! نحن ملتزمون بتحسين"
      +" وتطوير خدماتنا باستمرار. لذا في حال عدم رضاكم عن خدماتنا الرجاء ارسال مقترحاتكم عبر النموذج التالي."}}
    </p>
  </div>
  <div class="lg:max-w-3xl md:max-w-2xl sm:max-w-md  mx-auto   absolute inset-x-0 z-10 container">
    <div class="mx-auto w-full h-full bg-white rounded-[10px] shadow-lg items-center p-6 mt-14  " id="box">
      <form #myform="ngForm" [formGroup]="ngForm" (ngSubmit)="Complaint(ngForm)">
        <div class="w-full">
          <p class="lg:text-3xl md:text-3xl sm:text-xl font-medium text-Dark-Gray-2">
            {{getLang == 'en' ? "Let us know what happened" : "زودنا بكافة التفاصيل"}}
          </p>
        </div>
        <div class="lg:flex md:flex  mt-14">
          <div class="flex-1 mt-5 sm:pl-0" [ngClass]="getLang == 'en' ? 'lg:pr-4 md:pr-4 ' : 'lg:pl-4 md:pl-4 '">
            <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              <span class="text-red-500" *ngIf="this.ngForm.get('fname').value == ''"
                style="color: rgb(252, 0, 0);">*</span>
              {{getLang == 'en' ? "First Name"
              : "الاسم الأول"}}</label>
            <div class="relative">
              <input type="email" id="fname" name="fnamme" formControlName="fname"
                [ngClass]="ngForm.get('fname').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                placeholder="e.g. John" aria-describedby="email-error" />
            </div>
            <mat-error *ngIf="myform.form.controls['fname']?.errors?.['required'] && isSubmitted">
              {{getLang == 'en' ? validations.data[1].attributes.required_en :
              validations.data[1].attributes.required_ar}}
            </mat-error>
          </div>
          <div class="flex-1 mt-5">
            <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              <span class="text-red-500" *ngIf="this.ngForm.get('lname').value == ''"
                style="color: rgb(252, 0, 0);">*</span>
              {{getLang == 'en' ? "Last Name"
              : "الاسم الأخير"}}
            </label>
            <div class="relative">
              <input type="email" id="lname" name="lname" formControlName="lname"
                [ngClass]="ngForm.get('lname').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                placeholder="e.g. Doe" aria-describedby="email-error" />
            </div>
            <mat-error *ngIf="myform.form.controls['fname']?.errors?.['required'] && isSubmitted">
              {{getLang == 'en' ? validations.data[1].attributes.required_en :
              validations.data[1].attributes.required_ar}}
            </mat-error>
          </div>
        </div>
        <div class="lg:flex md:flex mt-5">
          <div class="flex-1 mt-5 sm:pl-0" [ngClass]="getLang == 'en' ? 'lg:pr-4 md:pr-4 ' : 'lg:pl-4 md:pl-4 '">
            <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              <span class="text-red-500"
                *ngIf="this.ngForm.get('phone').value == '' || this.ngForm.get('phone').value == undefined "
                style="color: rgb(252, 0, 0);">*</span>
              {{getLang == 'en' ? "Mobile Number" : "رقم الهاتف"}}</label>
            <div class="relative">
              <ngx-mat-intl-tel-input
                class="h-10 py-2 px-4 country-selector block border border-Light-Gray-2   rounded-md text-sm  w-full"
                inputPlaceholder=""
                [ngClass]="myform.form.controls['phone'].errors?.['validatePhoneNumber'] || ngForm.get('phone').value == '' && isSubmitted ? 'errorInputed errorInput' : ''"
                formControlName="phone" [enablePlaceholder]="true" [enableSearch]="true"
                [preferredCountries]="['bh', 'sa', 'ae', 'kw']" name="phone" formControlName="phone"
                (ngModelChange)="onPhoneNumberChange($event)">
              </ngx-mat-intl-tel-input>
            </div>
            <mat-error *ngIf="ngForm.get('phone').value == '' && isSubmitted"> {{getLang == 'en' ?
              validations.data[1].attributes.required_en : validations.data[1].attributes.required_ar}}</mat-error>
            <mat-error *ngIf="myform.form.controls['phone']?.errors?.['validatePhoneNumber']">{{getLang == 'en' ?
              validations.data[1].attributes.error_en : validations.data[1].attributes.error_ar}}</mat-error>
          </div>
          <div class="flex-1 mt-5">
            <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              <span class="text-red-500" *ngIf="this.ngForm.get('email').value == ''"
                style="color: rgb(252, 0, 0);">*</span>
              {{getLang == 'en' ? "Email address" : "البريد الإلكتروني"}}</label>
            <div class="relative">
              <input type="email" id="email" name="email" formControlName="email"
                [ngClass]="myform.form.controls['email'].errors?.['email'] || myform.form.controls['email'].errors?.['required'] && isSubmitted ? 'errorInputed errorInput' : ''"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required
                placeholder="e.g name@example.com" aria-describedby="email-error" />
            </div>
            <mat-error *ngIf="myform.form.controls['email']?.errors?.['email']">
              {{getLang == 'en' ? validations.data[0].attributes.error_en : validations.data[0].attributes.error_ar}}
            </mat-error>
            <mat-error *ngIf="myform.form.controls['email']?.errors?.['required'] && isSubmitted">
              {{getLang == 'en' ? validations.data[0].attributes.required_en :
              validations.data[0].attributes.required_ar}}
            </mat-error>
          </div>
        </div>
        <div class="lg:flex md:flex mt-5">
          <div class="flex-1 mt-5 sm:pl-0" [ngClass]="getLang == 'en' ? 'lg:pr-4 md:pr-4 ' : 'lg:pl-4 md:pl-4 '">
            <label for="email" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ? "Product" :
              "المنتج"}}</label>
            <div class="relative">
              <select #prod id="product" name="model" (change)="selectProduct(prod.value)"
                class="py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm"
                [ngClass]="getLang == 'en' ? 'block' : 'hidden'">
                <option value="" disabled selected class="text-Mid-Gray-4">
                  Select
                </option>
                <option value="Motor">
                  <p>Motor</p>
                </option>
                <option value="Travel">
                  <p>Travel</p>
                </option>
                <option value="Home">
                  <p>Home</p>
                </option>
                <option value="Domestic Helper">
                  <p>Domestic helper</p>
                </option>
                <option value="Personal Accident">
                  <p>Personal Accident</p>
                </option>
                <option value="Personal Cyber">
                  <p>Personaly Cyber</p>
                </option>
                <option value="Expat Medical Visa">
                  <p>Expat Medical Visa</p>
                </option>
                <option value="Life">
                  <p>Life</p>
                </option>
                <option value="Other">
                  <p>Other</p>
                </option>
              </select>
              <select #prodAr id="product_ar" name="model" (change)="selectProduct(prodAr.value)"
                class="py-2 px-4 h-10 block w-full border border-Light-Gray-2 rounded-md text-sm"
                [ngClass]="getLang == 'ar' ? 'block' : 'hidden'">
                <option value="" disabled selected class="text-left text-Mid-Gray-4">
                  الإختيار
                </option>
                <option value="Motor">
                  <p>المركبات</p>
                </option>
                <option value="Travel">
                  <p>السفر</p>
                </option>
                <option value="Home">
                  <p>المنازل</p>
                </option>
                <option value="Domestic Helper">
                  <p>العمالة المنزلية</p>
                </option>
                <option value="Medical">
                  <p>التأمين الطبي</p>
                </option>
                <option value="Personal Accident">
                  <p>الحوادث الشخصية</p>
                </option>
                <option value="Personal Cyber">
                  <p>الـتأمين السيبراني</p>
                </option>
                <option value="Expat Medical Visa">
                  <p>التأمين الطبي للإقامة الذهبية</p>
                </option>
                <option value="Life">
                  <p>الحياة</p>
                </option>
                <option value="Other">
                  <p>اخرى</p>
                </option>
              </select>
            </div>
          </div>
          <div class="flex-1 mt-5">
            <label for="claimNumber" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              {{getLang == 'en' ? "Claim Number" : "رقم المطالبة"}}</label>
            <div class="relative">
              <input type="claimNumber" id="claimNumber" name="claimNumber" formControlName="claimNumber"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                placeholder="e.g 12345678" aria-describedby="email-error" />
            </div>
          </div>
        </div>
        <div class="lg:flex md:flex mt-5">
          <div class="flex-1 mt-5 sm:pl-0" [ngClass]="getLang == 'en' ? 'lg:pr-4 md:pr-4 ' : 'lg:pl-4 md:pl-4 '">
            <label for="polNumber" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              {{getLang == 'en' ? "Policy Number" : "رقم الوثيقة"}}</label>
            <div class="relative">
              <input type="polNumber" id="polNumber" name="polNumber" formControlName="polNumber"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                placeholder="e.g 12345678" aria-describedby="email-error" />
            </div>
          </div>
          <div class="flex-1 mt-5">
            <label for="vNumber" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">
              {{getLang == 'en' ? "Vehicle Number" : "رقم المركبة"}}</label>
            <div class="relative">
              <input type="number" id="vNumber" name="vNumber" formControlName="vNumber"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                placeholder="e.g 12345678" aria-describedby="email-error" />
            </div>
          </div>
        </div>
        <div class="flex mt-5">
          <div class="w-full lg:mt-5 md:mt-5">
            <label for="subject" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ? "Subject"
              : "الموضوع"}}</label>
            <div class="relative">
              <input type="text" id="subject" name="subject" formControlName="subject"
                class="h-10 py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                placeholder="{{getLang == 'en' ? 'Title of what your message is about' : 'موضوع الرسالة'}}"
                aria-describedby="subject-error" />
            </div>
          </div>
        </div>
        <div class="flex mt-5">
          <div class="w-full lg:mt-5 md:mt-5">
            <label for="message" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ? "Message"
              : "الرسالة"}}</label>
            <div class="relative">
              <textarea type="text" id="message" name="message" formControlName="msg"
                class="py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
                placeholder="{{getLang == 'en' ? 'Type your message here': 'اكتب رسالتك هنا'}}"
                aria-describedby="message-error"></textarea>
              <!-- <textarea type="text" id="message" name="message"  formControlName="msg"
            class="py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm " required placeholder="اكتب رسالتك هنا"
            aria-describedby="message-error" [ngClass]="getLang == 'ar' ? 'block' : 'hidden'"></textarea> -->
              <!-- <input
              type="text"
              id="message"
              name="message"
              class="py-2 px-4 block w-full border border-Light-Gray-2 rounded-md text-sm "
              required
              aria-describedby="message-error"
            /> -->
            </div>
            <mat-error *ngIf="myform.form.controls['msg']?.errors?.['required'] && isSubmitted">
              {{getLang == 'en' ? validations.data[1].attributes.required_en :
              validations.data[1].attributes.required_ar}}
            </mat-error>
          </div>
        </div>
        <div class="flex mt-5">
          <div class="w-full mt-5">
            <label for="message" class="block text-sm font-normal text-Dark-Gray-2 mb-2 ">{{getLang == 'en' ?
              "Attachments" : "المرفقات"}}</label>
            <div class="flex justify-center items-center w-full">
              <label for="dropzone-file"
                class="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600  border-Light-Gray-2 text-sm ">
                <div *ngIf="!loading && imageUrl == ''" class="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5 18.9998C5 18.7346 5.10536 18.4802 5.29289 18.2927C5.48043 18.1051 5.73478 17.9998 6 17.9998H18C18.2652 17.9998 18.5196 18.1051 18.7071 18.2927C18.8946 18.4802 19 18.7346 19 18.9998C19 19.265 18.8946 19.5194 18.7071 19.7069C18.5196 19.8944 18.2652 19.9998 18 19.9998H6C5.73478 19.9998 5.48043 19.8944 5.29289 19.7069C5.10536 19.5194 5 19.265 5 18.9998ZM8.293 8.70679C8.10553 8.51926 8.00021 8.26495 8.00021 7.99979C8.00021 7.73462 8.10553 7.48031 8.293 7.29279L11.293 4.29279C11.4805 4.10532 11.7348 4 12 4C12.2652 4 12.5195 4.10532 12.707 4.29279L15.707 7.29279C15.8892 7.48139 15.99 7.73399 15.9877 7.99619C15.9854 8.25838 15.8802 8.5092 15.6948 8.6946C15.5094 8.88001 15.2586 8.98518 14.9964 8.98746C14.7342 8.98974 14.4816 8.88894 14.293 8.70679L13 7.41379V14.9998C13 15.265 12.8946 15.5194 12.7071 15.7069C12.5196 15.8944 12.2652 15.9998 12 15.9998C11.7348 15.9998 11.4804 15.8944 11.2929 15.7069C11.1054 15.5194 11 15.265 11 14.9998V7.41379L9.707 8.70679C9.51947 8.89426 9.26516 8.99957 9 8.99957C8.73484 8.99957 8.48053 8.89426 8.293 8.70679Z"
                      fill="#B0BABF" />
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-medium">Drag and drop
                      files here</span> </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">(max file size: 2MB)</p>
                  <a type="file" class="border p-2 mt-2  border-Light-Gray-2 rounded-md text-sm ">Browse</a>
                </div>
                <div class="my-4" *ngIf="loading">
                  <div class="spinner-border text-gray-500"></div>
                  <div class="ml-2 text-gray-500">Loading...</div>
                </div>
                <div class="" *ngIf="imageUrl">
                  <img [src]="imageUrl" alt="Uploaded Image" class="max-w-xs mx-auto h-40">
                </div>
                <input id="dropzone-file" type="file" class="hidden" (change)="onFileSelected($event)" />
              </label>
            </div>
          </div>
        </div> <br>
        <div class="flex mt-2 px-2 items-center justify-center">
          <re-captcha (resolved)="resolved($event)"></re-captcha>
        </div>
        <div class="flex mt-12">
          <div class="flex-1">
            <div class="w-full px-3 mb-5">
              <button type="submit"
                [disabled]="this.ngForm.get('fname').value == '' || this.ngForm.get('lname').value == '' || this.ngForm.get('phone').value == '' || this.ngForm.get('email').value == '' || this.ngForm.get('phone').value == '' || this.ngForm.get('phone').value == undefined || loading || !captcharesolved"
                class="button block w-full bg-primary-500 text-Light-Gray-5 rounded-md px-3 py-3 font-medium text-lg ">
                {{ !this.btnLoading ? getLang == 'en' ? "Send Message" : "ارسال" : ''}}
                <div role="status" *ngIf="this.btnLoading" class="flex justify-center items-center">
                  <svg aria-hidden="true" class=" mr-2 w-6 h-7 text-white animate-spin dark:text-white fill-white"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"></path>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- <div class="container mx-auto px-0 lg:max-w-7xl md:max-w-2xl sm:max-w-md" dir="auto"> -->
    <div id="documents">
      <!-- Header Section -->
      <div class="mt-10">
        <div class="flex pt-10">
          <span>
            <svg class="sm:h-[30px] sm:w-[30px] md:h-[40px] md:w-[40px] lg:h-[40px] lg:w-[40px]" viewBox="0 0 40 40"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2768_47354)">
                <path
                  d="M6.66668 9.99967H3.33334V33.333C3.33334 35.1663 4.83334 36.6663 6.66668 36.6663H30V33.333H6.66668V9.99967ZM33.3333 3.33301H13.3333C11.5 3.33301 10 4.83301 10 6.66634V26.6663C10 28.4997 11.5 29.9997 13.3333 29.9997H33.3333C35.1667 29.9997 36.6667 28.4997 36.6667 26.6663V6.66634C36.6667 4.83301 35.1667 3.33301 33.3333 3.33301ZM33.3333 26.6663H13.3333V6.66634H33.3333V26.6663ZM16.6667 14.9997H30V18.333H16.6667V14.9997ZM16.6667 19.9997H23.3333V23.333H16.6667V19.9997ZM16.6667 9.99967H30V13.333H16.6667V9.99967Z"
                  fill="#84919A" />
              </g>
              <defs>
                <clipPath id="clip0_2768_47354">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <p class="font-medium text-Dark-Gray-2 lg:text-3xl md:text-3xl sm:text-xl ltr:ml-2 rtl:mr-2">
            {{ getLang == 'en' ? this.product!.data.attributes.title_en : this.product!.data.attributes.title_ar }}
          </p>
        </div>
      </div>

      <!-- Documents Grid -->
      <div class="mt-5">
        <ul class="grid lg:grid-cols-2 gap-4 list-none">
          <li class="text-Dark-Gray-2 font-normal text-[16px] mt-3"
            *ngFor="let document of this.product!.data.attributes.documents">
            <a [href]="getLang == 'en' ? this.strapiApi.apiURL + document.doc_en.data.attributes.url : this.strapiApi.apiURL + document.doc_ar.data.attributes.url"
              target="_blank">
              <div class="p-5 block w-full max-w-[336px] border border-Light-Gray-3 rounded-md">
                <div class="flex justify-between items-center">
                  <div class="flex items-center">
                    <span>
                      <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.5 36V4C0.5 2.067 2.067 0.5 4 0.5H17.9C18.7987 0.5 19.663 0.845712 20.3138 1.46552L28.4138 9.1798C29.1074 9.8404 29.5 10.7564 29.5 11.7143V36C29.5 37.933 27.933 39.5 26 39.5H4C2.067 39.5 0.5 37.933 0.5 36Z"
                          fill="white" stroke="#E5E9EB" />
                        <path
                          d="M5.77156 24V16.7273H8.77582C9.32033 16.7273 9.79026 16.8338 10.1856 17.0469C10.5833 17.2576 10.8899 17.5523 11.1054 17.9311C11.3208 18.3075 11.4285 18.7455 11.4285 19.245C11.4285 19.7469 11.3184 20.1861 11.0983 20.5625C10.8805 20.9366 10.5691 21.2266 10.1643 21.4325C9.75949 21.6385 9.2789 21.7415 8.72255 21.7415H6.86886V20.3565H8.39585C8.661 20.3565 8.88235 20.3104 9.05991 20.218C9.23984 20.1257 9.37596 19.9967 9.46829 19.831C9.56062 19.6629 9.60679 19.4676 9.60679 19.245C9.60679 19.0201 9.56062 18.826 9.46829 18.6626C9.37596 18.4969 9.23984 18.3691 9.05991 18.2791C8.87999 18.1892 8.65863 18.1442 8.39585 18.1442H7.52937V24H5.77156ZM14.9232 24H12.235V16.7273H14.9197C15.6607 16.7273 16.2987 16.8729 16.8337 17.1641C17.3711 17.4529 17.7854 17.8696 18.0766 18.4141C18.3678 18.9562 18.5134 19.6049 18.5134 20.3601C18.5134 21.1177 18.3678 21.7687 18.0766 22.3132C17.7878 22.8577 17.3747 23.2756 16.8373 23.5668C16.2999 23.8556 15.6618 24 14.9232 24ZM13.9928 22.5014H14.8557C15.2629 22.5014 15.6074 22.4328 15.8891 22.2955C16.1732 22.1558 16.3875 21.9297 16.5319 21.6172C16.6787 21.3023 16.752 20.8833 16.752 20.3601C16.752 19.8369 16.6787 19.4202 16.5319 19.1101C16.3851 18.7976 16.1685 18.5727 15.882 18.4354C15.5979 18.2957 15.2475 18.2259 14.8309 18.2259H13.9928V22.5014ZM19.4797 24V16.7273H24.4442V18.1548H21.2375V19.6463H24.1281V21.0774H21.2375V24H19.4797Z"
                          fill="#F76659" />
                      </svg>
                    </span>
                    <div class="grid grid-rows-2 grid-cols-1 gap-1 rtl:mr-1 ltr:ml-1"
                      [ngClass]="getLang == 'ar' ? 'pr-3' : ''">
                      <p class="font-medium text-Dark-Gray-2 text-sm ltr:ml-2 rtl:mr-2">
                        {{ getLang == 'en' ? document.doc_title_en : document.doc_title_ar }}
                      </p>
                      <p class="font-medium text-Mid-Gray-3 text-xs ltr:ml-2 rtl:mr-2">
                        {{ getLang == 'en' ? document.doc_en.data.attributes.size : document.doc_ar.data.attributes.size
                        }} kb
                      </p>
                    </div>
                  </div>
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="#252C32" />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- </div> -->
  </div>
</div>
<!-- <br> <br> <br> <br> <br> <br> -->

<div class=" mx-auto lg:pt-[1300px] md:pt-[1300px] sm:pt-[1550px]" id="content" dir="auto">
  <div class="pb-20 w-full bg-white">
    <div class="mx-auto w-full text-center mt-28 lg:container md:container">
      <div class="lg:text-6xl md:text-6xl sm:text-3xl font-medium text-Dark-Gray-2">
        {{getLang == 'en' ? "Solidarity near you!" : "فرع سوليدرتي الأقرب لك!"}}
      </div>
      <p class="lg:text-xl md:text-xl sm:text-sm text-center lg:w-3/5 md:w-3/5 sm:w-4/5 mx-auto text-Mid-Gray-1 pt-4">
        {{getLang == 'en' ? "Want to visit a Solidarity branch? Use the map below to find the nearest one to you." :
        "تعرف على أقرب فرع لك عبر الاطلاع على الخارطة التالية:"}}
      </p>
    </div>

    <app-branches></app-branches>
  </div>
</div>

<app-coverd-section></app-coverd-section>
<app-footer></app-footer>
<div class="overlay" *ngIf="sentMessage">
  <div class="popup">
    <h3 class="popup-title">Message sent successfully!</h3>
    <!-- <div class="popup-message">
      <p>Your message:</p>
      
    </div> -->
    <button class="button popup-button" (click)="messageSent()">OK</button>
  </div>
</div>