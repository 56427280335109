import { Component, Input, OnInit } from '@angular/core';
import { size } from 'lodash-es';
import { Cover } from 'src/app/models/cover';
import { PolicyPremium } from 'src/app/models/policy-premium';
import { Quote } from 'src/app/models/quote';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-addons-screen',
  templateUrl: './addons-screen.component.html',
  styleUrls: ['./addons-screen.component.css']
})
export class AddonsScreenComponent implements OnInit {

  premium: Number = 0.000;
  totalPremium: Number = 0.000;
  tax : number = 0.000;
  changed:boolean = false;
  
  @Input() callbackFunction?: (args: any) => void;
  @Input() quote?: PolicyPremium ;

 coversList : Array<Cover> = [];
 coversToBeShown : Array<Cover> = [];


  constructor(    private webApi: WebapiService,
    ) {
      this.premium = this.quote?.Premium!;
      this.tax = this.quote?.Tax!;
      this.totalPremium = this.quote?.TotalPremium!;
     }

  ngOnInit(): void {
  }
  coverSelectedCallbackFunction = (args: Cover): void => {

    }
    nextClicked(){
      if(this.changed){
       
      }else{
        this.callbackFunction!(this.quote);
      }
      

    }
    // addCovers() {
    //   this.coversList = [];
    //   if (this.quote!.QuoteDetails!.PlanName == "PL") {
    //     // this.coversList.add(ReplacementCarCover(
    //     //     name: "Replacement Car (15 Days)",
    //     //     code: "CMOT28",
    //     //     optionValue: CarSizeDays(
    //     //         days: CarDays.fifteen,
    //     //         size: CarSize.small,
    //     //         optionTitle: "SMALL15".tr(),
    //     //         optionValue: "SMALL15"),
    //     //     mandatory: false,
    //     //     inPlan: true,
    //     //     minDays: CarDays.fifteen,
    //     //     selected: true));
    //     this.coversList.push( {
    //       coverCode: "CMOT16",
    //       coverName: "RoadSide Assistance",
    //       Descreption: "",
    //       Selected: false,
    //       Disabled: true,
    //       Value: "",
    //       InPlan: false,
    //       Mandatory: false,
    //       Type: "",
    //       Price: 0,
    //       coverProperty: []
    //     });
    //   } else {
    //     // this.coversList.add(ReplacementCarCover(
    //     //     name: "Replacement Car",
    //     //     code: "CMOT28",
    //     //     optionValue: CarSizeDays(
    //     //         days: CarDays.eight,
    //     //         size: CarSize.small,
    //     //         optionTitle: "SMALL8".tr(),
    //     //         optionValue: "SMALL8"),
    //     //     mandatory: false,
    //     //     inPlan: false,
    //     //     selected: false));
    //     //     this.coversList.push( {
    //     //       coverCode: "CMOT16",
    //     //       coverName: "RoadSide Assistance",
    //     //       Descreption: "",
    //     //       Selected: false,
    //     //       Disabled: false,
    //     //       Value: "",
    //     //       InPlan: false,
    //     //       Mandatory: false,
    //     //       Type: "",
    //     //       Price: 0,
    //     //       coverProperty: []
    //     //     });
     
    //   }
    //   this.coversList.push( {
    //     coverCode: "CMOT9",
    //     coverName: "Act of God",
    //     Descreption: "",
    //     Selected: false,
    //     Disabled: false,
    //     Value: "",
    //     InPlan: false,
    //     Mandatory: false,
    //     Type: "",
    //     Price: 0,
    //     coverProperty: []
    //   });
    //   this.coversList.push( {
    //     coverCode: "CMOT11",
    //     coverName: "Windshield Cover",
    //     Descreption: "",
    //     Selected: false,
    //     Disabled: false,
    //     Value: "",
    //     InPlan: false,
    //     Mandatory: false,
    //     Type: "",
    //     Price: 0,
    //     coverProperty: []
    //   });this.coversList.push( {
    //     coverCode: "CMOT13",
    //     coverName: "VIP",
    //     Descreption: "",
    //     Selected: false,
    //     Disabled: false,
    //     Value: "",
    //     InPlan: false,
    //     Mandatory: false,
    //     Type: "",
    //     Price: 0,
    //     coverProperty: []
    //   });
      
    //   this.coversList.push( {
    //     coverCode: "CMOT99",
    //     coverName: "Depreciation on parts",
    //     Descreption: "",
    //     Selected: false,
    //     Disabled: false,
    //     Value: "",
    //     InPlan: false,
    //     Mandatory: false,
    //     Type: "",
    //     Price: 0,
    //     coverProperty: []
    //   });
    //   this.coversList.push( {
    //     coverCode: "CMOT61",
    //     coverName: "RSMD",
    //     Descreption: "",
    //     Selected: false,
    //     Disabled: false,
    //     Value: "",
    //     InPlan: false,
    //     Mandatory: false,
    //     Type: "",
    //     Price: 0,
    //     coverProperty: []
    //   });
    //   this.coversList.push( {
    //     coverCode: "CMOT40",
    //     coverName: "PA Benefit to Driver",
    //     Descreption: "",
    //     Selected: false,
    //     Disabled: false,
    //     Value: "",
    //     InPlan: false,
    //     Mandatory: false,
    //     Type: "",
    //     Price: 0,
    //     coverProperty: []
    //   });


     

   
    //   // this.coversList.add(PolicyCover(
    //   //     code: "CMOT24",
    //   //     name: "Geographical extenion",
    //   //     description: "",
    //   //     selected: false,
    //   //     value: "",
    //   //     inPlan: false,
    //   //     mandatory: false,
    //   //     optionsTitle: "",
    //   //     options: [
    //   //       PolicyCoverOption(
    //   //           name: "ksa".tr(),
    //   //           value: "KSA",
    //   //           price: (policyPremium!.sumInsured! * (0.2 / 100) > 25
    //   //               ? policyPremium!.sumInsured! * (0.2 / 100)
    //   //               : 25),
    //   //           selected: true),
    //   //       PolicyCoverOption(
    //   //           name: "GCC".tr(),
    //   //           value: "GCC",
    //   //           price: (policyPremium!.sumInsured! * (0.5 / 100) > 35
    //   //               ? policyPremium!.sumInsured! * (0.5 / 100)
    //   //               : 35),
    //   //           selected: false)
    //   //     ],
    //   //     type: ""));
    // }

}
