import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'online';

  get dataV(): any {
    return localStorage.getItem('lang'); //Get Global Variable Value
  }
  delete()
  {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr()
  {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn()
  {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }
}
