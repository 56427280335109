import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Validations } from 'src/app/models/validations';
import { WebapiService } from 'src/app/services/webapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pa-inputs',
  templateUrl: './pa-inputs.component.html',
  styleUrls: ['./pa-inputs.component.css']
})
export class PaInputsComponent implements OnInit {

  isSubmitted = false;
  validateEmail = true;
  validations!: Validations;
  ngForm: any;
  loading: boolean = false;
  accepted: boolean = false;
  captcharesolved = false;
  sumInsured: string | undefined | null;
  constructor(public webApi: WebapiService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(async (params) => {

      this.validations = params['val'];
    });
    this.ngForm = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      sumInsured: new FormControl(null),
      phone: new FormControl(''),
      date: new FormControl(''),
      age: new FormControl(null),
    });
  }

  checking() {
    this.isSubmitted = true;
  }

  onCheckboxChangeAccepted(event: any) {
    if (event.target.checked) {
      this.accepted = true;
    } else {
      this.accepted = false;
    }
  }

  resolved(captchaResponse: string) {
    // Send the captcha response to your server for verification
    // console.log(`Resolved captcha with response ${captchaResponse}`);
    this.captcharesolved = true;
  }

  maxAge() {
    let today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var newDate = new Date(year - 70, month, day)
    return this.dateToString(newDate);
  }

  minAge() {
    let today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var newDate = new Date(year - 18, month, day)
    return this.dateToString(newDate);
  }

  dateToString(date: any) {
    const toTwoDigits = (num: string | number) => ((+num) < 10 ? "0" + num : num);
    let year = date.getFullYear();
    let month = toTwoDigits(date.getMonth() + 1);
    let day = toTwoDigits(date.getDate());
    return `${year}-${month}-${day}`;
  }

  getQuote(form: NgForm): void {
    // console.log(form);
    this.loading = true;
    if (form.valid) {
      // console.log(form);
      this.webApi.getHomeQuote(form.value["sumInsured"], "0", "0", "0", "0", "FALCON", null, null).subscribe((s) => {
        this.router.navigate(['quote/home'], { state: { quote: s } });
      });
    }
  }

  get getLang(): any {
    return localStorage.getItem('lang') ?? "en"; //Get Global Variable Value
  }
  delete() {
    localStorage.removeItem('lang'); //Delete Global Variable
  }
  getAr() {
    localStorage.setItem('lang', 'ar'); //Set Global Variable
  }
  getEn() {
    localStorage.setItem('lang', 'en'); //Set Global Variable
  }

  sendLead() {
    this.loading = true;
    if (this.ngForm.valid) {
      this.webApi.sendProductLead(
        this.ngForm.get('name').value.toString(),
        this.ngForm.get('phone').value.toString(),
        'GENERAL ACCIDENT',
        'New Insurance - Personal Accident',
        this.ngForm.get('email').value.toString(),
        1,
        1,
        'New PA Insurance Lead',
        '',
        null
      ).subscribe((s) => {

        this.goToPortalPA();

      });
    }
  }
  goToPortalPA() {
    const inputEmail = this.ngForm.get('email').value;
    const inputPhone = this.ngForm.get('phone').value;
    const inputName = this.ngForm.get('name').value;
    const inputSumInsured = this.ngForm.get('sumInsured').value;
    const inputDateOfBirth = new Date(this.ngForm.get('date').value);

    const data = {
      email: inputEmail,
      phone: inputPhone,
      name: inputName,
      sumInsured: inputSumInsured,
      dateOfBirth: inputDateOfBirth
    }

    const secretKey = 'Solid@abu1999';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();

    const siteToGoTo = environment.angularUrl + '/insurance-policies/personal-accident?react=true'
    window.location.href = `${siteToGoTo}/?data=${encodeURIComponent(encryptedData)}`;
  }

}
